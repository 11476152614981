import React from 'react'

import { PropTypes } from 'prop-types'

import { customProductPropType, invoicePropType, productPropType, projectPropType } from '../../elements/PropTypes'
import CustomProductItems from '../products/CustomProductItems'

export default function InvoiceForm ({ project, invoice, customProducts, setCustomProducts, products, productGroups, resetProducts, amountCols, session }) {
  return (
        <CustomProductItems
            invoice={invoice}
            customProducts={customProducts}
            setCustomProducts={setCustomProducts}
            session={session}
            customer={project.customer_obj}
            products={products}
            project={project}
            productGroups={productGroups}
            amountCols={amountCols}
            resetProducts={resetProducts}
            isDraggable={true}
            showDescriptions={true}
            stage={'invoice'}
        />
  )
}

InvoiceForm.propTypes = {
  project: projectPropType,
  invoice: invoicePropType,
  customProducts: PropTypes.arrayOf(customProductPropType),
  setCustomProducts: PropTypes.func,
  products: PropTypes.arrayOf(productPropType),
  productGroups: PropTypes.array,
  resetProducts: PropTypes.func,
  amountCols: PropTypes.object,
  session: PropTypes.object
}
