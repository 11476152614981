import React, { Fragment, useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { Container, Row } from 'reactstrap'

import { IconButton, Typography } from '@mui/material'

import AssignTool from '../../elements/AssignTool'
import Icon from '../../elements/Icon'
import { date2FormattedString, date2String, filterInPlace, hasPermission, hexTextColor } from '../../elements/utils'
import { API_URL_CONSTRUCTION, API_URL_TASK, API_URL_PROJECT, API_URL_USER, API_URL_CONSTRUCTIONEMPLOYEES, API_URL_TASKEMPLOYEES } from '../../settings'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { getConstructionFixedEmployeeIds, constructionType2ProjectType } from '../project_types/projectUtils'
import TaskFormModal from '../tasks/TaskFormModal'
import ConstructionModal from '../project_types/ConstructionModal'

export default function EmployeeAssignmentModal ({ date, resetParent, session, filter }) {
  // const [loadingElements, setLoadingElements] = useState({
  //   inProgress: false, submitError: false
  // })
  const [assignments, setAssignments] = useState(null)
  const [employees, setEmployees] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  // modals: add task / edit task / edit project
  const [isAddTask, setIsAddTask] = useState(false)
  const [chosenTask, setChosenTask] = useState(null)
  const [chosenProject, setChosenProject] = useState(null)

  // const constructions = useRef(null)
  // const tasks = useRef(null)

  const lastUpdateTime = useRef(null)

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOpen && lastUpdateTime.current && ((new Date() - lastUpdateTime.current) > 15000)) {
        reloadEvents()
      }
    }, 20000)

    return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [isOpen])

  const updateConstructionAssignments = (_assignments, constructions) => {
    constructions.forEach((c) => {
      const assignment = _assignments.find(a => a.eventType === 'construction' && a.event.id === c.id)
      if (assignment) {
        assignment.employees = c.employees
        assignment.name = `Baustelle ${c.customer_name} ${c.street_and_number}`
        assignment.event = c
      } else {
        _assignments.push({ id: `construction-${c.id}`, event: c, employees: c.employees, eventType: 'construction', name: `Baustelle ${c.customer_name} ${c.street_and_number}` })
      }
    })
    const constructionIds = constructions.map(c => c.id)
    filterInPlace(_assignments, a => a.eventType !== 'construction' || constructionIds.includes(a.event.id))
    return _assignments
  }

  const updateTaskAssignments = (_assignments, tasks) => {
    tasks.forEach((t) => {
      const assignment = _assignments.find(a => a.eventType === 'task' && a.event.id === t.id)
      if (assignment) {
        assignment.employees = t.employees
        assignment.name = `Aufgabe ${t.title}`
        assignment.event = t
      } else {
        _assignments.push({ id: `task-${t.id}`, event: t, employees: t.employees, eventType: 'task', name: `Aufgabe ${t.title}` })
      }
    })
    const taskIds = tasks.map(t => t.id)
    filterInPlace(_assignments, a => a.eventType !== 'task' || taskIds.includes(a.event.id))
    return _assignments
  }

  const loadData = () => {
    reloadEvents()
    getEmployees()
  }

  const resetState = () => {
    reloadEvents()
    if (resetParent) resetParent()
  }

  const reloadEvents = () => {
    lastUpdateTime.current = new Date()
    Promise.all([
      getConstructions(),
      getTasks()
    ]).then(([constructions, tasks]) => {
      setAssignments(_assignments => [
        ...updateTaskAssignments((_assignments || []).filter(a => a.eventType === 'task'), tasks),
        ...updateConstructionAssignments((_assignments || []).filter(a => a.eventType === 'construction'), constructions)
      ])
    })
  }

  const getConstructions = async () => {
    const response = await axios.get(API_URL_CONSTRUCTION, { params: { constructiondates__date: date2String(date) } })
    let constructions = response.data
    // Apply filtering based on the filter object
    if (filter.projectType) constructions = constructions.filter(c => constructionType2ProjectType(c.resourcetype) === filter.projectType)
    if (filter.employee) constructions = constructions.filter(c => c.employees.includes(filter.employee))
    if (filter.location) constructions = constructions.filter(c => c.planville_location === filter.location)
    if (filter.postalCode) constructions = constructions.filter(c => c.zip_and_city.startsWith(filter.postalCode))

    return constructions
  }

  const getTasks = async () => {
    const response = await axios.get(API_URL_TASK, { params: { date: date2String(date) } })
    let tasks = response.data
    // Apply filtering based on the filter object
    if (filter.projectType) tasks = tasks.filter(t => t.project_obj && constructionType2ProjectType(t.project_obj.resourcetype) === filter.projectType)
    if (filter.employee) tasks = tasks.filter(t => t.employees.includes(filter.employee))
    if (filter.taskType) tasks = tasks.filter(t => t.task_type === filter.taskType)
    if (filter.location) tasks = tasks.filter(t => t.project_planville_location && t.project_planville_location === filter.location)
    if (filter.postalCode) tasks = tasks.filter(t => t.address && t.address.includes(filter.postalCode))

    return tasks
  }

  const getEmployees = async () => {
    return axios.get(API_URL_USER, { params: { is_staff: true, is_active: true, visible: true, date: date2String(date) } })
      .then(res => {
        const employees = res.data.filter(user => {
          if (!user || !user.group_obj) return false

          return user.group_obj.is_construction_site_group
        })
        setEmployees(employees)
      })
  }

  const getOpenButton = (toggle) => {
    return <IconButton size="small" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={toggle}><Icon icon='task' style={{ color: '#424242' }} fontSize='small' /><Typography className='secondary-textcolor'>Mitarbeiterzuordnung</Typography></IconButton>
  }

  const getFooter = (toggle) => {
    return <SaveModalFooter
      // submitError={loadingElements.submitError}
      // inProgress={loadingElements.inProgress}
      onCancel={toggle}
      cancelBtnLabel='Schließen'
      onSave={() => setIsAddTask(true)}
      saveBtnLabel='Neue Aufgabe'
    />
  }

  const getEmployeeStyle = (employee) => {
    const color = employee.group_color ? employee.group_color : '#f8f8ff'
    return { background: employee.available ? color : `repeating-linear-gradient(-45deg, #a4a4a4, #a4a4a4 1px, ${color} 1px, ${color} 27.5px)`, color: hexTextColor(color) }
  }

  const getItemsFromAssignment = (assignment, employees) => {
    const items = []
    const fixedEmployeeIds = assignment.eventType === 'task' ? [assignment.event.responsible].filter(x => x) : getConstructionFixedEmployeeIds(assignment.event)
    assignment.employees.forEach(eId => {
      const employee = employees.find(e => e.id === eId)
      if (employee) {
        const item = { number: employee.n_assignments, id: eId, name: employee.name, style: getEmployeeStyle(employee), role: employee.group_key }
        if (assignment.eventType === 'construction' && (fixedEmployeeIds.includes(item.id))) item.fixed = true
        items.push(item)
      }
    })
    return items
  }

  const countEmployeeAssignments = (employees, assignments) => {
    employees.forEach(e => { e.n_assignments = 0 })
    assignments.forEach(assignment => {
      assignment.employees.forEach(eId => {
        const employee = employees.find(e => e.id === eId)
        if (employee) employee.n_assignments += 1
      })
    })
  }

  const getListsFromAssignments = (assignments) => {
    countEmployeeAssignments(employees, assignments)
    const lists = assignments.map(assignment => {
      return {
        id: assignment.id,
        name: assignment.name,
        items: getItemsFromAssignment(assignment, employees),
        assignment,
        onClick: () => {
          if (assignment.eventType === 'construction') axios.get(API_URL_PROJECT + assignment.event.project, { params: { nested: true } }).then(res => setChosenProject(res.data))
          else setChosenTask(assignment.event)
        }
      }
    })
    const sortedEmployees = [...employees]
    sortedEmployees.sort((a, b) => a.n_assignments - b.n_assignments)
    return [lists, sortedEmployees.map(e => ({ id: e.id, number: e.n_assignments, name: e.name, tentative: !e.available, style: getEmployeeStyle(e), role: e.group_key }))]
  }

  const _removeItemFromList = (item, lst) => {
    const assignment = lst.assignment
    const promise = (assignment.eventType === 'construction')
      ? axios.delete(API_URL_CONSTRUCTIONEMPLOYEES, { params: { construction: assignment.event.id, employee: item.id } })
      : axios.delete(API_URL_TASKEMPLOYEES, { params: { task: assignment.event.id, employee: item.id } })
    return promise
  }

  const _addItemToList = (item, lst) => {
    const assignment = lst.assignment
    const promise = (assignment.eventType === 'construction')
      ? axios.post(API_URL_CONSTRUCTIONEMPLOYEES, null, { params: { construction: assignment.event.id, employee: item.id } })
      : axios.post(API_URL_TASKEMPLOYEES, null, { params: { task: assignment.event.id, employee: item.id } })
    return promise
  }

  const removeItemFromList = (item, lst) => {
    _removeItemFromList(item, lst).then(resetState)
  }

  const addItemToList = (item, lst) => {
    _addItemToList(item, lst).then(resetState)
  }

  const moveItemBetweenLists = (item, srcLst, dstLst) => {
    Promise.all([
      _removeItemFromList(item, srcLst),
      _addItemToList(item, dstLst)
    ]).then(resetState)
  }

  const [lists, allItems] = assignments ? getListsFromAssignments(assignments) : [null, null]

  return (
    <CustomModal size='fullscreen' title={date ? `Mitarbeiterzuordnung ${date2FormattedString(date)}` : 'Mitarbeiterzuordnung'} getFooter={getFooter} getOpenButton={getOpenButton} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Fragment>
        <Container style={{ margin: 0 }}>
          <Row>
            {assignments && <AssignTool
              allItems={allItems}
              lists={lists}
              disabled={!hasPermission(session.user, 'task_assignment')}
              addItemToList={addItemToList}
              moveItemBetweenLists={moveItemBetweenLists}
              removeItemFromList={removeItemFromList}
            />}
          </Row>
          <br />
          <Row></Row>
        </Container>
        <ConstructionModal
          project={chosenProject}
          chosenDate={date}
          resetParent={resetState}
          session={session}
          isOpen={!!chosenProject}
          setIsOpen={(isOpen) => { if (!isOpen) setChosenProject(null) }}
        />
        <TaskFormModal
          isOpen={isAddTask}
          setIsOpen={(isOpen) => { if (!isOpen) { setIsAddTask(false) } }}
          resetParent={resetState}
          session={session}
          date={date}
        />
        <TaskFormModal
          task={chosenTask}
          date={date}
          isOpen={!!chosenTask}
          setIsOpen={(isOpen) => { if (!isOpen) { setChosenTask(null) } }}
          resetParent={resetState}
          session={session}
        />
      </Fragment>
    </CustomModal>)
}

EmployeeAssignmentModal.propTypes = {
  date: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  filter: PropTypes.object
}
