import { getEmptyFieldsError } from '../../../elements/utils'
import { emptyProjectForm } from './forms'
import { additionalPlantProjectFieldCheck, checkIfPlantProjectFieldIsEmpty, emptyPlantProjectForm } from './plant_projects/forms'
import { additionalHeatpumpProjectFieldCheck, checkIfHeatpumpProjectFieldIsEmpty, emptyHeatpumpProjectForm } from './heatpump_project/forms'

export const dataInputNeeded = (project) => {
  return project.resourcetype === 'PlantProject' || project.resourcetype === 'HeatpumpProject'
}

export const getEmptyProjectForm = (project) => {
  let form = emptyProjectForm
  if (project.resourcetype === 'PlantProject') form = emptyPlantProjectForm
  if (project.resourcetype === 'HeatpumpProject') form = emptyHeatpumpProjectForm
  return { ...form, resourcetype: project.resourcetype }
}

export const checkIfProjectFieldIsEmpty = (project) => {
  if (project.resourcetype === 'PlantProject') return checkIfPlantProjectFieldIsEmpty
  if (project.resourcetype === 'HeatpumpProject') return checkIfHeatpumpProjectFieldIsEmpty
  return null
}

export const getEmptyProjectFieldsError = (project) => {
  let emptyFieldsError = getEmptyFieldsError(project, getEmptyProjectForm(project), [], checkIfProjectFieldIsEmpty)
  if (project.resourcetype === 'PlantProject') emptyFieldsError = emptyFieldsError || additionalPlantProjectFieldCheck(project)
  if (project.resourcetype === 'HeatpumpProject') emptyFieldsError = emptyFieldsError || additionalHeatpumpProjectFieldCheck(project)
  return emptyFieldsError
}
