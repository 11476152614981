/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Col, Container, Row, Input, FormGroup } from 'reactstrap'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'

// mui packages
import { Typography } from '@mui/material'

// files
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import CustomModal from '../shared/modal_utils/CustomModal'
import DropDown from '../../elements/DropDown'
import { CustomButton } from '../../elements/StyledElements'
import { arrSum, date2FormattedString, date2String, defaultIfEmpty, formattedString2Date, string2Date } from '../../elements/utils'
import { API_URL_TIMEMANAGEMENTEVENT } from '../../settings'
import { timeManagementEventPropType, userPropType } from '../../elements/PropTypes'

const emptyTimeManagementEventForm = {
  start_time: '00:00', end_time: '00:00', start_date: null, end_date: null, duration: 0, event_type: 0
}

export default function TimeManagementModal ({ events, timeManagementEvent, chosenDate, isOpen, setIsOpen, resetParent, employee, session }) {
  const [timeMangementEventForm, setTimeMangementEventForm] = useState({
    ...emptyTimeManagementEventForm,
    user: employee ? employee.id : session.user.id,
    ...timeManagementEvent,
    start_date: timeManagementEvent ? date2FormattedString(timeManagementEvent.start_date) : date2FormattedString(chosenDate),
    end_date: timeManagementEvent ? date2FormattedString(timeManagementEvent.end_date) : date2FormattedString(chosenDate)
  })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const onChange = e => {
    setTimeMangementEventForm({ ...timeMangementEventForm, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const startdate = new Date(`${date2String(chosenDate)} ${timeMangementEventForm.start_time}`)
    const enddate = new Date(`${date2String(chosenDate)} ${timeMangementEventForm.end_time}`)
    const durationWithBreak = (enddate - startdate) / 36e5
    const duration = (durationWithBreak > 6) ? durationWithBreak - 0.75 : durationWithBreak
    setTimeMangementEventForm((e) => ({ ...e, duration }))
  }, [timeMangementEventForm.start_time, timeMangementEventForm.end_time])

  useEffect(() => {
    if (timeMangementEventForm.event_type !== 0) {
      setDefaultTime()
    }
  }, [timeMangementEventForm.event_type])

  const isEntryValid = () => {
    const start_date = formattedString2Date(timeMangementEventForm.start_date)
    const end_date = formattedString2Date(timeMangementEventForm.end_date)
    const allEvents = events.filter(e => e.id !== timeManagementEvent?.id)
    const dayEvents = allEvents
      .filter(entry => {
        const entryStartDate = string2Date(entry.start_date)
        const entryEndDate = string2Date(entry.end_date)
        const dateOverlap = ((entryStartDate <= start_date && start_date <= entryEndDate) || (entryStartDate <= end_date && end_date <= entryEndDate))
        return dateOverlap
      })
    const { event_type, duration } = timeMangementEventForm
    if (dayEvents.some(entry => entry.event_type === event_type)) return 'Für diesen Tag existiert schon ein Eintrag.'
    if (dayEvents.some(entry => entry.event_type !== 0)) return 'Für diesen Tag existiert schon ein ganztägiger Eintrag.'

    const sumHour = duration + arrSum(dayEvents.map(e => e.duration))
    if (sumHour > 15) return 'Die maximale Stundenanzahl des Tages ist überschritten.'

    return ''
  }

  const submit = async (timeMangementEventForm) => {
    if (timeManagementEvent) { return axios.put(API_URL_TIMEMANAGEMENTEVENT + timeManagementEvent.id, timeMangementEventForm) }
    return axios.post(API_URL_TIMEMANAGEMENTEVENT, timeMangementEventForm)
  }

  const onSubmit = async (onSuccess) => {
    const error = isEntryValid()
    if (error !== '') {
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: error })
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit({
      ...timeMangementEventForm,
      end_date: date2String(formattedString2Date(timeMangementEventForm.end_date)),
      start_date: date2String(formattedString2Date(timeMangementEventForm.start_date))
    })
      .then((res) => {
        resetParent(res.data)
        onSuccess()
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      }).catch(error => { console.error('Error in "AddTimeManagement:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const onDelete = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return axios.delete(API_URL_TIMEMANAGEMENTEVENT + timeManagementEvent.id).then((res) => {
      resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "AddTimeManagement:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={function () { onSubmit(toggle) }}
        onDelete={timeManagementEvent ? function () { onDelete(toggle) } : null}
        disabled={timeMangementEventForm.duration <= 0}
      />)
  }

  const setDefaultTime = () => {
    const startdate = new Date(`${date2String(chosenDate)} 08:00`)
    const duration = session.user.default_working_hours
    const durationWithBreak = (duration > 6) ? duration + 0.75 : duration
    const enddate = new Date(startdate.getTime() + (durationWithBreak * 36e5))
    const hours = ('0' + enddate.getHours()).slice(-2)
    const minutes = ('0' + enddate.getMinutes()).slice(-2)
    const endtime = `${hours}:${minutes}`
    setTimeMangementEventForm({ ...timeMangementEventForm, start_time: '08:00', end_time: endtime })
  }

  if (!chosenDate) return null

  const title = employee ? 'Zeiterfassung von ' + employee.first_name + ' ' + employee.last_name : 'Zeiterfassung'

  return (
    <CustomModal size="lg" isOpen={isOpen} setIsOpen={setIsOpen} title={title} getFooter={getFooter}>
      <Container>
        <Row>
          <Col>
            <FormGroup>
              <Typography className='secondary-textcolor'>Erfassung von</Typography>
              <DropDown
                value={timeMangementEventForm.event_type}
                options={[{ label: 'Arbeitszeit', value: 0 }, { label: 'Urlaub', value: 1 }, { label: 'Krankheit', value: 2 }, { label: 'Frei wegen 4-Tage-Woche', value: 3 }]}
                onChange={(value) => { setTimeMangementEventForm((e) => ({ ...e, event_type: value })) }}
              />
              {timeMangementEventForm.event_type === 0
                ? <>
                  &nbsp;&nbsp;
                  <CustomButton onClick={setDefaultTime}>{session.user.default_working_hours} Stunden</CustomButton> </>
                : null}
            </FormGroup>

            <hr className='secondary-textcolor' />

            {/* ############################################################## */}

            <FormGroup>
              <Typography className='secondary-textcolor'>{(timeMangementEventForm.event_type === 1 || timeMangementEventForm.event_type === 2) ? 'Startdatum' : 'Datum'} </Typography>
              <Input
                id="input"
                type="text"
                name="start_date"
                value={chosenDate.toLocaleDateString('de-DE')}
                autoComplete="off"
                disabled
                style={{ boxShadow: 'none' }}
              />
            </FormGroup>
            <Typography className='secondary-textcolor'></Typography>

            <hr className='secondary-textcolor' />

            {/* ############################################################## */}
            {timeMangementEventForm.event_type === 0
              ? <>
                <FormGroup>
                  <Typography className='secondary-textcolor'>Startzeit:</Typography>
                  <Input
                    id="input"
                    type="time"
                    name="start_time"
                    value={defaultIfEmpty(timeMangementEventForm.start_time.slice(0, 5))}
                    autoComplete="off"
                    onChange={onChange}
                    style={{ boxShadow: 'none' }}
                  />
                </FormGroup>
                <Typography className='secondary-textcolor'></Typography>

                <hr className='secondary-textcolor' />

                {/* ############################################################## */}

                <FormGroup>
                  <Typography className='secondary-textcolor'>Endzeit:</Typography>
                  <Input
                    id="input"
                    type="time"
                    name="end_time"
                    value={defaultIfEmpty(timeMangementEventForm.end_time.slice(0, 5))}
                    autoComplete="off"
                    onChange={onChange}
                    style={{ boxShadow: 'none' }}
                  />
                </FormGroup>
              </>
              : null}
            {timeMangementEventForm.event_type === 1 || timeMangementEventForm.event_type === 2
              ? <>
                <FormGroup>
                  <Typography className='secondary-textcolor'>Enddatum:</Typography>
                  <Input
                    id="input"
                    type="text"
                    name="end_date"
                    value={timeMangementEventForm.end_date}
                    autoComplete="off"
                    style={{ boxShadow: 'none' }}
                    onChange={onChange}
                  />
                </FormGroup>
                <Typography className='secondary-textcolor'></Typography>
                < hr className='secondary-textcolor' />
              </>
              : null
            }
          </Col>
        </Row>
      </Container>
    </CustomModal >
  )
}

TimeManagementModal.propTypes = {
  events: PropTypes.arrayOf(timeManagementEventPropType),
  timeManagementEvent: timeManagementEventPropType,
  chosenDate: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  employee: userPropType,
  session: PropTypes.object
}
