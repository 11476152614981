import React from 'react'
import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'

import { Typography } from '@mui/material'

import { defaultIfEmpty } from '../../elements/utils'
import { productPropType } from '../../elements/PropTypes'

export default function EditWarehouseProductTypeForm ({ warehouseProductType, setWarehouseProductType, showMissingFields }) {
  const setProductState = (update) => {
    setWarehouseProductType((prevWarehouseProductType) => ({
      ...prevWarehouseProductType,
      ...update
    }))
  }

  const onChange = e => {
    setProductState({ [e.target.name]: e.target.value })
  }

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FormGroup>
        <Typography className='secondary-textcolor'>Kategoriename</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={warehouseProductType ? defaultIfEmpty(warehouseProductType.name) : null}
          required={true}
          invalid={!!(showMissingFields && !(warehouseProductType ? warehouseProductType.name : null))}
          style={{ boxShadow: 'none', marginBottom: '200px' }}
        />
      </FormGroup>
    </Form>)
}

EditWarehouseProductTypeForm.propTypes = {
  warehouseProductType: productPropType,
  setWarehouseProductType: PropTypes.func,
  showMissingFields: PropTypes.bool
}
