import React from 'react'
import { PropTypes } from 'prop-types'

import { planningPropType, projectPropType } from '../../elements/PropTypes'
import PlantPlanningFormModal from './plant_project/plannings/PlantPlanningFormModal'
import HeatpumpPlanningFormModal from './heatpump_project/plannings/HeatpumpPlanningFormModal'
import RoofProjectPlanningFormModal from './roof_project/plannings/RoofProjectPlanningFormModal'

export default function PlanningFormModal ({ project, planning, setPlanning, getOpenButton, resetParent, session }) {
  if (project.resourcetype === 'PlantProject') {
    return <PlantPlanningFormModal
      project={project}
      planning={planning}
      setPlanning={setPlanning}
      getOpenButton={getOpenButton}
      resetParent={resetParent}
      session={session}
    />
  }
  if (project.resourcetype === 'HeatpumpProject') {
    return <HeatpumpPlanningFormModal
      project={project}
      planning={planning}
      setPlanning={setPlanning}
      getOpenButton={getOpenButton}
      resetParent={resetParent}
      session={session}
    />
  }
  if (project.resourcetype === 'RoofProject') {
    return <RoofProjectPlanningFormModal
      project={project}
      planning={planning}
      setPlanning={setPlanning}
      getOpenButton={getOpenButton}
      resetParent={resetParent}
      session={session}
    />
  }
  return null
}

PlanningFormModal.propTypes = {
  project: projectPropType,
  planning: planningPropType,
  setPlanning: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
