import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import DatePicker from 'react-datepicker'
import axios from 'axios'

import { FormGroup, Stack, Typography } from '@mui/material'

import DropDown from '../elements/DropDown'
import { date2String, round } from '../elements/utils'
import CarMap from './car_map/CarMap'
import ManageFleetModal from './car_map/ManageFleetModal'
import './car_map/styles.css' // needed for Datepicker to be in the foreground!
import { API_URL_VEHICLE } from '../settings'

export default function CarTracking ({ session }) {
  const [cars, setCars] = useState(null)
  const [car, setCar] = useState(null)
  const [date, setDate] = useState(new Date())

  const resetState = () => {
    loadData()
  }

  const loadData = () => {
    axios.get(API_URL_VEHICLE, { params: { date: date2String(date), nested: true } }).then((res) => setCars(res.data))
  }

  useEffect(() => {
    resetState()
  }, [date])

  return (
    <Fragment>
      <div style={{ width: round(window.innerWidth * 0.8), display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px' }}>
        <Stack direction='row' spacing={2}>
          <FormGroup>
            <Typography className='secondary-textcolor'>Datum wählen:</Typography>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale="de"
              selected={date}
              onChange={(date) => { setDate(date) }}
            />
          </FormGroup>
          <DropDown
            onChange={setCar}
            options={(cars || []).filter(car => car.plate).map((car) => ({ label: car.plate, value: car.id }))}
            value={car}
            text='Fahrzeug wählen'
            sort={true}
            search={true}
            size="medium"
          />
          {cars && <ManageFleetModal resetParent={resetState} session={session} cars={cars} />}
        </Stack>
      </div>
      {cars && <CarMap cars={car ? cars.filter(c => c.id === car) : cars} />}
    </Fragment>
  )
}

CarTracking.propTypes = {
  session: PropTypes.object.isRequired
}
