import { getRandomId, hasPermission } from '../../../../elements/utils'

export const getEmployeeSignatureUpdate = (employees, signatureSet) => {
  return employees.map(eId => {
    return signatureSet.find(s => s.employee === eId) || { id: -getRandomId(), image: null, employee: eId }
  })
}

export const getRoofConstructionFormTemplate = (project, form, employees, session) => {
  const constructionManagers = employees.filter(user => user.group_key === 'construction_managers')
  return [
    {
      type: 'subtitle',
      text: 'Fotos hochladen'
    },
    {
      type: 'images',
      name: 'Fotos vor dem Bau:',
      key: 'roofprojectconstructionimagebefore_set'
    },
    {
      type: 'images',
      name: 'Fotos nach dem Bau:',
      key: 'roofprojectconstructionimageafter_set'
    },
    {
      type: 'subtitle',
      text: 'Sonstiges'
    },
    {
      type: 'checkbox',
      name: '5-Sterne-Google-Bewertung erhalten.',
      key: 'five_star_google_rating'
    },
    {
      type: 'subtitle',
      text: 'Mitarbeiter'
    },
    {
      type: 'dropdown',
      name: 'Bauleiter:',
      key: 'construction_manager',
      condition: () => constructionManagers,
      options: (constructionManagers || []).map(h => ({ label: h.name, value: h.id })),
      additionalUpdate: (key, value, prevForm) => {
        const prevValue = prevForm[key]
        let employees = prevForm.employees
        if (prevValue && employees.includes(prevValue) && ((value && !employees.includes(value)) || !value)) {
          employees = employees.filter(x => x !== prevValue)
        }
        if (value && !employees.includes(value)) {
          employees = [...employees, value]
        }
        return { employees, roofprojectemployeesafetybriefingsignature_set: getEmployeeSignatureUpdate(employees, prevForm.roofprojectemployeesafetybriefingsignature_set) }
      }
    },
    {
      type: 'multiselect',
      name: 'Mitarbeiter auf der Baustelle:',
      key: 'employees',
      condition: () => employees,
      optional: false,
      disabled: !hasPermission(session.user, 'task_assignment'),
      options: employees.map(e => ({ label: e.name, value: e.id, hidden: !e.is_active, disabled: (e.id === form.construction_manager || !hasPermission(session.user, 'task_assignment')) })),
      additionalUpdate: (key, value, prevForm) => {
        return { roofprojectemployeesafetybriefingsignature_set: getEmployeeSignatureUpdate(value, prevForm.roofprojectemployeesafetybriefingsignature_set) }
      }
    },
    {
      type: 'n_signatures',
      name: 'Sicherheitsunterweisung:',
      key: 'roofprojectemployeesafetybriefingsignature_set',
      subfields: form.employees.map(eId => employees.find(e => e.id === eId)).filter(e => e)
        .map(e => ({ value_id: e.id, name: e.name, text: 'Ich habe eine Sicherheitsunterweisung über das Tragen von Sicherheitsschuhen, Helm und Handschuhen sowie über ein ordnungemäßes Gerüst und entsprechende Warnhinweise erhalten.' })),
      isEmpty: values => values.some(v => !v.image),
      valueIdKey: 'employee'
    },
    {
      type: 'signature',
      name: 'Unterschrift Bauleiter:',
      key: 'construction_manager_signature'
    }
  ]
}
