import React from 'react'

import { PropTypes } from 'prop-types'

import { offerPropType, projectPropType } from '../../elements/PropTypes'
import AcceptOfferModal from '../offers/AcceptOfferModal'
import AcceptPlantOfferModal from './plant_project/offers/AcceptPlantOfferModal'

export default function AcceptProjectOfferModal ({ project, offer, session, resetParent }) {
  if (project.resourcetype === 'PlantProject') {
    return <AcceptPlantOfferModal
            project={project}
            offer={offer}
            session={session}
            resetParent={resetParent}
        />
  }
  return <AcceptOfferModal
        project={project}
        offer={offer}
        resetParent={resetParent}
        session={session}
    />
}

AcceptProjectOfferModal.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  session: PropTypes.object,
  resetParent: PropTypes.func
}
