import React, { Fragment, useState, useEffect } from 'react'
import { Container, Input } from 'reactstrap'
import { Table } from 'react-bootstrap'
import axios from 'axios'
import { PropTypes } from 'prop-types'
import { toast } from 'react-toastify'

import { Typography, Stack, IconButton, ToggleButtonGroup } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { CustomButton, CustomToggle } from '../../elements/StyledElements'
import Collapsible from '../../elements/Collapsible'
import MultiSelect from '../../elements/MultiSelect'
import DropDown from '../../elements/DropDown'
import CustomModal from '../shared/modal_utils/CustomModal'

import { API_URL_PRODUCT } from '../../settings'
import { isMobileOnly } from 'react-device-detect'
import { defaultIfEmpty } from '../../elements/utils'
import WarehouseDeliveryLocationModal from './WarehouseDeliveryLocationModal'
import WarehouseDeliveryConfirmationModal from './WarehouseDeliveryConfirmationModal'
import WarehouseDeliveryEmailModal from './WarehouseDeliveryEmailModal'
import WarehouseUsedInProduct from './WarehouseUsedInProductModal'

const emptyProductUpdate = {
  id: null,
  name: null,
  supplier_id: null,
  amount: 1,
  comment: '',
  expand: false
}

const emptyWarehouseComission = {
  construction_id: null,
  warehouse_address_id: 1,
  delivery_date: new Date(),
  employee_id: null
}

export default function WarehouseDelivery ({ session, warehouseProducts, warehouseProductTypes, products, suppliers, employee, employees, resetState }) {
  const [showList, setShowList] = useState(false)
  const [isAddMaterial, setIsAddMaterial] = useState(true)
  const [isAllExpand, setIsAllExpand] = useState(true)
  const [selectedProductTypes, setSelectedProductTypes] = useState([])
  const [selectedSuppliers, setSelectedSuppliers] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [warehouseUsedInProduct, setWarehouseUsedInProduct] = useState([])
  const [productUpdates, setProductUpdates] = useState([])
  const [currentProductUpdate, setCurrentProductUpdate] = useState(emptyProductUpdate)
  const [currentProductSetUpdate, setCurrentProductSetUpdate] = useState([])
  const [showErrorFields, setShowErrorFields] = useState(false)
  const [invalidAmountIds, setInvalidAmountIds] = useState([])
  const [warehouseComission, setWarehouseComission] = useState({ ...emptyWarehouseComission, employee_id: employee })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleReset = () => {
    setShowList(true)
    setIsAllExpand(true)
    setProductUpdates([])
    setIsSubmitted(false)
    setWarehouseComission({ ...emptyWarehouseComission, employee_id: employee })
    setIsSubmitted(false)
  }

  useEffect(() => {
    getProduct()
  }, [selectedProduct])

  const getProduct = () => {
    if (!selectedProduct) {
      setCurrentProductSetUpdate([])
      setWarehouseUsedInProduct([])
      return
    }
    axios.get(API_URL_PRODUCT + selectedProduct).then(
      res => {
        setWarehouseUsedInProduct(res.data.warehouse_products.map(
          (e) => ({ warehouse_product_id: e.id, amount: e.amount })))
        setCurrentProductSetUpdate(res.data.warehouse_products.map((product) => {
          const warehouseProduct = warehouseProducts.filter((e) => (e.id === product.id))[0]
          return {
            ...product,
            recommended: product.amount,
            name: warehouseProduct.name,
            supplier_id: warehouseProduct.supplier_id,
            comment: '',
            expand: false
          }
        }))
      }
    ).catch((err) => {
      setCurrentProductSetUpdate([])
      setWarehouseUsedInProduct([])
      toast.error(err)
    })
  }

  const handleCurrentProductUpdate = (idx) => {
    const warehouseProduct = warehouseProducts.filter((e) => (e.id === idx))[0]
    setCurrentProductUpdate({
      ...currentProductUpdate,
      id: warehouseProduct.id,
      name: warehouseProduct.name,
      supplier_id: warehouseProduct.supplier_id
    })
  }

  const handleCurrentProductSetUpdate = (idx, amount) => {
    const newList = [...currentProductSetUpdate]
    newList[idx].amount = amount
    setCurrentProductSetUpdate(newList)
  }

  const handleProductUpdateChange = (idx, productId, amount) => {
    setInvalidAmountIds(invalidAmountIds.filter((e) => (e !== productId)))
    const newList = [...productUpdates]
    newList[idx] = { ...productUpdates[idx], amount }
    setProductUpdates(newList)
  }

  const handleProductUpdateField = (idx, field, value) => {
    const newList = [...productUpdates]
    newList[idx][field] = value
    setProductUpdates(newList)
  }

  const handleProductUpdateSupplier = (idx, value) => {
    if (isSubmitted) return
    const newList = [...productUpdates]
    newList[idx].supplier_id = value
    setProductUpdates(newList.sort((a, b) => {
      if (a.supplier_id === b.supplier_id) { return (a.id - b.id) }
      return a.supplier_id - b.supplier_id
    }))
  }

  const handleAllExpansion = (value) => {
    setIsAllExpand(value)
    setProductUpdates(productUpdates.map((e) => { e.expand = value; return e }))
  }

  const handleProductUpdateAdd = () => {
    if (!currentProductUpdate.id || productUpdates.filter((e) => (e.id === currentProductUpdate.id)).length > 0) {
      setShowErrorFields(true)
      return
    }
    if (!currentProductUpdate.amount && currentProductUpdate.amount !== 0) {
      setShowErrorFields(true)
      return
    }
    const newList = [...productUpdates]
    newList.push(currentProductUpdate)
    setProductUpdates(newList.sort((a, b) => {
      if (a.supplier_id === b.supplier_id) { return (a.id - b.id) }
      return a.supplier_id - b.supplier_id
    }))
    setCurrentProductUpdate(emptyProductUpdate)
    setShowErrorFields(false)
    setShowList(true)
  }

  const handleProductSetUpdateAdd = () => {
    for (let i = 0; i < currentProductSetUpdate.length; i++) {
      if (!currentProductSetUpdate[i].amount || currentProductSetUpdate[i].amount < 0) {
        setShowErrorFields(true)
        return
      }
    }

    const newList = [...productUpdates]
    for (let i = 0; i < currentProductSetUpdate.length; i++) {
      const row = newList.find(e => e.id === currentProductSetUpdate[i].id)
      if (row) {
        row.amount += currentProductSetUpdate[i].amount
        row.comment += ' ' + currentProductSetUpdate[i].comment
        row.expand ||= currentProductSetUpdate[i].expand
      } else {
        const { recommended, ...rest } = currentProductSetUpdate[i]
        newList.push(rest)
      }
    }

    setProductUpdates(newList.sort((a, b) => {
      if (a.supplier_id === b.supplier_id) { return (a.id - b.id) }
      return a.supplier_id - b.supplier_id
    }))
    setSelectedProduct(null)
    setShowErrorFields(false)
    setShowList(true)
  }

  const handleProductUpdateDelete = (idx) => {
    setProductUpdates(productUpdates.filter((_, i) => (i !== idx)))
  }

  const handleShowList = () => {
    setCurrentProductUpdate(emptyProductUpdate)
    setShowErrorFields(false)
    setShowList(true)
  }

  const handleHideList = () => {
    setInvalidAmountIds([])
    setShowErrorFields(false)
    setShowList(false)
  }

  const getOpenButton = (toggle) => {
    return (
      <CustomButton color="black" style={{ margin: '8px 0', width: '100%', paddingTop: '16px', paddingBottom: '16px' }} onClick={toggle}>
        <Typography variant={isMobileOnly ? 'body1' : 'h6'}>Bestellen</Typography>
      </CustomButton>
    )
  }

  return (
    <CustomModal size='fullscreen' title="Bestellen" getOpenButton={getOpenButton} >
      {showList
        ? (
          <Fragment>
            <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Stack direction="column" spacing={2} style={{ alignItems: 'center', marginTop: 20 }}>
                {isSubmitted && <Typography className='secondary-textcolor'>Erfolg</Typography>}
                <Stack direction="row" spacing={1} style={{ borderBottom: '1px solid black', alignItems: 'center', width: '100%' }}>
                  {isAllExpand
                    ? <IconButton onClick={() => { handleAllExpansion(false) }} style={{ width: '15%' }}>
                      <KeyboardArrowUp />
                    </IconButton>
                    : <IconButton onClick={() => { handleAllExpansion(true) }} style={{ width: '15%' }}>
                      <KeyboardArrowDown />
                    </IconButton>
                  }
                  <Typography className='secondary-textcolor' style={{ width: '45%' }}>
                    {(isMobileOnly && !isSubmitted) ? 'Material\nname' : 'Materialname'}
                  </Typography>
                  <Typography className='secondary-textcolor' style={{ width: '25%' }}>Anzahl</Typography>
                  <Typography className='secondary-textcolor' style={{ width: '15%' }}>Loschen</Typography>
                </Stack>
                {productUpdates.map((productUpdate, idx, array) => (
                  <Stack direction={'column'} spacing={1} style={{
                    paddingBottom: '10px',
                    width: '100%',
                    borderBottom: (
                      idx + 1 === array.length || array[idx + 1].supplier_id !== productUpdate.supplier_id ? '1px solid black' : ''
                    )
                  }} key={(idx)}>
                    {(idx === 0 || array[idx - 1].supplier_id !== array[idx].supplier_id) && <div>
                      {suppliers.find(e => e.id === array[idx].supplier_id)?.name}
                    </div>}
                    <Stack direction="row" spacing={1} style={{ alignItems: 'center', width: '100%' }}>
                      {productUpdate.expand
                        ? <IconButton onClick={() => { handleProductUpdateField(idx, 'expand', false) }} style={{ width: '15%' }}>
                          <KeyboardArrowUp />
                        </IconButton>
                        : <IconButton onClick={() => { handleProductUpdateField(idx, 'expand', true) }} style={{ width: '15%' }}>
                          <KeyboardArrowDown />
                        </IconButton>
                      }
                      <div style={{ width: '45%', maxWidth: '40%', wordWrap: 'break-word' }}>{'[i' + productUpdate.id + '] ' + productUpdate.name}</div>
                      <Input
                        style={{ width: '30%', boxShadow: 'none' }} type="number" min="1"
                        value={productUpdate.amount} disabled={isSubmitted}
                        invalid={!productUpdate.amount}
                        onChange={(e) => { handleProductUpdateChange(idx, productUpdate.id, e.target.value) }} />
                      {!isSubmitted && <IconButton style={{ width: '15%' }} onClick={() => { handleProductUpdateDelete(idx) }}>
                        <DeleteIcon />
                      </IconButton>}
                    </Stack>
                    {productUpdate.expand && <Stack direction="row" spacing={1} style={{ alignItems: 'center', width: '100%' }}>
                      <DropDown
                        style={{ width: '30%', boxShadow: 'none' }}
                        onChange={(val) => { handleProductUpdateSupplier(idx, val) }}
                        options={ suppliers.map((supplier) => ({ value: supplier.id, label: supplier.name })) }
                        value={ defaultIfEmpty(productUpdate.supplier_id) }
                        disabled={isSubmitted}
                        required={true}
                        invalid={!productUpdate.supplier_id}
                        text='Zulieferer'
                        search={true}
                      />
                      <Input
                        style={{ width: '70%', boxShadow: 'none' }} type="text"
                        value={productUpdate.comment} disabled={isSubmitted}
                        onChange={(e) => { handleProductUpdateField(idx, 'comment', e.target.value) }}/>
                    </Stack>}
                  </Stack>
                ))}
                {!isSubmitted && <CustomButton onClick={handleHideList} >Material Hinzufügen</CustomButton>}
                <Stack direction={isMobileOnly ? 'column' : 'row'} spacing={2}>
                  <WarehouseDeliveryLocationModal
                    parentComission={warehouseComission}
                    setParentComission={setWarehouseComission}
                    isSubmitted={isSubmitted}
                    session={session}
                  />
                  <WarehouseDeliveryConfirmationModal
                    warehouseComission={warehouseComission}
                    isSubmitted={isSubmitted}
                  />
                  <WarehouseDeliveryEmailModal
                    resetParent={handleReset}
                    session={session}
                    isSubmitted={isSubmitted}
                    setIsSubmitted={setIsSubmitted}
                    suppliers={suppliers}
                    warehouseProducts={warehouseProducts}
                    warehouseComission={warehouseComission}
                    setWarehouseComission={setWarehouseComission}
                    productUpdates={productUpdates}
                    employee={employee}
                    employees={employees}
                  />
                </Stack>
              </Stack>
            </Container>
          </Fragment>
          )
        : (isAddMaterial
            ? <Fragment>
            <Container>
              <Stack direction="column" spacing={2} style={{ alignItems: 'center', marginTop: 20 }}>
                <ToggleButtonGroup value={isAddMaterial} exclusive>
                  <CustomToggle value={true}>Material</CustomToggle>
                  <CustomToggle value={false} onClick={() => { setIsAddMaterial(false) }}>Produkt</CustomToggle>
                </ToggleButtonGroup>
                <DropDown
                  onChange={(val) => { handleCurrentProductUpdate(val) }}
                  options={ warehouseProducts.filter((warehouseProduct) => {
                    let satisfy = !(productUpdates.map(e => e.id).includes(warehouseProduct.id))
                    satisfy = (selectedProductTypes.length === 0 ||
                      (selectedProductTypes.includes(0) && warehouseProduct.product_type.length === 0) ||
                      (warehouseProduct.product_type.filter(e => selectedProductTypes.includes(e)).length > 0)
                    ) && satisfy
                    satisfy = (selectedSuppliers.length === 0 ||
                      selectedSuppliers.includes(warehouseProduct.supplier_id)
                    ) && satisfy
                    return satisfy
                  }).map((warehouseProduct) => ({
                    value: warehouseProduct.id,
                    label: (
                      '[i' + warehouseProduct.id + '] ' + warehouseProduct.name +
                      ' ([' + warehouseProduct.supplier_name + '] ' + warehouseProduct.supplier_product_name + ')'
                    )
                  })) }
                  value={ defaultIfEmpty(currentProductUpdate.id) }
                  required={true}
                  invalid={showErrorFields && (!currentProductUpdate.id || productUpdates.filter((e) => (e.id === currentProductUpdate.id)).length > 0)}
                  text='Eingabe'
                  search={true}
                />
                <Collapsible getOpenButton={
                  (toggle) => <IconButton size="medium" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={toggle}>
                    <FilterAltOutlinedIcon style={{ color: '#424242' }} fontSize='medium' />
                    <Typography className='secondary-textcolor'>Material filtern</Typography>
                  </IconButton>
                } style={{ display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 1 }}>
                  <Stack direction='column' spacing={2}>
                    <MultiSelect
                      onChange={(val) => { setSelectedProductTypes(val || []) }}
                      options={[{ id: 0, name: 'Unsortiert' }].concat(warehouseProductTypes).map((productType) => ({
                        value: productType.id,
                        label: productType.name
                      }))}
                      values={defaultIfEmpty(selectedProductTypes)}
                      text="Kategorie"
                      search={true}
                      name="product_type"
                    />
                    <MultiSelect
                      onChange={(val) => setSelectedSuppliers(val || [])}
                      options={suppliers.map((supplier) => ({
                        value: supplier.id,
                        label: supplier.name
                      }))}
                      values={defaultIfEmpty(selectedSuppliers)}
                      text="Zulieferer"
                      search={true}
                      name="supplier"
                    />
                  </Stack>
                </Collapsible>
                <div style={{ width: '200px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#888888' }}>
                  QR Code Scanner Platzhalter
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input style={{ boxShadow: 'none' }} type="number" min="1"
                    value={currentProductUpdate.amount}
                    onChange={(e) => { setCurrentProductUpdate({ ...currentProductUpdate, amount: parseInt(e.target.value) }) }}
                    invalid={showErrorFields && (!currentProductUpdate.amount && currentProductUpdate.amount !== 0)}
                  />
                  <CustomButton onClick={() => { setCurrentProductUpdate({ ...currentProductUpdate, amount: currentProductUpdate.amount + 1 }) }} >+</CustomButton>
                  <CustomButton onClick={() => { setCurrentProductUpdate({ ...currentProductUpdate, amount: Math.max(currentProductUpdate.amount - 1, 1) }) }} >-</CustomButton>
                </div>
                <Typography className='secondary-textcolor'> Kommentar </Typography>
                <Input style={{ boxShadow: 'none' }} type="text"
                  value={currentProductUpdate.comment}
                  onChange={(e) => { setCurrentProductUpdate({ ...currentProductUpdate, comment: e.target.value }) }}
                />

                <CustomButton onClick={handleProductUpdateAdd} >Hinzufügen</CustomButton>
                <CustomButton onClick={handleShowList} >Bestell-Liste anzeigen</CustomButton>

              </Stack>
            </Container>
          </Fragment>
            : <Fragment>
            <Container>
              <Stack direction="column" spacing={2} style={{ alignItems: 'center', marginTop: 20 }}>
                <ToggleButtonGroup value={isAddMaterial} exclusive>
                  <CustomToggle value={true} onClick={() => { setIsAddMaterial(true) }}>Material</CustomToggle>
                  <CustomToggle value={false}>Produkt</CustomToggle>
                </ToggleButtonGroup>
                <DropDown
                  onChange={(val) => { setSelectedProduct(val) }}
                  options={ products.map((product) => ({ value: product.id, label: product.name })) }
                  value={ defaultIfEmpty(selectedProduct) }
                  required={true}
                  invalid={showErrorFields && (!selectedProduct) }
                  text='Eingabe'
                  search={true}
                />
                <WarehouseUsedInProduct
                  selectedProduct={selectedProduct}
                  warehouseUsedInProduct={warehouseUsedInProduct}
                  setWarehouseUsedInProduct={setWarehouseUsedInProduct}
                  warehouseProducts={warehouseProducts}
                  resetParent={getProduct}
                />
                <Table className='table-not-hover'>
                  <thead>
                    <tr>
                      <th className="align-middle"><Typography className='secondary-textcolor'>Index</Typography></th>
                      <th className="align-middle"><Typography className='secondary-textcolor'>Material</Typography></th>
                      <th className="align-middle"><Typography className='secondary-textcolor'>Anzahl</Typography></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentProductSetUpdate.length > 0
                      ? currentProductSetUpdate.map((row, idx) => <tr key={idx}>
                      <td className="align-middle">{'i' + row.id}</td>
                      <td className="align-middle">{row.name}</td>
                      <td className="align-middle">
                        <Input style={{ boxShadow: 'none' }} type="number" min="1"
                          value={row.amount} placeholder={row.recommended}
                          onChange={(e) => { handleCurrentProductSetUpdate(idx, e.target.value) }}
                          invalid={showErrorFields && (!row.amount && row.amount < 0)}
                        />
                      </td>
                    </tr>)
                      : <tr>
                      <td colSpan="3" align="center">
                        <Typography className='secondary-textcolor'> Noch kein Material verknüpft </Typography>
                      </td>
                    </tr>}
                  </tbody>
                </Table>
                <CustomButton onClick={handleProductSetUpdateAdd} >Hinzufügen</CustomButton>
                <CustomButton onClick={handleShowList} >Bestell-Liste anzeigen</CustomButton>
              </Stack>

            </Container>
          </Fragment>
          )
      }
    </CustomModal>
  )
}

WarehouseDelivery.propTypes = {
  session: PropTypes.object.isRequired,
  warehouseProducts: PropTypes.array,
  warehouseProductTypes: PropTypes.array,
  products: PropTypes.array,
  suppliers: PropTypes.array,
  employee: PropTypes.number,
  employees: PropTypes.array,
  resetState: PropTypes.func
}
