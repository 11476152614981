import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import { PropTypes } from 'prop-types'
import DatePicker from 'react-datepicker'
import { Table } from 'react-bootstrap'
import axios from 'axios'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Typography, Stack, Button } from '@mui/material'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { CustomButton } from '../../elements/StyledElements'
import { API_URL_WAREHOUSEDELIVERYIMAGE, API_URL_WAREHOUSEDELIVERYSUPPLIER, API_URL_WAREHOUSEPRODUCT } from '../../settings'
import ImageStage from '../shared/images/ImageStage'
import { date2String, string2Date, numFormatter } from '../../elements/utils'

export default function WarehouseDeliveryAmountModal ({ resetParent, resetState, isInfo, warehouseDeliverySupplier, warehouseProducts, suppliers, session, style, employee }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [formData, setFormData] = useState(warehouseDeliverySupplier)
  const [deliveryImage, setDeliveryImage] = useState(null)
  const [materialImage, setMaterialImage] = useState(null)
  const [images, setImages] = useState([])

  const resetImages = () => {
    axios.get(API_URL_WAREHOUSEDELIVERYIMAGE, { params: { supplier: warehouseDeliverySupplier.id } }).then((res) => {
      setImages(res.data)
    })
  }

  useEffect(() => {
    resetImages()
  }, [])

  const getTotalPrice = () => {
    let totalPrice = 0
    for (let i = 0; i < warehouseDeliverySupplier.warehouse_products.length; i++) {
      const warehouseProduct = warehouseProducts.find(e => e.id === warehouseDeliverySupplier.warehouse_products[i].warehouse_product)
      if (warehouseProduct) {
        totalPrice += warehouseProduct.supplier_price * warehouseDeliverySupplier.warehouse_products[i].ordered_amount
      }
    }
    return totalPrice
  }

  const submitImage = (type) => {
    const postData = new FormData()
    postData.append('supplier', warehouseDeliverySupplier.id)
    postData.append('type', type)
    postData.append('image', type === 'delivery' ? deliveryImage : materialImage)
    axios.post(API_URL_WAREHOUSEDELIVERYIMAGE, postData).then((res) => {
      if (type === 'delivery') {
        setDeliveryImage(null)
      } else {
        setMaterialImage(null)
      }
      resetImages()
    })
  }

  const deleteImage = (id) => {
    axios.delete(API_URL_WAREHOUSEDELIVERYIMAGE + id).then(() => {
      resetImages()
    })
  }

  const getOpenButton = (toggle) => {
    return (isInfo
      ? <IconButton disableFocusRipple disableRipple style={{
        backgroundColor: 'transparent', float: 'right'
      }} size="small" onClick={toggle}>
        <InfoOutlinedIcon className='secondary-textcolor' fontSize='large' />
      </IconButton>
      : <CustomButton onClick={toggle} style={style}>{'Lieferung\nangekommen'}</CustomButton>
    )
  }

  const handleChangeAmount = (idx, val) => {
    const newFormData = { ...formData }
    newFormData.warehouse_products[idx].actual_amount = val
    setFormData(newFormData)
  }

  const submit = async () => {
    return axios.put(API_URL_WAREHOUSEDELIVERYSUPPLIER + warehouseDeliverySupplier.id, formData)
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    if (formData.warehouse_products.find(e => e.actual_amount !== 0 && !e.actual_amount)) {
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false, showMissingFields: true })
      return
    }
    return submit().then((res) => {
      resetState()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    })
      .catch(error => {
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
        console.error(error)
      })
  }

  const updateStocks = async (type) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return axios.post(API_URL_WAREHOUSEPRODUCT, {
      type,
      request_as: employee,
      location: formData.location ? formData.location : 1,
      updates: formData.warehouse_products.map(row => ({ id: row.warehouse_product, amount: row.actual_amount }))
    }).then((res) => {
      resetParent()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => {
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
      console.error(error)
    })
  }

  const getFooter = (toggle) => {
    return (<SaveModalFooter
      submitError={loadingElements.submitError}
      inProgress={loadingElements.inProgress}
      onSave={() => { onSubmit(toggle) }}
    />)
  }

  const onToggle = (isOpen) => {
    if (isOpen) setFormData(warehouseDeliverySupplier)
  }

  return (<CustomModal getOpenButton={getOpenButton} title={isInfo ? 'Lieferrung' : 'Lieferung angekommen'} getFooter={getFooter} onToggle={onToggle}>
        <br />
        {isInfo
          ? <div style={{ position: 'relative' }}>
          <Typography className='secondary-textcolor'>Datum:</Typography>
          <Typography className='secondary-textcolor'>{warehouseDeliverySupplier.create_date}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Zulieferer:</Typography>
          <Typography className='secondary-textcolor'>{suppliers.find(e => e.id === warehouseDeliverySupplier.supplier)?.name}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Kommission:</Typography>
          <Typography className='secondary-textcolor'>{warehouseDeliverySupplier.comission_name}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Besteller:</Typography>
          <Typography className='secondary-textcolor'>{warehouseDeliverySupplier.employee_name}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Lieferort:</Typography>
          <Typography className='secondary-textcolor'>{warehouseDeliverySupplier.address}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Lieferdatum:</Typography>
          <Typography className='secondary-textcolor'>{warehouseDeliverySupplier.delivery_date}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Gesamtpreis:</Typography>
          <Typography className='secondary-textcolor'>{numFormatter(getTotalPrice()) + ' €'}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Status:</Typography>
          <Typography className='secondary-textcolor'>{warehouseDeliverySupplier.status}</Typography>
          <br/>
          <Typography className='secondary-textcolor'>Angekommen am:</Typography>
          <DatePicker
            dateFormat="yyyy-MM-dd" locale="de"
            showIcon showYearDropdown
            selected={formData.fulfillment_date ? string2Date(formData.fulfillment_date) : null}
            onChange={(date) => { setFormData({ ...formData, fulfillment_date: date ? date2String(date) : null }) }}
          />
          <br/>
          <Typography className='secondary-textcolor'>Fotos Lieferschein:</Typography>
          <Stack direction={'row'} mt={1} spacing={1} style={{ overflowX: 'scroll' }}>
            {images.filter(
              e => (e.type === 'delivery')
            ).map(e => <Stack key={e.id} direction={'column'} spacing={1}>
              <ImageStage image={e.image} maxHeight={200}/>
              <Button
                variant="outlined"
                color="error"
                onClick={() => { deleteImage(e.id) }}>
                {'Löschen'}
              </Button>
            </Stack>)}
          </Stack>
          <Stack direction={'row'} mt={1} spacing={1}>
            <Input style={{ boxShadow: 'none', width: '300px' }} type="file" onChange={(e) => { setDeliveryImage(e.target.files[0]) }} />
            <CustomButton onClick={() => { submitImage('delivery') }} disabled={!deliveryImage} >Neue Fotos</CustomButton>
          </Stack>
          <br/>
          <Typography className='secondary-textcolor'>Fotos Material:</Typography>
          <Stack direction={'row'} mt={1} spacing={1} style={{ overflowX: 'scroll' }}>
            {images.filter(
              e => (e.type === 'material')
            ).map(e => <Stack key={e.id} direction={'column'} spacing={1}>
              <ImageStage image={e.image} maxHeight={200}/>
              <Button
                variant="outlined"
                color="error"
                onClick={() => { deleteImage(e.id) }}>
                {'Löschen'}
              </Button>
            </Stack>)}
          </Stack>
          <Stack direction={'row'} mt={1} spacing={1}>
            <Input style={{ boxShadow: 'none', width: '300px' }} type="file" onChange={(e) => { setMaterialImage(e.target.files[0]) }} />
            <CustomButton onClick={() => { submitImage('material') }} disabled={!materialImage} >Neue Fotos</CustomButton>
          </Stack>
        </div>
          : <div style={{ position: 'relative' }}>
          <Table>
            <thead>
              <tr>
                <th className="align-middle"><Typography className='secondary-textcolor'>Materialname</Typography></th>
                <th className="align-middle"><Typography className='secondary-textcolor'>Bestell</Typography></th>
                <th className="align-middle"><Typography className='secondary-textcolor'>Geliefert</Typography></th>
                <th className="align-middle"><Typography className='secondary-textcolor'>Bestand</Typography></th>
              </tr>
            </thead>
            <tbody>
              {formData.warehouse_products && formData.warehouse_products.map((row, idx) => <tr key={idx}>
                <td className="align-middle" style={{ maxWidth: '100px', wordWrap: 'break-word' }}>
                  <Typography className='secondary-textcolor'>{
                    warehouseProducts.find(e => (e.id === row.warehouse_product))?.name
                  }</Typography>
                </td>
                <td className="align-middle"><Typography className='secondary-textcolor'>{row.ordered_amount}</Typography></td>
                <td className="align-middle"><Input
                  style={{ boxShadow: 'none' }} type="number" min="1"
                  value={row.actual_amount}
                  invalid={loadingElements.showMissingFields && row.actual_amount !== 0 && !row.actual_amount}
                  onChange={(e) => { handleChangeAmount(idx, parseInt(e.target.value)) }}
                /></td>
                <td className="align-middle"><Typography className='secondary-textcolor'>{
                  warehouseProducts.find(e => (e.id === row.warehouse_product))?.amounts.find(
                    e => e.location_id === (formData.location ? formData.location : 1))?.amount
                }</Typography></td>
              </tr>)}
            </tbody>
          </Table>
          <Stack direction={'row'} mt={1} spacing={1}>
            <CustomButton onClick={() => { updateStocks('add') }}>Material zum Lagerbestand hinzufügen</CustomButton>
            <CustomButton onClick={() => { updateStocks('subtract') }}>Abnahme</CustomButton>
          </Stack>
          <br/>
          <Typography className='secondary-textcolor'>Fotos Lieferschein:</Typography>
          <Stack direction={'row'} mt={1} spacing={1} style={{ overflowX: 'scroll' }}>
            {images.filter(
              e => (e.type === 'delivery')
            ).map(e => <Stack key={e.id} direction={'column'} spacing={1}>
              <ImageStage image={e.image} maxHeight={200}/>
              <Button
                variant="outlined"
                color="error"
                onClick={() => { deleteImage(e.id) }}>
                {'Löschen'}
              </Button>
            </Stack>)}
          </Stack>
          <Stack direction={'row'} mt={1} spacing={1}>
            <Input style={{ boxShadow: 'none', width: '300px' }} type="file" onChange={(e) => { setDeliveryImage(e.target.files[0]) }} />
            <CustomButton onClick={() => { submitImage('delivery') }} disabled={!deliveryImage} >Neue Fotos</CustomButton>
          </Stack>
          <br/>
          <Typography className='secondary-textcolor'>Fotos Material:</Typography>
          <Stack direction={'row'} mt={1} spacing={1} style={{ overflowX: 'scroll' }}>
            {images.filter(
              e => (e.type === 'material')
            ).map(e => <Stack key={e.id} direction={'column'} spacing={1}>
              <ImageStage image={e.image} maxHeight={200}/>
              <Button
                variant="outlined"
                color="error"
                onClick={() => { deleteImage(e.id) }}>
                {'Löschen'}
              </Button>
            </Stack>)}
          </Stack>
          <Stack direction={'row'} mt={1} spacing={1}>
            <Input style={{ boxShadow: 'none', width: '300px' }} type="file" onChange={(e) => { setMaterialImage(e.target.files[0]) }} />
            <CustomButton onClick={() => { submitImage('material') }} disabled={!materialImage} >Neue Fotos</CustomButton>
          </Stack>
        </div>}
    </CustomModal>)
}

WarehouseDeliveryAmountModal.propTypes = {
  resetParent: PropTypes.func,
  resetState: PropTypes.func,
  isInfo: PropTypes.bool,
  warehouseDeliverySupplier: PropTypes.object,
  warehouseProducts: PropTypes.array,
  suppliers: PropTypes.array,
  session: PropTypes.object,
  style: PropTypes.object,
  employee: PropTypes.number
}
