import CustomModal from '../../../shared/modal_utils/CustomModal'
import React, { useEffect, useState } from 'react'
import { planningPropType, projectPropType } from '../../../../elements/PropTypes'
import { PropTypes } from 'prop-types'
import RoofProjectPlanningForm from './RoofProjectPlanningForm'
import { IconButton, Tooltip } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { CustomButton } from '../../../../elements/StyledElements'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import { checkIfValueIsEmpty, filterObj, getErrorMessage, hasPermission, isString } from '../../../../elements/utils'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import axios from 'axios'
import {
  API_URL_CREATEPLANNING,
  API_URL_PLANNING, API_URL_ROOFPROJECT_PLANNINGADDITIONALIMAGE
} from '../../../../settings'
import ErrorMessage from '../../../../elements/ErrorMessage'

const emptyPlanningForm = {
  project: null,
  notes_dach: '',
  notes_fester: '',
  roofprojectroofimage_set: [],
  roofprojectplanningadditionalimage_set: [],
  resourcetype: 'RoofProjectPlanning'
}

const mandatoryFields = ['roofprojectroofimage_set']
export default function RoofProjectPlanningFormModal ({ project, planning, setPlanning, getOpenButton, resetParent, session }) {
  const [planningForm, setPlanningForm] = useState({ ...emptyPlanningForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const existOffer = !!planning && project.baseoffer_set.some(offer => offer.planning === planning.id)

  // Autosave
  useEffect(() => {
    const debounceSubmit = debounce(() => onSubmit(null, false), 2500)

    if (!existOffer) debounceSubmit()

    return () => {
      debounceSubmit.cancel()
    }
  }, [planningForm])

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    if (planning && !setPlanning) {
      return (
        <Tooltip title={existOffer ? 'Sehen' : 'Bearbeiten'} PopperProps={{ style: { zIndex: 9999 } }} >
          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={toggle} >
            {existOffer
              ? <SearchOutlinedIcon className='secondary-textcolor' fontSize='large' />
              : <EditOutlinedIcon className='secondary-textcolor' fontSize='large' />
            }
          </IconButton>
        </Tooltip>
      )
    }
    return <CustomButton onClick={toggle}>Neue Planung</CustomButton>
  }
  const getImagePromises = async (planningId, images, url, getPromise) => {
    const isNewObj = (obj) => obj.id <= 0
    const deletePromise = (planning)
      ? axios.delete(url, { data: { planning: planningId, except_ids: images.filter(i => !isNewObj(i)).map(i => i.id) } })
      : Promise.resolve()
    return deletePromise
      .then(() => Promise.all(images.map(image => getPromise(planningId, image, url))))
  }

  const getImagePromise = async (planningId, image, url) => {
    const formData = new FormData()
    if (!isString(image.image)) {
      formData.append('image', image.image, image.image.name)
    } else return Promise.resolve()
    formData.append('planning', planningId)
    if (image.id < 0) return axios.post(url, formData)
    formData.append('id', image.id)
    return axios.put(url + image.id, formData)
  }

  const submit = async (planningForm, onSaveClicked) => {
    const promise = planning
      ? await axios.put(API_URL_PLANNING + planning.id, planningForm)
      : await axios.post(API_URL_PLANNING, planningForm)

    const { data } = promise
    const { id } = data
    await getImagePromises(id, planningForm.roofprojectplanningadditionalimage_set,
      API_URL_ROOFPROJECT_PLANNINGADDITIONALIMAGE, getImagePromise)
    if (onSaveClicked) await axios.post(API_URL_CREATEPLANNING, { planning: id })
    return promise
  }

  const fillWithDefault = (planningForm) => {
    return planningForm
  }
  const checkForEmptyFields = (submitWithDefault, planningFormToSubmit) => {
    const emptyFields = []
    for (const field of mandatoryFields) {
      const val = planningFormToSubmit[field]
      if (checkIfValueIsEmpty(val)) {
        emptyFields.push(field)
      }
    }

    return emptyFields
  }
  const onSubmit = async (onSuccess = null, onSaveClicked = false, submitWithDefault = false) => {
    let planningFormToSubmit = submitWithDefault ? fillWithDefault(planningForm) : planningForm
    planningFormToSubmit = filterObj(planningFormToSubmit, Object.keys(emptyPlanningForm))

    const emptyFields = checkForEmptyFields(submitWithDefault, planningFormToSubmit)
    if (onSaveClicked && emptyFields && emptyFields.length > 0) {
      setLoadingElements({ ...loadingElements, showMissingFields: true })
      console.error('Following fields are missing: ', emptyFields)
      toast.error(<ErrorMessage message='Bitte alle Informationen eintragen!' />)
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    planningFormToSubmit.is_complete_click = onSaveClicked

    try {
      if (planningFormToSubmit.project === null) return
      const planningResponse = await submit(planningFormToSubmit, onSaveClicked)
      resetParent(planningResponse.data)
      if (!planning && setPlanning) {
        setPlanning(planningResponse.data)
        setPlanningForm({ ...planningResponse.data, ...planningForm })
      }
      if (onSaveClicked && onSuccess) onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    } catch (error) {
      console.error('Error in PlantPlanningFormModal:onSubmit', error, error.stack)
      toast.error(getErrorMessage(error))
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    }
  }

  const getFooter = (toggle) => {
    return (
        <SaveModalFooter
          id="submit-planning-form"
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          saveBtnLabel='Abschließen'
          onSave={() => { onSubmit(toggle, true) }}
          saveDisabled={!hasPermission(session.user, 'customer_handling') || existOffer}
        />
    )
  }

  const onToggle = (isOpen) => {
    if (isOpen) {
      loadData()
    } else {
      clearData()
    }
    toast.dismiss()
  }

  const loadData = () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    let filledPlanning = { ...emptyPlanningForm }
    if (planning) {
      filledPlanning = { ...filledPlanning, ...planning }
    } else {
      filledPlanning = { ...filledPlanning, project: project.id }
    }
    setPlanningForm(filledPlanning)
  }

  const clearData = () => {
    setPlanningForm({ ...emptyPlanningForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    // setSubmitDefaultModal(false)
    if (setPlanning) setPlanning(null)
  }

  return (<CustomModal size='fullscreen' getOpenButton={_getOpenButton} title="Planung" getFooter={getFooter} onToggle={onToggle}>
    {(planningForm === null)
      ? null
      : <RoofProjectPlanningForm
          project={project}
          planning={planningForm}
          setPlanning={setPlanningForm}
          session={session}
          showMissingFields={loadingElements.showMissingFields}
        />
    }
  </CustomModal>)
}

RoofProjectPlanningFormModal.propTypes = {
  project: projectPropType,
  planning: planningPropType,
  setPlanning: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
