import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import EditWarehouseProductTypeForm from './EditWarehouseProductTypeForm'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { API_URL_WAREHOUSEPRODUCTTYPE } from '../../settings'
import { CustomButton } from '../../elements/StyledElements'
import DropDown from '../../elements/DropDown'

const emptyWarehouseProductTypeForm = {
  id: null,
  name: null
}

export default function EditWarehouseProductTypeModal ({ resetParent, session, style, warehouseProductType, employee }) {
  const [warehouseProductTypeForm, setWarehouseProductTypeForm] = useState({ ...emptyWarehouseProductTypeForm, request_as: employee })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [selectedWarehouseProductType, setSelectedWarehouseProductType] = useState(null)

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle} style={style}> Materialkategorie Bearbeiten </CustomButton>
  }

  const submit = async (warehouseProductTypeForm, isUpdate) => {
    return (isUpdate
      ? axios.put(API_URL_WAREHOUSEPRODUCTTYPE + warehouseProductTypeForm.id, warehouseProductTypeForm)
      : axios.delete(API_URL_WAREHOUSEPRODUCTTYPE + warehouseProductTypeForm.id, warehouseProductTypeForm)
    )
  }

  const onSubmit = async (onSuccess, isUpdate) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(warehouseProductTypeForm, isUpdate).then((res) => {
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    })
      .catch(error => {
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
        console.error(error)
      })
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                submitError={loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={function () { onSubmit(toggle, true) }}
                onDelete={selectedWarehouseProductType ? () => { onSubmit(toggle, false) } : null}
            />)
  }

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
  }

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setWarehouseProductTypeForm({ ...emptyWarehouseProductTypeForm, request_as: employee })
    setSelectedWarehouseProductType(null)
  }

  return (<CustomModal getOpenButton={getOpenButton} title="Materialkategorie" getFooter={getFooter} onToggle={onToggle}>
        <br />
        <div style={{ position: 'relative' }}>
        <DropDown
            onChange={(e) => {
              setSelectedWarehouseProductType(e)
              setWarehouseProductTypeForm({ ...warehouseProductType.find((type) => type.id === e), request_as: employee })
            }}
            options={
              warehouseProductType
                ? warehouseProductType
                  .map((type) => ({ value: type.id, label: type.name }))
                : null
            }
            value={selectedWarehouseProductType}
            text='Materialkategorie'
            search={true}
        />
        <EditWarehouseProductTypeForm
            warehouseProductType={warehouseProductTypeForm}
            session={session}
            showMissingFields={loadingElements.showMissingFields}
            setWarehouseProductType={setWarehouseProductTypeForm}
        />
        </div>
    </CustomModal>)
}

EditWarehouseProductTypeModal.propTypes = {
  warehouseProductType: PropTypes.array,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  style: PropTypes.object,
  employee: PropTypes.number
}
