import React, { Fragment, useState, useEffect, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Col, Container, Row, Input, FormGroup } from 'reactstrap'
import Collapsible from 'react-collapsible'
import { ibanToBic } from 'iban-to-bic'

import { Typography, FormControlLabel, Checkbox, IconButton } from '@mui/material'
import Add from '@mui/icons-material/Add'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import { CustomIconButton } from '../../../../elements/StyledElements'
import DropDown from '../../../../elements/DropDown'
import { defaultIfEmpty, eMeterActions, getRandomId, hasPermission } from '../../../../elements/utils'
import { eMeterPropType, projectPropType, customerPropType } from '../../../../elements/PropTypes'
import LoadingPage from '../../../../elements/LoadingPage'

const emptyEMeterForm = { id: null, value: null, e_meter_id: '', action: null, project: null }

export default function DataInputForm ({ project, setProject, customer, setCustomer, showMissingFields, session }) {
  const [varyingAddress, setVaryingAddress] = useState(null)

  const customerModified = useRef(false)

  useEffect(() => {
    if (customerModified.current) fillBIC(customer.iban)
  }, [customer.iban])

  useEffect(() => {
    if (varyingAddress === false) {
      setProject({
        ...project,
        street_and_number_project: customer.street_and_number,
        zip_and_city_project: customer.zip_and_city,
        province: customer.province
      })
    }
  }, [varyingAddress, customer.street_and_number, customer.zip_and_city, customer.province])

  useEffect(() => {
    setVaryingAddress((project.street_and_number_project !== customer.street_and_number) || (project.zip_and_city_project !== customer.zip_and_city) || (project.province_project !== customer.province))
  }, [])

  const addEMeter = () => {
    setProject(p => ({ ...p, emeter_set: [...p.emeter_set, { ...emptyEMeterForm, project: project.id, id: -getRandomId() }] }))
  }

  const changeEMeter = (eMeterId, attr, value) => {
    setProject(p => {
      const newEMeters = [...p.emeter_set]
      const eMeter = newEMeters.find(e => e.id === eMeterId)
      eMeter[attr] = value
      return { ...p, emeter_set: newEMeters }
    })
  }

  const onCustomerChange = e => {
    customerModified.current = true
    setCustomer(c => ({ ...c, [e.target.name]: e.target.value }))
  }

  const onProjectChange = e => {
    setProject(p => ({ ...p, [e.target.name]: e.target.value }))
  }

  const fillBIC = (iban) => {
    const bic = ibanToBic(iban)
    if (bic) setCustomer({ ...customer, bic })
    else setCustomer({ ...customer, bic: '' })
  }

  if (!customer || !project) return <LoadingPage />

  return (
    <Fragment>
      <>
        <Container>
          <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
            Persönliche Daten
          </Typography>
          <Row>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Anrede:</Typography>
                <DropDown
                  id="gender_dropdown"
                  onChange={(value) => setCustomer(c => ({ ...c, gender: value }))}
                  text={(customer.gender === 'Familie') ? 'Familie' : 'Anrede'}
                  options={['Frau', 'Herr', 'Firma']}
                  value={customer.gender}
                />
                {(showMissingFields && !(customer.gender))
                  ? <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                  </div>
                  : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Vorname</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="first_name"
                  value={customer.first_name}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && (customer.gender !== 'Firma') && !(customer.first_name))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Nachname</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="last_name"
                  value={customer.last_name}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && (customer.gender !== 'Firma') && !(customer.last_name))}

                />
              </FormGroup>
            </Col>
          </Row>
          {(customer.gender === 'Firma')
            ? <Row>
              <Col>
                <FormGroup>
                  <Typography className='secondary-textcolor'>Firma</Typography>
                  <Input
                    id="input"
                    required={true}
                    style={{ boxShadow: 'none' }}
                    type="text"
                    name="company"
                    value={customer.company}
                    autoComplete="off"
                    onChange={onCustomerChange}
                    invalid={!!(showMissingFields && (customer.gender === 'Firma') && !(customer.company))}

                  />
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
            : null}
          <Row>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Straße Hausnr.</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="street_and_number"
                  value={customer.street_and_number}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !(customer.street_and_number))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>PLZ Ort</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="zip_and_city"
                  value={customer.zip_and_city}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !(customer.zip_and_city))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Bundesland:</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="province"
                  value={customer.province}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !(customer.province))}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <FormControlLabel className='secondary-textcolor' control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} checked={!varyingAddress} onChange={(e) => { setVaryingAddress(!e.target.checked) }} />} label="Anlagenanschrift und Betreiberanschrift stimmen überein." />
          </FormGroup>
          {varyingAddress
            ? <>
              <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
                Abweichende Adresse der Anlage
              </Typography>
              <Row>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>Straße Hausnr.</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="street_and_number_project"
                      value={project.street_and_number_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !(project.street_and_number_project))}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>PLZ Ort</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="zip_and_city_project"
                      value={project.zip_and_city_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !(project.zip_and_city_project))}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>Bundesland:</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="province_project"
                      value={project.province_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !(project.province_project))}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
            : null}
          <hr className='secondary-textcolor' />
          {hasPermission(session.user, 'customer_handling_prices')
            ? <>
              <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
                Kontodaten
              </Typography>
              <div className='secondary-textcolor'>
                <Collapsible trigger="[Info öffnen]" triggerWhenOpen="[Info schließen]">
                  <p>
                    Damit der Netzbetreiber Ihnen das Geld für den eingespeisten Strom auszahlen kann, braucht dieser Ihre Kontodaten.
                  </p>
                </Collapsible>
              </div>

              <Row>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>Kontoinhaber</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="account_owner"
                      value={customer.account_owner}
                      autoComplete="off"
                      onChange={onCustomerChange}
                      invalid={!!(showMissingFields && !(customer.account_owner))}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>IBAN</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="iban"
                      value={customer.iban}
                      autoComplete="off"
                      onChange={onCustomerChange}
                      invalid={!!(showMissingFields && !(customer.iban))}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>Bank</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="bank"
                      value={customer.bank}
                      autoComplete="off"
                      onChange={onCustomerChange}
                      invalid={!!(showMissingFields && !(customer.bank))}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>BIC</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="bic"
                      value={customer.bic}
                      autoComplete="off"
                      onChange={onCustomerChange}
                      invalid={!!(showMissingFields && !(customer.bic))}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>Steuernummer</Typography>
                    <div className='secondary-textcolor'>
                      <Collapsible trigger="[Info öffnen]" triggerWhenOpen="[Info schließen]">
                        <p>
                          Es ist die Vorschrift, dass Sie die Anlage beim Finanzamt melden. Damit der Netzbetreiber dies verknüpfen kann, braucht dieser Ihre Steuernummer.
                        </p>
                      </Collapsible>
                    </div>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      name="tax_number"
                      value={customer.tax_number}
                      autoComplete="off"
                      onChange={onCustomerChange}
                      invalid={!!(showMissingFields && !(customer.tax_number))}
                    />
                  </FormGroup>
                </Col>
                <Col>
                </Col>
              </Row>
              <hr className='secondary-textcolor' />
            </>
            : null}
          <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
            Zählerinformationen
          </Typography>
          {showMissingFields && project.emeter_set.length === 0
            ? <div>
              &nbsp;&nbsp;
              <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
            </div>
            : null
          }
          {project.emeter_set.map((eMeter, eidx) => (
            <Row key={`emeter-settings-${eidx}`}>
              <Col>
                <FormGroup>
                  <Typography className='secondary-textcolor'>Zählernummer</Typography>
                  <Input
                    id="input"
                    required={true}
                    style={{ boxShadow: 'none' }}
                    type="text"
                    name={'emeter_id_' + eidx}
                    value={defaultIfEmpty(eMeter.e_meter_id)}
                    autoComplete="off"
                    onChange={(e) => changeEMeter(eMeter.id, 'e_meter_id', e.target.value)}
                    invalid={!!(showMissingFields && !(eMeter.e_meter_id))}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Typography className='secondary-textcolor'>Zählerstand</Typography>
                  <Input
                    id="input"
                    required={true}
                    style={{ boxShadow: 'none' }}
                    type="number"
                    name={'emeter_value_' + eidx}
                    value={defaultIfEmpty(eMeter.value)}
                    min="0"
                    onKeyPress={(e) => { if (e.code === 'Minus') { e.preventDefault() } }}
                    autoComplete="off"
                    onChange={(e) => changeEMeter(eMeter.id, 'value', e.target.value)}
                    invalid={!!(showMissingFields && !(eMeter.value))}
                    onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Typography className='secondary-textcolor'>Aktion</Typography>
                  <div className='secondary-textcolor'>
                    <Collapsible trigger="[Info öffnen]" triggerWhenOpen="[Info schließen]">
                      <>
                        <ul>
                          <li>Ausbau: Wenn Sie einen Zähler ausgebaut haben möchten</li>
                          <li>Keine Aktion: Wenn Sie haben schon einen Zweirichtungszähler haben. !!! Wichtig der Zähler muss wirklich zwischen 1.8.0 und 2.8.0 hin und her schalten!!!!</li>
                          <li>Wechsel: Wenn Sie noch keinen Zweirichtungszähler haben</li>
                        </ul>
                      </>
                    </Collapsible>
                  </div>
                  <DropDown
                    value={eMeter.action}
                    options={eMeterActions}
                    onChange={(value) => changeEMeter(eMeter.id, 'action', value)}
                    text={'Aktion auswählen'}
                  />
                  {showMissingFields && eMeter.action === null
                    ? <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                    </div>
                    : null}
                  <div style={{ float: 'right' }}>
                    <CustomIconButton onClick={() => {
                      setProject(p => {
                        const eMeters = p.emeter_set.filter(e => e.id !== eMeter.id)
                        return { ...p, emeter_set: eMeters }
                      })
                    }} icon={'delete'} />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          ))}
          <div><IconButton size="medium" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={addEMeter}><Add style={{ color: '#424242' }} fontSize='large' /><Typography className='secondary-textcolor'>Zähler hinzufügen</Typography></IconButton> <br /></div>
        </Container >
      </>
    </Fragment >
  )
}

DataInputForm.propTypes = {
  project: projectPropType,
  setProject: PropTypes.func,
  customer: customerPropType,
  setCustomer: PropTypes.func,
  eMeters: PropTypes.arrayOf(eMeterPropType),
  setEMeters: PropTypes.func,
  showMissingFields: PropTypes.bool,
  session: PropTypes.object
}
