import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'

import { isMobileOnly } from 'react-device-detect'
import { Col, Container, Row } from 'reactstrap'
import LoadingPage from '../elements/LoadingPage'
import { CustomButton } from '../elements/StyledElements'
import { getProjectOverview, hasPermission, string2Date } from '../elements/utils'
import {
  API_URL_PROJECT,
  BASE_URL
} from '../settings'
import CustomerTodos from './todos/CustomerTodos'
import ConstructionModal from './project_types/ConstructionModal'
import ConstructionDocumentationFormModal from './project_types/ConstructionDocumentationFormModal'
import TaskTable from './customers/TaskTable'

export default function Construction ({ session }) {
  const [project, setProject] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    getProject()
  }, [id])

  const resetState = () => {
    getProject()
  }

  const getProject = () => {
    axios.get(API_URL_PROJECT + id, { params: { nested: true } })
      .then(
        res => { setProject(res.data) })
      .catch(
        () => { window.location.replace(BASE_URL) })
  }

  if (!project) return <LoadingPage />

  const customer = project.customer_obj

  const projectOverview = getProjectOverview(project)

  const todoOverview = <>
    <div className="text-center">
      <CustomerTodos
        project={project}
        resetParent={resetState}
        parentKey='main_point_construction'
        session={session}
      />
    </div>
  </>

  const constructionModal = <ConstructionModal
    project={project}
    session={session}
    resetParent={resetState}
    disable={!project.accepted_offer}
  />

  const constructionDocumentationModal = <ConstructionDocumentationFormModal
    project={project}
    disable={!(project.construction_obj &&
           project.construction_obj.constructiondates_set &&
           project.construction_obj.constructiondates_set.length > 0 &&
           project.construction_obj.constructiondates_set.some(dateObj => string2Date(dateObj.date) <= new Date()))}
    resetParent={resetState}
    session={session}
  />

  const customerPage = <Link to={'/kunde/' + customer.id} style={{ textDecoration: 'none', display: 'contents' }}>
    <CustomButton style={{ width: '100%' }} icon="customer">Kunde</CustomButton>
  </Link>

  const projectPage = <Link to={'/projekt/' + project.id} style={{ textDecoration: 'none', display: 'contents' }}>
    <CustomButton style={{ width: '100%' }} icon="clipboard">Projekt</CustomButton>
  </Link>

  if (isMobileOnly) {
    return (
      <Fragment>
        <Container>
          <br />
          <br />
          <Col>{projectOverview}</Col>
          <br />
          <Col className=" align-items-center justify-content-center" style={{ paddingLeft: 50, paddingRight: 50 }}>
            {hasPermission(session.user, 'pages_customer_handling')
              ? <>
                <Row>{projectPage}</Row>
                <br />
                <Row>{customerPage}</Row>
              </>
              : null}
            <Row>{constructionModal}</Row>
            <br />
            <Row>{constructionDocumentationModal}</Row>
            <br />
          </Col>
        </Container>
        <br />
        <br />
        <hr className='secondary-textcolor' />
        <Container>{todoOverview}</Container >
      </Fragment >
    )
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <Col className='col-4'>
            <br />
            <Row>
              {projectOverview}
            </Row>
            <hr className='secondary-textcolor' />
            <Row>
              {hasPermission(session.user, 'pages_customer_handling')
                ? <>
                  <Col >
                    <Row>{projectPage}</Row><br /><Row>{customerPage}</Row>
                  </Col>
                  &nbsp;&nbsp;
                </>
                : null}
              <Col >
                <Row>{constructionModal}</Row><br /><Row>{constructionDocumentationModal}</Row>
              </Col>
            </Row>
          </Col>
          <Col>
            {todoOverview}
            <TaskTable customer={customer} project={project} session={session} />
          </Col>
        </Row>
      </Container >
      <br />
    </Fragment >
  )
}

Construction.propTypes = {
  session: PropTypes.object
}
