import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Col, Input, Row } from 'reactstrap'
import { Table } from 'react-bootstrap'
import DropDown from '../../../../elements/DropDown'
import { getImageWindowWithAuth, round } from '../../../../elements/utils'
import { Circle, Image, Layer, Line, Rect, Stage, Text } from 'react-konva'
import { PropTypes } from 'prop-types'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, IconButton, Typography } from '@mui/material'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import axios from 'axios'
import { API_URL_ROOF_PROJECT_LINETYPE } from '../../../../settings'
import LineTypeForm from './LineTypeForm'
import hull from '@andriiheonia/hull'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import ConfirmationModal from '../../../shared/modal_utils/ConfirmationModal'
import DeleteIcon from '@mui/icons-material/Delete'
import { isMobileOnly } from 'react-device-detect'

const emptyLineTypeForm = {
  id: null,
  key: null,
  name: null,
  type: 'Line'
}

export default function RoofProjectRoofImagePlanning ({
  image, maxHeight, maxWidth, currentImageWidthM,
  currentImageWidthPx, polygons, setPolygons,
  blendedStageRef, legendRef, setReadyToSubmit
}) {
  const [lineTypes, setLineTypes] = useState([])
  const [currLineTypeIdx, setCurrLineTypeIdx] = useState(0)
  // const [polygons, setPolygons] = useState([])
  const [imageState, setImageState] = useState({
    stageWidth: null, stageHeight: null, imageScale: null, imageWindow: null
  })
  const [currEditPolygonIdx, setCurrEditPolygonIdx] = useState(-1)
  const [magnifierCursor, setMagnifierCursor] = useState({ cursor: null, signHeight: 1, signWidth: 1 })

  /* Line types operations - New, Edit, and Delete */
  const [lineTypeForm, setLineTypeForm] = useState({ ...emptyLineTypeForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const scaleFactor = currentImageWidthM / currentImageWidthPx
  const magnifierZoom = 2

  const updateMagnifierCursor = (cursor) => {
    const signWidth = (cursor.x <= 100) ? 1 : -1
    const signHeight = (cursor.y <= 100) ? -1 : 1
    setMagnifierCursor({ cursor, signHeight, signWidth })
  }
  const fetchLineTypes = async () => {
    try {
      const res = await axios.get(API_URL_ROOF_PROJECT_LINETYPE, { params: { is_active: true } })
      const newLineTypes = res.data
      setLineTypes(newLineTypes)
      if (newLineTypes.length >= 1) setCurrLineTypeIdx(0)
    } catch (error) {
      console.error(error)
    }
  }
  const loadImage = () => {
    if (image === null) { setImageState({ ...imageState, imageWindow: null }); return }
    getImageWindowWithAuth(image, handleLoad)
  }
  const handleLoad = (newImageWindow) => {
    const scalerW = maxWidth / newImageWindow.width
    const scalerH = maxHeight / newImageWindow.height
    const scaler = (scalerW >= scalerH) ? scalerH : scalerW
    const stageWidth = round(newImageWindow.width * scaler)
    const stageHeight = round(newImageWindow.height * scaler)
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight
    })
  }

  const transformPoints = (points) => {
    const currPoints = []
    const n = points.length / 2
    for (let i = 0; i < n; i++) {
      const x = points[2 * i]
      const y = points[2 * i + 1]
      currPoints.push([x, y])
    }
    return currPoints
  }
  const getHullPoints = (points) => {
    const currPoints = transformPoints(points)
    const hullPoints = hull(currPoints, Infinity)
    hullPoints.pop()

    const newHullPoints = []
    for (const point of hullPoints) {
      newHullPoints.push(point[0])
      newHullPoints.push(point[1])
    }

    return newHullPoints
  }

  const getCentroid = (hullPoints) => {
    if (hullPoints.length > 4) {
      return calculateCentroid(hullPoints)
    } else if (hullPoints.length === 4) {
      const points = hullPoints.slice()
      return [(points[0] + points[2]) / 2, (points[1] + points[3]) / 2]
    }
    return []
  }

  const recalculatePolygon = (currPolygon) => {
    currPolygon.hullPoints = getHullPoints(currPolygon.points)
    if (currPolygon.hullPoints.length >= 4) {
      currPolygon.length = calculatePerimeter(currPolygon.hullPoints)
      currPolygon.area = calculateArea(currPolygon.hullPoints)
      currPolygon.centroid = getCentroid(currPolygon.hullPoints)
    }
  }
  const scaleUpPolygons = polygons => {
    if (!polygons) return []

    const newPolygons = structuredClone(polygons)

    for (const polygon of newPolygons) {
      const newPoints = []
      const newHullPoints = []
      for (const point of polygon.points) {
        const res = point * imageState.imageScale
        if (Number.isNaN(res)) {
          newPoints.push(point)
        } else {
          newPoints.push(res)
        }
      }
      for (const point of polygon.hullPoints) {
        const res = point * imageState.imageScale
        if (Number.isNaN(res)) {
          newHullPoints.push(point)
        } else {
          newHullPoints.push(res)
        }
      }

      polygon.points = newPoints
      polygon.hullPoints = newHullPoints
      if (polygon.centroid.slice().length === 2) {
        polygon.centroid = [polygon.centroid[0] * imageState.imageScale, polygon.centroid[1] * imageState.imageScale]
      }
    }

    return newPolygons
  }

  const handleTap = (e) => {
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    const currLineType = lineTypes[currLineTypeIdx]
    const initPoint = [cursor.x / imageState.imageScale, cursor.y / imageState.imageScale]
    const newPolygons = polygons.slice()

    if (currEditPolygonIdx === -1) {
      // init a polygon
      const polygon = {
        lineType: currLineType.id,
        name: currLineType.name + ' - ' + Number(polygons.length + 1),
        length: 0,
        area: 0,
        points: initPoint,
        hullPoints: [initPoint],
        centroid: []
      }
      setCurrEditPolygonIdx(polygons.length)
      newPolygons.push(polygon)
    } else {
      const currPolygon = newPolygons[currEditPolygonIdx]
      currPolygon.points = [...currPolygon.points.slice(), ...initPoint]
      recalculatePolygon(currPolygon)
    }

    setPolygons(newPolygons)
  }

  const clearAllPolygons = () => {
    setPolygons([])
    setCurrEditPolygonIdx(-1)
  }

  const onSubmitNewLineType = async (onSuccess) => {
    try {
      setLoadingElements({ ...loadingElements, inProgress: true })
      await axios.post(API_URL_ROOF_PROJECT_LINETYPE, lineTypeForm)
      await fetchLineTypes()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      setLineTypeForm({ ...emptyLineTypeForm })
    } catch (error) {
      console.error(error)
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: true })
    }
  }

  const getNewLineTypeOpenButton = (toggle) => {
    return <IconButton size={'small'} style={{ padding: '1px' }} onClick={
      () => {
        setLineTypeForm({ ...emptyLineTypeForm })
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
        toggle()
      }
    }>
        <AddIcon />
    </IconButton>
  }

  const getDeleteLineTypeOpenButton = (toggle) => {
    return <IconButton size={'small'} style={{ padding: '1px' }} onClick={
      () => {
        setLineTypeForm({ ...emptyLineTypeForm })
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
        toggle()
      }
    }>
        <DeleteIcon />
    </IconButton>
  }
  const getNewLineTypeFooter = (toggle) => {
    return (
      <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={ function () { onSubmitNewLineType(toggle) } }
      />)
  }

  const handleEditPoint = (e, polygonIdx, pointIdx) => {
    handleMagnifierMove(e)
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    const newPolygons = polygons.slice()
    const newPoint = [cursor.x / imageState.imageScale, cursor.y / imageState.imageScale]
    const currPolygon = newPolygons[polygonIdx]

    currPolygon.points[2 * pointIdx] = newPoint[0]
    currPolygon.points[2 * pointIdx + 1] = newPoint[1]

    recalculatePolygon(currPolygon)
    setPolygons(newPolygons)
  }

  const onDeletePolygon = (idx) => {
    const newPolygons = polygons.slice()
    newPolygons.splice(idx, 1)

    setPolygons(newPolygons)
    setCurrEditPolygonIdx(-1)
  }

  const onUpdatePolygonName = (e) => {
    const name = e.target.value
    const newPolygons = polygons.slice()
    const currPolygon = newPolygons[currEditPolygonIdx]
    currPolygon.name = name

    setPolygons(newPolygons)
  }

  const calculatePerimeter = (points, inMeter = true) => {
    const n = points.length / 2
    let perimeter = 0
    for (let i = 0; i < n; i++) {
      const x1 = points[2 * i]
      const y1 = points[2 * i + 1]
      const x2 = points[2 * ((i + 1) % n)]
      const y2 = points[2 * ((i + 1) % n) + 1]
      perimeter += Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
    }
    if (n === 2) perimeter /= 2
    if (inMeter) perimeter *= scaleFactor

    return round(perimeter, 2)
  }

  const calculateArea = (points, inMeter = true) => {
    const n = points.length / 2
    let area = 0
    for (let i = 0; i < n; i++) {
      const x1 = points[2 * i]
      const y1 = points[2 * i + 1]
      const x2 = points[2 * ((i + 1) % n)]
      const y2 = points[2 * ((i + 1) % n) + 1]
      area += x1 * y2 - y1 * x2
    }
    area = Math.abs(area) / 2
    if (inMeter) area *= Math.pow(scaleFactor, 2)
    return round(area, 2)
  }

  const calculateCentroid = (points) => {
    const n = points.length / 2
    let Cx = 0
    let Cy = 0
    let signedArea = 0

    for (let i = 0; i < n; i++) {
      const x1 = points[2 * i]
      const y1 = points[2 * i + 1]
      const x2 = points[2 * ((i + 1) % n)]
      const y2 = points[2 * ((i + 1) % n) + 1]
      const factor = (x1 * y2 - y1 * x2)
      signedArea += factor
      Cx += (x1 + x2) * factor
      Cy += (y1 + y2) * factor
    }

    signedArea *= 0.5
    Cx /= (6 * signedArea)
    Cy /= (6 * signedArea)

    return [Cx, Cy]
  }
  const handleMagnifierMove = (e) => {
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    updateMagnifierCursor(cursor)
  }

  const removePolygonWithLineTypeId = (lineTypeId) => {
    const newPolygons = []

    polygons.forEach((polygon) => {
      if (polygon.lineType === lineTypeId) return

      newPolygons.push(polygon)
    })

    setPolygons(newPolygons)
  }

  useEffect(() => {
    fetchLineTypes()
  }, [])

  useEffect(() => {
    setReadyToSubmit(currEditPolygonIdx === -1)
  }, [currEditPolygonIdx])

  useEffect(() => { loadImage() }, [image])

  const scaledUpPolygons = useMemo(() => scaleUpPolygons(polygons), [polygons, imageState.imageScale])

  if (!imageState.imageWindow) return <></>

  return (
      <Row>
        <div style={ { display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row' } }>
          <Col style={ { flexGrow: 0, flexBasis: '13em' } }>
            <div style={ { margin: '5px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <Typography className='secondary-textcolor' sx={{ fontWeight: 'bold' }}>Linientyp:</Typography>
                <div style={{ display: 'flex', alignItems: 'center', padding: '1px', gap: '5px' }}>
                  <DropDown
                    onChange={name => setCurrLineTypeIdx(lineTypes.findIndex(l => l.name === name))}
                    text="Line type"
                    options={lineTypes.map(lineType => lineType.name)}
                    value={lineTypes[currLineTypeIdx] ? lineTypes[currLineTypeIdx].name : 'Keine Linientypen'}
                  />
                  <CustomModal getOpenButton={getNewLineTypeOpenButton} title="Neuer Linientyp" getFooter={getNewLineTypeFooter}>
                   <LineTypeForm
                     lineTypeForm={lineTypeForm}
                     setLineTypeForm={setLineTypeForm}
                     showMissingFields={loadingElements.showMissingFields}
                   />
                  </CustomModal>
                  {currLineTypeIdx >= 0 && lineTypes[currLineTypeIdx] &&
                    <ConfirmationModal
                      title={'Möchten Sie Linientyp ' + lineTypes[currLineTypeIdx].name + ' wirklich löschen?'}
                      resetParent={async () => await fetchLineTypes()}
                      confirm={async () => {
                        removePolygonWithLineTypeId(lineTypes[currLineTypeIdx].id)
                        await axios.put(API_URL_ROOF_PROJECT_LINETYPE + lineTypes[currLineTypeIdx].id, { is_active: false })
                        await fetchLineTypes()
                      }}
                      getOpenButton={getDeleteLineTypeOpenButton}
                    />
                  }
                </div>
                <Typography className='secondary-textcolor' sx={{ fontWeight: 'bold' }}>Andere:</Typography>
                <div>
                  <Button variant="outlined" color="error" onClick={clearAllPolygons}>Alle löschen</Button>
                </div>
              </div>
            </div>
            <br />
            <Stage width={150} height={150}>
              <Layer>
                {magnifierCursor.cursor
                  ? <>
                    <Rect
                      fillPatternImage={imageState.imageWindow}
                      fillPatternX={parseInt(-magnifierCursor.cursor.x * magnifierZoom + 75)}
                      fillPatternY={parseInt(-magnifierCursor.cursor.y * magnifierZoom + 75)}
                      fillPatternScaleX={imageState.imageScale * magnifierZoom}
                      fillPatternScaleY={imageState.imageScale * magnifierZoom}
                      x={0} y={0}
                      width={150}
                      height={150}
                      stroke={'black'}
                      strokeWidth={1}
                    />
                    <Circle
                      x={75}
                      y={75}
                      radius={3}
                      fill={'red'}
                    />
                  </>
                  : null}
              </Layer>
            </Stage>
          </Col>
          <Col style={{ margin: '5px' }}>
            <Stage width={imageState.stageWidth} height={imageState.stageHeight} ref={blendedStageRef}>
              <Layer>
                <Image
                  id='scaleroofimage'
                  image={imageState.imageWindow}
                  scaleX={imageState.imageScale}
                  scaleY={imageState.imageScale}
                  onTap={handleTap}
                  onClick={handleTap}
                  onMouseMove={handleMagnifierMove}
                  onMouseLeave={() => setMagnifierCursor({ cursor: null, signHeight: 1, signWidth: 1 })}
                />
                {magnifierCursor.cursor
                  ? <Circle
                  fillPatternImage={imageState.imageWindow}
                  fillPatternX={parseInt(-magnifierCursor.cursor.x * magnifierZoom)}
                  fillPatternY={parseInt(-magnifierCursor.cursor.y * magnifierZoom)}
                  fillPatternScaleX={imageState.imageScale * magnifierZoom}
                  fillPatternScaleY={imageState.imageScale * magnifierZoom}
                  x={magnifierCursor.cursor.x + magnifierCursor.signWidth * 50}
                  y={magnifierCursor.cursor.y - magnifierCursor.signHeight * 50}
                  radius={50}
                  stroke={'black'}
                  strokeWidth={1}
                />
                  : null}
              {magnifierCursor.cursor
                ? <Circle
                  x={magnifierCursor.cursor.x + magnifierCursor.signWidth * 50}
                  y={magnifierCursor.cursor.y - magnifierCursor.signHeight * 50}
                  radius={3}
                  fill={'red'}
                />
                : null}
                {
                  scaledUpPolygons.map((polygon, i) => {
                    return <Fragment key={i}>
                      {polygon.hullPoints.length >= 4 &&
                        <Line
                          key={i}
                          fill="#00D2FF"
                          x={0} y={0}
                          points={polygon.hullPoints}
                          closed
                          stroke="red"
                          opacity={polygon.hullPoints.length <= 4 ? 1 : 0.5}
                          onTap={handleTap}
                          onClick={handleTap}
                        />
                      }
                      {polygon.centroid.length === 2 &&
                        <Fragment>
                          <Circle
                            fill={'green'}
                            x={parseInt(polygon.centroid[0])}
                            y={parseInt(polygon.centroid[1])}
                            radius={16}
                            strokeWidth={1}
                            stroke={'black'}
                          />
                          <Text
                            text={i + 1}
                            x={parseInt(polygon.centroid[0]) - 8}
                            y={parseInt(polygon.centroid[1]) - 8}
                            align={'center'}
                            verticalAlign={'middle'}
                            fontFamily='Calibri'
                            fontSize={18}
                            fill='white'
                          />
                        </Fragment>
                      }
                      {transformPoints(polygon.points).map((point, j) => {
                        return <Fragment key={j}>
                          <Circle
                            key={j}
                            onTap={handleTap}
                            onClick={handleTap}
                            onDragMove={ (e) => handleEditPoint(e, i, j)}
                            onDragEnd={() => setMagnifierCursor({ cursor: null, signHeight: 1, signWidth: 1 })}
                            x={parseInt(point[0])} y={parseInt(point[1])}
                            radius={7}
                            fill={'blue'}
                            draggable={true}
                          />
                        </Fragment>
                      })}
                    </Fragment>
                  })
                }
              </Layer>
            </Stage>
          </Col>
        </div>
        <div style={ { display: 'flex' }}>
           <Col style={!isMobileOnly ? { flexGrow: 0, flexBasis: '50em' } : {} }>
            <Table ref={legendRef}>
              <thead>
                <tr>
                  <th style={{ color: 'white', backgroundColor: '#424242' }}>#</th>
                  <th style={{ color: 'white', backgroundColor: '#424242' }}>Name</th>
                  <th style={{ color: 'white', backgroundColor: '#424242' }}>Länge [m]</th>
                  <th style={{ color: 'white', backgroundColor: '#424242' }}>Fläche [m2]</th>
                  <th data-html2canvas-ignore='true' style={{ color: 'white', backgroundColor: '#424242' }}></th>
                </tr>
              </thead>
              <tbody>
              {
                scaledUpPolygons.map((polygon, i) => {
                  return (
                    <tr key={polygon.id}>
                      <td>{i + 1}</td>
                      {i === currEditPolygonIdx
                        ? <td><Input
                          key={`input ${i + 1}`}
                          id="input"
                          name={`input ${i + 1}`}
                          onChange={onUpdatePolygonName}
                          value={polygon.name}
                          bsSize="sm"
                          style={{ boxShadow: 'none', margin: '1px 1px' }}
                        /></td>
                        : <td>{polygon.name}</td>
                      }
                      <td>{polygon.length}</td>
                      <td>{polygon.area}</td>
                      <td data-html2canvas-ignore='true' style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {i === currEditPolygonIdx
                        ? <IconButton size={'small'}
                                      style={{ padding: '1px' }}
                                      onClick={() => setCurrEditPolygonIdx(-1)}>
                            <SaveIcon/>
                          </IconButton>
                        : <IconButton size={'small'}
                                      style={{ padding: '1px' }}
                                      onClick={() => setCurrEditPolygonIdx(i)}>
                            <EditIcon/>
                          </IconButton>
                      }
                        <IconButton size={'small'}
                                    style={{ padding: '1px' }}
                                    onClick={() => onDeletePolygon(i)}>
                          <ClearIcon/>
                        </IconButton>
                     </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </Table>
           </Col>
          </div>
      </Row>
  )
}

RoofProjectRoofImagePlanning.propTypes = {
  image: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  currentImageWidthM: PropTypes.number,
  currentImageDepthM: PropTypes.number,
  currentImageWidthPx: PropTypes.number,
  currentImageDepthPx: PropTypes.number,
  polygons: PropTypes.array,
  setPolygons: PropTypes.func,
  blendedStageRef: PropTypes.func,
  legendRef: PropTypes.func,
  setReadyToSubmit: PropTypes.func
}
