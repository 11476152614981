import React from 'react'

import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'

import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { SortableHandle } from 'react-sortable-hoc'

import './CollapsibleTableStyle.css'
import { isString } from './utils'

function Cell ({ text, link, style = {}, targetBlank = false }) {
  const content = (typeof text === 'object') ? text : <Typography className='secondary-textcolor'>{text}</Typography>
  if (!link) return <td style={style}>{content}</td>

  if (!isString(link)) {
    return <td style={style} onClick={link}>{content}</td>
  }
  return (
    <td style={style}>
      <Link to={link} style={{ textDecoration: 'none' }} target={targetBlank ? '_blank' : '_self'}>
      {content}
      </Link>
    </td>
  )
}

Cell.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
  targetBlank: PropTypes.bool
}

export function CollapseButton ({ isOpen, onClick }) {
  return <IconButton
    aria-label="expand row"
    size="small"
    onClick={onClick}
  >
    {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  </IconButton>
}

CollapseButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
}

const RowHandler = SortableHandle(() => <td><Typography className='secondary-textcolor' style={{ cursor: 'grab' }}>::</Typography></td>)

export default function CollapsibleTableRow ({ columns, row, index, rowsLength, counter = false, collapsible = true, startCounter = 1, hover = true, buttomLine = true, openRow, setOpenRow, isDraggable, colSpan, targetBlank = false }) {
  let trClassName = hover ? 'my-row-hover' : 'my-row-not-hover'
  if (!buttomLine && index === rowsLength - 1) trClassName += ' last-table-tr'
  const rowLink = row.link
    ? (isString(row.link) ? row.link : () => { row.link(row) })
    : () => setOpenRow(openRow === row.key ? null : row.key)

  return (
    [<tr className={trClassName} key={`table-row-${index}`} style={row.style}>
        {isDraggable ? <RowHandler/> : null}
        {collapsible
          ? <td>
            <CollapseButton
              onClick={() => setOpenRow(openRow === row.key ? null : row.key)}
              isOpen={openRow === row.key || openRow === -1}
            />
          </td>
          : null}
        {counter ? <Cell key={`table-row-${index}-column-counter`} text={index + startCounter} link={rowLink} targetBlank={targetBlank} /> : null}
        {row.data
          ? row.data
          : columns.map((column, cIdx) => {
            const content = column.render
              ? column.render(row[column.key], row)
              : row[column.key]

            return (
              <Cell style={column.style ? column.style : {}} key={`table-row-${index}-column-${cIdx}`} text={content} link={(column.clickable !== false) ? rowLink : null} targetBlank={targetBlank} />
            )
          })}
      </tr>,
    collapsible
      ? (
          <tr key={`table-row-${index}-child`} style={(openRow !== row.key && openRow !== -1) ? { display: 'none' } : {}}>
            <td></td>
            <td colSpan={colSpan} align="center" style={{ padding: '3px' }}>
              <Collapse in={openRow === row.key || openRow === -1} timeout="auto" unmountOnExit>
                {row.child}
              </Collapse>
            </td>
          </tr>
        )
      : null]
  )
}

CollapsibleTableRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.object,
  index: PropTypes.number,
  rowsLength: PropTypes.number,
  counter: PropTypes.bool,
  collapsible: PropTypes.bool,
  startCounter: PropTypes.number,
  hover: PropTypes.bool,
  buttomLine: PropTypes.bool,
  openRow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  setOpenRow: PropTypes.func,
  isDraggable: PropTypes.bool,
  colSpan: PropTypes.number,
  targetBlank: PropTypes.bool
}
