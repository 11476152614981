import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import { Spinner } from 'reactstrap'

import { Typography, IconButton } from '@mui/material'

import Add from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'

import SendOperatorRequestModal from './SendOperatorRequestModal'

import { API_URL_OPERATORREQUEST, PROTOCOL } from '../../../../settings'
import PdfViewModal from '../../../shared/modal_utils/PdfViewModal'
import { getCustomerName } from '../../../../elements/utils'
import { projectPropType } from '../../../../elements/PropTypes'

export default function ImplementedOperatorRequest ({ isOpen, project, resetParent, requestMode, session }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, modeInProgress: []
  })

  const resetState = () => { resetParent(); setLoadingElements({ inProgress: false, submitError: false, modeInProgress: [] }) }

  useEffect(() => {
    if (!isOpen) setLoadingElements({ inProgress: false, submitError: false, modeInProgress: [] })
  }, [isOpen])

  const createRequest = (mode) => {
    setLoadingElements({ inProgress: true, submitError: false, modeInProgress: [mode] })
    axios.post(API_URL_OPERATORREQUEST, { mode, project: project.id })
      .then(resetState)
      .catch(error => {
        setLoadingElements({ inProgress: false, submitError: false, modeInProgress: [] })
        if (error.response.status === 422) {
          alert('Folgende Daten für den Kunden fehlen, um den Netzantrag zu erstellen: ' + error.response.data.join(', '))
          return
        }
        return Promise.reject(error)
      })
  }

  // const createAllAndSend = () => {
  //   const modes = [0, 1, 2]
  //   setLoadingElements({ inProgress: true, submitError: false, modeInProgress: modes })
  //   const promises = modes.map((mode) =>
  //     axios.post(API_URL_OPERATORREQUEST, { mode, project: project.id })
  //       .then(async (docRes) => {
  //         return axios.get(API_URL_SENDOPERATORREQUEST, { params: { mode } })
  //           .then((emailRes) => wrapEmailMessage(session, emailRes.data, project.customer_obj, null))
  //           .then((message) =>
  //             axios.post(API_URL_SENDOPERATORREQUEST, { mode, project: project.id, mail: { subject: 'Netzantrag ' + getCustomerName(project.customer_obj), message } })
  //               .then((res) => onSend(res, mode, docRes.data))
  //           )
  //       })
  //       .catch(error => {
  //         if (!error.response) return Promise.reject(error)
  //         if (error.response.status === 422) {
  //           alert('Folgende Daten für den Kunden fehlen, um den Netzantrag zu erstellen: ' + error.response.data.join(', '))
  //           return
  //         }
  //         return Promise.reject(error)
  //       })
  //   )
  //   Promise.all(promises).then(resetParent)
  // }

  const onSend = (res, mode, modeDocuments) => {
    return res
  }

  const getRow = (mode, title) => {
    const modeDocuments = project.operatorrequestdoc_set.filter(doc => doc.mode === mode)
    const nDocuments = modeDocuments.length
    const sent = modeDocuments.some(doc => doc.sent)
    if (nDocuments === 0) {
      return <tr key={`${title}_row`}>
        <td><Typography className='secondary-textcolor'>{title}</Typography></td>
        <td className="align-right" align="right">
          <IconButton disableFocusRipple disableRipple disabled={loadingElements.inProgress} style={{ backgroundColor: 'transparent' }} onClick={() => createRequest(mode)}><Add className='secondary-textcolor' fontSize='large' /></IconButton>
          {(loadingElements.inProgress && loadingElements.modeInProgress.includes(mode)) ? <Spinner color="dark" size="sm" /> : null}
        </td>
        <td></td>
        <td></td>
      </tr>
    }
    const rows = []
    modeDocuments.forEach((doc, idx) => {
      const style = (mode === requestMode || (Array.isArray(requestMode) && requestMode.includes(mode))) ? { backgroundColor: '#cfe2f3' } : {}
      rows.push(<tr key={`${title}_filled_row`} style={style}>
        {(idx === 0) ? <td rowSpan={nDocuments}>{title}</td> : null}
        {(idx === 0)
          ? <td rowSpan={nDocuments} className="align-right" align="right">
            <IconButton disableFocusRipple disableRipple disabled={loadingElements.inProgress} style={{ backgroundColor: 'transparent' }} onClick={() => createRequest(mode)}><RefreshIcon className='secondary-textcolor' fontSize='large' /></IconButton>
            {(loadingElements.inProgress && loadingElements.modeInProgress.includes(mode)) ? <Spinner color="dark" size="sm" /> : null}
            {(!sent) ? <SendOperatorRequestModal disabled={loadingElements.inProgress} project={project} mode={mode} resetParent={resetState} onSubmit={(res) => onSend(res, mode, modeDocuments)} session={session} /> : <IconButton disabled={true} disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }}>  <MarkEmailReadOutlinedIcon color="success" style={{ float: 'right' }} fontSize='large' /> </IconButton>}
          </td>
          : null}
        <td><Typography className='secondary-textcolor'>{doc.name}</Typography></td>
        <td className="align-right" align="right">
          <PdfViewModal
            title={doc.name + ' ' + getCustomerName(project.customer_obj)}
            filepath={(doc.pdf) ? doc.pdf.replace('http:', PROTOCOL + ':') : null}
          />
        </td>
      </tr>)
    })
    return rows
  }

  return <Table>
    <thead>
      <tr>
        <th style={{ width: '15%' }}></th>
        <th style={{ width: '15%' }}></th>
        <th style={{ width: '60%' }}><Typography className='secondary-textcolor'>Dokument</Typography></th>
        <th style={{ width: '10%' }}></th>
      </tr>
    </thead>
    <tbody>
      {getRow(0, 'Anfrage')}
      {getRow(1, 'Anmeldung (nach Montage)')}
      {getRow(2, 'Inbetriebssetzungsantrag (nach Zählerwechsel)')}
      {getRow(3, 'Wallbox')}
    </tbody>
  </Table>
}

ImplementedOperatorRequest.propTypes = {
  isOpen: PropTypes.bool,
  project: projectPropType,
  resetParent: PropTypes.func,
  requestMode: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  session: PropTypes.object
}
