import React from 'react'
import { PropTypes } from 'prop-types'

import NewPlanningOfferModal from '../offers/NewPlanningOfferModal'
import OfferFormModal from '../offers/OfferFormModal'
import { projectPropType } from '../../elements/PropTypes'

export default function NewOfferModal ({ project, session, getOpenButton, resetParent, additionalDetails, planning, setPlanning }) {
  if (['PlantProject', 'HeatpumpProject', 'RoofProject'].includes(project.resourcetype)) {
    return <NewPlanningOfferModal
            project={project}
            session={session}
            getOpenButton={getOpenButton}
            resetParent={resetParent}
            additionalDetails={additionalDetails}
            planning={planning}
            setPlanning={setPlanning}
        />
  }
  return <OfferFormModal
        project={project}
        getOpenButton={getOpenButton}
        resetParent={resetParent}
        session={session}
        additionalDetails={additionalDetails}
    />
}

NewOfferModal.propTypes = {
  project: projectPropType,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  additionalDetails: PropTypes.object,
  planning: PropTypes.object,
  setPlanning: PropTypes.func
}
