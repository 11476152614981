import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'
import { ibanToBic } from 'iban-to-bic'

import { Typography, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'

import LoadingPage from '../../../../../elements/LoadingPage'
import CustomForm, { getEmptyFieldsErrorFromTemplate } from '../../../../../elements/CustomForm'
import CustomModal from '../../../../shared/modal_utils/CustomModal'
import ConfirmationModalFooter from '../../../../shared/modal_utils/ConfirmationModalFooter'
import { CustomButton } from '../../../../../elements/StyledElements'
import { getDataInputFormTemplate } from './DataInputFormTemplate'
import { CAPI_URL_PVDATAINPUT } from '../../../../../settings'
import LogoHeader from '../../../../../elements/LogoHeader'
import { getRandomId } from '../../../../../elements/utils'

const emptyDataInputForm = {
  iban: null,
  bic: null,
  bank: null,
  account_owner: null,
  tax_number: null,
  birth_date: null,
  emeter_set: []
}

export default function DataInput ({ session }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [projectInfo, setProjectInfo] = useState(null)
  const [notFound, setNotFound] = useState(null)
  const [dataInputForm, setDataInputForm] = useState(emptyDataInputForm)
  const [showSuccessForProject, setShowSuccessForProject] = useState(null)

  const { token } = useParams()

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    const bic = ibanToBic(dataInputForm.iban)
    setDataInputForm(f => ({ ...f, bic: bic || null }))
  }, [dataInputForm.iban])

  const loadData = () => {
    Promise.all([
      axios.get(CAPI_URL_PVDATAINPUT, { params: { token } })
        .then(res => { setProjectInfo(res.data); setDataInputForm({ ...emptyDataInputForm, emeter_set: [{ id: -getRandomId(), e_meter_id: null, value: null, action: null, project: res.data.project_id }] }) })
        .catch(error => {
          setNotFound(true)
          console.error(error)
        })
    ])
  }

  const getOpenButton = (toggle) => {
    return <CustomButton style={{ float: 'right' }} color='#357a38' icon='send' onClick={toggle}>Daten absenden</CustomButton>
  }

  const submit = async () => {
    return axios.post(CAPI_URL_PVDATAINPUT, { token, ...dataInputForm })
  }

  if (notFound) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={6} sm={6} md={6} item>
          <br />
          <br />
          <LogoHeader />
          <br />
          <div className="text-center">
            <Typography color={'error'} className='secondary-textcolor' variant='h4'>Die Dateneingabe ist bereits erfolgt.</Typography>
            {/* <br />
            <Typography className='secondary-textcolor' variant='h5'>Die Dateneingabe ist bereits erfolgt.</Typography> */}
          </div>
        </Grid>
      </Grid>
    )
  }
  if (!projectInfo) return <LoadingPage />

  const template = getDataInputFormTemplate(projectInfo.project_id)

  const onSubmit = async (onSuccess) => {
    const emptyFieldsError = getEmptyFieldsErrorFromTemplate(template, dataInputForm, emptyDataInputForm)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
      console.error(emptyFieldsError)
      onSuccess()
      return
    }
    return submit()
      .then((res) => {
        onSuccess()
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
        setShowSuccessForProject(true)
      }).catch(error => { console.error('Error in "customer:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return <ConfirmationModalFooter
      btnLabel="Bestätigen"
      id="view-submit-data-input"
      // submitError={loadingElements.submitError}
      inProgress={loadingElements.inProgress}
      onConfirm={() => onSubmit(toggle)}
      onCancel={() => toggle()}
    />
  }

  if (showSuccessForProject) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={6} sm={6} md={6} item>
          <LogoHeader />
          <br />
          <br />
          <Alert severity="success">Ihre Daten wurden erfolgreich gespeichert!</Alert>
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={6} sm={6} md={6} item>
          <div className="text-center">
            <br />
            <Grid container alignItems="center" justifyContent="center">
              <Grid xs={2} sm={2} md={2} item>
                <LogoHeader />
              </Grid>
              <Grid xs={10} sm={10} md={10} item>
                <Typography className='secondary-textcolor' variant='h5'>Dateneingabe für Projekt {projectInfo.street_and_number}</Typography>
              </Grid>
            </Grid>
            <br />
            <Typography className='secondary-textcolor' align='justify'>
              Folgende Daten benötigen wir, um den Netzantrag für Ihre PV-Anlage zu stellen.
              Ihre Kontodaten sind beispielsweise erforderlich, damit der Netzbetreiber Ihnen die Einspeisevergütung für ihren eingespeisten Strom überweisen kann.
              Zudem ist es (unabhängig von einer Steuerbefreiung) Pflicht, dass der Netzbetreiber die Einnahmen beim Finanzamt meldet. Dazu benötigt dieser Ihre Steuernummer.
              <br /><br />
              Bitte tragen Sie daher folgend Ihre Daten ein, damit wir Ihren Netzantrag bearbeiten können.
              Sobald Sie alle Daten eingegeben haben, klicken Sie bitte &quot;Daten absenden&quot;.
              Falls Daten fehlen, wird eine Fehlermeldung, andernfalls eine Meldung über die erfolgreiche Übermittlung Ihrer Daten angezeigt.
              Sobald uns Ihre Daten übermittelt wurden, können wir mit der Bearbeitung Ihres Netzantrages beginnen.
              {/* Die folgenden Daten benötigen wir für Ihren Netzantrag.
              Wenn Sie ein Angebot angenommen und eine Bestätigungsemail erhalten haben, in der Sie zur
              Dateneingabe aufgefordert werden, tragen Sie bitte die Informationen ein.
              Sobald Sie alle Daten eingegeben haben, klicken Sie bitte &quot;Daten absenden&quot;.
              Falls Daten fehlen, wird Ihnen eine Fehlermeldung angezeigt.
              Andernfalls werden Ihre Daten an uns übermittelt und wir können mit der Bearbeitung Ihres Netzantrages fortfahren. */}
            </Typography>
            {loadingElements.showMissingFields ? <Alert fontSize="12" severity="error">Die Daten sind nicht vollständig. Bitte füllen Sie die fehlenden Daten aus!</Alert> : null}
          </div>
          <br />
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={6} sm={6} md={6} >
          <CustomForm
            template={template}
            form={dataInputForm}
            setForm={setDataInputForm}
            defaultForm={emptyDataInputForm}
            showMissingFields={loadingElements.showMissingFields}
          />

          <CustomModal size='md' getOpenButton={getOpenButton} title="Dateneingabe fertigstellen" getFooter={getFooter}>
            <br />
            <Typography className='secondary-textcolor' sx={{ fontWeight: 'bold' }} >
              {'Mit einem Klick auf "Bestätigen" wird die Firma Planville bevollmächtigt, alle für den Betrieb einer Photovoltaikanlage notwendigen Anmeldungen bei dem zuständigen Netzbetreiber vorzunehmen!'}
            </Typography>
            <br />
          </CustomModal>
          {/* <hr className='secondary-textcolor' /> */}
          <div className="text-center">
            <br />

            <br />
            <Typography className='secondary-textcolor' variant='body'>{'Bei Problemen bei der Dateneingabe wenden Sie sich bitte an Planville!'}</Typography>
            <br />
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
    </Fragment >
  )
}

DataInput.propTypes = {
  session: PropTypes.object
}
