import React, { useState } from 'react'

import { PropTypes } from 'prop-types'

import CheckIcon from '@mui/icons-material/Check'
import { IconButton, Tooltip } from '@mui/material'

import { offerPropType, projectPropType } from '../../../../elements/PropTypes'
import AcceptOfferModal from '../../../offers/AcceptOfferModal'
import UploadMap from './UploadMap'

export default function AcceptPlantOfferModal ({ project, offer, resetParent, session }) {
  const [mapIsOpen, setMapIsOpen] = useState(false)
  const [acceptedIsOpen, setAcceptedIsOpen] = useState(false)

  return (
    <>
      <Tooltip title='Angenommen' PopperProps={{ style: { zIndex: 9999 } }} >
        <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={() => setMapIsOpen(true)}><CheckIcon style={{ color: '#d4bc0b' }} fontSize='large' /></IconButton>
      </Tooltip>
      <UploadMap
        project={project}
        offer={offer}
        onSubmit={() => { setAcceptedIsOpen(true); setMapIsOpen(false) }}
        isOpen={mapIsOpen}
        setIsOpen={setMapIsOpen}
        session={session}
      />
      <AcceptOfferModal
        project={project}
        offer={offer}
        isOpen={acceptedIsOpen}
        setIsOpen={setAcceptedIsOpen}
        getOpenButton={() => null}
        resetParent={resetParent}
        session={session}
      />

    </>
  )
}

AcceptPlantOfferModal.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
