import { PropTypes } from 'prop-types'
import React from 'react'

import { Box, Chip, Link, Typography } from '@mui/material'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'

import { hexToRGB, string2FormattedString } from '../../elements/utils'
import AttachmentList from './AttachmentList'
import { taskPropType } from '../../elements/PropTypes'
import { getTaskEmployeeNames, getTaskStatus, taskPriorities } from './utils'
import { TaskActions } from './TaskActions'

export default function TaskInfo ({ task, setTask, resetParent, session, withAction = true }) {
  const priority = task && taskPriorities.find(p => p.value === task.priority)

  return (
    <Box sx={{
      textAlign: 'left',
      paddingX: 3,
      paddingBottom: 1,
      marginBottom: 1,
      marginTop: 1,
      borderBottom: '1px solid #00000055'
    }}>
      {withAction && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
          <TaskActions task={task} setTask={setTask} resetParent={resetParent} session={session} />
        </Box>
      )}
      <Typography fontSize='h6.fontsize' fontWeight='bold' ><Link href={`/aufgabe/${task.id}`}>{task.title}</Link></Typography>
      <Typography>{task.description}</Typography>
      <Typography display="inline" inline={true}><b>Priorität: </b></Typography><Chip key={'task-priority'} label={priority.label} sx={{ backgroundColor: hexToRGB(priority.color, 1) }} size="small" />
      <Typography><b>Status: </b>{getTaskStatus(task)}</Typography>
      <Typography><b>Mitarbeiter: </b>{getTaskEmployeeNames(task, false)}</Typography>
      {task.taskdates_set && task.taskdates_set.length > 0
        ? (
          <Typography>
            <b>Termin: </b>
            {task.taskdates_set.map(dateObj => string2FormattedString(dateObj.date)).join(', ')}
            {task.start_time ? ` ${task.start_time.slice(0, 5)}` : ''}
            {task.end_time ? `-${task.end_time.slice(0, 5)}` : ''}
            {task.reminder ? <NotificationsOutlinedIcon fontSize="small" /> : <NotificationsOffOutlinedIcon fontSize="small" />}
          </Typography>
          )
        : null}
      {task.deadline ? <Typography><b>Frist: </b>{string2FormattedString(task.deadline)} {task.reminder ? <NotificationsOutlinedIcon fontSize="small"/> : <NotificationsOffOutlinedIcon fontSize="small"/>}</Typography> : null}
      {task.customer ? <Typography><b>Kunde: </b><Link href={`/kunde/${task.customer}`}>{task.customer_name}</Link></Typography> : null}
      {task.project ? <Typography><b>Projekt: </b><Link href={`/projekt/${task.project}`}>{task.project_name}</Link></Typography> : null}
      {task.address ? <Typography><b>Adresse: </b><Link href={'http://maps.google.com/maps?q="' + encodeURIComponent(task.address) + '"'}>{task.address}</Link></Typography> : null}
      <Typography><b>Vor Ort: </b>{task.on_site ? '\u2713' : '\u2715'}</Typography>
      {task.registered_by_name && <Typography><b>Erstellt von: </b> {task.registered_by_name}</Typography>}
      {task.coordinator_name
        ? <Typography><b>Koordinator: </b>{task.coordinator_name}</Typography>
        : null}
      {task.taskattachment_set && task.taskattachment_set.length > 0
        ? <>
          <Typography><b>Anhänge: </b></Typography>
          <AttachmentList task={task} header={false} session={session} />
        </>
        : null}
      {task.taskcomment_set && task.taskcomment_set.length > 0
        ? <>
          <Typography><b>Kommentare: </b></Typography>
          {task.taskcomment_set.map((c, cIdx) =>
            <Typography key={`taskcomment-${cIdx}`}><i>{c.author_name}:</i> {c.text}</Typography>
          )}
        </>
        : null}
      {task.previous_task_set && task.previous_task_set.length > 0
        ? <>
          <Typography><b>Vorherige Aufgaben: </b></Typography>
          {task.previous_task_set.map((t, tIdx) =>
            <Typography key={`previous-task-${tIdx}`}><Link href={`/aufgabe/${t.id}`}>{t.title}</Link></Typography>
          )}
        </>
        : null}
      {task.next_tasks && task.next_tasks.length > 0
        ? <>
          <Typography><b>Nachfolgende Aufgaben: </b></Typography>
          {task.next_tasks.map((t, tIdx) =>
            <Typography key={`next-task-${tIdx}`}><Link href={`/aufgabe/${t.id}`}>{t.title}</Link></Typography>
          )}
        </>
        : null}
      {task.label_set && task.label_set.length > 0
        ? <>
          <Typography display="inline" inline={true}><b>Labels: </b></Typography>
          {task.label_set.map((t, tIdx) =>
            <Chip key={`task-label-${tIdx}`} label={t.name} sx={{ backgroundColor: hexToRGB(t.color, 0.7) }} size="small" />
          )}
          <br />
        </>
        : null}
      {task.task_type_obj
        ? <>
          <Typography display="inline" inline={true}><b>Aufgaben-Typ: </b></Typography>
          <Chip label={task.task_type_obj.name} sx={{ backgroundColor: hexToRGB(task.task_type_obj.color, 0.7) }} size="small" />
          <br />
        </>
        : null}
    </Box>
  )
}

TaskInfo.propTypes = {
  task: taskPropType,
  setTask: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  withAction: PropTypes.bool
}
