import React, { Fragment, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import { projectPropType } from '../../../../elements/PropTypes'
import { CustomButton } from '../../../../elements/StyledElements'
import { checkIfValueIsEmpty, filterObj, getCustomerName, getEmptyFieldsError, getImagePromises, hasPermission, removeFromObj, getSignaturePromise } from '../../../../elements/utils'
import { API_URL_CONSTRUCTION, API_URL_HEATPUMPIMAGE, API_URL_USER } from '../../../../settings'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import CustomForm from '../../../../elements/CustomForm'
import { getHeatpumpConstructionDocumentationFormTemplate } from './HeatpumpConstructionDocumentationForm'

const emptyConstructionDocumentationForm = {
  project: null,
  heating_installer: null,
  documentation_completed: false,
  constructionheatpumpimage_set: [],
  employees: [],
  heating_installer_signature: null,
  constructiondates_set: []
}

export default function HeatpumpConstructionDocumentationFormModal ({ project, disable, getOpenButton, resetParent, session }) {
  const [constructionDocumentationForm, setConstructionDocumentationForm] = useState(emptyConstructionDocumentationForm)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [employees, setEmployees] = useState([])
  const [heatingInstallers, setHeatingInstallers] = useState([])

  const construction = project.construction_obj

  const loadData = () => {
    const documentationForm = { ...emptyConstructionDocumentationForm, ...construction, project: project.id }
    if (!!documentationForm.heating_installer && !documentationForm.employees.includes(documentationForm.heating_installer)) documentationForm.employees = [...documentationForm.employees, construction.heating_installer]
    setConstructionDocumentationForm(documentationForm)
    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true, include: documentationForm.employees.map(x => x.toString()).join() } })
      .then(res => {
        const groups = ['heating_installers', 'heating_installer_construction_manager', 'installer_students', 'installers', 'construction_managers', 'electricians']
        const employees = res.data
          .filter(user => groups.includes(user.group_key))
        setEmployees(employees)
        const heatingInstallers = res.data
          .filter(user => ['heating_installers', 'heating_installer_construction_manager'].includes(user.group_key))
        setHeatingInstallers(heatingInstallers)
      })
  }

  const clearData = () => {
    setConstructionDocumentationForm({ ...emptyConstructionDocumentationForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setEmployees([])
    setHeatingInstallers([])
  }

  const _getOpenButton = (toggle) => {
    return getOpenButton
      ? getOpenButton(toggle)
      : <CustomButton disabled={disable} onClick={toggle} icon="documentation">Dokumentation</CustomButton>
  }

  const submit = async (form) => {
    form = filterObj(form, Object.keys(emptyConstructionDocumentationForm))
    const _form = form.heating_installer_signature ? removeFromObj(form, ['heating_installer_signature']) : form
    _form.documentation = true

    /**
     * Prepare form for put request by preventing empty keys
     * @param {Record<string, unknown>} form
     * @returns Record<string, unknown> form
     */
    const preventEmptyKeysOnPutRequest = (form) => {
      const removeNullKeys = ['heating_installer_signature']
      return Object.keys(form).reduce((acc, key) => {
        if (!removeNullKeys.includes(key) || (form[key] == null)) {
          acc[key] = form[key]
        }
        return acc
      }, {})
    }

    const constructionPromise = construction
      ? axios.put(API_URL_CONSTRUCTION + construction.id, preventEmptyKeysOnPutRequest(_form))
      : axios.post(API_URL_CONSTRUCTION, _form)
    return constructionPromise.then((constructionRes) => {
      return getImagePromises('construction', constructionRes.data.id, form.constructionheatpumpimage_set, API_URL_HEATPUMPIMAGE)
        .then(() => {
          const signaturePromise = getSignaturePromise(form.heating_installer_signature, 'heatpump_installer_sig.png', null)

          return signaturePromise.then(sigRes => {
            const formData = new FormData()
            if (sigRes) formData.append('heating_installer_signature', sigRes)
            formData.append('documentation', true)
            formData.append('form', true)
            return axios.put(API_URL_CONSTRUCTION + constructionRes.data.id, formData)
          })
        })
    })
  }

  const onSubmit = async (onSuccess, finalize) => {
    if (finalize) {
      const additionalOptionalKeys = []
      const trueKeys = []
      const checkIfEmpty = (key, val) => {
        if (trueKeys.includes(key) && !val) return true
        if (checkIfValueIsEmpty(val)) return true
        return false
      }
      const emptyFieldsError = getEmptyFieldsError(constructionDocumentationForm, emptyConstructionDocumentationForm, additionalOptionalKeys, checkIfEmpty)
      if (emptyFieldsError) {
        setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
        console.error(emptyFieldsError)
        return
      }
      constructionDocumentationForm.documentation_completed = true
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(constructionDocumentationForm).then((res) => {
      if (resetParent) resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => {
      console.error('Error in ConstructionFormModal:onSubmit', error, error.stack)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle, false)}
        onComplete={hasPermission(session.user, 'finish_construction_documentation') ? () => onSubmit(toggle, true) : null}
        saveDisabled={!(hasPermission(session.user, 'customer_handling') || hasPermission(session.user, 'page_constructions')) || !(construction) || (construction.documentation_completed && !hasPermission(session.user, 'finish_construction_documentation'))}
      />
    )
  }

  const onToggle = (isOpen) => {
    if (isOpen) { loadData() } else clearData()
  }

  const title = (project) ? 'Dokumentation zur Bauplanung von ' + getCustomerName(project.customer_obj) : 'Baudokumentation'

  const template = getHeatpumpConstructionDocumentationFormTemplate(constructionDocumentationForm, heatingInstallers, employees, session)

  return (<CustomModal size='fullscreen' getOpenButton={_getOpenButton} title={title} getFooter={getFooter} onToggle={onToggle}>
    <Fragment>
      <CustomForm
        title='Baudokumentation'
        template={template}
        form={constructionDocumentationForm}
        setForm={setConstructionDocumentationForm}
        defaultForm={emptyConstructionDocumentationForm}
        showMissingFields={loadingElements.showMissingFields}
      />
    </Fragment>
  </CustomModal>)
}

HeatpumpConstructionDocumentationFormModal.propTypes = {
  project: projectPropType,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
