import React, { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Container } from 'reactstrap'
import axios from 'axios'

import FinanceInvoiceList from './invoices/FinanceInvoiceList'

import { Grid, Stack, Typography } from '@mui/material'

import LoadingPage from '../elements/LoadingPage'
import { hasPermission } from '../elements/utils'
import { API_URL_INVOICE } from '../settings'
import TabView from '../elements/TabView'
import { CustomIconButton } from '../elements/StyledElements'

export default function Invoices ({ session }) {
  // const [projects, setProjects] = useState([])
  const [invoices, setInvoices] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [invoiceParams, setInvoiceParams] = useState({ batch: 0, paid: false, sent: false })
  const [totals, setTotals] = useState(null)

  // pagination
  const batchSize = 20
  const [nPages, setNPages] = useState(null)

  useEffect(() => {
    onLoad()
  }, [invoiceParams])

  const onLoad = () => {
    setLoaded(false)
    axios.get(API_URL_INVOICE, {
      params: {
        sent: invoiceParams.sent,
        settled: hasPermission(session.user, 'invoices_paid') && invoiceParams.paid,
        batch_size: batchSize,
        batch: invoiceParams.batch,
        nested: true,
        ascending_ids: false
      }
    })
      .then(res => {
        setInvoices(res.data)
        setNPages(res.headers.length)
        setTotals(hasPermission(session.user, 'invoices_total') ? { gross: parseFloat(res.headers.total_gross), net: parseFloat(res.headers.total_net), open: parseFloat(res.headers.total_open) } : null)
        setLoaded(true)
        return res.data
      })
    // .then(async (invoices) => {
    //   return axios.get(API_URL_PROJECT, { params: { ids: removeDuplicates(invoices.map(i => i.project)).join(',') } })
    //     .then(
    //       async (res) => {
    //         const projects = res.data
    //         return axios.get(API_URL_CUSTOMER, { params: { ids: removeDuplicates(projects.map(p => p.customer)).join(',') } })
    //           .then(
    //             res => {
    //               const customers = res.data
    //               projects.forEach((project) => { project.customer_obj = customers.find(c => c.id === project.customer) })
    //               setProjects(projects)
    //               setLoaded(true)
    //             }
    //           )
    //       }
    //     )
    // })
  }

  const pagination = nPages && nPages > 1
    ? <Stack direction="row" spacing={2}>
      <CustomIconButton
        disabled={invoiceParams.batch === 0}
        icon='previous'
        onClick={() => { setInvoiceParams(p => ({ ...p, batch: p.batch - 1 })) }}
      />
      <Typography className="secondary-textcolor">{invoiceParams.batch + 1}</Typography>
      <CustomIconButton
        disabled={invoiceParams.batch === nPages - 1}
        icon='next'
        onClick={() => { setInvoiceParams(p => ({ ...p, batch: p.batch + 1 })) }}
      />
    </Stack>
    : null

  return (
    <Fragment>
      <Container>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={12} md={12}>
            <TabView
              components={[
                {
                  title: 'Nicht gesendet',
                  getChild: () => loaded
                    ? <FinanceInvoiceList
                      invoices={invoices}
                      title='Nicht gesendete Rechnungen'
                      resetParent={() => { setInvoiceParams({ paid: false, sent: false, batch: 0 }) }}
                      session={session}
                      totals={totals ? { gross: totals.gross, net: totals.net } : null}
                      emptyTable={true}
                    />
                    : <LoadingPage />,
                  onLoad: () => { setInvoiceParams({ paid: false, sent: false, batch: 0 }) }
                },
                {
                  title: 'Offen',
                  getChild: () => loaded
                    ? <>
                      <FinanceInvoiceList
                        invoices={invoices}
                        title='Offene Rechnungen'
                        resetParent={() => { setInvoiceParams({ paid: false, sent: true, batch: 0 }) }}
                        session={session}
                        emptyTable={true}
                        totals={totals}
                      />
                      {pagination}
                    </>
                    : <LoadingPage />,
                  onLoad: () => { setInvoiceParams({ paid: false, sent: true, batch: 0 }) }
                },
                ...(hasPermission(session.user, 'invoices_paid')
                  ? [{
                      title: 'Bezahlt',
                      getChild: () => loaded
                        ? <>
                        <FinanceInvoiceList
                          invoices={invoices}
                          title='Bezahlte Rechnungen'
                          resetParent={() => { setInvoiceParams({ paid: true, sent: true, batch: 0 }) }}
                          session={session}
                          emptyTable={true}
                          totals={totals ? { gross: totals.gross, net: totals.net } : null}
                        />
                        {pagination}
                      </>
                        : <LoadingPage />,
                      onLoad: () => { setInvoiceParams({ paid: true, sent: true, batch: 0 }) }
                    }]
                  : [])
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Fragment>)
}

Invoices.propTypes = {
  session: PropTypes.object
}
