import React, { useRef, useEffect } from 'react'
import Collapsible from 'react-collapsible'

import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'
import { ibanToBic } from 'iban-to-bic'
import DatePicker from 'react-datepicker'
import { Stack, Typography } from '@mui/material'

import AddressInput from '../../../elements/AddressInput'
import { customerPropType } from '../../../elements/PropTypes'
import { defaultIfEmpty, string2Date, date2String } from '../../../elements/utils'
import DropDown from '../../../elements/DropDown'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

export default function CustomerForm ({ showMissingFields, showAllMissingFields, customer, setCustomer, session }) {
  const bankingInformationModified = useRef(false)

  useEffect(() => {
    if (bankingInformationModified.current) fillBIC(customer.iban)
  }, [customer.iban])
  const pickerRef1 = useRef(null)
  const fillBIC = (iban) => {
    const bic = ibanToBic(iban)
    if (bic) setCustomer({ ...customer, bic })
    else setCustomer({ ...customer, bic: '' })
  }

  const onBankingChange = e => {
    bankingInformationModified.current = true
    onChange(e)
  }

  const onChange = e => {
    setCustomer(c => ({ ...c, [e.target.name]: e.target.value }))
  }

  if (!customer) return null

  return (
    <Form>
      <FormGroup>
        <Typography className='secondary-textcolor'>Kundennummer:</Typography>
        <div className="input-group">
          <Input
            style={{ width: '50%' }}
            type="text"
            id="input"
            name="customer_id"
            value={defaultIfEmpty(customer.id)}
            disabled={true}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Anrede:</Typography>
        <Stack direction='row' spacing={2}>
          <DropDown
            id="gender_dropdown"
            onChange={(value) => setCustomer(c => ({ ...c, gender: value }))}
            text={'Anrede'}
            options={['Frau', 'Herr', 'Firma']}
            value={customer.gender}
          />
          {(showMissingFields && !(customer.gender))
            ? <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
            : null}
        </Stack>
      </FormGroup>
      {customer.gender === 'Firma'
        ? <FormGroup>
          <Typography className='secondary-textcolor'>Firma:</Typography>
          <Input
            type="text"
            id="input"
            name="company"
            onChange={onChange}
            value={defaultIfEmpty(customer.company)}
            required={true}
            autoComplete="off"
            invalid={!!(showMissingFields && (customer.gender === 'Firma') && !(customer.company))}
            style={{ boxShadow: 'none' }}
          />
        </FormGroup>
        : null
      }
      <FormGroup>
        <Typography className='secondary-textcolor'>Vorname:</Typography>
        <Input
          type="text"
          id="input"
          name="first_name"
          onChange={onChange}
          value={defaultIfEmpty(customer.first_name)}
          required={true}
          autoComplete="off"
          invalid={!!(showMissingFields && (customer.gender !== 'Firma') && !(customer.first_name))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Nachname:</Typography>
        <Input
          id="input"
          type="text"
          name="last_name"
          onChange={onChange}
          value={defaultIfEmpty(customer.last_name)}
          required={true}
          autoComplete="off"
          invalid={!!(showMissingFields && (customer.gender !== 'Firma') && !(customer.last_name))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>

      <AddressInput
        address={{ street_and_number: customer.street_and_number, zip_and_city: customer.zip_and_city, province: customer.province }}
        setAddress={(address) => setCustomer(customer => ({ ...customer, street_and_number: address.street_and_number, zip_and_city: address.zip_and_city, province: address.province }))}
        setStreetAndNumber={(streetAndNumber) => setCustomer(customer => ({ ...customer, street_and_number: streetAndNumber }))}
        setZipAndCity={(zipAndCity) => setCustomer(customer => ({ ...customer, zip_and_city: zipAndCity }))}
        setProvince={(province) => setCustomer(customer => ({ ...customer, province }))}
        showMissingFields={showMissingFields}
        session={session}
      />
      <div className='secondary-textcolor'>
        <Collapsible trigger="[Info öffnen]" triggerWhenOpen="[Info schließen]">
          <p>
            Die Netzbetreiber fragen nach dem Geburtsdatum des Anlagenbetreibers. Diese Information wird von uns nur weitergegeben.
          </p>
        </Collapsible>
      </div>
      <FormGroup>
        <Typography className='secondary-textcolor'>Geburtsdatum:</Typography>
          <DatePicker
            ref={pickerRef1}
            dateFormat="dd/MM/yyyy"
            locale="de"
            selected={customer.birth_date ? string2Date(customer.birth_date) : null}
            onChange={(date) => {
              setCustomer((c) => ({
                ...c,
                birth_date: date ? date2String(date) : null
              }))
            }}
            maxDate={new Date()}
          />

      </FormGroup>

      <hr className='secondary-textcolor' />
      <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
        Kontodaten
      </Typography>
      <div className='secondary-textcolor'>
        <Collapsible trigger="[Info öffnen]" triggerWhenOpen="[Info schließen]">
          <p>
            Damit der Netzbetreiber Ihnen das Geld für den eingespeisten Strom auszahlen kann, braucht dieser Ihre Kontodaten.
          </p>
        </Collapsible>
      </div>

      <FormGroup>
        <Typography className='secondary-textcolor'>Kontoinhaber</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: 'none' }}
          type="text"
          name="account_owner"
          value={customer.account_owner}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !(customer.account_owner))}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>IBAN</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: 'none' }}
          type="text"
          name="iban"
          value={customer.iban}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !(customer.iban))}
        />
      </FormGroup>

      <FormGroup>
        <Typography className='secondary-textcolor'>Bank</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: 'none' }}
          type="text"
          name="bank"
          value={customer.bank}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !(customer.bank))}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>BIC</Typography>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: 'none' }}
          type="text"
          name="bic"
          value={customer.bic}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !(customer.bic))}
        />
      </FormGroup>

      <FormGroup>
        <Typography className='secondary-textcolor'>Steuernummer</Typography>
        <div className='secondary-textcolor'>
          <Collapsible trigger="[Info öffnen]" triggerWhenOpen="[Info schließen]">
            <p>
              Es ist die Vorschrift, dass Sie die Anlage beim Finanzamt melden. Damit der Netzbetreiber dies verknüpfen kann, braucht dieser Ihre Steuernummer.
            </p>
          </Collapsible>
        </div>
        <Input
          id="input"
          required={true}
          style={{ boxShadow: 'none' }}
          name="tax_number"
          value={customer.tax_number}
          autoComplete="off"
          onChange={onBankingChange}
          invalid={!!(showAllMissingFields && !(customer.tax_number))}
        />
      </FormGroup>
    </Form>
  )
}

CustomerForm.propTypes = {
  showMissingFields: PropTypes.bool,
  showAllMissingFields: PropTypes.bool,
  customer: customerPropType,
  setCustomer: PropTypes.func,
  session: PropTypes.object
}
