import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'react-bootstrap'
import { Typography } from '@mui/material'
import axios from 'axios'
import { API_URL_LEAD_SETTING, DAPI_URL_LEADSEVALUATIONSTATS } from '../../settings'
import LoadingPage from '../../elements/LoadingPage'
import { numFormatter, THIS_YEAR, MONTHS } from '../../elements/utils'
import DropDown from '../../elements/DropDown'
import LeadSettings from './LeadSettings'

export default function LeadEvaluation ({ leadType, setLeadType, leadTypes, leadSources, refreshLeadSources }) {
  const [year, setYear] = useState(new Date().getFullYear())
  const [source, setSource] = useState('Gesamt')
  const [leadEvaluations, setLeadEvaluations] = useState({})
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false
  })
  const [openLeadSettings, setOpenLeadSettings] = useState(false)
  const [leadSettings, setLeadSettings] = useState([])

  useEffect(() => {
    if (openLeadSettings) {
      return
    }
    const fetchLeadEvaluation = async () => {
      setLoadingElements({ ...loadingElements, inProgress: true })
      try {
        const res = await axios.get(DAPI_URL_LEADSEVALUATIONSTATS,
          { params: { year, source: source === 'Gesamt' ? 'all' : source, lead_type: leadType } })
        setLeadEvaluations({ ...leadEvaluations, ...(res.data) })
      } finally {
        setLoadingElements({ ...loadingElements, inProgress: false })
      }
    }
    const _refreshLeadSources = async () => {
      setLoadingElements({ ...loadingElements, inProgress: true })
      try {
        await refreshLeadSources()
      } finally {
        setLoadingElements({ ...loadingElements, inProgress: false })
      }
    }

    fetchLeadEvaluation().then(() => {})
    _refreshLeadSources().then(() => {})
  }, [year, source, leadType, openLeadSettings])

  useEffect(() => {
    if (openLeadSettings) {
      return
    }
    const getSettings = async () => {
      setLoadingElements({ ...loadingElements, inProgress: true })

      try {
        const res = await axios.get(API_URL_LEAD_SETTING)
        setLeadSettings(res.data)
      } finally {
        setLoadingElements({ ...loadingElements, inProgress: false })
      }
    }
    getSettings().then(() => {})
  }, [openLeadSettings])

  if (loadingElements.inProgress) {
    return (
        <LoadingPage />
    )
  }

  return (
    <Fragment>
      <div style={{ display: 'flex', gap: '5px' }}>
        <div>
          <DropDown
              onChange={setYear}
              options={Array.from({ length: ((THIS_YEAR) - 2022) + 1 }, (v, i) => 2022 + i)}
              value={year}
              text='Jahr'
          />
        </div>
        <div>
          <DropDown
            onChange={(val) => { !val ? setSource('Gesamt') : setSource(val) }}
            options={['Gesamt'].concat(leadSources)}
            value={source}
            text='Leadquelle'
            search={true}
            style={{ paddingBottom: '5px' }}
          />
        </div>
        <div>
          <DropDown
              onChange={setLeadType}
              options={leadTypes ? leadTypes.map(t => ({ label: t.name, value: t.id })) : []}
              value={leadType}
              text='Lead-Typ'
              sort={true}
              search={true}
              style={{ paddingBottom: '5px' }}
          />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <LeadSettings
            isOpen={openLeadSettings}
            setIsOpen={setOpenLeadSettings}
            leadSettings={leadSettings}
            setLeadSettings={setLeadSettings}
            leadSources={leadSources}
          />
        </div>
      </div>
      <Table>
        <thead>
        <tr>
          <th colSpan={13} style={{ textAlign: 'center' }}>{source}</th>
        </tr>
        <tr>
          <th><Typography className='secondary-textcolor'></Typography></th>
          {
            MONTHS.map(m =>
              (
                    <th key={m} className="align-middle"><Typography className='secondary-textcolor'>{m}</Typography></th>
              ))
            }
          </tr>
        </thead>

        <tbody>
        <tr>
          <td><Typography className='secondary-textcolor'>Generierte Leads gesamt</Typography></td>
          {leadEvaluations.generated_leads && leadEvaluations.generated_leads.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{val}</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>Leads erreicht</Typography></td>
          {leadEvaluations.reached_leads && leadEvaluations.reached_leads.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{val}</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>Termine ausgemacht</Typography></td>
          {leadEvaluations.appointments_made && leadEvaluations.appointments_made.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{val}</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>Terminquote</Typography></td>
          {leadEvaluations.appointments_rate && leadEvaluations.appointments_rate.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{numFormatter(val * 100)}%</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>Verkäufe</Typography></td>
          {leadEvaluations.sales && leadEvaluations.sales.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{val}</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>Quote Verkauf nach Termin</Typography></td>
          {leadEvaluations.sales_rate_after_appointment && leadEvaluations.sales_rate_after_appointment.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{numFormatter(val * 100)}%</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>Quote Verkauf nach Leads</Typography></td>
          {leadEvaluations.sales_rate_after_leads && leadEvaluations.sales_rate_after_leads.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{numFormatter(val * 100)}%</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>Kosten</Typography></td>
          {leadEvaluations.costs && leadEvaluations.costs.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{numFormatter(val)}€</Typography></td>
          )}
        </tr>
        <tr>
          <td><Typography className='secondary-textcolor'>CAC</Typography></td>
          {leadEvaluations.cac && leadEvaluations.cac.map((val, i) =>
              <td key={i}><Typography className='secondary-textcolor'>{numFormatter(val)}€</Typography></td>
          )}
        </tr>
        </tbody>
      </Table>
    </Fragment>
  )
}

LeadEvaluation.propTypes = {
  leadType: PropTypes.object,
  setLeadType: PropTypes.func,
  leadTypes: PropTypes.object,
  leadSources: PropTypes.object,
  refreshLeadSources: PropTypes.func
}
