import React, { useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete, TextField, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  inputfocused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#424242 !important',
      borderWidth: '1px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#424242 !important',
      borderWidth: '1px'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#424242 !important'
  }
}))

export default function DropDown ({ onChange, options, text = 'Auswählen', value, disabled, sort = false, search = null, style = {}, listBoxStyle = {}, noOptionsText = null, onInputChange = null, id, disableClearable = false, invalid, size = 'small' }) {
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [selected, setSelected] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    const newOptions = (options)
      ? options.map(s => {
        return (Object.prototype.toString.call(s) === '[object Object]') ? s : { value: s, label: (toString.call(s) === '[object String]') ? s : s.toString() }
      })
      : []
    if (sort) {
      newOptions.sort((a, b) => {
        if (a.order === 'first' && b.order !== 'first') return -1
        if (a.order !== 'first' && b.order === 'first') return 1
        if (a.order === 'last' && b.order !== 'last') return 1
        if (a.order !== 'last' && b.order === 'last') return -1
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    }
    setDropdownOptions(newOptions)
  }, [options])

  useEffect(() => {
    setSelected(dropdownOptions.find(i => i.value === value))
  }, [value, dropdownOptions])

  const dropdownclicked = (chosen) => {
    onChange(chosen ? chosen.value : null, value)
  }

  const autoselectclicked = (event, chosen) => {
    onChange(chosen ? chosen.value : null, value)
  }

  const filteredOptions = dropdownOptions.filter(o => !o.hidden || value === o.value)

  if (search !== null) {
    return <Autocomplete
      ListboxProps={{ style: listBoxStyle }}
      style={style}
      disablePortal={true}
      id={id}
      options={dropdownOptions}
      onChange={autoselectclicked}
      value={selected || null}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => value ? option.value === value.value : false}
      sx={{ width: 250 }}
      getOptionDisabled={option => option.disabled}
      noOptionsText={noOptionsText}
      onInputChange={onInputChange ? (e, input, reason) => onInputChange(input, reason) : undefined}
      disableClearable={disableClearable}
      renderInput={(params) =>
        <TextField
          {...params}
          label={text}
          size={size}
          error={invalid}
          className={classes.inputfocused}
          // sx={selected?.props?.style?.backgroundColor ? {  backgroundColor: selected.props.style.backgroundColor } : {}}
        />}
      search={search}
      renderOption={(props, option) => {
        const key = `listItem-${option.value}-${option.label}`
        return (
          <li {...props} {...(option.props || {})} key={key}>
            {option.label}
          </li>
        )
      }}
    />
  }

  return (
    <ButtonDropdown id={id} style={style} isOpen={isOpen} toggle={() => setIsOpen(prev => !prev)} direction={'right'} >
      {/* {(!isOpen || !search) ? */}
      < DropdownToggle caret disabled={disabled} style={{ backgroundColor: '#ffffff', color: '#424242', borderColor: '#424242' }}>
        {selected ? selected.label : text}
      </DropdownToggle>
      <DropdownMenu style={{ backgroundColor: '#ffffff', maxHeight: '200px', overflowY: 'auto' }}>
        {
          filteredOptions.map(option => {
            return <DropdownItem key={'Dropdown_' + option.value} disabled={option.disabled === true} onClick={(e) => dropdownclicked(option)}><Typography style={{ color: '#424242' }}>{option.label}</Typography></DropdownItem>
          })
        }
      </DropdownMenu>
    </ButtonDropdown >

  )
}

DropDown.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool])),
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
  disabled: PropTypes.bool,
  sort: PropTypes.bool,
  search: PropTypes.bool,
  style: PropTypes.object,
  listBoxStyle: PropTypes.object,
  id: PropTypes.string,
  noOptionsText: PropTypes.string,
  onInputChange: PropTypes.func,
  disableClearable: PropTypes.bool,
  invalid: PropTypes.bool,
  size: PropTypes.string
}
