import { PropTypes } from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'

import axios from 'axios'

import { Box, IconButton, Link, Paper, Stack, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { date2String, round, string2FormattedString, useDidMountEffect } from '../../elements/utils'
import { API_URL_CONSTRUCTION } from '../../settings'
import CollapsibleTable from '../../elements/CollapsibleTable'
import { CustomIconButton } from '../../elements/StyledElements'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import Collapsible from '../../elements/Collapsible'
import { getNextDate } from '../tasks/utils'

export default function ConstructionTable ({ header, queryParams, resetParent, defaultIsOpen = true, collapsible = true, showIfEmpty = true, session }) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const [constructions, setConstructions] = useState(null)
  const [batch, setBatch] = useState(0)
  const [nPages, setNPages] = useState(null)
  const prevQueryParams = useRef(null)

  useEffect(() => {
    // ensure that a new request is only done, if the query params change
    // the useEffect is triggered at every rerender, as a new object is created in the parent component for
    if (JSON.stringify(queryParams) === JSON.stringify(prevQueryParams.current)) return
    prevQueryParams.current = { ...queryParams }
    if (batch !== 0) setBatch(0)
    else reloadConstructions()
  }, [queryParams])

  useDidMountEffect(() => {
    reloadConstructions()
  }, [batch])

  const reloadConstructions = () => {
    axios.get(API_URL_CONSTRUCTION, {
      params: {
        ...(queryParams || {}),
        employees: session.user.id,
        constructiondates__date: date2String(new Date()),
        batch_size: 20,
        batch
      }
    }).then(res => {
      setConstructions(res.data)
      setNPages(res.headers.length)
    })
  }

  if (!constructions) return null
  if (!showIfEmpty && constructions.length === 0) return null

  return (
    <Fragment >
      <Paper elevation={3} width='100%' style={{ marginTop: '10px', marginBottom: '20px' }}>
        <div className="text-center">
          <Typography sx={{ cursor: 'pointer' }} fontSize='h4.fontsize' fontWeight='bold' onClick={() => setIsOpen(!isOpen)} >{collapsible && (isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />)} {header}</Typography>
        </div>

        {isOpen && <>
          {constructions.length === 0 && <CollapsibleTable collapsible={false} columns={[{ name: '', key: 'col' }]} rows={[{ col: 'Keine Baustellen vorhanden', style: { textAlign: 'center' } }]} />}
          {constructions.length > 0 && (
            <>
              <Box sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}>
                <CollapsibleTable
                  columns={[
                    { name: 'Titel', key: 'title' },
                    { name: 'Kunde', key: 'customer' },
                    { name: 'Datum', key: 'date' },
                    { name: 'Mitarbeiter', key: 'employees' },
                    { name: 'kWp', key: 'kwp' }
                  ]}
                  rows={
                    [
                      ...(constructions || []).map(construction => ({
                        key: `construction-${construction.id}`,
                        title: `Baustelle ${construction.project_name}`,
                        date: construction.constructiondates_set && construction.constructiondates_set.length > 0 ? string2FormattedString(getNextDate(construction.constructiondates_set, new Date())) : '',
                        customer: construction.customer_name,
                        employees: <Typography>{construction.employee_names.join(', ')}</Typography>,
                        kwp: construction.kwp ? round(construction.kwp, 2) : null,
                        child: <Box marginLeft={0} marginTop={0} marginBottom={3} sx={{ textAlign: 'left' }}>
                          <Typography fontSize='h6.fontsize' fontWeight='bold' ><Link href={`/projekt/${construction.project}/bauplanung`}>{`Baustelle ${construction.project_name}`}</Link></Typography>
                          <Typography><b>Mitarbeiter: </b>{construction.employee_names.join(', ')}</Typography>
                          {construction.constructiondates_set && construction.constructiondates_set.length > 0
                            ? (
                              <Typography><b>Termin: </b>
                              {construction.constructiondates_set.map(dateObj => string2FormattedString(dateObj.date)).join(', ')}
                              </Typography>
                              )
                            : null}
                          <Typography><b>Kunde: </b><Link href={`/kunde/${construction.customer}`}>{construction.customer_name}</Link></Typography>
                          <Typography><b>Projekt: </b><Link href={`/projekt/${construction.project}`}>{construction.project_name}</Link></Typography>
                          <Typography><b>Adresse: </b><Link href={'http://maps.google.com/maps?q="' + encodeURIComponent(`${construction.street_and_number} ${construction.zip_and_city}`) + '"'}>{`${construction.street_and_number} ${construction.zip_and_city}`}</Link></Typography>
                          {construction.kwp ? <Typography><b>kWp: </b>{round(construction.kwp, 2)}</Typography> : null}
                        </Box>
                      }))
                    ]
                  }
                  collapsible={true}
                />
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', paddingX: 3 }}>
                {constructions.map(construction => (
                  <Collapsible
                    key={`construction-${construction.id}`}
                    getOpenButton={(toggle) => (
                      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} paddingY={1} flex={1}>
                        <Typography flexGrow={1}>
                          <Link href={`/projekt/${construction.project}/bauplanung`}>
                            {`Baustelle ${construction.project_name}`}
                          </Link>
                        </Typography>
                        <IconButton onClick={toggle} size="sm">
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    )}
                  >
                    <Box marginTop={1} marginBottom={1} paddingX={3} paddingBottom={1} borderBottom="1px solid #00000055" sx={{ textAlign: 'left' }}>
                      <Typography fontSize='h6.fontsize' fontWeight='bold' >
                        <Link href={`/projekt/${construction.project}/bauplanung`}>
                          {`Baustelle ${construction.project_name}`}
                        </Link>
                      </Typography>
                      <Typography><b>Mitarbeiter: </b>{construction.employee_names.join(', ')}</Typography>
                      {construction.constructiondates_set && construction.constructiondates_set.length > 0
                        ? (
                          <Typography><b>Termin: </b>
                          {construction.constructiondates_set.map(dateObj => string2FormattedString(dateObj.date)).join(', ')}
                          </Typography>
                          )
                        : null}
                      <Typography><b>Kunde: </b><Link href={`/kunde/${construction.customer}`}>{construction.customer_name}</Link></Typography>
                      <Typography><b>Projekt: </b><Link href={`/projekt/${construction.project}`}>{construction.project_name}</Link></Typography>
                      <Typography><b>Adresse: </b>
                        <Link href={'http://maps.google.com/maps?q="' + encodeURIComponent(`${construction.street_and_number} ${construction.zip_and_city}`) + '"'}>
                          {`${construction.street_and_number} ${construction.zip_and_city}`}
                        </Link>
                      </Typography>
                      {construction.kwp ? <Typography><b>kWp: </b>{round(construction.kwp, 2)}</Typography> : null}
                    </Box>
                  </Collapsible>
                )
                )}
              </Box>
            </>
          )}
          {nPages && nPages > 1
            ? <Stack direction="row" spacing={2}>
              <CustomIconButton
                disabled={batch === 0}
                icon='previous'
                onClick={() => setBatch(batch - 1)}
              />
              <Typography className="secondary-textcolor">{batch + 1}</Typography>
              <CustomIconButton
                disabled={batch === nPages - 1}
                icon='next'
                onClick={() => setBatch(batch + 1)}
              />
            </Stack>
            : null}
        </>}
      </Paper>
    </Fragment>
  )
}

ConstructionTable.propTypes = {
  header: PropTypes.string,
  queryParams: PropTypes.object,
  resetParent: PropTypes.func,
  boxStyle: PropTypes.object,
  showIfEmpty: PropTypes.bool,
  collapsible: PropTypes.bool,
  defaultIsOpen: PropTypes.bool,
  session: PropTypes.object
}
