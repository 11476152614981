export const getCarFormTemplate = () => [
  {
    type: 'text',
    key: 'plate',
    name: 'Kennzeichen'
  },
  {
    type: 'text',
    key: 'brand_model',
    name: 'Fahrzeug'
  },
  {
    type: 'text',
    key: 'tracker_id',
    name: 'Tracker-ID',
    disabled: true
  },
  // {
  //   type: 'text',
  //   key: 'vin',
  //   name: 'Fahrzeug ID (FIN)',
  // },
  {
    type: 'color',
    key: 'color',
    name: 'Farbe'
  }
]
