import React, { useState, useEffect } from 'react'

import { PropTypes } from 'prop-types'
import axios from 'axios'
import { Input } from 'reactstrap'

import { leadPropType } from '../../elements/PropTypes'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { API_URL_LEAD } from '../../settings'
import { CustomButton } from '../../elements/StyledElements'
import DropDown from '../../elements/DropDown'
import CustomModal from '../shared/modal_utils/CustomModal'
import { Typography } from '@mui/material'

export default function ArchiveLeadModal ({
  getOpenButton,
  lead,
  resetParent,
  session,
  isOpenFromParent = false,
  setIsOpenFromParent = null,
  callbackOnSubmit = null
}) {
  const [isReasonOpen, setIsReasonOpen] = useState(false)
  const [reason, setReason] = useState('')
  const [textInput, setTextInput] = useState(false)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, skipInProgress: false
  })

  useEffect(() => {
    if (isReasonOpen) setReason('')
  }, [isReasonOpen])

  const submit = () => {
    return axios.put(API_URL_LEAD + lead.id, { archived: true, archiving_reason: reason })
  }

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton
      color='#ffffff'
      description={'Archivieren'}
      icon={'archive'}
      onClick={toggle}
    >Archivieren</CustomButton>
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false })
    return submit()
      .then((res) => {
        if (resetParent) resetParent(res.data)
        onSuccess()
        if (typeof callbackOnSubmit === 'function') {
          callbackOnSubmit()
        }
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      })
      .catch(error => { console.error('Error in ArchiveLeadModal:', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => <SaveModalFooter
    submitError={loadingElements.submitError}
    inProgress={loadingElements.inProgress}
    disabled={!reason || reason === ''}
    onSave={() => { onSubmit(toggle) }}
    onCancel={toggle}
    saveBtnLabel={'Weiter'}
    cancelBtnLabel={'Abbrechen'}
  />

  return (
    <>
      <CustomModal title="Lead archivieren" isOpen={isReasonOpen || isOpenFromParent} setIsOpen={(x) => {
        if (setIsOpenFromParent) setIsOpenFromParent(x)
        setIsReasonOpen(x)
      }} getOpenButton={_getOpenButton} getFooter={getFooter}>
        <div style={{
          minHeight: '175px',
          padding: '20px 0'
        }}>
          <Typography className='secondary-textcolor'>Archivierung des Leads:</Typography>
          <DropDown
            onChange={(val) => { setReason((val !== 'Sonstiges') ? val : ''); setTextInput(val === 'Sonstiges') }}
            options={['mehrmals nicht erreicht', 'doch gegen PV entschieden', 'für Konkurrenz entschieden', 'Dach ungeeignet', 'Sonstiges']}
            value={reason}
            text='Grund für die Archivierung'
            style={{ overflowY: 'visible' }}
            listBoxStyle={{ padding: '20px 0px', maxHeight: '90px', overflowY: 'auto' }}
            search={null}
          />
          {textInput
            ? <Input
              type="text"
              style={{ marginTop: '20px' }}
              name="archiving_reason"
              onChange={e => setReason(e.target.value)}
              value={reason}
              placeholder="Grund für die Archivierung"
              autoComplete="off" />
            : null}
        </div>
      </CustomModal>
    </>
  )
}

ArchiveLeadModal.propTypes = {
  lead: leadPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  getOpenButton: PropTypes.func,
  isOpenFromParent: PropTypes.bool,
  setIsOpenFromParent: PropTypes.func,
  callbackOnSubmit: PropTypes.func
}
