import React, { useEffect, useMemo, useRef, useState } from 'react'

import axios from 'axios'
import moment from 'moment'
import 'moment/locale/de'
import { PropTypes } from 'prop-types'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import TaskFormModal from '../tasks/TaskFormModal'
import ConstructionDate from './ConstructionDate'

import { isHoliday } from 'feiertagejs'
import LoadingPage from '../../elements/LoadingPage'
import { API_URL_USER, API_URL_CONSTRUCTION, API_URL_TASK, API_URL_PLANVILLELOCATION, API_URL_TASKTYPE } from '../../settings'
import { HOLIDAYREGION, addDaysToDate, date2String, defaultIfEmpty, germanCalendarMsgs, getCalendarRange, getHolidayEvents, hexToRGB, string2Date, useDidMountEffect } from '../../elements/utils'
import { ProjectType2ConstructionType, constructionType2ProjectType, getConstructionDateColor, projectTypes } from '../project_types/projectUtils'
import '../shared/calendarStyle.css'
import { Col, Container, Row, FormGroup, Input } from 'reactstrap'
import { Avatar, Stack, Tooltip } from '@mui/material'
import { CustomButton } from '../../elements/StyledElements'
import DropDown from '../../elements/DropDown'
import ScheduleModal from './ScheduleModal'

// require('moment/locale/de.js')
moment.locale('de-DE')
const localizer = momentLocalizer(moment)

export default function ConstructionCalendar ({ session }) {
  const [cookies, setCookie] = useCookies(['employee', 'projectType', 'planvilleLocation', 'postalCode'])
  const [loaded, setLoaded] = useState(false)

  // events
  const [constructions, setConstructions] = useState([])
  const [tasks, setTasks] = useState([])

  // filters
  const [showFilter, setShowFilter] = useState(false)
  const [isCurrentUserFiltering, setIsCurrentUserFiltering] = useState(false)
  // filter options
  const [filteredEmployees, setFilteredEmployees] = useState([])
  const [filteredProjectTypes, setFilteredProjectTypes] = useState(projectTypes)
  const [planvilleLocations, setPlanvilleLocations] = useState([])
  const [taskTypes, setTaskTypes] = useState([])

  // chosen options
  const [employee, setEmployee] = useState(null)
  const [projectType, setProjectType] = useState(null)
  const [location, setLocation] = useState(null)
  const [postalCode, setPostalCode] = useState(null)
  const [taskType, setTaskType] = useState(null)
  const [filter, setFilter] = useState({
    employee: null,
    projectType: null,
    location: null,
    postalCode: null,
    taskType: null
  })

  // open modals when selecting construction / task / date
  const [chosenTask, setChosenTask] = useState(null)
  const [chosenDate, _setChosenDate] = useState(null)

  // calendar
  const [view, setView] = useState('month')

  // window size
  const [winHeight, setWinHeight] = useState(window.innerHeight)

  const allTasks = useRef([])
  const allConstructions = useRef([])
  const calendarRange = useRef(getCalendarRange(new Date(), view))
  const dataRange = useRef(null)
  const allEmployees = useRef([])

  useEffect(() => {
    const handleWindowResize = () => {
      setWinHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const history = useHistory()
  const { search } = useLocation()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const setPersistentCookie = (name, value) => {
    const farFutureDate = new Date(new Date().setFullYear(new Date().getFullYear() + 10))
    setCookie(name, value, { path: '/', expires: farFutureDate })
  }

  const setChosenDate = (date) => {
    if (date) searchParams.set('date', date2String(date))
    else searchParams.delete('date')
    history.push({ search: searchParams.toString() })
  }

  useEffect(() => {
    if (searchParams.get('date')) _setChosenDate(string2Date(searchParams.get('date')))
    else _setChosenDate(null)
  }, [searchParams])

  useEffect(() => {
    loadData()
  }, [])

  useDidMountEffect(() => {
    updateTasks()
    updateConstructions()
    updateFilter()
  }, [employee, projectType, location, postalCode, taskType])

  const updateTasks = () => setTasks(filterTasks())
  const updateConstructions = () => setConstructions(filterConstructions())
  const updateFilter = () => setFilter({ employee, projectType, location, postalCode, taskType })

  const loadData = () => {
    setLoaded(false)

    // reset data
    allConstructions.current = []
    allEmployees.current = []
    allTasks.current = []
    dataRange.current = null

    const promises = []
    promises.push(axios.get(API_URL_USER, {
      params: { is_staff: true, visible: true, is_active: true }
    })
      .then(res => res.data).then(_employees => {
        setFilteredEmployees(_employees)
        allEmployees.current = _employees
        // set filters
        setEmployee(cookies.employee || null)
        setProjectType(cookies.projectType || null)
        setLocation(cookies.planvilleLocation || null)
        setPostalCode(cookies.postalCode || null)
        setTaskType(cookies.taskType || null)
      })
    )

    promises.push(axios.get(API_URL_PLANVILLELOCATION).then(res => setPlanvilleLocations(res.data)))
    promises.push(axios.get(API_URL_TASKTYPE).then(res => setTaskTypes(res.data)))
    promises.push(loadEvents(calendarRange.current))

    Promise.all(promises).then(() => {
      setLoaded(true)
    })
  }

  const loadEvents = async (range) => {
    const promises = []
    promises.push(axios.get(API_URL_CONSTRUCTION, {
      params: {
        start_date: date2String(range.start),
        end_date: date2String(range.end),
        project__declined: false
      }
    })
      .then(res => res.data).then(_constructions => {
        const newConstructionIds = _constructions.map(c => c.id)
        allConstructions.current = [...allConstructions.current.filter(c => !newConstructionIds.includes(c.id)), ..._constructions]
        updateConstructions()
      })
    )
    promises.push(axios.get(API_URL_TASK, {
      params: {
        start_date: date2String(range.start),
        end_date: date2String(range.end)
      }
    })
      .then(res => res.data).then(_tasks => {
        const newTaskIds = _tasks.map(t => t.id)
        allTasks.current = [...allTasks.current.filter(t => !newTaskIds.includes(t.id)), ..._tasks]
        updateTasks()
      })
    )

    return Promise.all(promises).then(() => {
      const prevRange = dataRange.current
      dataRange.current = (prevRange === null) ? range : { start: Math.min(range.start, prevRange.start), end: Math.max(range.end, prevRange.end) }
    })
  }

  const filterConstructions = () => {
    let _constructions = [...allConstructions.current]
    if (projectType) _constructions = _constructions.filter(c => constructionType2ProjectType(c.resourcetype) === projectType)
    if (employee) _constructions = _constructions.filter(c => c.employees.includes(employee))
    if (location) _constructions = _constructions.filter(c => c.planville_location === location)
    if (postalCode) _constructions = _constructions.filter(c => c.zip_and_city.startsWith(postalCode))
    return _constructions
  }

  const filterTasks = () => {
    let _tasks = [...allTasks.current]
    if (projectType) _tasks = _tasks.filter(t => t.project_resourcetype === projectType)
    if (employee) _tasks = _tasks.filter(t => t.employees.includes(employee))
    if (taskType) _tasks = _tasks.filter(t => t.task_type === taskType)
    if (location) _tasks = _tasks.filter(t => t.project_planville_location && t.project_planville_location === location)
    if (postalCode) _tasks = _tasks.filter(t => t.address && t.address.includes(postalCode))
    return _tasks
  }

  const onRangeChange = (newRange) => {
    if (Array.isArray(newRange)) {
      newRange = { start: newRange[0], end: newRange[newRange.length - 1] }
    }
    calendarRange.current = newRange
    if (!dataRange.current) { loadEvents(calendarRange.current) } else {
      const promise = Promise.resolve()
      if (calendarRange.current.start < dataRange.current.start) {
        setLoaded(false)
        promise.then(() => loadEvents({ start: calendarRange.current.start, end: addDaysToDate(dataRange.current.start, -1) }))
      }
      if (calendarRange.current.end > dataRange.current.end) {
        setLoaded(false)
        promise.then(() => loadEvents({ start: addDaysToDate(dataRange.current.end, 1), end: calendarRange.current.end }))
      }
      promise.then(() => setLoaded(true))
    }
  }

  const onEmployeeChange = (employee) => {
    setEmployee(employee)
    setPersistentCookie('employee', employee)

    if (!employee) {
      setFilteredProjectTypes(projectTypes)
    } else {
      const relatedProjectTypes = constructions
        .filter(construction => construction.employees.includes(employee))
        .map(construction => constructionType2ProjectType(construction.resourcetype))
      const uniqueProjectTypeKeys = [...new Set(relatedProjectTypes)]
      const uniqueProjectTypes = projectTypes.filter(pt => uniqueProjectTypeKeys.includes(pt.key))
      setFilteredProjectTypes(uniqueProjectTypes)
    }
  }

  const onProjectTypeChange = (projectType) => {
    setProjectType(projectType)
    setPersistentCookie('projectType', projectType)

    if (!projectType) {
      setFilteredEmployees(allEmployees.current)
    } else {
      const relatedEmployees = constructions
        .filter(construction => construction.resourcetype === ProjectType2ConstructionType(projectType))
        .flatMap(construction => construction.employees)
      const uniqueEmployeeIds = [...new Set(relatedEmployees)]
      const uniqueEmployees = allEmployees.current.filter(e => uniqueEmployeeIds.includes(e.id))
      setFilteredEmployees(uniqueEmployees)
    }
  }

  const onLocationChange = (location) => {
    setLocation(location)
    setPersistentCookie('planvilleLocation', location)
  }

  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value)
    setPersistentCookie('postalCode', e.target.value)
  }

  const onTaskTypeChange = (type) => {
    setTaskType(type)
    setPersistentCookie('taskType', type)
  }

  const resetFilter = () => {
    setEmployee(null)
    setProjectType(null)
    setLocation(null)
    setPostalCode(null)
    setTaskType(null)
    setPersistentCookie('employee', null)
    setPersistentCookie('projectType', null)
    setPersistentCookie('planvilleLocation', null)
    setPersistentCookie('postalCode', null)
    setPersistentCookie('taskType', null)
  }

  const resetState = () => loadData()

  const constructions2calendarEventData = (constructions) => {
    return constructions
      .filter(c => c.constructiondates_set && c.constructiondates_set.length > 0)
      .flatMap(item => {
        return item.constructiondates_set.map(dateObj => {
          const startdate = new Date(`${dateObj.date}`)
          const enddate = new Date(`${dateObj.date}`)
          return {
            title: `${item.customer_name}`,
            start: startdate,
            end: enddate,
            allDay: true,
            event: item,
            backgroundColor: item.color || '#142b70',
            type: 'construction',
            tentative: !item.construction_date_confirmed
          }
        })
      })
  }

  const tasks2calendarEventData = (tasks) => {
    return tasks
      .filter(item => item.taskdates_set && item.taskdates_set.length > 0)
      .flatMap(item => {
        return item.taskdates_set.map(dateObj => {
          const startdate = moment(date2String(dateObj.date) + ' ' + item.start_time).toDate()
          const enddate = moment(date2String(dateObj.date) + ' ' + item.end_time).toDate()
          const color = item.finished ? '#B3B3B3' : '#404040'
          const customerTitle = (item.customer_name && item.customer_name !== '') ? ` (${item.customer_name})` : ''
          return {
            title: `${item.title}${customerTitle}`,
            start: startdate,
            end: enddate,
            event: item,
            backgroundColor: color,
            type: 'task',
            tentative: false
          }
        })
      })
  }

  const legend = constructions.filter(c => c.color_label && c.color).reduce(function (r, c) {
    r[c.color_label] = r[c.color_label] || c.color
    return r
  }, {})

  const togglePersonalizeFilter = () => {
    setIsCurrentUserFiltering(current => {
      const newState = !current
      if (newState) {
        setConstructions(allConstructions.current.filter(construction => construction.employees.includes(session.user.id)))
        setTasks(allTasks.current.filter(task => task.employees.includes(session.user.id)))
      } else {
        setConstructions([...allConstructions.current])
        setTasks([...allTasks.current])
      }
      return newState
    })
  }

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '16px' }}>
        {loaded ? null : <LoadingPage />}
        {isMobileOnly && (
          <>
            <ScheduleModal resetParent={resetState} session={session} />
            <CustomButton
              icon='filter'
              description='Filter'
              onClick={() => setShowFilter(prevShowFilter => !prevShowFilter)}
              style={{ backgroundColor: showFilter ? 'lightgrey' : '' }}
            >
              Filter
            </CustomButton>
          </>
        )}
      </div>
      <Row>
        <Col>
          <Row className="mb-1">
            <span style={{ alignItems: 'center' }}>
              <div style={{ float: 'right' }}>
                <Stack direction="row" spacing={2}>
                  {Object.entries(legend).map(([colorLabel, color], cIdx) =>
                    <Tooltip key={`color-avatar-${cIdx}`} title={colorLabel}>
                      <Avatar sx={{ bgcolor: color }}>{colorLabel.split(' ').filter(x => ![' ', ''].includes(x)).map(x => x[0]).join('')}
                      </Avatar>
                    </Tooltip>
                  )}
                </Stack>
              </div>
              {!isMobileOnly && (
              <>
                <CustomButton
                  icon='filter'
                  description='Filter'
                  onClick={() => setShowFilter(prevShowFilter => !prevShowFilter)}
                  style={{ backgroundColor: showFilter ? 'lightgrey' : '', marginRight: '8px' }}
                >
                  Filter
                </CustomButton>
                <ScheduleModal resetParent={resetState} session={session} />
              </>
              )}
            </span>
          </Row>
          {showFilter && <Col style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
            <Col>
              <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', gap: isMobileOnly ? 0 : '1rem' }}>
                {!isCurrentUserFiltering && (
                  <FormGroup>
                    <DropDown
                      onChange={onEmployeeChange}
                      options={filteredEmployees.map(e => ({ value: e.id, label: e.name }))}
                      value={employee}
                      text='Mitarbeiter'
                      search={true}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <DropDown
                    onChange={onProjectTypeChange}
                    options={filteredProjectTypes.map(p => ({ value: p.key, label: p.name }))}
                    value={projectType}
                    text='Projekttyp'
                    search={true}
                  />
                </FormGroup>
                <FormGroup>
                  <DropDown
                    onChange={onTaskTypeChange}
                    options={taskTypes.map(t => ({ value: t.id, label: t.name, props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } } }))}
                    value={taskType}
                    text='Aufgaben-Typ'
                    search={true}
                    noOptionsText={'Keine Aufgaben-Typen'}
                  />
                </FormGroup>
              </div>
              <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', gap: isMobileOnly ? 0 : '1rem' }}>
                <FormGroup>
                  <Input
                      style={{ flex: 1, backgroundColor: '#ffffff', color: '#424242', borderColor: '#424242', boxShadow: 'none', width: '250px' }}
                      type="text"
                      name="searchPostalCode"
                      onChange={onPostalCodeChange}
                      value={defaultIfEmpty(postalCode)}
                      placeholder="PLZ"
                      autoComplete="off"
                    />
                </FormGroup>
                <FormGroup>
                  <DropDown
                    onChange={onLocationChange}
                    options={planvilleLocations.map(p => ({ value: p.id, label: p.name }))}
                    value={location}
                    text='Standort'
                    search={true}
                  />
                </FormGroup>
                <CustomButton
                  description='Filter zurücksetzen'
                  onClick={resetFilter}
                  style = {{ width: '250px', height: '40px' }}
                >
                  Filter zurücksetzen
                </CustomButton>
              </div>
            </Col>
            <Col>
              {!employee && !isMobileOnly && (
                <CustomButton
                  icon='person'
                  description='Persönliche Baustellen/Aufgaben'
                  onClick={togglePersonalizeFilter}
                  style={{ marginBottom: '10px', backgroundColor: isCurrentUserFiltering ? 'lightgrey' : '' }}
                >
                  Persönliche Baustellen/Aufgaben
                </CustomButton>
              )}
            </Col>
            {
              !employee && isMobileOnly && (
                <CustomButton
                  icon='person'
                  description='Persönliche Baustellen/Aufgaben'
                  onClick={togglePersonalizeFilter}
                  style={{ marginBottom: '10px', backgroundColor: isCurrentUserFiltering ? 'lightgrey' : '' }}
                >
                  Persönliche Baustellen/Aufgaben
                </CustomButton>
              )
            }
          </Col>
          }
          <Row>
            <Calendar
              className='secondary-textcolor'
              views={isMobileOnly ? ['day', 'month', 'work_week'] : ['day', 'month', 'work_week']}
              selectable
              localizer={localizer}
              defaultDate={new Date()}
              view={view}
              onView={setView}
              style={{ height: isMobileOnly ? `${winHeight - 150}px` : `${winHeight - 100}px` }}
              scrollToTime={moment()
                .set({ h: 8, m: 0 })
                .toDate()}
              events={constructions2calendarEventData(constructions).concat(getHolidayEvents()).concat(tasks2calendarEventData(tasks))}
              onSelectEvent={(event) => {
                if (event.type === 'construction' && view !== 'month') history.push('/projekt/' + event.event.project + '/bauplanung') // setChosenConstruction(event.event)
                else if (event.type === 'task' && view !== 'month') setChosenTask(event.event)
                else setChosenDate(event.start)
              }}
              onSelectSlot={(slot) => setChosenDate(slot.start)}
              onRangeChange={onRangeChange}
              longPressThreshold={isMobileOnly ? 30 : 50}
              step={60}
              timeslots={1}
              eventPropGetter={(event) => {
                const backgroundColor = event.backgroundColor
                return { style: { backgroundColor, opacity: event.tentative ? 0.5 : 1 } }
              }}
              dayPropGetter={(day) => {
                if (isHoliday(day, HOLIDAYREGION)) return {} // holidays
                if ([0, 6].includes(day.getDay())) return {} // weekend
                const dayConstructions = constructions.filter(c => c !== undefined && c.constructiondates_set.some(dateObj => date2String(dateObj.date) === date2String(day)))
                return { style: { backgroundColor: (date2String(new Date()) === date2String(day)) ? '#51555c' : getConstructionDateColor(dayConstructions), opacity: 0.7 } }
              }}
              messages={germanCalendarMsgs}
            />
            <ConstructionDate
              constructions={constructions}
              tasks={tasks}
              employees={allEmployees.current}
              date={chosenDate}
              isOpen={!!chosenDate}
              setIsOpen={(isOpen) => { if (!isOpen) setChosenDate(null) }}
              session={session}
              resetParent={resetState}
              filter={filter}
            />
            <TaskFormModal
              task={chosenTask}
              isOpen={!!chosenTask}
              setIsOpen={(isOpen) => { if (!isOpen) { setChosenTask(null) } }}
              resetParent={resetState}
              session={session}
            />
          </Row>
        </Col>
      </Row>
    </Container>

  )
}

ConstructionCalendar.propTypes = {
  session: PropTypes.object
}
