import React, { useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'
import { Image, Layer, Stage } from 'react-konva'

import { getImageWindowWithAuth } from '../../../elements/utils'

export default function ImageStage ({ image, height, width, size = 'fix', maxHeight = 450, maxWidth = 600 }) {
  const [imageState, setImageState] = useState({
    stageWidth: null, stageHeight: null, imageScale: null, imageWindow: null
  })
  const updateState = (update) => setImageState({ ...imageState, ...update })

  useEffect(() => { loadImage() }, [image])

  const loadImage = () => {
    if (image === null) { updateState({ imageWindow: null }); return }
    getImageWindowWithAuth(image, handleLoad)
  }

  const handleLoad = (newImageWindow) => {
    let scalerW, scalerH
    if (height) {
      scalerH = height / newImageWindow.height
      scalerW = maxWidth ? maxWidth / newImageWindow.width : Infinity
    } else if (width) {
      scalerW = width / newImageWindow.width
      scalerH = maxHeight ? maxHeight / newImageWindow.height : Infinity
    } else {
      scalerW = maxWidth / newImageWindow.width
      scalerH = maxHeight / newImageWindow.height
    }
    const scaler = (scalerW >= scalerH) ? scalerH : scalerW
    const stageWidth = newImageWindow.width * scaler
    const stageHeight = newImageWindow.height * scaler
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight
    })
  }

  if (imageState.imageWindow === null) return null

  return (
    <Stage
      width={size === 'fix' ? imageState.stageWidth : null}
      height={size === 'fix' ? imageState.stageHeight : null}
      listening={false}
    >
      <Layer>
        <Image
          image={imageState.imageWindow}
          scaleX={size === 'fix' ? imageState.imageScale : null}
          scaleY={size === 'fix' ? imageState.imageScale : null}
        />

      </Layer>
    </Stage>
  )
}

ImageStage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Blob), PropTypes.instanceOf(File)]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
