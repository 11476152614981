import React from 'react'

import { PropTypes } from 'prop-types'
import { Form } from 'reactstrap'

import { Typography } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import { projectPropType } from '../../../../elements/PropTypes'
import PlantAddMandateSignature from './PlantAddMandateSignature'
import PdfViewModal from '../../../shared/modal_utils/PdfViewModal'
import ImageViewModal from '../../../shared/modal_utils/ImageViewModal'

export default function PlantMandateSignatureForm ({ project, setProject, showAllMissingFields, session }) {
  return (
    <Form>
      <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
        Vollmacht
      </Typography>
      {showAllMissingFields && !project.accept_mandate_signature && !project.accept_mandate_document
        ? <div>
          &nbsp;&nbsp;
          <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
        </div>
        : null}
      <br/>
      {project.accept_mandate_signature
        ? <ImageViewModal
            title={'Unterschrift Vollmacht'}
            width={200}
            filepath={project.accept_mandate_signature}
          />
        : project.accept_mandate_document
          ? <PdfViewModal
            title={'Vollmacht'}
            filepath={project.accept_mandate_document}
          />
          : null}
      <PlantAddMandateSignature
        project={project}
        setProject={setProject}
        session={session}
      />
    </Form>
  )
}

PlantMandateSignatureForm.propTypes = {
  project: projectPropType,
  setProject: PropTypes.func,
  showAllMissingFields: PropTypes.bool,
  session: PropTypes.object
}
