import React, { useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'
import { Form, FormFeedback, FormGroup, Input } from 'reactstrap'

import Add from '@mui/icons-material/Add'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { IconButton, Stack, Typography } from '@mui/material'

import AddressInput from '../../elements/AddressInput'
import DropDown from '../../elements/DropDown'
import { leadPropType, leadTypePropType, userPropType } from '../../elements/PropTypes'
import { defaultIfEmpty, isValidEmailAddress, isValidPhoneNumber, LEAD_SOURCES } from '../../elements/utils'
import TextField from '../../elements/TextField'
import { CustomButton } from '../../elements/StyledElements'
import { API_URL_ASSIGNLEAD } from '../../settings'
import axios from 'axios'

export default function LeadForm ({ showMissingFields, lead, setLead, leadTypes, salesmen, errors, setErrors, session }) {
  const [nPhones, setNPhones] = useState(1)
  const [otherLeadSource, setOtherLeadSource] = useState(false)

  useEffect(() => {
    if (lead.id) {
      setNPhones((lead.phone3) ? 3 : ((lead.phone2) ? 2 : 1))
      if (!LEAD_SOURCES.includes(lead.source) && lead.source !== null) setOtherLeadSource(true)
    }
  }, [lead])

  const onChange = e => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'email') {
      if (!!value && !isValidEmailAddress(value)) {
        setErrors({ ...errors, email: 'Ungültige E-Mail.' })
      } else {
        setErrors({ ...errors, email: null })
      }
    } else if (name === 'phone1' || name === 'phone2' || name === 'phone3') {
      if (!!value && !isValidPhoneNumber(value)) {
        setErrors({ ...errors, [name]: 'Ungültige Telefonnummer.' })
      } else {
        setErrors({ ...errors, [name]: null })
      }
    }
    setLead(c => ({ ...c, [name]: value }))
  }

  const setGender = gender => {
    setLead(c => ({ ...c, gender }))
  }

  const setLeadSource = value => {
    if (value === 'Andere') {
      setLead((lead) => ({ ...lead, source: null }))
      setOtherLeadSource(true)
    } else {
      setOtherLeadSource(false)
      setLead((lead) => ({ ...lead, source: value }))
    }
  }

  if (!lead) return null

  return (
    <Form>
      {(lead.id)
        ? (
          <FormGroup>
            <Typography className='secondary-textcolor'>Leadnummer:</Typography>
            <div className="input-group">
              <Input
                style={{ width: '50%' }}
                type="text"
                id="input"
                name="lead_id"
                value={defaultIfEmpty(lead.id)}
                disabled={true}
              />
            </div>
          </FormGroup>)
        : null
      }
      <FormGroup>
        <Typography className='secondary-textcolor'>Anrede:</Typography>
        <DropDown
          id="gender_dropdown"
          onChange={setGender}
          text={(lead.gender === 'Familie') ? 'Familie' : 'Anrede'}
          options={['Frau', 'Herr', 'Firma']}
          value={lead.gender}
        />
        {(showMissingFields && !(lead.gender))
          ? <div>
            &nbsp;&nbsp;
            <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
          </div>
          : null}
      </FormGroup>
      {lead.gender === 'Firma'
        ? <FormGroup>
          <Typography className='secondary-textcolor'>Firma:</Typography>
          <Input
            type="text"
            id="input"
            name="company"
            onChange={onChange}
            value={defaultIfEmpty(lead.company)}
            required={true}
            autoComplete="off"
            invalid={!!(showMissingFields && (lead.gender === 'Firma') && !(lead.company))}
            style={{ boxShadow: 'none' }}
          />
        </FormGroup>
        : null
      }
      <FormGroup>
        <Typography className='secondary-textcolor'>Vorname:</Typography>
        <Input
          type="text"
          id="input"
          name="first_name"
          onChange={onChange}
          value={defaultIfEmpty(lead.first_name)}
          required={true}
          autoComplete="off"
          invalid={!!(showMissingFields && (lead.gender !== 'Firma') && !(lead.first_name))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Nachname:</Typography>
        <Input
          id="input"
          type="text"
          name="last_name"
          onChange={onChange}
          value={defaultIfEmpty(lead.last_name)}
          required={true}
          autoComplete="off"
          invalid={!!(showMissingFields && !(lead.last_name))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>

      <AddressInput
        address={{ street_and_number: lead.street_and_number, zip_and_city: lead.zip_and_city, province: lead.province }}
        setAddress={(address) => setLead(lead => ({ ...lead, street_and_number: address.street_and_number, zip_and_city: address.zip_and_city, province: address.province }))}
        setStreetAndNumber={(streetAndNumber) => setLead(lead => ({ ...lead, street_and_number: streetAndNumber }))}
        setZipAndCity={(zipAndCity) => setLead(lead => ({ ...lead, zip_and_city: zipAndCity }))}
        setProvince={(province) => setLead(lead => ({ ...lead, province }))}
        showMissingFields={showMissingFields}
        session={session}
      />
      <FormGroup>
        <Typography className='secondary-textcolor'>E-Mail:</Typography>
        <Input
          id="input"
          type="email"
          name="email"
          onChange={onChange}
          value={defaultIfEmpty(lead.email)}
          required={true}
          autoComplete="off"
          invalid={!!(showMissingFields || (!!(lead.email) && !isValidEmailAddress(lead.email)))}
          style={{ boxShadow: 'none' }}
        />
        {
          errors.email &&
          <FormFeedback>
            {errors.email}
          </FormFeedback>
        }
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Telefon:</Typography>
        <Input
          id="input"
          type="text"
          name="phone1"
          onChange={onChange}
          value={defaultIfEmpty(lead.phone1)}
          required={true}
          autoComplete="off"
          invalid={!!(showMissingFields || (!!(lead.phone1) && !isValidPhoneNumber(lead.phone1)))}
          style={{ boxShadow: 'none' }}
          onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
        />
        {
          errors.phone1 &&
          <FormFeedback>
            {errors.phone1}
          </FormFeedback>
        }
      </FormGroup>
      {(nPhones >= 2)
        ? <FormGroup>
          <Typography className='secondary-textcolor'>Telefon:</Typography>
          <Input
            id="input"
            type="text"
            name="phone2"
            onChange={onChange}
            value={defaultIfEmpty(lead.phone2)}
            required={false}
            autoComplete="off"
            style={{ boxShadow: 'none' }}
            invalid={!!(showMissingFields || (!!(lead.phone2) && !isValidPhoneNumber(lead.phone2)))}
            onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
          />
          {
            errors.phone2 &&
            <FormFeedback>
              {errors.phone2}
            </FormFeedback>
          }
        </FormGroup>
        : null}
      {(nPhones >= 3)
        ? <FormGroup>
          <Typography className='secondary-textcolor'>Telefon:</Typography>
          <Input
            id="input"
            type="text"
            name="phone3"
            onChange={onChange}
            value={defaultIfEmpty(lead.phone3)}
            required={false}
            autoComplete="off"
            style={{ boxShadow: 'none' }}
            invalid={!!(showMissingFields || (!!(lead.phone3) && !isValidPhoneNumber(lead.phone3)))}
            onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
          />
          {
            errors.phone3 &&
            <FormFeedback>
              {errors.phone3}
            </FormFeedback>
          }
        </FormGroup>
        : null}
      {(nPhones < 3) ? <div><IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={() => setNPhones(nPhones + 1)}><Add className='secondary-textcolor' fontSize='large' /><Typography className='secondary-textcolor'>Telefonnr. hinzufügen</Typography></IconButton> <br /></div> : null}
      <FormGroup>
        <Typography className='secondary-textcolor'>Notizen:</Typography>
        <TextField
          text={lead.notes}
          setText={(text) => setLead(l => ({ ...l, notes: text }))}
          description={null}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>{`Auf ${session.companyConfig.name} aufmerksam geworden durch:`}</Typography>
        <DropDown
          id="aware_of_company_dropdown"
          search={true}
          onChange={setLeadSource}
          options={LEAD_SOURCES.concat(['Andere'])}
          value={otherLeadSource ? 'Andere' : lead.source}
        />

        {otherLeadSource
          ? <>
            <br />
            <Input
              id="input"
              type="text"
              name="source"
              onChange={onChange}
              value={defaultIfEmpty(lead.source)}
              required={false}
              autoComplete="off"
              maxLength={100}
              style={{ boxShadow: 'none' }}
            />
          </>
          : null}
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Lead-Type</Typography>
        <DropDown
          id="lead_type"
          search={true}
          onChange={(value) => setLead(l => ({ ...l, lead_type: value }))}
          options={leadTypes.map(leadType => ({ value: leadType.id, label: leadType.name }))}
          value={lead.lead_type}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Verkäufer</Typography>
        <Stack direction='row' spacing={2}>
          <DropDown
            id="salesman"
            search={true}
            onChange={(value) => setLead(l => ({ ...l, salesman: value }))}
            options={salesmen.map(salesman => ({ value: salesman.id, label: salesman.name }))}
            value={lead.salesman}
          />
          <CustomButton onClick={() => { axios.get(API_URL_ASSIGNLEAD, { params: { zip_and_city: lead.zip_and_city, lead_type: lead.lead_type, salesman: lead.salesman } }).then(res => setLead(l => ({ ...l, salesman: res.data }))) }}>Zuordnen</CustomButton>
        </Stack>
      </FormGroup>
      {lead.archived
        ? <FormGroup>
          <Typography className='secondary-textcolor'>Archivierungsgrund: {lead.archiving_reason}</Typography>
        </FormGroup>
        : null}
    </Form>
  )
}

LeadForm.propTypes = {
  showMissingFields: PropTypes.bool,
  lead: leadPropType,
  setLead: PropTypes.func,
  leadTypes: PropTypes.arrayOf(leadTypePropType),
  salesmen: PropTypes.arrayOf(userPropType),
  session: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func
}
