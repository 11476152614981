import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Col, Container, Row, Input } from 'reactstrap'
import { Table } from 'react-bootstrap'
import axios from 'axios'

import { Typography, Tooltip, IconButton, Chip } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

import { CustomIconButton } from '../../elements/StyledElements'
import DropDown from '../../elements/DropDown'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { API_URL_TODOFILTER, API_URL_TODOTYPE } from '../../settings'

export default function EditFiltersModal ({ filters, setFilters, applyFilter, session }) {
  const [todoFilter, setTodoFilter] = useState({ name: '', included_todos: [], excluded_todos: [] })
  const [customerToDoInput, setCustomerToDoInput] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [toDoFilterOptions, setToDoFilterOptions] = useState([])

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const resetFilter = () => setTodoFilter({ name: '', included_todos: [], excluded_todos: [] })

  const loadData = () => {
    axios.get(API_URL_TODOTYPE)
      .then(res => {
        const filterOptions = res.data
          .filter(x => x.key)
          .map(x => ({ value: x.id, label: x.name }))
        setToDoFilterOptions(filterOptions)
      })
  }

  const clearData = () => {
    resetFilter()
    setSaveModalOpen(false)
    setLoadingElements({ inProgress: false, submitError: false })
    setToDoFilterOptions([])
  }

  const onToDoFilter = (key) => { // TODO
    if (key === null) resetFilter()
    else setTodoFilter(x => ({ ...x, [key]: x[key].concat([customerToDoInput]) }))
    setCustomerToDoInput(null)
  }

  const getOpenButton = (toggle) => {
    // return <CustomButton onClick={toggle} icon="filter">ToDo-Filter bearbeiten</CustomButton>
    return <CustomIconButton onClick={toggle} icon="edit" size='medium' description="ToDo-Filter bearbeiten"/>
  }

  const deleteFilter = (filter) => {
    axios.delete(API_URL_TODOFILTER + filter.id)
      .then(res => setFilters(filters => filters.filter(f => f.id !== filter.id)))
  }

  const saveFilter = () => {
    setLoadingElements(x => ({ ...x, inProgress: true, submitError: false }))
    axios.post(API_URL_TODOFILTER, todoFilter)
      .then(res => {
        setFilters(filters => [...filters, res.data])
        setLoadingElements(x => ({ ...x, inProgress: false, submitError: false }))
      }).catch(error => { console.error('Error in "EditFiltersModal:saveFilter"', error, error.stack); setLoadingElements(x => ({ ...x, submitError: true, inProgress: false })) })
  }

  const getToDoFilters = (filter) => {
    return (<>
            {filter.included_todos.map((todo) => {
              const option = toDoFilterOptions.find(x => x.value === todo)
              return <Chip
                    key={`filter_${filter.id ? filter.id : 'new'}_${todo}`}
                    style={{ backgroundColor: '#c3e3b257', marginRight: '10px', marginBottom: '10px' }}
                    size="small" label={option ? option.label : null}
                    onDelete={filter.id ? undefined : () => setTodoFilter(x => ({ ...x, included_todos: x.included_todos.filter(y => y !== todo) }))}
                />
            })
            }
            {filter.excluded_todos.map((todo) => {
              const option = toDoFilterOptions.find(x => x.value === todo)
              return <Chip
                    key={`filter_${filter.id ? filter.id : 'new'}_${todo}`}
                    style={{ backgroundColor: '#e3b2b257', marginRight: '10px', marginBottom: '10px' }}
                    size="small" label={option ? option.label : null}
                    onDelete={filter.id ? undefined : () => setTodoFilter(x => ({ ...x, excluded_todos: x.excluded_todos.filter(y => y !== todo) }))}
                />
            })
            }
        </>)
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                onSave={() => setSaveModalOpen(true)}
                onComplete={() => { applyFilter(todoFilter); toggle() }}
                completeBtnLabel={'Anwenden'}
                disabled={todoFilter.included_todos.length === 0 && todoFilter.excluded_todos.length === 0}
            />
    )
  }

  const getSaveModalFooter = (toggle) => {
    return (
            <SaveModalFooter
                submitError={loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={() => { saveFilter(); resetFilter(); setSaveModalOpen(false) }}
                disabled={todoFilter.name === ''}
            />
    )
  }

  const getToDoFilterElement = () => {
    return <>
            <DropDown
                onChange={setCustomerToDoInput}
                options={toDoFilterOptions.filter(x => !todoFilter.excluded_todos.includes(x.value) && !todoFilter.included_todos.includes(x.value))}
                value={customerToDoInput}
                text='To Do wählen'
                sort={true}
            />
            <Tooltip title="Erledigt" placement="top-start">
                <IconButton
                    type="button" sx={{ p: '10px' }} aria-label="Erledigt"
                    disabled={customerToDoInput === null}
                    onClick={() => onToDoFilter('included_todos')}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Unerledigt" placement="top-start">
                <IconButton
                    type="button" sx={{ p: '10px' }} aria-label="Unerledigt"
                    disabled={customerToDoInput === null}
                    onClick={() => onToDoFilter('excluded_todos')}>
                    <RemoveIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Reset" placement="top-start">
                <IconButton
                    type="button" sx={{ p: '10px' }} aria-label="Reset"
                    disabled={todoFilter.included_todos.length === 0 && todoFilter.excluded_todos.length === 0}
                    onClick={() => onToDoFilter(null)}>
                    <RestartAltIcon />
                </IconButton>
            </Tooltip>
            <br />
            <br />
            {getToDoFilters(todoFilter)}
        </>
  }

  return (
        <>
            <CustomModal isOpen={saveModalOpen} setIsOpen={setSaveModalOpen} getFooter={getSaveModalFooter} size="small" title={'Filter erstellen'}>
                <Typography className={'secondary-textcolor'}>Filtername:</Typography>
                <Input
                    style={{ width: '70%', backgroundColor: '#ffffff', color: '#424242', borderColor: '#424242', boxShadow: 'none' }}
                    type="text"
                    name="filterNameInput"
                    onChange={(e) => setTodoFilter(x => ({ ...x, name: e.target.value }))}
                    value={todoFilter.name}
                    placeholder="Filtername"
                    autoComplete="off"
                />
            </CustomModal>
            <CustomModal size="lg" getFooter={getFooter} getOpenButton={getOpenButton} title={'Filter'} onToggle={onToggle}>
                <Table className={!filters || filters.length <= 0 ? 'table-not-hover' : 'table-hover'}>
                    <thead>
                        <tr>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Filter</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Todos</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'></Typography></th>
                        </tr>
                    </thead>

                    <tbody>
                        {!filters || filters.length <= 0
                          ? (
                            <tr>
                                <td colSpan="4" align="center">
                                    <Typography className='secondary-textcolor'> Keine Filter vorhanden </Typography>
                                </td>
                            </tr>
                            )
                          : (
                              filters.map(filter => (
                                <tr key={filter.id}>
                                    <td className="align-middle"><Typography className='secondary-textcolor'>{filter.name}</Typography></td>
                                    <td className="align-middle"><Typography className='secondary-textcolor'>{getToDoFilters(filter)}</Typography></td>
                                    <td className="align-middle"><Typography className='secondary-textcolor'><CustomIconButton icon='delete' onClick={() => deleteFilter(filter)} /></Typography></td>
                                </tr>
                              ))
                            )}
                    </tbody>

                </Table>
                <br />
                <Container >
                    <Row>
                        <Col>
                            <Typography className='secondary-textcolor'>Filter erstellen / anwenden</Typography>
                            {getToDoFilterElement()}
                        </Col>
                    </Row>
                </Container >
            </CustomModal >
        </>
  )
}

EditFiltersModal.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  setFilters: PropTypes.func,
  applyFilter: PropTypes.func,
  session: PropTypes.object
}
