import React, { useState, Fragment } from 'react'
import { PropTypes } from 'prop-types'

import { Typography } from '@mui/material'

import UploadCroppedImage from './UploadCroppedImage'
import RoofImagePlanning from './RoofImagePlanning'
import RoofScale from './RoofScale'
import CropImage from './CropImage'
import TransformImage from './TransformImage'

import CustomModal from '../modal_utils/CustomModal'

import { CustomButton } from '../../../elements/StyledElements'
import { modulePropType, projectPropType } from '../../../elements/PropTypes'
import UploadRoofImage from './UploadRoofImage'
import { round } from '../../../elements/utils'

export default function RoofImageUpload ({ project, module, addRoofImage, winWidth, winHeight, session }) {
  const [currentPanels, setCurrentPanels] = useState([])
  const [currentAngle, setCurrentAngle] = useState(0)
  const [currentDirection, setCurrentDirection] = useState(null)
  const [currentFlatRoof, setCurrentFlatRoof] = useState(false)
  const [currentRoofImage, setCurrentRoofImage] = useState(null)
  const [currentHouseImage, setCurrentHouseImage] = useState(null)
  const [currentImageWidthM, setCurrentImageWidthM] = useState(null)

  const [page, setPage] = useState(0)
  const nextPage = () => setPage(page + 1)
  const previousPage = () => setPage(page - 1)

  const [originalImage, setOriginalImage] = useState(null)
  const [transformedImage, setTransformedImage] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onToggle = (isOpen) => { if (!isOpen) clearData() }

  const clearData = () => {
    setPage(0)
    setCurrentPanels([])
    setCurrentAngle(0)
    setCurrentDirection(null)
    setCurrentFlatRoof(false)
    setCurrentRoofImage(null)
    setCurrentHouseImage(null)
    setCurrentImageWidthM(null)
    setOriginalImage(null)
    setTransformedImage(null)
    setCroppedImage(null)
  }

  const upload = () => {
    addRoofImage(currentDirection, currentRoofImage, currentImageWidthM, currentHouseImage, currentAngle, currentPanels, currentFlatRoof)
  }

  const getOpenButton = (toggle) => {
    return <CustomButton id="upload-new-roof-img-btn" onClick={toggle}>Neues Dachfoto hochladen</CustomButton>
  }

  const next = () => {
    if (page === 0) setTransformedImage(originalImage) // 0 (Upload) -> 1 (Transform)
    if (page === 1) setCroppedImage(transformedImage) // 1 (Transform) -> 2 (Crop)
    if (page === 2) setCurrentRoofImage(croppedImage) // 2 (Crop) -> 3 (Scale)
    nextPage()
  }

  const previous = () => {
    if (page === 1) setTransformedImage(null) // 0 (Upload) <- 1 (Transform)
    if (page === 2) { setCroppedImage(null); setTransformedImage(originalImage) } // 1 (Transform) <- 2 (Crop)
    if (page === 3) { setCurrentRoofImage(null); setCroppedImage(transformedImage) } // 2 (Crop) <- 3 (Scale)
    previousPage()
  }

  const getFooter = (toggle) => {
    return <div>
      {(page !== 0) ? <CustomButton color={'#ffffff'} onClick={previous}>Zurück</CustomButton> : null}
      &nbsp;
      &nbsp;
      {((page === 0 && originalImage && currentDirection && (currentFlatRoof != null)) || (page === 1 && transformedImage) || (page === 2 && croppedImage) || (page === 3 && currentImageWidthM) || (page === 4)) ? <CustomButton id="upload-roof-img-next-btn" color={'#ffffff'} onClick={next}>Weiter</CustomButton> : null}
      {(page === 5 && currentHouseImage) ? <CustomButton id="finish-upload-roof-image-steps-btn" color={'#ffffff'} onClick={() => { upload(); toggle() }}>Bild hinzufügen</CustomButton> : null}
    </div>
  }

  return (
    <Fragment>
      <CustomModal size="fullscreen" getOpenButton={getOpenButton} getFooter={getFooter} title="Dachfoto hochladen" onToggle={onToggle}>

        {(page === 0)
          ? (
            <UploadRoofImage
              direction={currentDirection}
              setDirection={setCurrentDirection}
              flatRoof={currentFlatRoof}
              setFlatRoof={setCurrentFlatRoof}
              image={originalImage}
              setImage={setOriginalImage}
              maxWidth={round(95 / 100 * winWidth)}
              maxHeight={round(80 / 100 * winHeight)}
            />
            )
          : null}
        {(page === 1)
          ? (
            <TransformImage
              image={originalImage}
              setTransformedImage={setTransformedImage}
              maxWidth={round(95 / 100 * winWidth)}
              maxHeight={round(85 / 100 * winHeight)}
            />
            )
          : null}
        {(page === 2)
          ? (
            <CropImage
              image={transformedImage}
              setCroppedImage={setCroppedImage}
              maxImageWidth={round(95 / 100 * winWidth)}
              maxImageHeight={round(85 / 100 * winHeight)}
            />
            )
          : null}
        {(page === 3)
          ? (
            <RoofScale
              image={currentRoofImage}
              onImageWidthChange={setCurrentImageWidthM}
              project={project}
              maxWidth={round(95 / 100 * winWidth)}
              maxHeight={round(80 / 100 * winHeight)}
              session={session}
            />
            )
          : null}
        {(page === 4)
          ? (
            <RoofImagePlanning
              direction={currentDirection}
              flatRoof={currentFlatRoof}
              image={currentRoofImage}
              imageWidthM={currentImageWidthM}
              module={module}
              panels={currentPanels}
              setPanels={setCurrentPanels}
              angle={currentAngle}
              setAngle={setCurrentAngle}
              maxWidth={round(95 / 100 * winWidth)}
              maxHeight={round(80 / 100 * winHeight)}
            />
            )
          : null}
        {(page === 5)
          ? (
            <div>
              <Typography fontSize='h6.fontSize' className='secondary-textcolor'>Außenansicht Haus:</Typography>
              <UploadCroppedImage
                id="exterior-view"
                maxImageWidth={round(95 / 100 * winWidth)}
                maxImageHeight={round(85 / 100 * winHeight)}
                setCroppedImage={setCurrentHouseImage} />
            </div>
            )
          : null}
      </CustomModal>
    </Fragment>
  )
}

RoofImageUpload.propTypes = {
  project: projectPropType,
  module: modulePropType,
  addRoofImage: PropTypes.func,
  winWidth: PropTypes.number,
  winHeight: PropTypes.number,
  session: PropTypes.object
}
