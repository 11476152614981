import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import NotImplementedOperatorRequest from './NotImplementedOperatorRequest'
import ImplementedOperatorRequest from './ImplementedOperatorRequest'

import { API_URL_OPERATOR } from '../../../../settings'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import { CustomButton } from '../../../../elements/StyledElements'
import { projectPropType } from '../../../../elements/PropTypes'

export default function OperatorRequestModal ({ project, getOpenButton, requestMode, resetParent, session }) {
  const [operators, setOperators] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const onToggle = (isOpen) => {
    if (isOpen) {
      if (project.construction_obj.e_meter && !project.emeter_set.every(x => x.action === 2)) { alert('Ein neuer Zähler wird eingebaut, jedoch wurde durch den Kunden nicht ausgewählt, dass alle Zähler ausgetauscht werden müssen.') }
      loadData()
    } else clearData()
  }

  const loadData = () => {
    axios.get(API_URL_OPERATOR).then(res => setOperators(res.data))
  }

  const clearData = () => {
    setOperators(null)
  }

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(() => setIsOpen(!isOpen))
    return <CustomButton onClick={() => setIsOpen(!isOpen)} disabled={!project.construction_obj} icon="electricPlug">Netzantrag</CustomButton>
  }

  const operator = (operators && project.construction_obj.operator) ? operators.find(o => o.id === project.construction_obj.operator) : null
  return (<CustomModal getOpenButton={_getOpenButton} isOpen={isOpen} setIsOpen={setIsOpen} onToggle={onToggle} size='xl' title="Netzantrag">
    {(operator && operator.implemented)
      ? <ImplementedOperatorRequest
        project={project}
        resetParent={resetParent}
        isOpen={isOpen}
        requestMode={requestMode}
        session={session}
      />
      : <NotImplementedOperatorRequest
        project={project}
        resetParent={resetParent}
        isOpen={isOpen}
        requestMode={requestMode}
        session={session}
      />
    }
  </CustomModal>)
}

OperatorRequestModal.propTypes = {
  project: projectPropType,
  getOpenButton: PropTypes.func,
  requestMode: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  resetParent: PropTypes.func,
  session: PropTypes.object
}
