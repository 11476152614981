import React from 'react'

import { PropTypes } from 'prop-types'
import { usePlacesWidget } from 'react-google-autocomplete'
import { FormGroup, Input } from 'reactstrap'

import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@mui/material'

import './GooglePredictionStyle.css'

import { defaultIfEmpty } from './utils'

const useStyles = makeStyles((theme) => ({
  inputfocused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#424242 !important',
      borderWidth: '1px'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#424242 !important'
  }
}))

export default function AddressInput ({ address, setAddress, setStreetAndNumber, setZipAndCity, setProvince, showMissingFields, session }) {
  const classes = useStyles()
  const { ref: materialRef } = usePlacesWidget({
    apiKey: session.creds.google_api_key,
    onPlaceSelected: (place) => {
      const province = place.address_components.filter(c => c.types.includes('administrative_area_level_1'))[0].long_name

      setAddress({ street_and_number: place.formatted_address.split(',')[0], zip_and_city: place.formatted_address.split(',')[1].substring(1), province })
    },
    // inputAutocompleteValue: "country",
    options: {
      componentRestrictions: { country: 'de' },
      types: ['address'],
      fields: ['name', 'formatted_address', 'address_components']
    }
  })

  return (
    <>
      <FormGroup>
        <Typography className='secondary-textcolor'>Straße Hausnr.:</Typography>
        <TextField
          id="input"
          type="text"
          name="street_and_number"
          variant="outlined"
          className={classes.inputfocused}
          onChange={(e) => setStreetAndNumber(e.target.value)}
          value={defaultIfEmpty(address.street_and_number)}
          required={true}
          size="small"
          error={!!(showMissingFields && !(address.street_and_number))}
          fullWidth
          inputRef={materialRef}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline
            }
          }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>PLZ Ort:</Typography>
        <Input
          id="input"
          type="text"
          name="zip_and_city"
          onChange={(e) => setZipAndCity(e.target.value)}
          value={defaultIfEmpty(address.zip_and_city)}
          required={true}
          invalid={!!(showMissingFields && !(address.zip_and_city))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Bundesland:</Typography>
        <Input
          id="input"
          type="text"
          name="province"
          onChange={(e) => setProvince(e.target.value)}
          value={defaultIfEmpty(address.province)}
          required={true}
          invalid={!!(showMissingFields && !(address.province))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
    </>
  )
}

AddressInput.propTypes = {
  address: PropTypes.object,
  setAddress: PropTypes.func,
  setStreetAndNumber: PropTypes.func,
  setZipAndCity: PropTypes.func,
  setProvince: PropTypes.func,
  showMissingFields: PropTypes.bool,
  session: PropTypes.object
}
