import { PropTypes } from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'

import LoadingPage from '../elements/LoadingPage'

import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { API_URL_TASKREMINDERNOTED } from '../settings'
import LogoHeader from '../elements/LogoHeader'

export default function ReminderNoticed ({ session }) {
  const [success, setSuccess] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    axios.post(API_URL_TASKREMINDERNOTED, { task: id }).then(() => setSuccess(true))
  })

  return (
    <Fragment>
      <LogoHeader />
      {success
        ? <div className="text-center">
          <Typography fontSize='h5.fontSize' style={{ padding: '40px 0px' }}>Die Erinnung wurde zur Kenntnis genommen.</Typography>
        </div >
        : <LoadingPage />}
    </Fragment>
  )
}

ReminderNoticed.propTypes = {
  session: PropTypes.object.isRequired
}
