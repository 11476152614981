import React, { useState } from 'react'

import { PropTypes } from 'prop-types'

import CustomForm, { getEmptyFieldsErrorFromTemplate } from '../../elements/CustomForm'
import { CustomButton } from '../../elements/StyledElements'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import axios from 'axios'
import { API_URL_NEWSLETTEREMAILDESIGN } from '../../settings'

const emptyNewsletterEmailDesignForm = {
  id: null,
  name: null,
  design: null
}

const newsletterEmailDesignTemplate = [
  {
    type: 'text',
    name: 'Vorlagenname:',
    key: 'name'
  }
]

export default function SaveDesignModal ({ emailEditorRef, disable, getOpenButton, resetParent, session }) {
  const [newsletterEmailDesignForm, setNewsletterEmailDesignForm] = useState({ ...emptyNewsletterEmailDesignForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton disabled={disable} onClick={toggle} icon="save">Vorlage speichern</CustomButton>
  }

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const loadData = () => {
    emailEditorRef.current?.editor?.saveDesign((design) => {
      setNewsletterEmailDesignForm({ ...emptyNewsletterEmailDesignForm, design })
    })
  }

  const clearData = () => {
    setNewsletterEmailDesignForm({ ...emptyNewsletterEmailDesignForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
  }

  const submit = async (form) => {
    return axios.post(API_URL_NEWSLETTEREMAILDESIGN, form)
  }

  const onSubmit = async (onSuccess) => {
    const emptyFieldsError = getEmptyFieldsErrorFromTemplate(newsletterEmailDesignTemplate, newsletterEmailDesignForm, emptyNewsletterEmailDesignForm)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
      console.error(emptyFieldsError)
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(newsletterEmailDesignForm).then((res) => {
      if (resetParent) resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => {
      console.error('Error in ConstructionFormModal:onSubmit', error, error.stack)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit-planning-form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => { onSubmit(toggle) }}
      />
    )
  }

  return (
    <CustomModal getOpenButton={_getOpenButton} onToggle={onToggle} getFooter={getFooter} title="Newsletter Vorlage speichern" >
      <CustomForm
        template={newsletterEmailDesignTemplate}
        form={newsletterEmailDesignForm}
        setForm={setNewsletterEmailDesignForm}
        defaultForm={emptyNewsletterEmailDesignForm}
        showMissingFields={loadingElements.showMissingFields}
      />
    </CustomModal>
  )
}

SaveDesignModal.propTypes = {
  emailEditorRef: PropTypes.object,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
