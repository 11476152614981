import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

import { Typography } from '@mui/material'

import CollapsibleTable from '../elements/CollapsibleTable'
import { getCustomerName, getCustomerOverview, hasPermission } from '../elements/utils'
import { API_URL_CREATEAPPACCESS, API_URL_CUSTOMER } from '../settings'
import LoadingPage from './../elements/LoadingPage'
import { CustomButton } from './../elements/StyledElements'
import CustomerFormModal from './customers/CustomerFormModal'
import CustomerActivities from './project/CustomerActivities'
import CustomerEmails from './project/CustomerEmails'
import ProjectFormModal from './project/ProjectFormModal'
import { getProjectType } from './project_types/projectUtils'
import ConfirmationModal from './shared/modal_utils/ConfirmationModal'
import ContactDenialModal from './customers/ContactDenialModal'
import TextField from '../elements/TextField'
import TaskTable from './customers/TaskTable'

export default function Customer ({ session }) {
  const history = useHistory()

  const [customer, setCustomer] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    resetState()
  }, [id])

  const resetState = () => {
    getCustomer()
  }

  const getCustomer = () => {
    axios.get(API_URL_CUSTOMER + id, { params: { nested: true } }).then(
      res => { setCustomer(res.data) }).catch(
      () => history.push('/'))
  }

  if (!customer) return <LoadingPage />

  const customerOverview = getCustomerOverview(customer, false)

  const customerInfo = <CustomerFormModal
    customer={customer}
    resetParent={resetState}
    session={session}
  />

  const appAccount = hasPermission(session.user, 'customer_handling')
    ? <ConfirmationModal
      title={customer.user
        ? "Die Zugangsdaten für '" + getCustomerName(customer) + "' zurücksetzen?"
        : "Die Zugangsdaten für '" + getCustomerName(customer) + "' erstellen?"}
      resetParent={resetState}
      confirm={() => axios.post(API_URL_CREATEAPPACCESS, { customer: customer.id })}
      getOpenButton={(toggle) => <CustomButton onClick={toggle} icon="openLock">App-Zugang</CustomButton>}
      btnLabel="Senden"
    />
    : null

  const emailOverview = hasPermission(session.user, 'customer_handling') ? <CustomerEmails customer={customer} session={session} /> : null
  const activityOverview = hasPermission(session.user, 'customer_handling') ? <CustomerActivities customer={customer} session={session} /> : null

  const remove = (hasPermission(session.user, 'customer_delete'))
    ? <ConfirmationModal
      title={"Soll '" + getCustomerName(customer) + "' wirklich entfernt werden?"}
      resetParent={resetState}
      confirm={() => {
        axios.delete(API_URL_CUSTOMER + customer.id)
          .then(() => history.push('/kunden/'))
      }}
      getOpenButton={(toggle) => <CustomButton onClick={toggle} icon="delete" iconClassName="IconDeleteButton">Löschen</CustomButton>}
    />
    : null

  const projectTable = <CollapsibleTable
    columns={[{
      name: 'Name',
      key: 'name'
    }, {
      name: 'Typ',
      key: 'type'
    }, {
      name: 'Nächster Schritt',
      key: 'todo'
    }]}
    rows={customer.project_set
      .map(project => ({
        key: `${project.id}`,
        name: project.name,
        type: getProjectType(project),
        link: `/projekt/${project.id}`,
        todo: project.open_todo_name
      }))}
    counter={true}
    collapsible={false}
  />

  const newProject = hasPermission(session.user, 'customer_handling')
    ? <ProjectFormModal
      customer={customer}
      resetParent={resetState}
      session={session}
    />
    : null

  const denyContact = (!customer.contact_denied && hasPermission(session.user, 'customer_handling'))
    ? <ContactDenialModal
      customer={customer}
      resetParent={resetState}
      session={session} />
    : null

  if (isMobileOnly) {
    return (
      <Fragment>
        <Container>
          <Col>{customerOverview}</Col>
          <br />
          <Col className=" align-items-center justify-content-center" style={{ paddingLeft: 50, paddingRight: 50 }}>
            {hasPermission(session.user, 'customer_handling')
              ? <>
                <Row>{customerInfo}</Row>
                <br />
                <Row>{appAccount}</Row>
                <br />
                <Row>{emailOverview}</Row>
                <br />
                <Row>{activityOverview}</Row>
                <hr className='secondary-textcolor' />
              </>
              : null}
            <Row>
              <TextField text={customer.note} save={(text) => axios.put(API_URL_CUSTOMER + customer.id, { note: text }).then(resetState)} />
            </Row>
            <hr className='secondary-textcolor' />
            <Row>{denyContact}</Row>
            {remove
              ? <>
                <br />
                <Row>{remove}</Row>
              </>
              : null}
          </Col>
          <br />
          <Col>
            <Typography className="secondary-textcolor" style={{ fontSize: 22 }}>Projekte</Typography>
            {projectTable}
            <br />
            {newProject}
            <>
              <br />
              <TaskTable customer={customer} session={session} />
            </>
          </Col>
        </Container>
      </Fragment >
    )
  }

  return (
    <Fragment>
      <Container >
        <Row>
          <Col className='col-4'>
            <br />
            <Row>{customerOverview}</Row>
            {hasPermission(session.user, 'customer_handling')
              ? <>
                <hr className='secondary-textcolor' />
                <Row>
                  <Col >
                    <Row>{customerInfo}</Row><br /><Row>{emailOverview}</Row>
                  </Col>
                  &nbsp;&nbsp;
                  <Col >
                    <Row>{appAccount}</Row><br /><Row>{activityOverview}</Row>
                  </Col>
                </Row>
              </>
              : null}
            <hr className='secondary-textcolor' />
            <TextField text={customer.note} save={(text) => axios.put(API_URL_CUSTOMER + customer.id, { note: text }).then(resetState)} />
            <hr className='secondary-textcolor' />
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                {remove ? <>{remove} &nbsp; &nbsp;</> : null}
                {denyContact ? <>{denyContact} &nbsp; &nbsp;</> : null}
              </Col>
            </Row>
          </Col>
          <Col>
            <br />
            <br />
            <Typography className="secondary-textcolor" style={{ fontSize: 22 }}>Projekte</Typography>
            {projectTable}
            <br />
            {newProject}
            <>
              <br />
              <br />
              <TaskTable customer={customer} session={session} />
            </>
          </Col>
        </Row>
      </Container >
      <br />
    </Fragment >
  )
}

Customer.propTypes = {
  session: PropTypes.object
}
