import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

import { hasPermission } from '../elements/utils'

import TabView from '../elements/TabView'
import ComponentsStatistics from './statistics/ComponentsStatistics'
import RevenueStatistics from './statistics/RevenueStatistics'
import OfferStatistics from './statistics/OfferStatistics'
import ConstructionStatistics from './statistics/ConstructionStatistics'

export default function Statistics ({ session }) {
  return (
    <Fragment>
      <TabView
        components={[
          {
            title: 'Angebote',
            getChild: () => <OfferStatistics
              session={session}
            />
          },
          {
            title: 'Baustellen',
            getChild: () => <ConstructionStatistics
              session={session}
            />
          },
          ...(hasPermission(session.user, 'statistics_all')
            ? [{
                title: 'Umsatz',
                getChild: () => <RevenueStatistics
                session={session}
              />
              }]
            : []),
          ...(hasPermission(session.user, 'statistics_all')
            ? [{
                title: 'Komponenten',
                getChild: () => <ComponentsStatistics
                session={session}
              />
              }]
            : [])
        ]}
      />
    </Fragment>)
}

Statistics.propTypes = {
  session: PropTypes.object
}
