import React from 'react'

import Collapsible from 'react-collapsible'
import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'

import Add from '@mui/icons-material/Add'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Typography, IconButton } from '@mui/material'

import AddressInput from '../../../../elements/AddressInput'
import DropDown from '../../../../elements/DropDown'
import { projectPropType } from '../../../../elements/PropTypes'
import { CustomIconButton } from '../../../../elements/StyledElements'
import { defaultIfEmpty, eMeterActions, getRandomId } from '../../../../elements/utils'
import { emptyEMeterForm } from './forms'

export default function PlantProjectForm ({ project, setProject, showMissingFields, showAllMissingFields, session }) {
  if (!project) return null

  const changeEMeter = (eMeterId, attr, value) => {
    setProject(p => {
      const newEMeters = [...p.emeter_set]
      const eMeter = newEMeters.find(e => e.id === eMeterId)
      eMeter[attr] = value
      return { ...p, emeter_set: newEMeters }
    })
  }

  const addEMeter = () => {
    setProject(p => ({ ...p, emeter_set: [...p.emeter_set, { ...emptyEMeterForm, project: project.id, id: -getRandomId() }] }))
  }

  return (
    <Form>
      <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
        Addresse
      </Typography>
      <AddressInput
        address={{ street_and_number: project.street_and_number_project, zip_and_city: project.zip_and_city_project, province: project.province_project }}
        setAddress={(address) => setProject(p => ({ ...p, street_and_number_project: address.street_and_number, zip_and_city_project: address.zip_and_city, province_project: address.province }))}
        setStreetAndNumber={(streetAndNumber) => setProject(p => ({ ...p, street_and_number_project: streetAndNumber }))}
        setZipAndCity={(zipAndCity) => setProject(p => ({ ...p, zip_and_city_project: zipAndCity }))}
        setProvince={(province) => setProject(p => ({ ...p, province_project: province }))}
        showMissingFields={showMissingFields}
        session={session}
      />
      <hr className='secondary-textcolor' />
      <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
        Zähler
      </Typography>
      {showAllMissingFields && (!project.emeter_set || project.emeter_set.length === 0)
        ? <div>
            &nbsp;&nbsp;
            <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
          </div>
        : null}
      <br />
      {project.emeter_set?.map((eMeter, eIdx) => (
        <>
          <FormGroup>
            <Typography className='secondary-textcolor'>Zählernummer</Typography>
            <Input
              id="input"
              required={true}
              style={{ boxShadow: 'none' }}
              type="text"
              name={'emeter-id-' + eIdx}
              value={defaultIfEmpty(eMeter.e_meter_id)}
              autoComplete="off"
              onChange={(e) => changeEMeter(eMeter.id, 'e_meter_id', e.target.value)}
              invalid={!!(showMissingFields && !(eMeter.e_meter_id))}
            />
          </FormGroup>
          <FormGroup>
            <Typography className='secondary-textcolor'>Zählerstand</Typography>
            <Input
              id="input"
              required={true}
              style={{ boxShadow: 'none' }}
              type="number"
              name={'emeter_value_' + eIdx}
              value={defaultIfEmpty(eMeter.value)}
              min="0"
              onKeyPress={(e) => { if (e.code === 'Minus') { e.preventDefault() } }}
              autoComplete="off"
              onChange={(e) => changeEMeter(eMeter.id, 'value', e.target.value)}
              invalid={!!(showMissingFields && !(eMeter.value))}
              onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
            />
          </FormGroup>
          <FormGroup>
            <Typography className='secondary-textcolor'>Aktion</Typography>
            <div className='secondary-textcolor'>
              <Collapsible trigger="[Info öffnen]" triggerWhenOpen="[Info schließen]">
                <>
                  <ul>
                    <li>Ausbau: Wenn Sie einen Zähler ausgebaut haben möchten</li>
                    <li>Keine Aktion: Wenn Sie haben schon einen Zweirichtungszähler haben. !!! Wichtig der Zähler muss wirklich zwischen 1.8.0 und 2.8.0 hin und her schalten!!!!</li>
                    <li>Wechsel: Wenn Sie noch keinen Zweirichtungszähler haben</li>
                  </ul>
                </>
              </Collapsible>
            </div>
            <DropDown
              value={eMeter.action}
              options={eMeterActions}
              onChange={(value) => changeEMeter(eMeter.id, 'action', value)}
              text={'Aktion auswählen'}
            />
            {showMissingFields && eMeter.action === null
              ? <div>
                &nbsp;&nbsp;
                <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
              </div>
              : null}
            <div style={{ float: 'right' }}>
              <CustomIconButton onClick={() => {
                setProject(p => ({ ...p, emeter_set: p.emeter_set.filter(e => e.id !== eMeter.id) }))
              }} icon={'delete'} />
            </div>
          </FormGroup>
        </>
      ))}
      <div><IconButton size="medium" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={addEMeter}><Add style={{ color: '#424242' }} fontSize='large' /><Typography className='secondary-textcolor'>Zähler hinzufügen</Typography></IconButton> <br /></div>
    </Form>
  )
}

PlantProjectForm.propTypes = {
  showMissingFields: PropTypes.bool,
  showAllMissingFields: PropTypes.bool,
  project: projectPropType,
  setProject: PropTypes.func,
  session: PropTypes.object
}
