import React, { useState } from 'react'

import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input, Spinner } from 'reactstrap'

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'

import AddressInput from '../../elements/AddressInput'
import DropDown from '../../elements/DropDown'
import { projectPropType, userPropType } from '../../elements/PropTypes'
import { defaultIfEmpty } from '../../elements/utils'
import { getProjectName, getProjectType, projectTypes } from '../project_types/projectUtils'
import PlanvilleLocationModal from './PlanvilleLocationModal'
import CustomModal from '../shared/modal_utils/CustomModal'

export default function ProjectForm ({ project, setProject, locations, loadLocations, nearestLocation, setNearestLocation, deleteLocation, salesmen, showMissingFields, varyingAddress, setVaryingAddress, session }) {
  const [confirmChangeLocation, setConfirmChangeLocation] = useState(false)
  const [otherLocation, setOtherLocation] = useState(project.id && !project.planville_location)

  const onChange = e => {
    setProject(p => ({ ...p, [e.target.name]: e.target.value }))
  }

  const updateAddress = update => {
    setProject(p => {
      const newProject = { ...p, ...update }
      const newProjectName = getProjectName(newProject)
      if (newProjectName) newProject.name = newProjectName
      return newProject
    })
  }

  const onChangeLocation = value => {
    if (value !== project.planville_location) {
      const zipCodeRegex = /^\d{5}$/
      const potentialZip = project.zip_and_city_project.split(' ')
      setProject(p => ({ ...p, planville_location: value }))
      if (!value) {
        setOtherLocation(true)
      } else if (zipCodeRegex.test(potentialZip[0]) && value !== nearestLocation) {
        setConfirmChangeLocation(true)
        setOtherLocation(false)
      }
    }
  }

  const confirmChangeLocationFooter = (toggle) => {
    return (
      <Stack spacing={2} direction='row'>
        <Button
            variant="contained"
            color={'success'}
            onClick={() => { toggle(); setNearestLocation(project.planville_location) }}>
            Ja
        </Button>
        <Button
            variant="contained"
            color={'error'}
            onClick={() => { toggle() }}>
            Nein
        </Button>
    </Stack>
    )
  }

  if (!project) return null

  return (
    <>
      <CustomModal isOpen={confirmChangeLocation} setIsOpen={setConfirmChangeLocation} getFooter={confirmChangeLocationFooter} title='Standort geändert'>
        <Typography className='secondary-textcolor' pt={2}>Soll dieser Standort bei Projekten mit dieser PLZ immer verwendet werden?</Typography>
      </CustomModal>
      <Form>
        {(project.id)
          ? (
            <FormGroup>
              <Typography className='secondary-textcolor'>Projektnummer:</Typography>
              <div className="input-group">
                <Input
                  style={{ width: '50%' }}
                  type="text"
                  id="input"
                  name="project_id"
                  value={defaultIfEmpty(project.id)}
                  disabled={true}
                />
              </div>
            </FormGroup>)
          : null
        }
        {(project.id)
          ? (
            <FormGroup>
              <Typography className='secondary-textcolor'>Projekttyp:</Typography>
              <div className="input-group">
                <Input
                  style={{ width: '50%' }}
                  type="text"
                  id="input"
                  name="project_id"
                  value={getProjectType(project)}
                  disabled={true}
                />
              </div>
            </FormGroup>)
          : <>
            <Typography className='secondary-textcolor'>Projekttyp:</Typography>
            <DropDown
              onChange={value => {
                setProject(p => {
                  const newProject = { ...p, resourcetype: value }
                  newProject.name = getProjectName(newProject)
                  return newProject
                })
              }}
              options={projectTypes.map(t => ({ label: t.name, value: t.key }))}
              value={project.resourcetype}
              text='Auswählen'
            />
            <br />
          </>
        }
        <Stack direction='row' alignItems='center' spacing={2}>
          <FormGroup>
            <Typography className='secondary-textcolor'>Standort:</Typography>
            <DropDown
              onChange={onChangeLocation}
              options={[...locations.map(location => ({ label: location.name, value: location.id })), { label: 'Sonstiges', value: null }]}
              value={project.planville_location}
            />
          </FormGroup>
          {!project.planville_location && !otherLocation && <Spinner size='sm' />}
        </Stack>
        <PlanvilleLocationModal locations={locations} loadLocations={loadLocations} deleteLocation={deleteLocation} session={session} />
        <FormGroup>
          <Typography className='secondary-textcolor'>Name:</Typography>
          <Input
            type="text"
            id="input"
            name="name"
            onChange={onChange}
            value={defaultIfEmpty(project.name)}
            required={true}
            autoComplete="off"
            invalid={!!(showMissingFields && !(project.name))}
            style={{ boxShadow: 'none' }}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel className='secondary-textcolor' control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} checked={!varyingAddress} onChange={(e) => { setVaryingAddress(!e.target.checked) }} />} label="Projekt- und Kundenanschrift stimmen überein." />
        </FormGroup>
        {varyingAddress
          ? <>
            <Typography className='secondary-textcolor'>
              Projektadresse
            </Typography>
            <AddressInput
              address={{ street_and_number: project.street_and_number_project, zip_and_city: project.zip_and_city_project, province: project.province_project }}
              setAddress={(address) => updateAddress({ street_and_number_project: address.street_and_number, zip_and_city_project: address.zip_and_city, province_project: address.province })}
              setStreetAndNumber={(streetAndNumber) => updateAddress({ street_and_number_project: streetAndNumber })}
              setZipAndCity={(zipAndCity) => updateAddress({ zip_and_city_project: zipAndCity })}
              setProvince={(province) => updateAddress({ province_project: province })}
              showMissingFields={showMissingFields}
              session={session}
            />
          </>
          : null}

        {(salesmen !== null)
          ? (
            <FormGroup>
              <Typography className='secondary-textcolor'>Verkäufer:</Typography>
              <DropDown
                id="salesman_dropdown"
                onChange={(salesman) => setProject(p => ({ ...p, salesman }))}
                options={salesmen.map(user => ({ label: user.first_name + ' ' + user.last_name, value: user.id }))}
                value={project.salesman}
                text='Verkäufer wählen'
                sort={true}
              />
              {showMissingFields && !(project.salesman)
                ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
                : null}
            </FormGroup>)
          : null
        }

        <FormGroup>
          <Typography className='secondary-textcolor'>Notiz:</Typography>
          <Input
            id="input"
            type="textarea"
            name="note"
            onChange={onChange}
            value={defaultIfEmpty(project.note)}
            required={false}
            autoComplete="off"
            maxLength={50}
            style={{ boxShadow: 'none' }}
          />
        </FormGroup>
      </Form>
    </>
  )
}

ProjectForm.propTypes = {
  project: projectPropType,
  setProject: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.object),
  loadLocations: PropTypes.func,
  nearestLocation: PropTypes.number,
  setNearestLocation: PropTypes.func,
  deleteLocation: PropTypes.func,
  salesmen: PropTypes.arrayOf(userPropType),
  showMissingFields: PropTypes.bool,
  varyingAddress: PropTypes.bool,
  setVaryingAddress: PropTypes.func,
  session: PropTypes.object
}
