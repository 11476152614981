import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import { getCarFormTemplate } from './CarForm'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import CustomModal from '../shared/modal_utils/CustomModal'
import CustomForm, { getEmptyFieldsErrorFromTemplate } from '../../elements/CustomForm'

import { API_URL_VEHICLE } from '../../settings'
import axios from 'axios'
import { carPropType } from '../../elements/PropTypes'

const emptyCarForm = {
  id: null,
  brand_model: null,
  vin: null,
  plate: 'ERK-',
  color: '#0000ff'
}

export default function CarFormModal ({ car, isOpen, setIsOpen, resetParent, session }) {
  const [carForm, setCarForm] = useState({ ...emptyCarForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const clearData = () => {
    setCarForm(emptyCarForm)
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
  }

  const loadData = () => {
    setCarForm(car || { ...emptyCarForm })
  }

  const submit = async (carForm) => {
    return car ? axios.put(API_URL_VEHICLE + car.id, carForm) : axios.post(API_URL_VEHICLE, carForm)
  }

  const onSubmit = async (onSuccess) => {
    const emptyFieldsError = getEmptyFieldsErrorFromTemplate(template, carForm, emptyCarForm)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
      console.error(emptyFieldsError)
      return
    }

    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })

    return submit(carForm).then((res) => {
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "car:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_car_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />
    )
  }

  const template = getCarFormTemplate()

  return (
    <CustomModal title={(car && car.plate) ? `Auto ${car.plate}` : 'Auto registrieren'} getFooter={getFooter} onToggle={onToggle} isOpen={isOpen} setIsOpen={setIsOpen}>
      <CustomForm
        template={template}
        form={carForm}
        setForm={setCarForm}
        defaultForm={emptyCarForm}
        showMissingFields={loadingElements.showMissingFields}
        session={session}
      />
    </CustomModal>
  )
}

CarFormModal.propTypes = {
  car: carPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
