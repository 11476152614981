import React from 'react'
import { PropTypes } from 'prop-types'

import axios from 'axios'

import { IconButton, Tooltip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import { getAcceptedOfferEmailSubject, getPostAcceptedOfferRequest } from '../project_types/projectUtils'
import { API_URL_ACCEPTOFFER } from '../../settings'
import EmailModal from '../shared/modal_utils/EmailModal'
import { offerPropType, projectPropType } from '../../elements/PropTypes'

export default function AcceptOfferModal ({ project, offer, isOpen, setIsOpen = null, getOpenButton, resetParent, session }) {
  const postRequest = (res) => {
    getPostAcceptedOfferRequest(project)
    return res
  }

  const submit = async (subject, message) => axios.post(API_URL_ACCEPTOFFER, { offer: offer.id, mail: { subject, message } }).then(postRequest)

  const skip = async () => axios.post(API_URL_ACCEPTOFFER, { offer: offer.id }).then(postRequest)

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <Tooltip title="Annehmen" PopperProps={{ style: { zIndex: 9999 } }} ><IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={toggle}><CheckIcon style={{ color: '#d4bc0b' }} fontSize='large' /></IconButton></Tooltip>
  }

  const title = `Angenommenes Angebot ${offer.id}`

  return (
        <EmailModal
            customer={project.customer_obj}
            title={title}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            subject={getAcceptedOfferEmailSubject(project, session)}
            getEmailMessage={() => axios.get(API_URL_ACCEPTOFFER, { params: { offer: offer.id } })}
            submit={(subject, message) => submit(subject, message)}
            skip={skip}
            resetParent={resetParent}
            getModalOpenButton={_getOpenButton}
            session={session}
        />
  )
}

AcceptOfferModal.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
