import React, { Fragment, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import {
  API_URL_CONSTRUCTION, API_URL_CUSTOMPRODUCT, API_URL_PRODUCT, API_URL_HEATPUMP, API_URL_USER, API_URL_PRODUCTGROUP
} from '../../../../settings'
import { constructionPropType, projectPropType } from '../../../../elements/PropTypes'
import { CustomButton } from '../../../../elements/StyledElements'
import { date2String, getCustomerName, getEmptyFieldsError, filterObj, hasPermission } from '../../../../elements/utils'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import { getHeatpumpConstructionFormTemplate } from './HeatpumpConstructionForm'
import CustomForm, { getOptionalKeysFromTemplate } from '../../../../elements/CustomForm'
import LoadingPage from '../../../../elements/LoadingPage'
import { toast } from 'react-toastify'
import ErrorMessage from '../../../../elements/ErrorMessage'

const emptyConstructionForm = {
  project: null,
  planning: null,
  heatpump: null,
  constructiondates_set: null,
  completion_date: null,
  heating_installer: null,
  resourcetype: 'HeatpumpConstruction',
  customproduct_set: []
}

// const fixedProductKeys = ['anlage', 'pv_module', 'anlage', 'elektroinstallation', 'batterie', 'batterie_kwh']

export default function HeatpumpConstructionFormModal ({ project, construction, chosenDate, disable, resetParent, session, isOpen, setIsOpen, onClose }) {
  const [constructionForm, setConstructionForm] = useState({ ...emptyConstructionForm })
  const [amountCols, setAmountCols] = useState({ plannedAmountCol: false, realAmountCol: false, offerAmountCol: false, invoiceAmountCol: false })
  const [products, setProducts] = useState([])
  const [productGroups, setProductGroups] = useState([])
  const [heatpumps, setHeatpumps] = useState(null)
  const [heatingInstallers, setHeatingInstallers] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const acceptedPlanning = project ? project.planning_set.find(p => p.id === project.accepted_offer_obj.planning) : null

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const clearData = () => {
    setConstructionForm({ ...emptyConstructionForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setProducts([])
    setHeatpumps(null)
    setHeatingInstallers(null)
  }

  /* FILL ############################################################## */
  const loadData = () => {
    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true, include: construction && construction.heating_installer ? [construction.heating_installer].map(x => x.toString()).join() : null } })
      .then(res => setHeatingInstallers(res.data.filter(user => ['heating_installers', 'heating_installer_construction_manager'].includes(user.group_key))))
    Promise.all([
      axios.get(API_URL_HEATPUMP),
      resetProducts()
    ]).then(async (res) => {
      const heatpumps = res[0].data.filter(heatpump => heatpump.available || heatpump.id === (acceptedPlanning ? acceptedPlanning.heatpump : null) || heatpump.id === (construction ? construction.heatpump : null))
      setHeatpumps(heatpumps)
      const [products, amountCols] = res[1]
      // fill Construction
      let filledConstruction = { ...emptyConstructionForm, planning: acceptedPlanning.id, project: project.id }
      const constructionDate = chosenDate ? date2String(chosenDate) : null
      const constructionDateObj = chosenDate ? [{ date: date2String(chosenDate), construction: construction ? construction.id : null }] : null

      if (construction) {
        filledConstruction = {
          ...filledConstruction,
          ...construction
        }
        if (chosenDate) {
          filledConstruction = {
            ...filledConstruction,
            constructiondates_set: constructionDateObj,
            completion_date: constructionDate
          }
        }
        setConstructionForm(filledConstruction)
      } else {
        axios.get(API_URL_CUSTOMPRODUCT, { params: { offer: project.accepted_offer } })
          .then(async (res) => {
            const customProducts = res.data
            filledConstruction = {
              ...filledConstruction,
              heatpump: acceptedPlanning.heatpump,
              constructiondates_set: constructionDateObj,
              completion_date: constructionDate,
              customproduct_set: products
                .filter(p => showProduct(p, amountCols))
                .map(product => {
                  const customProduct = customProducts.find(cp => cp.product === product.id)
                  return {
                    id: null,
                    name: customProduct.name ?? product.name,
                    description: customProduct.description ?? product.description,
                    product: product.id,
                    price: product.price,
                    amount: getAmount(product, amountCols),
                    order: customProducts.find(cp => cp.product === product.id).order
                  }
                })
            }
            setConstructionForm(filledConstruction)
          })
        // filledConstruction.roofimage_set = acceptedPlanning.roofimage_set.map(roofImage => ({
        //   ...roofImage,
        //   id: -getRandomId(),
        //   string_set: roofImage.string_set.map(string => ({ ...string, id: -getRandomId() })),
        //   roofimagepanel_set: roofImage.roofimagepanel_set.map(panel => ({ ...panel, id: -getRandomId() }))
        // })
        // )
      }
      // setConstructionForm(filledConstruction)
    })
  }

  const resetProducts = async () => {
    const requestOfferId = project.accepted_offer_obj.id
    const requestPlanningId = project.accepted_offer_obj.planning_obj.id
    return axios.get(API_URL_PRODUCT, { params: { project: project.id, offer: requestOfferId, planning: requestPlanningId } })
      .then((res) => {
        const products = res.data
        setProducts(products)
        const amountCols = {
          plannedAmountCol: res.headers.planned_amounts === 'True',
          realAmountCol: res.headers.real_amounts === 'True',
          offerAmountCol: res.headers.offer_amounts === 'True',
          invoiceAmountCol: res.headers.invoice_amounts === 'True'
        }
        setAmountCols(amountCols)
        return axios.get(API_URL_PRODUCTGROUP)
          .then((res) => {
            const productGroups = res.data
            setProductGroups(productGroups)
            return [products, amountCols, productGroups]
          })
      })
  }

  const getAmount = (product, amountCols) => {
    const amount = amountCols.offerAmountCol
      ? product.amount_offer
      : 0
    return Math.max(amount, 0)
  }

  const showProduct = (product, amountCols) =>
    (amountCols.plannedAmountCol && product.amount_planned !== 0) ||
    (amountCols.invoiceAmountCol && product.amount_invoices !== 0) ||
    (amountCols.realAmountCol && product.amount_real !== 0) ||
    (amountCols.offerAmountCol && product.amount_offer !== 0)

  /* MODAL ############################################################## */

  const getOpenButton = (toggle) => {
    if (isOpen === null || isOpen === undefined) { return <CustomButton disabled={disable} onClick={toggle} icon="construction">Baustelle</CustomButton> }
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => { onSubmit(toggle) }}
        saveDisabled={!(hasPermission(session.user, 'customer_handling') || hasPermission(session.user, 'page_constructions')) || (construction && construction.documentation_completed && !hasPermission(session.user, 'finish_construction_documentation'))}
      />
    )
  }

  /* UPLOADING ############################################################## */

  // const getImagePromises = async (constructionId, images, url, getPromise) => {
  //   const deletePromise = (construction)
  //     ? axios.delete(url, { data: { construction: constructionId, except_ids: images.filter(i => !isNewObj(i)).map(i => i.id) } })
  //     : Promise.resolve()
  //   return deletePromise
  //     .then(() => Promise.all(images.map(image => getPromise(constructionId, image, url))))
  // }

  // const isNewObj = (obj) => obj.id <= 0

  const submit = async (form) => {
    const promise = construction
      ? axios.put(API_URL_CONSTRUCTION + construction.id, form)
      : axios.post(API_URL_CONSTRUCTION, form)
    return promise
      .then(async res => {
        const constructionResponse = res
        const constructionId = constructionResponse.data.id
        const promises = []
        promises.push(getProductPromise(constructionId, form.customproduct_set))
        return Promise.all(promises)
          .then(() => constructionResponse)
      })
  }

  const getProductPromise = async (constructionId, customProducts) => {
    const customProductPromise = construction
      ? axios.delete(API_URL_CUSTOMPRODUCT, { data: { construction: constructionId, except_ids: customProducts.filter(x => x.amount > 0).filter(x => x.id).map(x => x.id) } })
      : Promise.resolve()
    return customProductPromise
      .then(async () => Promise.all(
        customProducts
          .filter(x => x.amount > 0)
          .map(customProduct => {
            customProduct.construction = constructionId
            if (customProduct.id) { return axios.put(API_URL_CUSTOMPRODUCT + customProduct.id, customProduct) }
            return axios.post(API_URL_CUSTOMPRODUCT, customProduct)
          })
      ))
  }

  const onSubmit = async (onSuccess) => {
    const optionalKeys = getOptionalKeysFromTemplate(formTemplate, constructionForm)
    const emptyFieldsError = getEmptyFieldsError(constructionForm, emptyConstructionForm, optionalKeys)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false })
      console.error(emptyFieldsError)
      toast.error(<ErrorMessage message={'Bitte alle Felder ausfüllen!'} />)
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(filterObj(constructionForm, Object.keys(emptyConstructionForm))).then((res) => {
      if (resetParent) resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => {
      console.error('Error in ConstructionFormModal:onSubmit', error, error.stack)
      toast.error(<ErrorMessage message={'Anfrage konnte nicht an Server übermittelt werden!'} />)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    })
  }

  const formTemplate = getHeatpumpConstructionFormTemplate(project, heatingInstallers, heatpumps, products, productGroups, resetProducts, amountCols, session)
  const title = (project) ? 'Bauplanung von ' + getCustomerName(project.customer_obj) : 'Bauplanung'
  return (<CustomModal size='fullscreen' isOpen={isOpen} setIsOpen={setIsOpen} getOpenButton={getOpenButton} title={title} getFooter={getFooter} onToggle={onToggle} onClose={onClose}>
    {(heatpumps === null || constructionForm === null)
      ? <LoadingPage/>
      : <Fragment>
        <CustomForm
          title={'Bauplanung'}
          template={formTemplate}
          form={constructionForm}
          setForm={setConstructionForm}
          defaultForm={emptyConstructionForm}
          showMissingFields={loadingElements.showMissingFields}
        />
      </Fragment>
    }
  </CustomModal>)
}

HeatpumpConstructionFormModal.propTypes = {
  project: projectPropType,
  construction: constructionPropType,
  chosenDate: PropTypes.instanceOf(Date),
  disable: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func
}
