import React from 'react'
import { PropTypes } from 'prop-types'
import DatePicker from 'react-datepicker'
import './DateInputStyle.css'
import moment from 'moment'

export default function DateInput ({ value, onChange, disableFuture = false, placeholder }) {
  return (
    <DatePicker
      maxDate={disableFuture ? moment().toDate() : null}
      locale="de"
      showIcon
      showYearDropdown
      dateFormat="dd.MM.yyyy"
      selected={value}
      onChange={(date) => onChange(date || null)}
      placeholderText={placeholder}
    />
  )
}

DateInput.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  disableFuture: PropTypes.bool,
  placeholder: PropTypes.string
}
