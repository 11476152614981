import React from 'react'

import { PropTypes } from 'prop-types'

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import { IconButton, Typography, Tooltip } from '@mui/material'

import PDFView from '../../../elements/PDFViewer'
import { isString } from '../../../elements/utils'
import { HOST_PORT, PROTOCOL } from '../../../settings'
import CustomModal from './CustomModal'

// class PdfViewModal extends Component {
export default function PdfViewModal ({ title, disable, filepath, getOpenButton, iconSize = 'large', isOpen, setIsOpen }) {
  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return (
      <Tooltip title='Ansehen' PopperProps={{ style: { zIndex: 9999 } }}>
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} disabled={disable} size="small" onClick={toggle}><RemoveRedEyeOutlinedIcon className='secondary-textcolor' fontSize={iconSize} /></IconButton>
      </Tooltip>
    )
  }

  filepath = isString(filepath) ? filepath.replace(`http://${HOST_PORT}`, `${PROTOCOL}://${HOST_PORT}`) : (!filepath ? filepath : URL.createObjectURL(filepath))

  return (<CustomModal getOpenButton={_getOpenButton} size='xl' title={title} isOpen={isOpen} setIsOpen={setIsOpen}>
    { (filepath) ? <PDFView filepath={filepath} /> : <Typography fontSize='h5.fontSize' className='secondary-textcolor'>Keine Datei verfügbar.</Typography> }
  </CustomModal>)
}

PdfViewModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  disable: PropTypes.bool,
  filepath: PropTypes.string,
  getOpenButton: PropTypes.func,
  iconSize: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}
