import React from 'react'
import { PropTypes } from 'prop-types'
import { Spinner, Fade } from 'reactstrap'

import Button from '@mui/material/Button'

import { CustomButton } from '../../../elements/StyledElements'
import { isString } from '../../../elements/utils'

export default function ConfirmationModalFooter ({ inProgress, submitError, onConfirm, onCancel, btnLabel, color }) {
  return (
        <div>
            {submitError
              ? <Fade in={!!submitError} tag="h5" className="mt-3" style={{ color: 'red' }}>
                    {isString(submitError) ? submitError : 'Anfrage konnte nicht an Server übermittelt werden!'}
                </Fade>
              : null
            }
            <Button
                variant="contained"
                color={color || (btnLabel ? 'success' : 'error')}
                onClick={onConfirm}
                disabled={inProgress}>
                {btnLabel || 'Entfernen'}
            </Button>
            &nbsp;
            {inProgress ? <Spinner color="danger" size="sm" /> : null}
            &nbsp;
            <CustomButton
                color={'#ffffff'}
                onClick={onCancel}
                disabled={inProgress}>
                Abbrechen
            </CustomButton>
        </div>
  )
}

ConfirmationModalFooter.propTypes = {
  inProgress: PropTypes.bool,
  submitError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  btnLabel: PropTypes.string,
  color: PropTypes.string
}
