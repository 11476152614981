import React from 'react'

import { PropTypes } from 'prop-types'
import { Col, Container, Row } from 'reactstrap'

import { Typography } from '@mui/material'

import { projectPropType } from '../../elements/PropTypes'
import { CustomButton } from '../../elements/StyledElements'
import { getCustomerName, hasPermission } from '../../elements/utils'
import NewOfferModal from '../project_types/NewOfferModal'
import CustomModal from '../shared/modal_utils/CustomModal'
import OfferList from './OfferList'

export default function OfferModal ({ project, disable, getOpenButton, resetParent, session, additionalDetails, planning, setPlanning }) {
  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton disabled={disable} onClick={toggle} icon="handshake">Angebot</CustomButton>
  }

  return (
    <CustomModal size="fullscreen" getOpenButton={_getOpenButton} title={'Angebot für ' + getCustomerName(project.customer_obj)}>
      <Container >
        <Row>
          <Col>
            <Typography fontSize='h5.fontSize' className='secondary-textcolor'>Erstellte Angebote</Typography>
            <OfferList
              project={project}
              resetParent={resetParent}
              session={session}
              additionalDetails={additionalDetails}
              setPlanning={setPlanning}
            />
            <br />
            {hasPermission(session.user, 'customer_handling')
              ? <NewOfferModal
                project={project}
                session={session}
                resetParent={resetParent}
                additionalDetails={additionalDetails}
                planning={planning}
                setPlanning={setPlanning}
              />
              : null}
            <br />
          </Col>
        </Row>
      </Container >
    </CustomModal>
  )
}

OfferModal.propTypes = {
  project: projectPropType,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  additionalDetails: PropTypes.object,
  planning: PropTypes.object,
  setPlanning: PropTypes.func
}
