import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import Collapse from '@mui/material/Collapse'

import Icon from '../elements/Icon'
import { getNavigation } from '../navigation'
import { Grid } from '@mui/material'

const drawerWidth = 220

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    position: 'fixed',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

export default function MiniDrawer ({ pageName, session }) {
  const theme = useTheme()
  const history = useHistory()
  const navigation = getNavigation(session)

  const [open, setOpen] = useState(false)
  const [openCollapse, setOpenCollapse] = useState(null)

  const routeChangePassword = () => {
    const path = '/benutzer/'
    history.push(path)
    handleDrawerClose()
  }

  const routeHome = () => {
    const path = '/'
    history.push(path)
    handleDrawerClose()
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  function refreshLogout () {
    routeHome()
    // history.go(0)
    session.logout()
  }

  const route = (item) => {
    history.push(`/${item.url}/`)
    handleDrawerClose()
  }

  const getListItem = (item, level) => {
    return (item.url || item.button) ? getChildItem(item, level) : getParentItem(item, level)
  }

  const getChildItem = (item, level) => {
    if (item.button) {
      const passedOpenButton = (toggle) => (
        <ListItemButton onClick={toggle}
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5 + level * 2
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : 'auto',
              justifyContent: 'center'
            }}
          >
            <Icon icon={item.icon} className='icon-color-topbar' />
          </ListItemIcon>
          <ListItemText className='primary-textcolor' primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      )

      return item.button.getElmt({
        session,
        passedOpenButton,
        onClose: () => {
          handleDrawerClose()
          setOpenCollapse(null)
        }
      })
    }

    return <ListItem key={`child-item-${item.key}`} disablePadding sx={{ display: 'block' }}>
      <ListItemButton onClick={() => route(item)}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5 + level * 2
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center'
          }}
        >
          <Icon icon={item.icon} className='icon-color-topbar' />
        </ListItemIcon>
        <ListItemText className='primary-textcolor' primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  }

  const getParentItem = (item, level) => {
    return <div key={`parent-item-${item.key}`} onClick={() => setOpenCollapse(item.key)} onMouseOver={() => setOpenCollapse(item.key)} onMouseLeave={() => setOpenCollapse(null)}>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5 + level * 2
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : 'auto',
              justifyContent: 'center'
            }}
          >
            <Icon icon={item.icon} className='icon-color-topbar' />
          </ListItemIcon>
          <ListItemText className='primary-textcolor' primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
      <Collapse in={openCollapse === item.key} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map(child => getListItem(child, level + 1))}
        </List>
      </Collapse>
    </div>
  }

  return (
    <Box zIndex={5000} position="sticky" sx={{ display: 'flex', background: '#424242' }}>
      {isMobileOnly
        ? <AppBar component="nav" position="sticky" open={open} style={{ background: '#424242' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              alignItems="left"
              sx={{
                ...(open && { display: 'none' })
              }}
            >
              {/* <
                src={`/${process.env.REACT_APP_COMPANY}/logo_icon.png`}
                alt="Logo"
                className="img-thumbnail"
                style={{ border: 'none', backgroundColor: 'transparent', maxHeight: '50px' }}
              /> */}
              <Icon icon="menu" fontSize='large' />
            </IconButton>
            <Typography
              fontSize='h6.fontSize'
              sx={{ my: 2 }}
              className='primary-textcolor' style={{ flex: 1 }} // for align the following <div> to right
            >
              {pageName || `${session.companyConfig.name} App`}
            </Typography>
            {!open
              ? <div>
                {session.isAuthenticated ? <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={routeHome}> <HomeIcon className='icon-color-topbar' /> </IconButton> : null}
                {session.isAuthenticated ? <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={routeChangePassword}> <PersonIcon className='icon-color-topbar' /> </IconButton> : null}
                {session.isAuthenticated ? <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={() => { session.logout(); routeHome() }}> <LogoutIcon className='icon-color-topbar' /> </IconButton> : null}
              </div>
              : null}
          </Toolbar>
        </AppBar>
        : null}
      {!isMobileOnly || open
        ? <Drawer variant="permanent" open={open} onMouseOver={handleDrawerOpen} onMouseLeave={handleDrawerClose}
          sx={{
            '& .MuiDrawer-paper': {
              background: '#000000',
              backgroundImage: 'url("/drawer_background.jpg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }

          }}>
          {isMobileOnly
            ? <DrawerHeader>
              <Grid container paddingTop={'10px'} alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                <img
                  src={`/${process.env.REACT_APP_COMPANY}/logo_icon.png`}
                  alt="Logo"
                  className="img-thumbnail"
                  style={{ border: 'none', backgroundColor: 'transparent', maxHeight: '50px' }}
                />
              </Grid>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon className='primary-textcolor' /> : <ChevronLeftIcon className='primary-textcolor' />}
              </IconButton>
            </DrawerHeader>
            : <DrawerHeader>
              {open
                ? <Grid container alignContent={'center'} alignItems={'center'} justifyContent={'center'}> {/* open not mobile */}
                  <img
                    src={`/${process.env.REACT_APP_COMPANY}/logo_icon.png`}
                    alt="Logo"
                    className="img-thumbnail"
                    style={{ marginTop: '20px', border: 'none', backgroundColor: 'transparent', maxHeight: '50px' }}
                  />
                </Grid>
                : null}
              {!open
                ? <> {/* closed not mobile */}
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <img
                        src={`/${process.env.REACT_APP_COMPANY}/logo_icon.png`}
                        alt="Logo"
                        className="img-thumbnail"
                        style={{ marginTop: '20px', border: 'none', backgroundColor: 'transparent', maxHeight: '50px' }}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </>
                : null}
            </DrawerHeader>
          }
          <Divider />
          <List>
            {navigation.map(navItem => getListItem(navItem, 0))}
          </List>

          <List style={{ marginTop: 'auto' }}>
          {/* <List>
            <ListItem style={{ flexGrow: 1 }} />
            <ListItem style={{ flexGrow: 1 }} />
            <ListItem style={{ flexGrow: 1 }} />
            <ListItem style={{ flexGrow: 1 }} />
            <ListItem style={{ flexGrow: 1 }} />
            <ListItem style={{ flexGrow: 1 }} />
            <ListItem style={{ flexGrow: 1 }} />
            <ListItem style={{ flexGrow: 1 }} /> */}

            {session.isAuthenticated
              ? <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton onClick={routeHome}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <HomeIcon className='icon-color-topbar' />
                    </ListItemIcon>
                    <ListItemText className='primary-textcolor' primary={'Home'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
              </ListItem>
              : null}

            {session.isAuthenticated
              ? <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton onClick={routeChangePassword}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <PersonIcon className='icon-color-topbar' />
                    </ListItemIcon>
                    <ListItemText className='primary-textcolor' primary={'Benutzer'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
              </ListItem>
              : null
            }

            {/* <ListItem style={{ flexGrow: 1 }} /> */}

            {
              session.isAuthenticated
                ? <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton onClick={() => { handleDrawerClose(); refreshLogout() }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <LogoutIcon className='icon-color-topbar' />
                    </ListItemIcon>
                    <ListItemText className='primary-textcolor' primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                : null
            }
          </List >
        </Drawer >
        : null
      }
    </Box >
  )
}

MiniDrawer.propTypes = {
  pageName: PropTypes.string,
  session: PropTypes.object
}
