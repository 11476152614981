import React from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'
import Stack from '@mui/material/Stack'
import { Tooltip } from '@mui/material'
import { invoicePropType, projectPropType } from '../../elements/PropTypes'
import { CustomIconButton } from '../../elements/StyledElements'
import { getCustomerName, hasPermission, isInvoiceCompletelyPaid, isInvoiceOverpaid } from '../../elements/utils'
import { API_URL_CANCEL_INVOICE, API_URL_INVOICE, PROTOCOL } from '../../settings'
import ConfirmationModal from '../shared/modal_utils/ConfirmationModal'
import PdfViewModal from '../shared/modal_utils/PdfViewModal'
import InvoiceFormModal from './InvoiceFormModal'
import PaidInvoiceModal from './PaidInvoiceModal'
import SendInvoiceModal from './SendInvoiceModal'
import DiscountInvoiceModal from './DiscountInvoiceModal'

export default function InvoiceActions ({ project, invoice, resetParent, allowDeletion, session }) {
  if (!project || !invoice) return null

  const acceptedOfferId = project.accepted_offer ? project.accepted_offer : null
  const constructionId = project.construction ? project.construction : null
  // const [skonto, setSkonto] = useState(null)
  // const [customSkontoId, setCustomSkontoId] = useState(null)
  // const [skontoPrice, setSkontoPrice] = useState(null)
  // const [customSkonto, setCustomSkonto] = useState(null)
  // useEffect(() => {
  //   const fetchDataSkonto = async () => {
  //     const responseSkonto = await axios.get(API_URL_PRODUCT, { params: { project: project.id, key: 'skonto' } })
  //     if (responseSkonto.data.length > 0) setSkonto(responseSkonto.data[0])
  //     const response = await axios.get(API_URL_CUSTOMPRODUCT, { params: { invoice: invoice.id } })
  //     if (response.data.length > 0) {
  //       response.data.forEach((customProduct) => {
  //         if (customProduct.product === responseSkonto.data[0].id) {
  //           setSkontoPrice(customProduct.price)
  //           setCustomSkontoId(customProduct.id)
  //           setCustomSkonto(customProduct)
  //         }
  //       })
  //     }
  //     return responseSkonto
  //   }
  //   fetchDataSkonto()
  // }, [resetParent])

  return (
    <div>
      <Stack direction="row" justifyContent="end">
        <PdfViewModal
          title={'Rechnung für ' + getCustomerName(project.customer_obj)}
          filepath={(invoice.pdf) ? invoice.pdf.replace('http:', PROTOCOL + ':') : null} />
        {hasPermission(session.user, 'customer_handling')
          ? <>
            &nbsp;
            <InvoiceFormModal
              invoice={invoice}
              productRequestParams={{
                offer: acceptedOfferId,
                construction: constructionId
              }}
              project={project}
              resetParent={resetParent}
              session={session}
              disabled={invoice.sent_to_tax_advisor || !!invoice.cancellation_invoice || invoice.cancellation || !!invoice.final_invoice_obj}
            />

            {!invoice.cancellation && !invoice.cancellation_invoice && invoice.final && (invoice.paid || invoice.sent) && (!isInvoiceOverpaid(invoice)) && (!isInvoiceCompletelyPaid(invoice))
              ? <DiscountInvoiceModal
                project={project}
                invoice={invoice}
                resetParent={resetParent}
                session={session}
              />
              : null}

            {(allowDeletion && !invoice.cancellation && (!invoice.cancellation_invoice) && (!invoice.sent_to_tax_advisor) && (hasPermission(session.user, 'invoices_delete')))
              ? (
                <ConfirmationModal title={'Soll die Rechnung (inklusive Rechnungsnummer) wirklich gelöscht werden?'}
                  confirm={() => axios.delete(API_URL_INVOICE + invoice.id)}
                  getOpenButton={(toggle) => <CustomIconButton icon='delete' onClick={toggle} size='large' description='Löschen' title='Löschen'/>}
                  resetParent={resetParent} />)
              : null}

            {(allowDeletion && (invoice.sent) && !invoice.cancellation && (!invoice.cancellation_invoice) && (!isInvoiceOverpaid(invoice)) && (hasPermission(session.user, 'invoices_cancel')))
              ? (
                <ConfirmationModal title={'Soll die Rechnung wirklich storniert werden?'}
                  confirm={() => axios.post(API_URL_CANCEL_INVOICE, { invoice: invoice.id })}
                  getOpenButton={(toggle) => <CustomIconButton icon='reverse' onClick={toggle} size='large' description='Stornieren' title='Stornieren'/>}
                  btnLabel={'Stornieren'}
                  resetParent={resetParent} />)
              : null}

            {invoice.sent && !invoice.cancellation_invoice
              ? <PaidInvoiceModal
                project={project}
                invoice={invoice}
                resetParent={resetParent}
                session={session}
              />
              : null}

              {/* {!invoice.cancellation && !invoice.cancellation_invoice && invoice.final && customSkonto
                ? <SkontoInvoiceModal
              project={project}
              invoice={invoice}
              resetParent={resetParent}
              session={session}
              price={skontoPrice}
              productId={skonto.id}
              customProductId={customSkontoId}
            />
                : null} */}

            {(!invoice.sent)
              ? <SendInvoiceModal
                project={project}
                invoice={invoice}
                resetParent={resetParent}
                session={session}
              />
              : null}

            {invoice.sent
              ? <Tooltip title='Gesendet' PopperProps={{ style: { zIndex: 9999 } }} >
              <MarkEmailReadOutlinedIcon color="success" style={{ float: 'right' }} fontSize='large'/>
              </Tooltip>
              : null}
          </>
          : null}
      </Stack>
    </div>
  )
}

InvoiceActions.propTypes = {
  project: projectPropType,
  invoice: invoicePropType,
  resetParent: PropTypes.func,
  allowDeletion: PropTypes.bool,
  session: PropTypes.object
}
