import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { BiCategoryAlt } from 'react-icons/bi'
import { IconButton, Tooltip } from '@mui/material'

import ProductForm from './ProductForm'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { API_URL_PRODUCT } from '../../settings'
import { CustomButton } from '../../elements/StyledElements'
import { productPropType, customerPropType } from '../../elements/PropTypes'

const emptyProductForm = {
  id: null,
  priority: null,
  key: null,
  name: null,
  price: null,
  description: null,
  quantity_description: 'Stk.',
  in_pv_simulation: null,
  vat: null,
  customer: null,
  product_group: []
}

export default function ProductFormModal ({ product, customer, resetParent, session, style, productGroup, groupIcon }) {
  const [productForm, setProductForm] = useState({ ...emptyProductForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const getOpenButton = (toggle) => {
    if (groupIcon) {
      return (
      <Tooltip title='Produktgruppe' PopperProps={{ style: { zIndex: 9999 } }} >
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="large" onClick={toggle} sx={{ p: '5px' }}><BiCategoryAlt className='secondary-textcolor' fontSize='34px' /></IconButton>
      </Tooltip>
      )
    }
    if (product) {
      return (
      <Tooltip title='Produktinformationen' PopperProps={{ style: { zIndex: 9999 } }} >
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={toggle}><InfoOutlinedIcon className='secondary-textcolor' fontSize='large' /></IconButton>
      </Tooltip>
      )
    }
    return <CustomButton onClick={toggle} style={style}> Neues Produkt erstellen </CustomButton>
  }

  const submit = async (productForm) => {
    if (product) { return axios.put(API_URL_PRODUCT + product.id, productForm) }
    return axios.post(API_URL_PRODUCT, productForm)
  }

  const onSubmit = async (onSuccess) => {
    const optionalKeys = ['key', 'customer', 'product_group', 'priority']
    if (productForm.priority === '') productForm.priority = null
    if (!product) optionalKeys.push('id')
    const productKeys = Object.keys(productForm).filter(x => !optionalKeys.includes(x))
    const emptyFields = productKeys.filter(key => (productForm[key] === '' || productForm[key] === null))
    if (emptyFields.length !== 0) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', showMissingFields: true })
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(productForm).then((res) => {
      const resetSearch = groupIcon || product ? false : res.data
      resetParent(resetSearch)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    })
      .catch(error => {
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
        console.error(error)
      })
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                submitError={loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={function () { onSubmit(toggle) }}
            />)
  }

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
    else loadData()
  }

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setProductForm({ ...emptyProductForm })
  }

  const loadData = () => {
    setProductForm(product || { ...emptyProductForm })
  }

  return (<CustomModal getOpenButton={getOpenButton} title="Produktinformationen" getFooter={getFooter} onToggle={onToggle}>
        <ProductForm
            product={productForm}
            session={session}
            showMissingFields={loadingElements.showMissingFields}
            setProduct={setProductForm}
            customer={customer}
            productGroup={productGroup}
            groupIcon={groupIcon}
        />
    </CustomModal>)
}

ProductFormModal.propTypes = {
  product: productPropType,
  customer: customerPropType,
  resetParent: PropTypes.func,
  groupIcon: PropTypes.bool,
  session: PropTypes.object,
  style: PropTypes.object,
  productGroup: PropTypes.array
}
