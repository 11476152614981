import React from 'react'

import { PropTypes } from 'prop-types'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'

import { arrSum, arrange } from './utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
)

export default function CustomPlot ({ height, title, datasets, additionalData, ticks, xlabel, ylabel, type, stacked = true, zoom = true }) {
  const nValues = datasets.length > 0 ? datasets[0].data.length : 0
  const values = stacked && datasets.length > 1
    ? arrange(nValues).map(idx => arrSum(datasets.map(d => d.data[idx])))
    : datasets.map(d => d.data).flat().filter(x => !isNaN(x))
  const minY = Math.min(0, Math.min.apply(Math, values))
  const maxY = Math.max.apply(Math, values)
  const minX = 0
  const maxX = Math.max(datasets.map(d => d.data.length))

  const isMobile = () => {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  }

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: title,
        color: 'black',
        font: {
          size: 18
        }
      },
      legend: {
        display: true,
        labels: {
          color: 'black',
          font: {
            size: 16
          }
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        footerFont: {
          weight: 'normal'
        },
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ''
            if (label) {
              label += ': '
            }
            if (context.parsed.y !== null) {
              if (context.dataset.label === 'Umsatz') label += context.parsed.y.toFixed(2)
              else label += context.parsed.y
            }
            return label
          },
          footer: function (context) {
            if (additionalData) {
              const additionalLabel = additionalData[0].label || ''
              const additionalInfo = additionalData[0].data[context[0].dataIndex]
              return `${additionalLabel}: ${additionalInfo}`
            }
          }
        }
      }
    },
    scales: {
      y: {
        stacked,
        ticks: {
          color: 'black',
          font: {
            size: 16
          }
        },
        grid: {
          color: '#424242'
        },
        title: {
          display: true,
          text: ylabel,
          color: 'black',
          font: {
            size: 16
          }
        }
      },
      x: {
        stacked,
        ticks: {
          color: 'black',
          font: {
            size: 16
          }
        },
        grid: {
          color: '#424242'
        },
        title: {
          display: true,
          text: xlabel,
          color: 'black',
          font: {
            size: 16
          }
        }
      }
    }
  }

  options.plugins.zoom = {
    limits: {
      x: { min: minX, max: maxX },
      y: { min: minY, max: maxY + maxY * 0.1 }
    },
    zoom: {
      wheel: {
        enabled: !isMobile() && zoom
      },
      pinch: {
        enabled: !isMobile() && zoom
      },
      drag: {
        enabled: !isMobile() && zoom
      },
      mode: 'xy',
      scaleMode: 'xy'
    }
  }

  const data = {
    labels: ticks,
    datasets
  }

  return (type === 'bar') ? <Bar options={options} data={data} height={height} /> : <Line options={options} data={data} height={height} />
};

CustomPlot.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  datasets: PropTypes.arrayOf(PropTypes.object),
  additionalData: PropTypes.arrayOf(PropTypes.object),
  ticks: PropTypes.arrayOf(PropTypes.string),
  xlabel: PropTypes.string,
  ylabel: PropTypes.string,
  type: PropTypes.string,
  stacked: PropTypes.bool,
  zoom: PropTypes.bool
}
