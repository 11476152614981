import { PropTypes } from 'prop-types'
import React, { Fragment, useState } from 'react'

import { date2String } from '../../elements/utils'
import TaskTable from './TaskTable'
import TaskCarousel from './TaskCarousel'
import TaskFormModal from './TaskFormModal'
import { CustomButton } from '../../elements/StyledElements'
import ConstructionTable from '../constructions/ConstructionTable'

export default function TaskWidget ({ session }) {
  const [seed, setSeed] = useState(0)

  const resetState = () => setSeed(Math.random())

  const getQueryParams = (params) => {
    return {
      finished: false,
      employees: session.user.id,
      nested: true,
      seed,
      ...params
    }
  }

  return (
    <Fragment>
      {session.user.is_staff && (
        <TaskFormModal
          getOpenButton={(toggle) => <CustomButton onClick={toggle}>Neue Aufgabe</CustomButton>}
          resetParent={resetState}
          session={session}
        />
      )}

      <ConstructionTable
        header='Baustellen'
        queryParams={{}}
        resetParent={resetState}
        collapsible={false}
        showIfEmpty={false}
        session={session}
      />

      <TaskCarousel
        queryParams={getQueryParams({})}
        resetParent={resetState}
        collapsible={false}
        session={session}
        includeConstruction={false}
      />

      <TaskTable
        header='Frist verstrichen'
        queryParams={getQueryParams({
          deadline_or_date_passed: date2String(new Date())
        })}
        resetParent={resetState}
        collapsible={false}
        showIfEmpty={false}
        session={session}
      />

      <TaskTable
        header='Abgelehnt'
        queryParams={getQueryParams({
          accepted: false
        })}
        resetParent={resetState}
        collapsible={false}
        showIfEmpty={false}
        session={session}
      />

      <TaskTable
        header='Bestätigung ausstehend'
        queryParams={getQueryParams({
          wait_for_acceptance: true
        })}
        resetParent={resetState}
        collapsible={false}
        showIfEmpty={false}
        session={session}
      />

      <TaskTable
        header='Nicht terminiert'
        queryParams={getQueryParams({
          scheduled: false
        })}
        resetParent={resetState}
        collapsible={false}
        showIfEmpty={false}
        session={session}
      />
      <TaskTable
        header='Überprüfung ausstehend'
        queryParams={getQueryParams({
          finished: true,
          finished_confirmed: false
        })}
        showIfEmpty={false}
        resetParent={resetState}
        collapsible={false}
        session={session}
      />
    </Fragment>
  )
}

TaskWidget.propTypes = {
  session: PropTypes.object
}
