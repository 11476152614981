import React, { Fragment } from 'react'
import { Container, Row } from 'reactstrap'
import { isMobileOnly } from 'react-device-detect'

import { Typography, IconButton } from '@mui/material'
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined'

import CustomModal from './modal_utils/CustomModal'

import { CustomButton } from '../../elements/StyledElements'
import { PropTypes } from 'prop-types'

// import MapContainer from "../../elements/drafts/GoogleMapsView";

export default function GoogleRatingModal ({ session, onClose = () => { }, smallButton = false, passedOpenButton = null }) {
  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else onClose()
  }

  const loadData = () => {
  }

  const iconfontsize = isMobileOnly ? 30 : 40

  const getOpenButton = (toggle) =>
        <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', marginLeft: '0px' }} onClick={toggle}>
            <ThumbUpOffAltOutlinedIcon className='secondary-textcolor' style={{ fontSize: iconfontsize }} fontSize='large' />
            &nbsp;
            <Typography className='secondary-textcolor' fontSize='h6.fontSize'>
                Google Bewertung
            </Typography>
        </IconButton>

  const getSmallOpenButton = (toggle) =>
        <CustomButton onClick={toggle} icon="thumbUp">Google Bewertung</CustomButton>

  const openButton = passedOpenButton || (smallButton ? getSmallOpenButton : getOpenButton)

  return (
        <CustomModal getOpenButton={openButton} size="xl" title="Google Bewertung" onToggle={onToggle}>
            <Fragment>
                <Container>
                    <Row>
                        <div className="text-center">
                            <br />
                            <Typography className='secondary-textcolor' fontSize='h4.fontSize'>
                                Wir freuen uns auf Ihre Meinung:
                            </Typography>
                            <br />
                            <img
                                src={`/${process.env.REACT_APP_COMPANY}/google_qr.png`}
                                alt="Logo"
                                className="img-thumbnail"
                                style={{ border: 'none', backgroundColor: 'transparent', maxHeight: '300px' }}
                            />
                            <img
                                src="https://assets-global.website-files.com/602cf6148109ccfeb1d80c49/60d4509851d12743d030a9eb_5c11336dd43b9272273fb4ce_Google-Reviews.jpeg"
                                alt="Logo"
                                className="img-thumbnail"
                                style={{ border: 'none', backgroundColor: 'transparent', maxHeight: '150px' }}
                            />
                            <br />
                            <br />
                            <Typography className='secondary-textcolor' fontSize='h4.fontSize'>
                                Vielen Dank für Ihre Bewertung!
                            </Typography>
                        </div>
                    </Row>
                </Container>
                {/* <MapContainer
                    apiKey={session.creds.google_api_key}
                    // language=
                /> */}
            </Fragment>
        </CustomModal>
  )
}

GoogleRatingModal.propTypes = {
  session: PropTypes.object,
  onClose: PropTypes.func,
  smallButton: PropTypes.bool,
  passedOpenButton: PropTypes.func
}
