import axios from 'axios'
import { PropTypes } from 'prop-types'
import React from 'react'

import { API_URL_GROWATTACCOUNT } from '../../../../settings'
import { projectPropType } from '../../../../elements/PropTypes'
import { CustomButton } from '../../../../elements/StyledElements'

export default function GrowattAccountModal ({ project, onAccountCreation, session }) {
  const createAccount = () => {
    axios.put(API_URL_GROWATTACCOUNT, { project: project.id })
      .then(res => onAccountCreation(res.data))
      .catch(e => {
        if (e.response.status === 503) {
          console.error('Error in GrowattAccountModal:onOpen', e.response.data)
        } else {
          console.error('Error in "GrowattAccountModal:onOpen"', e)
        }
        alert('Fehler bei der Erstellung des Growatt-Accounts!')
      })
  }

  return <CustomButton disabled={(!!project.growatt_user)} onClick={createAccount} icon="flash">Growatt</CustomButton>
}

GrowattAccountModal.propTypes = {
  project: projectPropType,
  onAccountCreation: PropTypes.func,
  session: PropTypes.object
}
