import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import CustomModal from '../shared/modal_utils/CustomModal'
import { CustomButton } from '../../elements/StyledElements'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import axios from 'axios'
import { API_URL_LEAD_SETTING, API_URL_LEAD_SETTING_BULK } from '../../settings'
import { Table } from 'react-bootstrap'
import { Input } from 'reactstrap'
import { isNumeric } from '../../elements/utils'
import { toast } from 'react-toastify'

export default function LeadSettings ({ isOpen, setIsOpen, leadSettings, setLeadSettings }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })
  const getOpenButton = (toggle) => {
    return (
      <CustomButton onClick={toggle}> Leadeinstellungen </CustomButton>
    )
  }

  const onSave = async () => {
    setLoadingElements({ ...loadingElements, inProgress: true })
    for (const leadSetting of leadSettings) {
      if (!isNumeric(leadSetting.price)) {
        toast.error('Ungültiger Preis!')
        setLoadingElements({ ...loadingElements, inProgress: false })
        return
      }
    }
    try {
      await axios.put(API_URL_LEAD_SETTING_BULK, leadSettings)
      setIsOpen(false)
    } catch (e) {
      console.error(e)
      setLoadingElements({ ...loadingElements, submitError: true })
    } finally {
      setLoadingElements({ ...loadingElements, inProgress: false })
    }
  }

  const getFooter = () => {
    return <SaveModalFooter
      submitError={loadingElements.submitError}
      inProgress={loadingElements.inProgress}
      onSave={onSave}
    />
  }

  const onChange = (e) => {
    const source = e.target.name
    let price = e.target.value
    if (price < 0) price = 0

    const newLeadSettings = structuredClone(leadSettings)
    for (const leadSetting of newLeadSettings) {
      if (leadSetting.source === source) {
        leadSetting.price = price
        break
      }
    }

    setLeadSettings(newLeadSettings)
  }

  useEffect(() => {
    if (!isOpen) return
    const getSettings = async () => {
      const res = await axios.get(API_URL_LEAD_SETTING)
      setLeadSettings(res.data)
    }
    getSettings().then(() => {})
  }, [isOpen])

  return (
    <CustomModal size='fullscreen' title='Leadeinstellungen' getOpenButton={getOpenButton} getFooter={getFooter} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Table>
        <thead>
          <tr>
            <th>Leadquelle</th>
            <th>Preis [€]</th>
          </tr>
        </thead>
        <tbody>
          { leadSettings.map(setting => {
            return (
              <tr key={setting.source}>
                <td>{setting.source}</td>
                <td>
                  <Input
                      id={setting.source}
                      type="number"
                      name={setting.source}
                      onChange={onChange}
                      value={setting.price}
                      required={true}
                      style={{ boxShadow: 'none' }}
                      min={0}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </CustomModal>
  )
}

LeadSettings.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  leadSettings: PropTypes.object,
  setLeadSettings: PropTypes.func
}
