import React from 'react'
import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'

import { Typography } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { CustomToggle } from '../../elements/StyledElements'

import { defaultIfEmpty } from '../../elements/utils'
import { customerPropType, productPropType } from '../../elements/PropTypes'

export default function EditProductGroupForm ({ productGroup, setProductGroup, showMissingFields, selectedProductGroup }) {
  const setProductState = (update) => {
    setProductGroup((prevProductGroup) => ({
      ...prevProductGroup,
      ...update
    }))
  }

  const onChange = e => {
    setProductState({ [e.target.name]: e.target.value })
  }
  const onToggleChange = (e, val) => {
    if (val !== null) {
      setProductState({ state: val })
    }
  }
  return (
    <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
        <Typography className='secondary-textcolor'>Gruppenname</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={productGroup ? defaultIfEmpty(productGroup.name) : null}
          required={true}
          invalid={!!(showMissingFields && !(productGroup ? productGroup.name : null))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Zustand</Typography>
        <ToggleButtonGroup
        size="small"
        value={productGroup ? productGroup.state : true}
        exclusive
        onChange={onToggleChange}>
          <CustomToggle value={true}>Aktiv</CustomToggle>
          <CustomToggle value={false}>Deaktiviert</CustomToggle>
        </ToggleButtonGroup>
      </FormGroup>
    </Form>)
}

EditProductGroupForm.propTypes = {
  customer: customerPropType,
  productGroup: productPropType,
  setProductGroup: PropTypes.func,
  showMissingFields: PropTypes.bool,
  selectedProductGroup: PropTypes.array
}
