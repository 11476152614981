import React, { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { InputGroup, Input, FormGroup } from 'reactstrap'

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'

import CustomProductItems from '../products/CustomProductItems'
import { getFixedProductKeysForOffer, useDefaultExtraCharge } from '../project_types/projectUtils'
import { CustomButton, CustomToggle } from '../../elements/StyledElements'
import { defaultIfEmpty, modifyExtraCharge } from '../../elements/utils'
import { offerPropType, projectPropType, productPropType, customProductPropType } from '../../elements/PropTypes'

export default function OfferForm ({ project, offer, setOffer, customProducts, setCustomProducts, products, productGroups, resetProducts, productKeysForExtraCosts, amountCols, session, lineMappings, setLineMappings }) {
  const [currentChargeInput, setCurrentChargeInput] = useState(0) // dicount for offer, extra charge for blank offer
  useEffect(() => {
    setCurrentChargeInput(useDefaultExtraCharge(project)
      ? session.user.default_extra_charge - offer.extra_charge
      : offer.extra_charge)
  }, [offer.extra_charge])

  const setExtraCharge = (extraCharge) => {
    const prevExtraCharge = offer.extra_charge
    const newCustomProducts = modifyExtraCharge(customProducts, productKeysForExtraCosts, prevExtraCharge, extraCharge)
    setCustomProducts(newCustomProducts)
    setOffer({ ...offer, extra_charge: extraCharge })
  }

  const handleVatChange = (e, val) => {
    if (val !== null) {
      setOffer({ ...offer, vat: val })
      if (val === true) {
        const newCustomProducts = customProducts.map((cp) => {
          return { ...cp, vat: val }
        })
        setCustomProducts(newCustomProducts)
      } else {
        const newCustomProducts = customProducts.map((cp) => {
          const product = products.find((p) => p.id === cp.product)
          return { ...cp, vat: product.vat }
        })
        setCustomProducts(newCustomProducts)
      }
    }
  }

  if (!products) return null

  return (
    <Fragment>
      <CustomProductItems
        offer={offer}
        customProducts={customProducts}
        setCustomProducts={setCustomProducts}
        session={session}
        customer={project.customer_obj}
        amountCols={amountCols}
        products={products}
        productGroups={productGroups}
        project={project}
        resetProducts={resetProducts}
        showDescriptions={true}
        fixedProductKeys={getFixedProductKeysForOffer(project)}
        isDraggable={true}
        lineMappings={lineMappings}
        setLineMappings={setLineMappings}
        stage={'offer'}
      />
      <br />
      {(useDefaultExtraCharge(project))
        ? <InputGroup style={{ width: '40%' }}>
          <Input
            id="input"
            placeholder="Eingabe"
            type="number"
            width={'15px'}
            value={defaultIfEmpty(currentChargeInput)}
            style={{ boxShadow: 'none' }}
            onChange={(e) => { setCurrentChargeInput(e.target.value) }}
            onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) { e.preventDefault() } }}
            min={0}
            onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
            />
          <CustomButton disabled={currentChargeInput > session.user.default_extra_charge} variant="outlined" id="secondary-textcolor" onClick={() => setExtraCharge(session.user.default_extra_charge - currentChargeInput)}>
            Rabatt
          </CustomButton>
          <Button variant="outlined" color="error" onClick={() => { setExtraCharge(session.user.default_extra_charge) }}>
            Entfernen
          </Button>
        </InputGroup>
        : <InputGroup style={{ width: '40%' }}>
          <Input
            id="input"
            placeholder="Eingabe"
            type="number"
            width={'15px'}
            value={defaultIfEmpty(currentChargeInput)}
            style={{ boxShadow: 'none' }}
            onChange={(e) => { setCurrentChargeInput(e.target.value) }}
            onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) { e.preventDefault() } }}
            min={0}
            onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
            />
          <CustomButton variant="outlined" id="secondary-textcolor" onClick={() => setExtraCharge(currentChargeInput)}>
            Aufschlag
          </CustomButton>
          <Button variant="outlined" color="error" onClick={() => { setExtraCharge(0) }}>
            Entfernen
          </Button>
        </InputGroup>
      }
      <div><br /></div>
      <FormGroup>
        <Typography className='secondary-textcolor'>Alle Produkte mit MwSt.:</Typography>
        <ToggleButtonGroup size="small" value={offer.vat} exclusive onChange={handleVatChange}>
          <CustomToggle value={false}>Nein</CustomToggle>
          <CustomToggle value={true}>Ja</CustomToggle>
        </ToggleButtonGroup>
      </FormGroup>
      <div><br /></div>
    </Fragment>
  )
}

OfferForm.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  setOffer: PropTypes.func,
  customProducts: PropTypes.arrayOf(customProductPropType),
  setCustomProducts: PropTypes.func,
  products: PropTypes.arrayOf(productPropType),
  productGroups: PropTypes.arrayOf(PropTypes.object),
  resetProducts: PropTypes.func,
  productKeysForExtraCosts: PropTypes.arrayOf(PropTypes.string),
  amountCols: PropTypes.object,
  session: PropTypes.object,
  lineMappings: PropTypes.object,
  setLineMappings: PropTypes.func
}
