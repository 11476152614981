import React from 'react'
import { isMobileOnly } from 'react-device-detect'

export default function LogoHeader () {
  const imgHeight = isMobileOnly ? '200px' : '350px'
  return (
    <div className="text-center">
      <br />
      <img
        src={`/${process.env.REACT_APP_COMPANY}/logo.png`}
        alt="Logo"
        className="img-thumbnail"
        style={{ marginTop: '20px', border: 'none', backgroundColor: 'transparent', maxHeight: imgHeight }}
      />
    </div>
  )
}
