import React, { Fragment, useState, useEffect, useRef } from 'react'

import { PropTypes } from 'prop-types'
import { Stage, Layer, Image, Circle, Line } from 'react-konva'
import { OpenCvProvider, useOpenCv } from 'opencv-react'

import { isString } from '../../../elements/utils'
import LoadingPage from '../../../elements/LoadingPage'

const MAGNIFIERZOOM = 3
const CIRCLERADIUS = 15

const CIRCLEOFFSET = 40

const PerspectiveTransform = ({ image, onComplete, maxWidth, maxHeight }) => {
  let { cv, loaded } = useOpenCv()
  if (!loaded) cv = window.cv

  const [imageState, setImageState] = useState({
    stageWidth: null, stageHeight: null, imageScale: null, imageWindow: null
  })
  // const [magnifierCursor, setMagnifierCursor] = useState({ cursor: null, signHeight: 1, signWidth: 1 })
  const [magnifierKey, setMagnifierKey] = useState(null)
  const [cropPoints, setCropPoints] = useState({ initial: true, posLT: { x: null, y: null }, posRT: { x: null, y: null }, posLB: { x: null, y: null }, posRB: { x: null, y: null } })
  const [tmpCropPoints, setTmpCropPoints] = useState({ posLT: { x: null, y: null }, posRT: { x: null, y: null }, posLB: { x: null, y: null }, posRB: { x: null, y: null } })
  const prevCursor = useRef({ x: null, y: null })

  const performTransform = (imagesrc) => {
    getTransformedImage(imagesrc).then(function (blob) {
      if (blob) onComplete(blob)
    })
  }

  const getTransformedImage = (imagesrc) => {
    const randomPart = (Math.random() + 1).toString(36).substring(7)
    const dst = cv.imread(imagesrc)
    transform(
      dst,
      cropPoints,
      imageState.imageScale
    )

    const tempCanvas = document.createElement('canvas')
    cv.imshow(tempCanvas, dst)
    return (fetch(tempCanvas.toDataURL('image/jpg'))
      .then(function (res) { return res.arrayBuffer() })
      .then(function (buf) { return new File([buf], randomPart + '.jpg', { type: 'image/jpg' }) }))
  }

  const transform = (docCanvas, cropPoints, scaler) => {
    const dst = docCanvas
    const bR = cropPoints.posRB
    const bL = cropPoints.posLB
    const tR = cropPoints.posRT
    const tL = cropPoints.posLT

    // create source coordinates matrix
    const sourceCoordinates = [tL, tR, bR, bL].map((point) => [
      point.x / scaler,
      point.y / scaler
    ])

    // // get max width
    const maxWidth = Math.max(bR.x - bL.x, tR.x - tL.x) / scaler
    // get max height
    const maxHeight = Math.max(bL.y - tL.y, bR.y - tR.y) / scaler

    // create dest coordinates matrix
    const offset = 500
    const destCoordinates = [
      [0 + offset, 0 + offset],
      [maxWidth - 1 + offset, 0 + offset],
      [maxWidth - 1 + offset, maxHeight - 1 + offset],
      [0 + offset, maxHeight - 1 + offset]
    ]

    // convert to open cv matrix objects
    const Ms = cv.matFromArray(4, 1, cv.CV_32FC2, [].concat(...sourceCoordinates))
    const Md = cv.matFromArray(4, 1, cv.CV_32FC2, [].concat(...destCoordinates))
    const transformMatrix = cv.getPerspectiveTransform(Ms, Md)

    const dsize = new cv.Size(maxWidth + 1000, maxHeight + 1000)
    cv.warpPerspective(dst, dst, transformMatrix, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar(255, 255, 255, 255))
    // dst.delete()
    // Ms.delete()
    // Md.delete()
  }

  const transformImg = () => {
    if (image === null) { return }
    const src = isString(image) ? image : URL.createObjectURL(image)
    const newImageWindow = new window.Image()
    newImageWindow.src = src
    newImageWindow.addEventListener('load', () => performTransform(newImageWindow))
  }

  useEffect(() => { loadImage() }, [image])
  useEffect(() => { if (!cropPoints.initial) transformImg() }, [cropPoints])

  const loadImage = () => {
    if (image === null) { setImageState({ ...imageState, imageWindow: null }); return }
    const src = isString(image) ? image : URL.createObjectURL(image)
    const newImageWindow = new window.Image()
    newImageWindow.src = src
    newImageWindow.addEventListener('load', () => handleLoad(newImageWindow))
  }

  const handleLoad = (newImageWindow) => {
    const scalerW = maxWidth / newImageWindow.width
    const scalerH = maxHeight / newImageWindow.height
    const scaler = (scalerW >= scalerH) ? scalerH : scalerW
    const stageWidth = newImageWindow.width * scaler
    const stageHeight = newImageWindow.height * scaler
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight
    })
    const initialPos = { posLT: { x: 100, y: 100 }, posRT: { x: stageWidth - 100, y: 100 }, posLB: { x: 100, y: stageHeight - 100 }, posRB: { x: stageWidth - 100, y: stageHeight - 100 } }
    setCropPoints({ initial: true, ...initialPos })
    setTmpCropPoints(initialPos)
  }

  const handleMoveStart = (e) => {
    const stage = e.target.getStage()
    prevCursor.current = stage.getPointerPosition()
    updateMagnifierCursor(prevCursor.current)
  }

  const handleMove = (e) => {
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    updateMagnifierCursor(cursor)
  }

  const handleMoveEnd = e => {
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    updateMagnifierCursor(cursor)
    transformImg()
  }

  const updateMagnifierCursor = (cursor) => {
    // const signWidth = (cursor.x >= imageState.stageWidth - 100) ? -1 : 1
    // const signHeight = (cursor.y <= 100) ? -1 : 1
    // setMagnifierCursor({ cursor: cursor, signHeight: signHeight, signWidth: signWidth })
  }

  const shiftPoint = (key, point, reverse) => {
    let xShiftSign, yShiftSign
    if (key === 'posLT') { xShiftSign = -1; yShiftSign = -1 }
    if (key === 'posRT') { xShiftSign = +1; yShiftSign = -1 }
    if (key === 'posRB') { xShiftSign = +1; yShiftSign = +1 }
    if (key === 'posLB') { xShiftSign = -1; yShiftSign = +1 }
    if (reverse) { xShiftSign = -1 * xShiftSign; yShiftSign = -1 * yShiftSign }

    return { x: point.x + xShiftSign * CIRCLEOFFSET, y: point.y + yShiftSign * CIRCLEOFFSET }
  }
  const shiftPoints = (points, reverse) => {
    return { posLT: shiftPoint('posLT', points.posLT, reverse), posLB: shiftPoint('posLB', points.posLB, reverse), posRT: shiftPoint('posRT', points.posRT, reverse), posRB: shiftPoint('posRB', points.posRB, reverse) }
  }

  const handleDragEnd = (e) => {
    handleMove(e)
    const key = e.target.id()
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    const newPoint = shiftPoint(key, cursor, true)
    setCropPoints((prevCropPoints) => ({ ...prevCropPoints, [key]: newPoint, initial: false }))
    setTmpCropPoints((prevCropPoints) => ({ ...prevCropPoints, [key]: newPoint, initial: false }))
    setMagnifierKey(null)
  }

  const handleMoveCircle = (e) => {
    handleMove(e)
    const key = e.target.id()
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    const newPoint = shiftPoint(key, cursor, true)
    setTmpCropPoints((prevCropPoints) => ({ ...prevCropPoints, [key]: newPoint, initial: false }))
    setMagnifierKey(key)
  }

  const shiftedCropPoints = shiftPoints(cropPoints)
  const shiftedTmpCropPoints = shiftPoints(tmpCropPoints)

  if (!cv) {
    return <LoadingPage />
  }

  return (
    <Fragment>
      <Stage
        width={imageState.stageWidth}
        height={imageState.stageHeight}
      >
        <Layer>
          <Image
            id='transroofimage'
            image={imageState.imageWindow}
            scaleX={imageState.imageScale}
            scaleY={imageState.imageScale}
            onTouchStart={handleMoveStart}
            onTouchMove={handleMove}
            onTouchEnd={handleMoveEnd}
            onMouseDown={handleMoveStart}
            onMouseMove={handleMove}
            onMouseUp={handleMoveEnd}
          />
          {/* {magnifierCursor.cursor ?
                        <Circle
                            onTouchStart={handleMoveStart}
                            onTouchMove={handleMove}
                            onTouchEnd={handleMoveEnd}
                            onMouseDown={handleMoveStart}
                            onMouseMove={handleMove}
                            onMouseUp={handleMoveEnd}
                            fillPatternImage={imageState.imageWindow}
                            fillPatternX={parseInt(-magnifierCursor.cursor.x * MAGNIFIERZOOM)}
                            fillPatternY={parseInt(-magnifierCursor.cursor.y * MAGNIFIERZOOM)}
                            fillPatternScaleX={imageState.imageScale * MAGNIFIERZOOM}
                            fillPatternScaleY={imageState.imageScale * MAGNIFIERZOOM}
                            x={magnifierCursor.cursor.x + magnifierCursor.signWidth * 50}
                            y={magnifierCursor.cursor.y - magnifierCursor.signHeight * 50}
                            radius={50}
                            stroke={'black'}
                            strokeWidth={1}
                        /> : null}
                    {magnifierCursor.cursor ?
                        <Circle
                            onTouchStart={handleMoveStart}
                            onTouchMove={handleMove}
                            onTouchEnd={handleMoveEnd}
                            onMouseDown={handleMoveStart}
                            onMouseMove={handleMove}
                            onMouseUp={handleMoveEnd}
                            x={magnifierCursor.cursor.x + magnifierCursor.signWidth * 50}
                            y={magnifierCursor.cursor.y - magnifierCursor.signHeight * 50}
                            radius={3}
                            fill={'red'}
                        /> : null} */}
          {cropPoints.posLT.x
            ? (
              <>
                <Circle
                  id={'posLT'}
                  onTouchStart={handleMoveStart}
                  onTouchMove={handleMove}
                  onTouchEnd={handleMoveEnd}
                  onMouseDown={handleMoveStart}
                  onMouseMove={handleMove}
                  onMouseUp={handleMoveEnd}
                  onDragStart={handleMove}
                  onDragMove={handleMoveCircle}
                  onDragEnd={handleDragEnd}
                  x={shiftedCropPoints.posLT.x}
                  y={shiftedCropPoints.posLT.y}
                  radius={CIRCLERADIUS}
                  fill={'red'}
                  stroke={'red'}
                  strokeWidth={5}
                  draggable
                />
                <Circle
                  id={'posRT'}
                  onTouchStart={handleMoveStart}
                  onTouchMove={handleMove}
                  onTouchEnd={handleMoveEnd}
                  onMouseDown={handleMoveStart}
                  onMouseMove={handleMove}
                  onMouseUp={handleMoveEnd}
                  onDragStart={handleMove}
                  onDragMove={handleMoveCircle}
                  onDragEnd={handleDragEnd}
                  x={shiftedCropPoints.posRT.x}
                  y={shiftedCropPoints.posRT.y}
                  radius={CIRCLERADIUS}
                  fill={'red'}
                  stroke={'red'}
                  strokeWidth={5}
                  draggable
                />
                <Circle
                  id={'posLB'}
                  onTouchStart={handleMoveStart}
                  onTouchMove={handleMove}
                  onTouchEnd={handleMoveEnd}
                  onMouseDown={handleMoveStart}
                  onMouseMove={handleMove}
                  onMouseUp={handleMoveEnd}
                  onDragStart={handleMove}
                  onDragMove={handleMoveCircle}
                  onDragEnd={handleDragEnd}
                  x={shiftedCropPoints.posLB.x}
                  y={shiftedCropPoints.posLB.y}
                  radius={CIRCLERADIUS}
                  fill={'red'}
                  stroke={'red'}
                  strokeWidth={5}
                  draggable
                />
                <Circle
                  id={'posRB'}
                  onTouchStart={handleMoveStart}
                  onTouchMove={handleMove}
                  onTouchEnd={handleMoveEnd}
                  onMouseDown={handleMoveStart}
                  onMouseMove={handleMove}
                  onMouseUp={handleMoveEnd}
                  onDragStart={handleMove}
                  onDragMove={handleMoveCircle}
                  onDragEnd={handleDragEnd}
                  x={shiftedCropPoints.posRB.x}
                  y={shiftedCropPoints.posRB.y}
                  radius={CIRCLERADIUS}
                  fill={'red'}
                  stroke={'red'}
                  strokeWidth={5}
                  draggable
                />
                <Line
                  key={'lineLTRT'}
                  points={[(tmpCropPoints.posLT.x), (tmpCropPoints.posLT.y), (tmpCropPoints.posRT.x), (tmpCropPoints.posRT.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                <Line
                  key={'lineRTRB'}
                  points={[(tmpCropPoints.posRT.x), (tmpCropPoints.posRT.y), (tmpCropPoints.posRB.x), (tmpCropPoints.posRB.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                <Line
                  key={'lineRBLB'}
                  points={[(tmpCropPoints.posRB.x), (tmpCropPoints.posRB.y), (tmpCropPoints.posLB.x), (tmpCropPoints.posLB.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                <Line
                  key={'lineLBLT'}
                  points={[(tmpCropPoints.posLB.x), (tmpCropPoints.posLB.y), (tmpCropPoints.posLT.x), (tmpCropPoints.posLT.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                <Line
                  key={'lineLT'}
                  points={[(tmpCropPoints.posLT.x), (tmpCropPoints.posLT.y), (shiftedTmpCropPoints.posLT.x), (shiftedTmpCropPoints.posLT.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                <Line
                  key={'lineRT'}
                  points={[(tmpCropPoints.posRT.x), (tmpCropPoints.posRT.y), (shiftedTmpCropPoints.posRT.x), (shiftedTmpCropPoints.posRT.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                <Line
                  key={'lineRB'}
                  points={[(tmpCropPoints.posRB.x), (tmpCropPoints.posRB.y), (shiftedTmpCropPoints.posRB.x), (shiftedTmpCropPoints.posRB.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                <Line
                  key={'lineLB'}
                  points={[(tmpCropPoints.posLB.x), (tmpCropPoints.posLB.y), (shiftedTmpCropPoints.posLB.x), (shiftedTmpCropPoints.posLB.y)]}
                  stroke={'red'} strokeWidth={3} listening={false}
                />
                {magnifierKey
                  ? <Circle
                    fillPatternImage={imageState.imageWindow}
                    fillPatternX={parseInt(-tmpCropPoints[magnifierKey].x * MAGNIFIERZOOM)}
                    fillPatternY={parseInt(-tmpCropPoints[magnifierKey].y * MAGNIFIERZOOM)}
                    fillPatternScaleX={imageState.imageScale * MAGNIFIERZOOM}
                    fillPatternScaleY={imageState.imageScale * MAGNIFIERZOOM}
                    x={['posRT', 'posRB'].includes(magnifierKey) ? 50 : imageState.stageWidth - 50}
                    y={50}
                    radius={50}
                    stroke={'black'}
                    strokeWidth={1}
                  />
                  : null}
                {magnifierKey
                  ? <Circle
                    x={['posRT', 'posRB'].includes(magnifierKey) ? 50 : imageState.stageWidth - 50}
                    y={50}
                    radius={3}
                    fill={'red'}
                  />
                  : null}
              </>
              )
            : null}
        </Layer>
      </Stage>
      <br />
    </Fragment>
  )
}

PerspectiveTransform.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onComplete: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number
}

export default function PerspectiveTransformImg ({ image, onComplete, maxWidth, maxHeight }) {
  return <OpenCvProvider openCvPath="/opencv.js">
    <PerspectiveTransform image={image} onComplete={onComplete} maxWidth={maxWidth} maxHeight={maxHeight} />
  </OpenCvProvider>
}

PerspectiveTransformImg.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onComplete: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number
}
