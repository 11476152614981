import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export default function ErrorMessage ({ message }) {
  const [copied, setCopied] = useState(false)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(message)
    setCopied(true)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
        {message}
      </div>
      {!copied && (
        <div
          onClick={copyToClipboard}
          style={{
            cursor: 'pointer',
            marginLeft: '0.5rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ContentCopyIcon />
        </div>
      )}
      {copied && <div style={{ marginLeft: '0.5rem' }}>kopiert!</div>}
    </div>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired
}
