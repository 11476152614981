import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Col, Container, Row, Spinner } from 'reactstrap'
import { Table } from 'react-bootstrap'
import axios from 'axios'

import { Typography, IconButton, Tooltip } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'
import Add from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'

import { API_URL_UNKNWONOPERATORREQUEST, API_URL_OPERATORREQUEST, API_URL_SENDMARKTSTAMM, BASE_URL, PROTOCOL } from '../../../../settings'
import EmailModal from '../../../shared/modal_utils/EmailModal'
import PdfViewModal from '../../../shared/modal_utils/PdfViewModal'
import { getCustomerName } from '../../../../elements/utils'
import { projectPropType } from '../../../../elements/PropTypes'

export default function NotImplementedOperatorRequest ({ isOpen, project, resetParent, requestMode, session }) {
  const [data, setData] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, modeInProgress: []
  })

  useEffect(() => {
    if (!isOpen) clearData()
    else loadData()
  }, [isOpen])

  const loadData = () => {
    axios.get(API_URL_UNKNWONOPERATORREQUEST, { params: { project: project.id } })
      .then((res) => { setData(res.data) })
      .catch(error => { console.error('Error in UnknownOperatorRequest.post:', error, error.stack); setLoadingElements({ inProgress: false, errorMsg: 'Anfrage konnte nicht an Server übermittelt werden.' }) })
  }

  const clearData = () => {
    setData(null)
    setLoadingElements({ inProgress: false, submitError: false, modeInProgress: [] })
  }

  const resetState = () => { resetParent(); loadData(); setLoadingElements({ inProgress: false, submitError: false, modeInProgress: [] }) }

  const getValue = (value) => {
    return (value === false) ? <>&#x2716;</> : ((value === true) ? <>&#10004;</> : value)
  }

  const download = (url, filename) => {
    axios({
      url, // your url
      method: 'GET',
      responseType: 'blob' // important

    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename) // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  const getFilename = (name) => {
    let filename = name + '_' + getCustomerName(project.customer_obj) + '.pdf'
    filename = filename.replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue').replace(' ', '_')
    filename = filename.replace(/[^._0-9a-zA-Z]/gi, '')
    return filename
  }

  const createRequest = (mode) => {
    setLoadingElements({ inProgress: true, submitError: false, modeInProgress: [mode] })
    axios.post(API_URL_OPERATORREQUEST, { unknown_operator: true, mode, project: project.id })
      .then(resetState)
      .catch(error => {
        setLoadingElements({ inProgress: false, submitError: false, modeInProgress: [] })
        if (error.response.status === 422) {
          alert('Folgende Daten für den Kunden fehlen, um den Netzantrag zu erstellen: ' + error.response.data.join(', '))
          return
        }
        return Promise.reject(error)
      })
  }

  const getRow = (mode, title) => {
    const modeDocuments = project.operatorrequestdoc_set.filter(doc => doc.mode === mode)
    const nDocuments = modeDocuments.length
    if (nDocuments === 0) {
      return <tr key={`${title}_row`}>
        <td><Typography className='secondary-textcolor'>{title}</Typography></td>
        <td className="align-right" align="right">
          <Tooltip title='Hinzufügen' PopperProps={{ style: { zIndex: 9999 } }} >
          <IconButton disableFocusRipple disableRipple disabled={loadingElements.inProgress} style={{ backgroundColor: 'transparent' }} onClick={() => createRequest(mode)}><Add className='secondary-textcolor' fontSize='large' /></IconButton>
          </Tooltip>
          {(loadingElements.inProgress && loadingElements.modeInProgress.includes(mode)) ? <Spinner color="dark" size="sm" /> : null}
        </td>
        <td></td>
        <td></td>
      </tr>
    }
    const rows = []
    modeDocuments.forEach((doc, idx) => {
      const style = (mode === requestMode || (Array.isArray(requestMode) && requestMode.includes(mode))) ? { backgroundColor: '#cfe2f3' } : {}
      rows.push(<tr key={`${title}_filled_row`} style={style}>
        {(idx === 0) ? <td rowSpan={nDocuments}>{title}</td> : null}
        {(idx === 0)
          ? <td rowSpan={nDocuments} className="align-right" align="right">
            <Tooltip title='Aktualisierung' PopperProps={{ style: { zIndex: 9999 } }} >
            <IconButton disableFocusRipple disableRipple disabled={loadingElements.inProgress} style={{ backgroundColor: 'transparent' }} onClick={() => createRequest(mode)}><RefreshIcon className='secondary-textcolor' fontSize='large' /></IconButton>
            </Tooltip>
            {(loadingElements.inProgress && loadingElements.modeInProgress.includes(mode)) ? <Spinner color="dark" size="sm" /> : null}
            {/* {(!sent) ? <SendOperatorRequestModal disabled={loadingElements.inProgress} customer={customer} mode={mode} resetParent={() => {resetState(); if (resetParent) resetParent()}} session={session} /> : <MarkEmailReadOutlinedIcon color="success" style={{ float: 'right' }} fontSize='large' />} */}
          </td>
          : null}
        <td><Typography className='secondary-textcolor'>{doc.name}</Typography></td>
        <td className="align-right" align="right">
          <PdfViewModal
            title={doc.name + ' ' + getCustomerName(project.customer_obj)}
            filepath={(doc.pdf) ? doc.pdf.replace('http:', PROTOCOL + ':') : null}
          />
          {doc.pdf
            ? (
              <Tooltip title='Herunterladen' PopperProps={{ style: { zIndex: 9999 } }} >
              <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={() => download(doc.pdf.replace('http:', PROTOCOL + ':'), getFilename(doc.name))}><FileDownloadIcon className='secondary-textcolor' fontSize={'large'} /></IconButton>
              </Tooltip>
              )
            : null}
          {(doc.name === 'Marktstammdaten' && doc.pdf && !doc.sent)
            ? <EmailModal
              customer={project.customer_obj}
              title={'Marktstammdaten für ' + getCustomerName(project.customer_obj) + ' senden'}
              subject={'Anleitung zur Meldung Ihrer Anlage ' + project.street_and_number_project + ' beim Marktstammdatenregister'}
              getEmailMessage={API_URL_SENDMARKTSTAMM}
              submit={(subject, message) => axios.post(API_URL_SENDMARKTSTAMM, { project: project.id, mail: { subject, message } })}
              getModalOpenButton={(toggle) => (
                <Tooltip title='Senden' PopperProps={{ style: { zIndex: 9999 } }} >
                <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={toggle}><ForwardToInboxOutlinedIcon className='secondary-textcolor' fontSize='large' /></IconButton>
                </Tooltip>
              )}
              session={session}
              resetParent={resetState}
            />
            : null}
          {(doc.name === 'Marktstammdaten' && doc.sent) ? <IconButton disabled={true} disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }}><MarkEmailReadOutlinedIcon color="success" style={{ float: 'right' }} fontSize='large' /></IconButton> : null}
        </td>
      </tr>)
    })
    return rows
  }

  return <Container>
    {(data)
      ? data.map(topic => {
        return <>
          <Typography className='secondary-textcolor' fonSize='h5.fontSize'>{topic.title}</Typography>
          <div style={{ height: '0.5rem' }}></div>
          {topic.data.map((item, idx) =>
            <Row key={`operator-request-topic-data-${idx}`}>
              <Col><Typography className='secondary-textcolor'>{item.name}</Typography></Col>
              <Col>
                {(!item.type) ? <Typography className='secondary-textcolor'>{getValue(item.value)}</Typography> : null}
                {(item.type === 'list')
                  ? item.value.map(_item => {
                    return <>
                      {(!_item.type) ? <Typography className='secondary-textcolor'>{_item.name}: {getValue(_item.value)}</Typography> : null}
                      {(item.type === 'file')
                        ? <PdfViewModal
                          title={item.name + ' für' + getCustomerName(project.customer_obj)}
                          filepath={BASE_URL + item.value}
                          iconSize={'small'}
                        />
                        : null}
                    </>
                  })
                  : null}
              </Col>
              <div style={{ height: '0.5rem' }}></div>
            </Row>
          )}
          <hr className='secondary-textcolor' />
        </>
      })
      : null}
    <Typography className='secondary-textcolor' fonSize='h5.fontSize'>Dokumente</Typography>
    <Table >
      <thead>
        <tr>
          <th style={{ width: '15%' }}></th>
          <th style={{ width: '15%' }}></th>
          <th style={{ width: '60%' }}><Typography className='secondary-textcolor'>Dokument</Typography></th>
          <th style={{ width: '10%' }}></th>
        </tr>
      </thead>
      <tbody>
        {getRow(0, 'Anfrage')}
        {getRow(1, 'Anmeldung (nach Montage)')}
        {getRow(2, 'Inbetriebssetzungsantrag (nach Zählerwechsel)')}
        {getRow(3, 'Wallbox')}
      </tbody>
    </Table>
    {/* {loadingElements.inProgress ? null : <IconButton disableFocusRipple disableRipple style={{ backgroundColor: "transparent" }} size="small" onClick={() => loadData()}><RefreshIcon className='secondary-textcolor' fontSize={"medium"} /></IconButton>} */}
  </Container>
}

NotImplementedOperatorRequest.propTypes = {
  isOpen: PropTypes.bool,
  project: projectPropType,
  resetParent: PropTypes.func,
  requestMode: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  session: PropTypes.object
}
