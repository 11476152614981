import React, { Fragment, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { Container } from 'reactstrap'

import { Typography } from '@mui/material'

import LoadingPage from '../../../../elements/LoadingPage'
import { projectPropType } from '../../../../elements/PropTypes'
import { CustomButton } from '../../../../elements/StyledElements'
import { filterObj, hasPermission } from '../../../../elements/utils'
import { API_URL_CUSTOMER, API_URL_EMETER, API_URL_PROJECT } from '../../../../settings'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import DataInputForm from './DataInputForm'
import DataInputRequestModal from './DataInputRequestModal'

const emptyEMeterForm = { id: null, value: null, e_meter_id: '', action: null, project: null }
const emptyCustomerForm = {
  id: null,
  first_name: null,
  last_name: null,
  street_and_number: null,
  zip_and_city: null,
  province: null,
  iban: null,
  bic: null,
  bank: null,
  account_owner: null,
  tax_number: null
}
const emptyProjectForm = {
  id: null,
  street_and_number_project: null,
  zip_and_city_project: null,
  province: null,
  emeter_set: []
}

export default function DataInputFormModal ({ project, getOpenButton, resetParent, session }) {
  const [customerForm, setCustomerForm] = useState(null)
  const [projectForm, setProjectForm] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })
  const [sent, setSent] = useState(true)

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const loadData = () => {
    setCustomerForm(project.customer_obj)
    setProjectForm(project)
  }

  const clearData = () => {
    setCustomerForm(null)
    setProjectForm(null)
    setLoadingElements({ inProgress: false, submitError: false })
  }

  const submit = async () => {
    const promises = [
      axios.put(API_URL_CUSTOMER + project.customer, filterObj(customerForm, Object.keys(emptyCustomerForm))),
      axios.put(API_URL_PROJECT + project.id, filterObj(projectForm, Object.keys(emptyProjectForm))),
      axios.delete(API_URL_EMETER, { data: { except_ids: projectForm.emeter_set.filter(x => x.id > 0).map(x => x.id), project: project.id } })
        .then(() => Promise.all(
          projectForm.emeter_set
            .map(eMeterForm => {
              const form = filterObj(eMeterForm, Object.keys(emptyEMeterForm))
              return (eMeterForm.id && eMeterForm.id > 0)
                ? axios.put(API_URL_EMETER + eMeterForm.id, form)
                : axios.post(API_URL_EMETER, { ...form, id: null })
            })
        ))
    ]
    return Promise.all(promises).then((res) => { setSent(true); return res })
  }

  const onSubmit = async (onSuccess) => {
    setSent(false)
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false })
    return submit()
      .then((res) => {
        resetParent(res.data)
        onSuccess()
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      })
      .catch(error => {
        console.error('Error in "customer:onSubmit"', error, error.stack)
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
      })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        saveDisabled={!hasPermission(session.user, 'customer_handling')}
      />)
  }

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton onClick={toggle} icon="clipboard">Dateneingabe</CustomButton>
  }

  if (!sent) return <LoadingPage />

  return (<CustomModal getOpenButton={_getOpenButton} size='fullscreen' title="Dateneingabe" getFooter={getFooter} onToggle={onToggle}>
    {customerForm
      ? <DataInputForm
        customer={customerForm}
        setCustomer={setCustomerForm}
        project={projectForm}
        setProject={setProjectForm}
        session={session}
      />
      : null}

    <Fragment>
      <Container>
        <hr className='secondary-textcolor' />
        <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
          Daten anfragen
        </Typography>
        <br />
        {hasPermission(session.user, 'customer_handling')
          ? <DataInputRequestModal
            project={project}
            resetParent={resetParent}
            session={session}
          />
          : null}
      </Container>
    </Fragment>
  </CustomModal>)
}

DataInputFormModal.propTypes = {
  project: projectPropType,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
