import React from 'react'
import { PropTypes } from 'prop-types'

import { projectPropType } from '../../elements/PropTypes'
import HeatpumpConstructionDocumentationFormModal from './heatpump_project/constructions/HeatpumpConstructionDocumentationFormModal'
import PlantConstructionDocumentationFormModal from './plant_project/constructions/PlantConstructionDocumentationFormModal'

export default function ConstructionDocumentationFormModal ({ project, chosenDate, disable, resetParent, session, isOpen, setIsOpen, onClose }) {
  if (project.resourcetype === 'PlantProject') {
    return <PlantConstructionDocumentationFormModal
      project={project}
      chosenDate={chosenDate}
      disable={disable}
      session={session}
      onClose={onClose}
      resetParent={resetParent}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  }
  if (project.resourcetype === 'PlantProject') {
    return <HeatpumpConstructionDocumentationFormModal
      project={project}
      chosenDate={chosenDate}
      disable={disable}
      session={session}
      onClose={onClose}
      resetParent={resetParent}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  }
  return null
}

ConstructionDocumentationFormModal.propTypes = {
  project: projectPropType,
  chosenDate: PropTypes.instanceOf(Date),
  disable: PropTypes.bool,
  resetParent: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  session: PropTypes.object,
  onClose: PropTypes.func
}
