import React, { Fragment, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Input, Container, Row, Col } from 'reactstrap'
import Compress from 'browser-image-compression'

import ImageStage from './ImageStage'

import { CustomIconButton } from '../../../elements/StyledElements'
import { getRandomId } from '../../../elements/utils'

export default function UploadImages ({ images, addImage, deleteImage }) {
  const filename = useRef(null)

  const onFileChange = (e) => {
    if ((!e.target.files) || e.target.files.length < 1) return
    const file = e.target.files[0]
    filename.current = file.name.split('.')[0]

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1600,
      // Use webworker for faster compression
      useWebWorker: true
    }

    Compress(file, options)
      .then(compressedBlob => {
        compressedBlob.lastModifiedDate = new Date()
        const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })
        addImage({ id: -getRandomId(), image: convertedBlobFile })
      }).catch(e => {
        alert('Fehler bei der Bildkompression!')
        console.error(e)
      })
  }

  return (
    <Fragment>
      <br />
      <Input style={{ width: '30%', boxShadow: 'none' }} id="input" type="file" onChange={onFileChange} />
      <br />
      <Container>
        <Row xs={10} md={7}>
          {images && images.map((img, iIdx) =>
            <Col key={`upload-images-${iIdx}`} style={{ minWidth: 400 }}>
              <CustomIconButton icon='delete' onClick={() => { deleteImage(img) }} />
              <ImageStage image={img.image} width={400} />
            </Col>)}
        </Row>
      </Container>
    </Fragment>
  )
}

UploadImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  addImage: PropTypes.func,
  deleteImage: PropTypes.func
}
