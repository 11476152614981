import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import DefaultWorkingHoursForm from './DefaultWorkingHoursForm'

import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import CustomModal from '../shared/modal_utils/CustomModal'
import { CustomButton } from '../../elements/StyledElements'
import { API_URL_USER } from '../../settings'

export default function DefaultWorkingHoursModal ({ session, resetParent }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const [employees, setEmployees] = useState(null)

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const loadData = () => {
    axios.get(API_URL_USER, { params: { is_staff: true, is_active: true, visible: true } }).then(res => {
      const employees = res.data
        .filter(user => ['salesmen', 'telemarketers', 'sales_managers', 'managers', 'installers', 'construction_managers', 'electricians', 'heating_installers', 'installer_students', 'heating_installer_construction_manager'].includes(user.group_key))
      setEmployees(employees.sort((a, b) => a.id - b.id))
    })
  }

  const clearData = () => {
    setLoadingElements({ submitError: false, inProgress: false })
  }

  const onConfirm = (onSuccess) => {
    setLoadingElements({ ...loadingElements, submitError: false, inProgress: true })
    Promise.all(employees.map(s => axios.put(API_URL_USER + s.id, { ...s, default_working_hours: s.default_working_hours ? s.default_working_hours : 0 }))).then(() => {
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false })
    }).catch(() => { setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getOpenButton = (toggle) => {
    return <CustomButton icon="time" onClick={toggle}>Standardstunden</CustomButton>
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                submitError={loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={() => onConfirm(toggle)}
                onCancel={toggle}
                btnLabel={'Speichern'}
            />
    )
  }

  return <CustomModal getOpenButton={getOpenButton} getFooter={getFooter} onToggle={onToggle} size="lg" title={'Standard Arbeitsstunden'}>
        <DefaultWorkingHoursForm
            session={session}
            employees={employees}
            setEmployees={setEmployees}
        />
    </CustomModal>
}

DefaultWorkingHoursModal.propTypes = {
  session: PropTypes.object,
  resetParent: PropTypes.func
}
