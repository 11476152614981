import React, { useState } from 'react'

import { PropTypes } from 'prop-types'
import 'react-datepicker/dist/react-datepicker.css'
import { IconButton, Typography } from '@mui/material'
import Add from '@mui/icons-material/Add'

import ChooseProjectToScheduleModal from './ChooseProjectToScheduleModal'
import ScheduleProjectModal from './ScheduleProjectModal'

export default function ScheduleModal ({ resetParent, session }) {
  const [isOpen, setIsOpen] = useState(false)
  const [chosenProject, setChosenProject] = useState(null)

  return (
    <>
      <IconButton size="small" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={() => setIsOpen(true)}>
        <Add style={{ color: '#424242' }} fontSize='small' /><Typography display="inline" className='secondary-textcolor'>Baustelle hinzufügen</Typography>
      </IconButton>
      <ChooseProjectToScheduleModal
        setChosenProject={setChosenProject}
        chosenDate={null}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <ScheduleProjectModal
        project={chosenProject}
        resetParent={() => { setIsOpen(false); resetParent() }}
        session={session}
        isOpen={!!chosenProject}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenProject(null) }}
      />
    </>
  )
}

ScheduleModal.propTypes = {
  chosenDate: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
