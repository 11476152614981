import React, { useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import { IconButton, Tooltip } from '@mui/material'

import { offerPropType, projectPropType } from '../../elements/PropTypes'
import { generateString, getCustomerName, usePreviousValue } from '../../elements/utils'
import { API_URL_SENDOFFER, API_URL_SENDREFERRALCODE } from '../../settings'
import { getOfferEmailSubject, sendReferralEmail } from '../project_types/projectUtils'
import EmailModal from '../shared/modal_utils/EmailModal'

export default function SendOfferModal ({ project, offer, resetParent, session }) {
  const [isOpen, setIsOpen] = useState(false)
  const [referralEmailIsOpen, setReferralEmailIsOpen] = useState(false)

  const prevIsOpen = usePreviousValue(isOpen)
  const prevReferralEmailIsOpen = usePreviousValue(referralEmailIsOpen)

  const referralCode = useRef(null)

  const referralEmail = sendReferralEmail(project) &&
    !project.customer_obj.referral_code &&
    project.baseoffer_set.length === 1 && false // TODO: move referral email

  useEffect(() => {
    if (prevIsOpen === true && isOpen === false && !referralEmail) {
      if (resetParent) resetParent()
    }
  }, [isOpen])

  useEffect(() => {
    if (prevReferralEmailIsOpen === true && referralEmailIsOpen === false && referralEmail) {
      if (resetParent) resetParent()
    }
  }, [referralEmailIsOpen])

  useEffect(() => {
    if (isOpen) loadData()
  }, [isOpen])

  const loadData = () => {
    referralCode.current = generateString(8)
  }

  const submit = (subject, message) => {
    return axios.post(API_URL_SENDOFFER, { offer: offer.id, project: project.id, mail: { subject, message } })
  }

  const skip = () => {
    return axios.post(API_URL_SENDOFFER, { offer: offer.id, project: project.id })
  }

  const submitReferralEmail = (subject, message) => {
    return axios.post(API_URL_SENDREFERRALCODE, { customer: project.customer, referral_code: referralCode.current, mail: { subject, message } })
  }

  const getOpenButton = (toggle) => {
    return (
      <Tooltip title='Senden' PopperProps={{ style: { zIndex: 9999 } }} >
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={() => setIsOpen(true)} >
      <ForwardToInboxOutlinedIcon className='secondary-textcolor' fontSize='large' />
    </IconButton>
    </Tooltip>
    )
  }

  const title = 'Angebot ' + offer.id + ' versenden an ' + getCustomerName(project.customer_obj)

  return (
    <>
      <EmailModal
        customer={project.customer_obj}
        title={title}
        subject={getOfferEmailSubject(project, session)}
        getEmailMessage={() => axios.get(API_URL_SENDOFFER, { params: { offer: offer.id } })}
        submit={async (subject, message) => {
          return submit(subject, message).then((res) => {
            if (referralEmail) setReferralEmailIsOpen(true)
            setIsOpen(false)
            return res
          })
        }}
        skip={async () => {
          return skip().then((res) => {
            if (referralEmail) setReferralEmailIsOpen(true)
            setIsOpen(false)
            return res
          })
        }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getModalOpenButton={getOpenButton}
        session={session}
      />
      <EmailModal
        customer={project.customer_obj}
        title={title}
        subject={`${session.companyConfig.name} Bonusprogramm`}
        getEmailMessage={() =>
          axios.get(API_URL_SENDREFERRALCODE).then(res => {
            let msg = res.data
            msg = msg.replace('__CUSTOMER_COMMISSION_CODE__', referralCode.current)
            return { data: msg }
          })
        }
        submit={(subject, message) => submitReferralEmail(subject, message).then((res) => { setIsOpen(false); return res })}
        isOpen={referralEmailIsOpen}
        setIsOpen={setReferralEmailIsOpen}
        getModalOpenButton={() => null}
        session={session}
      />
    </>
  )
}

SendOfferModal.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
