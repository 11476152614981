import React from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import { IconButton } from '@mui/material'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'

import { API_URL_SENDOPERATORREQUEST } from '../../../../settings'
import { getCustomerName } from '../../../../elements/utils'
import EmailModal from '../../../shared/modal_utils/EmailModal'
import { projectPropType } from '../../../../elements/PropTypes'

export default function SendOperatorRequestModal ({ project, mode, resetParent, disabled, onSubmit, session }) {
  const submit = (subject, message) => {
    const promise = axios.post(API_URL_SENDOPERATORREQUEST, { mode, project: project.id, mail: { subject, message } })
    if (onSubmit) return promise.then(onSubmit)
    return promise
  }

  const skip = () => {
    return axios.post(API_URL_SENDOPERATORREQUEST, { mode, project: project.id })
  }

  const getOpenButton = (toggle) => {
    return <IconButton disableFocusRipple disableRipple disabled={disabled} style={{ backgroundColor: 'transparent', float: 'right' }} onClick={toggle}><ForwardToInboxOutlinedIcon className='secondary-textcolor' fontSize='large' /></IconButton>
  }

  const address = project.street_and_number_project
  const customer = project.customer_obj
  const title = 'Netzantrag für ' + getCustomerName(customer) + ' versenden'
  return (
        <EmailModal
            customer={customer}
            title={title}
            subject={'Netzantrag ' + getCustomerName(customer) + ', ' + address}
            getEmailMessage={() => axios.get(API_URL_SENDOPERATORREQUEST, { params: { mode } })}
            submit={submit}
            skip={skip}
            resetParent={resetParent}
            getModalOpenButton={getOpenButton}
            session={session}
            address={'Sehr geehrte Damen und Herren'}
        />
  )
}

SendOperatorRequestModal.propTypes = {
  project: projectPropType,
  mode: PropTypes.number,
  resetParent: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  session: PropTypes.object
}
