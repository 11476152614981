import React, { Fragment, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { Table, Spinner } from 'reactstrap'

import CheckIcon from '@mui/icons-material/Check'
// import ClearIcon from '@mui/icons-material/Clear'
// import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
// import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'
import { Typography, Tooltip, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { projectPropType } from '../../elements/PropTypes'
import { getCustomerName, hasPermission, sortById } from '../../elements/utils'
import { API_URL_OFFER, API_URL_OFFERWITHDRAWAL, PROTOCOL } from '../../settings'
import AcceptProjectOfferModal from '../project_types/AcceptProjectOfferModal'
// import { getExtraOfferActions, getTechnicalApprovalPermissionKey, isTechnicalApprovalRequired } from '../project_types/projectUtils'
import { getExtraOfferActions } from '../project_types/projectUtils'
import ConfirmationModal from '../shared/modal_utils/ConfirmationModal'
import PdfViewModal from '../shared/modal_utils/PdfViewModal'
import OfferCopy from './OfferCopy'
import OfferFormModal from './OfferFormModal'
import SendOfferModal from './SendOfferModal'
import Icon from '../../elements/Icon'
import CommentOfferModal from './CommentOfferModal'
// import TechnicalApprovalModal from './TechnicalApprovalModal'
import ConfirmOfferModal from './ConfirmOfferModal'

export default function OfferList ({ project, resetParent = null, session, additionalDetails, setPlanning }) {
  const offers = project.baseoffer_set
  const [loadingElements, setLoadingElements] = useState({ inProgress: false })

  // const technicalApprovalRequired = isTechnicalApprovalRequired(project)
  // const technicalApprovalPermissionKey = getTechnicalApprovalPermissionKey(project)

  // const cancelTitle = project.construction_obj ? 'Wurde die Annahme des Angebots wirklich widerrufen? Mit dem Widerruf wird die Bauplanung gelöscht!' : 'Wurde die Annahme des Angebotes wirklich widerrufen?'

  return (
    <Fragment>
    {loadingElements?.inProgress && <Stack direction="column-reverse" alignItems={'end'}>
      <Spinner color="dark" size="md" />
    </Stack>}
    <Table >
      <thead>
        <tr>
          <th className="align-middle"><Typography className='secondary-textcolor'>Angebots-Nr.</Typography></th>
          <th className="align-middle"><Typography className='secondary-textcolor'>Erstellt am</Typography></th>
          <th className="align-middle" style={{ textAlign: 'center' }}><Typography className='secondary-textcolor'>Aktion</Typography></th>
        </tr>
      </thead>

      <tbody>
        {!offers || offers.length <= 0
          ? <tr>
            <td colSpan="4" align="center">
              <Typography className='secondary-textcolor'>Keine Angebote vorhanden</Typography>
            </td>
          </tr>
          : (
              sortById(offers)
                .map(offer => (
                <tr key={`offer-list-${offer.id}`}>
                  <td className="align-middle"><Typography className={(offer.pdf) ? 'secondary-textcolor' : 'disable-textcolor'}>{offer.id}</Typography></td>
                  <td className="align-middle"><Typography className={(offer.pdf) ? 'secondary-textcolor' : 'disable-textcolor'}>{offer.registration_date + '  ' + offer.registration_time.split('.')[0]}</Typography></td>
                  <td className="align-middle" align="center">
                    <PdfViewModal
                      title={'Angebot für ' + getCustomerName(project.customer_obj)}
                      filepath={(offer.pdf) ? offer.pdf.replace('http:', PROTOCOL + ':') : null} />
                    {hasPermission(session.user, 'customer_handling')
                      ? <>

                      {(!offer.sent)
                        ? <OfferFormModal
                          offer={offer}
                          planningId={offer.planning}
                          project={project}
                          resetParent={resetParent}
                          session={session}
                          additionalDetails={additionalDetails}
                          setPlanning={setPlanning}
                        />
                        : null}

                      <OfferCopy
                        offer={offer}
                        resetParent={resetParent}
                        session={session}
                        setLoadingElements={setLoadingElements}
                      />

                      <CommentOfferModal
                        offer={offer}
                        project={project}
                        resetParent={resetParent}
                        session={session}
                      />

                      {!offer.sent
                        ? <ConfirmationModal
                          title={'Soll das Angebot ' + offer.id + ' wirklich entfernt werden?'}
                          resetParent={resetParent}
                          confirm={() => axios.delete(API_URL_OFFER + offer.id)}
                        />
                        : null}

                      {(offer.sent && !offer.accepted && !project.accepted_offer)
                        ? <AcceptProjectOfferModal
                          offer={offer}
                          project={project}
                          resetParent={resetParent}
                          session={session}
                        />
                        : null}

                      {(offer.sent && offer.accepted)
                        ? <ConfirmationModal
                          title={'Wurde die Annahme des Angebotes wirklich widerrufen?'}
                          confirm={() => axios.post(API_URL_OFFERWITHDRAWAL, { offer: offer.id })}
                          resetParent={resetParent}
                          btnLabel={'widerrufen'}
                          getOpenButton={(toggle) => (
                            <Tooltip title='widerrufen' PopperProps={{ style: { zIndex: 9999 } }} >
                              <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={toggle}>
                                <Icon icon="reverse" color="error" fontSize='large' />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                        />
                        : null}

                        {!offer.confirmed && offer.accepted &&
                          <ConfirmOfferModal
                            offer={offer}
                            project={project}
                            resetParent={resetParent}
                            session={session}
                          />
                        }

                        {/* {offer.confirmed && technicalApprovalRequired &&
                          <Tooltip title='Bestätigt' PopperProps={{ style: { zIndex: 9999 } }}>
                            <ThumbUpOffAltIcon color="success" style={{ float: 'right' }} fontSize='large' />
                          </Tooltip>
                        } */}

                        {/* {!offer.confirmed && offer.reviewed && technicalApprovalRequired &&
                        <ConfirmOfferModal
                          offer={offer}
                          project={project}
                          resetParent={resetParent}
                          session={session}
                        />
                      } */}

                        {/* {!offer.confirmed && offer.reviewed && technicalApprovalRequired &&
                          <ConfirmOfferModal
                            offer={offer}
                            project={project}
                            resetParent={resetParent}
                            session={session}
                          />
                        } */}

                        {/* {offer.reviewed &&
                          <Tooltip title='Bewertet' PopperProps={{ style: { zIndex: 9999 } }}>
                            <AssignmentTurnedInOutlinedIcon color="success" style={{ float: 'right' }} fontSize='large' />
                          </Tooltip>
                        }

                        {(!offer.reviewed && offer.accepted && technicalApprovalRequired && !hasPermission(session.user, technicalApprovalPermissionKey)) &&
                          <Tooltip title='Überprüfung' PopperProps={{ style: { zIndex: 9999 } }} >
                            <PendingActionsOutlinedIcon className='secondary-textcolor' fontSize='large' />
                          </Tooltip>
                        }

                        {(!offer.reviewed && offer.accepted && technicalApprovalRequired && hasPermission(session.user, technicalApprovalPermissionKey)) &&
                          <TechnicalApprovalModal
                            project={project}
                            offer={offer}
                            resetParent={resetParent}
                          />
                        } */}

                      {(offer.sent && offer.accepted)
                        ? <Tooltip title='Angenommen' PopperProps={{ style: { zIndex: 9999 } }} ><CheckIcon color="success" style={{ float: 'right' }} fontSize='large' /></Tooltip>
                        : null}

                      {!offer.sent
                        ? <SendOfferModal
                          offer={offer}
                          project={project}
                          resetParent={resetParent}
                          session={session}
                        />
                        : null}

                      {getExtraOfferActions(project, offer, session)}

                      {offer.sent
                        ? <Tooltip title='Gesendet' PopperProps={{ style: { zIndex: 9999 } }} ><MarkEmailReadOutlinedIcon color="success" style={{ float: 'right' }} fontSize='large'/></Tooltip>
                        : null}

                    </>
                      : null}

                  </td>
                </tr>
                ))
            )}
      </tbody>
    </Table>
    </Fragment>
  )
}

OfferList.propTypes = {
  project: projectPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  additionalDetails: PropTypes.object,
  setPlanning: PropTypes.func
}
