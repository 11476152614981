import React from 'react'
import { PropTypes } from 'prop-types'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Stack } from '@mui/material'

export default function CollapsibleArrow ({ isOpen, children }) {
  return <Stack direction="row" spacing={1} alignItems="center">
    {isOpen
      ? <KeyboardArrowUpIcon fontSize="small" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        size: 'small'
      }} />
      : <KeyboardArrowDownIcon fontSize="small" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }} />}
    {children}
  </Stack>
}

CollapsibleArrow.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node
}
