import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

import { Typography } from '@mui/material'

import PerspectiveTransformImg from './PerspectiveTransformImg'

export default function TransformImage ({ image, setTransformedImage, maxWidth, maxHeight }) {
  const maxImageWidth = maxWidth
  const maxImageHeight = maxHeight - 100
  return (
    <Fragment>
      <Typography fontSize='h5.fontSize' className='secondary-textcolor'> Bild entzerren </Typography>
      <Typography className='secondary-textcolor'> Zum Entzerren des Bildes die Ecken des roten Rechtecks auf dem Dach platzieren. </Typography>
      <br />
      <PerspectiveTransformImg image={image} onComplete={setTransformedImage} maxWidth={maxImageWidth} maxHeight={maxImageHeight} />
    </Fragment>
  )
}

TransformImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setTransformedImage: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number
}
