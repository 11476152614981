import React from 'react'
import { PropTypes } from 'prop-types'

import { Typography } from '@mui/material'

import CustomPlot from '../../elements/Plot'
import { arrange, numFormatter, SHORT_MONTHS } from '../../elements/utils'
import { DAPI_URL_REVENUESTATS } from '../../settings'
import StatsWrapper, { convert, getDatasets } from './StatsWrapper'

export default function RevenueStatistics ({ session }) {
  const renderStats = (data, resolution, year, projectType, salesmanComparison, projectComparison, nTotal, height, projectTypesList) => {
    const _convert = (data, getValue) => convert(data, getValue, salesmanComparison, projectComparison, nTotal, projectTypesList)

    const revenues = _convert(data, (x, mIdx) => x.revenues[mIdx])
    const revenueInvoices = _convert(data, (x, mIdx) => x.revenue_invoices[mIdx])
    const nOffers = _convert(data, (x, mIdx) => x.n_offers[mIdx])
    const nInvoicesSent = _convert(data, (x, mIdx) => x.n_invoices_sent[mIdx])

    let totalRevenue = 0
    let totalRevenueInvoices = 0

    if (data) {
      data.forEach(x => {
        totalRevenue += x.revenues.reduce((a, b) => a + b, 0)
        totalRevenueInvoices += x.revenue_invoices.reduce((a, b) => a + b, 0)
      })
    }

    const ticks = resolution === 'month' ? SHORT_MONTHS : arrange(nTotal).map(i => `W${i}`)

    return (
      <>
        {(projectType !== 'blankinvoiceproject') && !projectComparison
          ? <>
            <Typography className='secondary-textcolor'>Umsatz nach Angeboten in {year}: {numFormatter(totalRevenue)} €</Typography>
            <br />
            <CustomPlot title={'Umsatz nach Angeboten ' + year} xlabel={'Monat'} ylabel={'Umsatz [€]'} ticks={ticks}
              datasets={getDatasets(revenues, 'Umsatz', salesmanComparison, projectComparison)}
              additionalData={getDatasets(nOffers, 'Angeboten', salesmanComparison, projectComparison)}
              stacked={!(salesmanComparison || projectComparison)}
              type={'bar'} height={height} />
            <br />
            <br />
            <hr className='secondary-textcolor' />
          </>
          : null}
        <Typography className='secondary-textcolor'>Umsatz nach Rechnungen in {year}: {numFormatter(totalRevenueInvoices)} €</Typography>
        <br />
        <CustomPlot title={'Umsatz nach Rechnungen ' + year} xlabel={'Monat'} ylabel={'Umsatz [€]'} ticks={ticks}
          datasets={getDatasets(revenueInvoices, 'Umsatz', salesmanComparison, projectComparison)}
          additionalData={getDatasets(nInvoicesSent, 'Rechnungen', salesmanComparison, projectComparison)}
          stacked={!(salesmanComparison || projectComparison)}
          type={'bar'} height={height} />
        <br />
        <br />
        <hr className='secondary-textcolor' />
      </>
    )
  }

  return (
    <StatsWrapper
      url={DAPI_URL_REVENUESTATS}
      dataKeys={['revenues', 'n_offers', 'revenue_invoices', 'n_invoices_sent']}
      renderStats={renderStats}
      session={session}
    />
  )
}

RevenueStatistics.propTypes = {
  session: PropTypes.object
}
