import { checkIfValueIsEmpty, getEmptyFieldsError } from '../../../../elements/utils'
import { emptyProjectForm } from '../forms'

export const emptyEMeterForm = { id: null, value: null, e_meter_id: '', action: null, project: null }

export const checkIfPlantProjectFieldIsEmpty = (key, val, projectForm) => {
  if (key === 'accept_mandate_signature' || key === 'accept_mandate_document') {
    return checkIfValueIsEmpty(projectForm.accept_mandate_signature) && checkIfValueIsEmpty(projectForm.accept_mandate_document)
  }
  return checkIfValueIsEmpty(val)
}

export const additionalPlantProjectFieldCheck = (project) => {
  let emptyFieldsError = null
  project.emeter_set.forEach(emeterForm => {
    emptyFieldsError = emptyFieldsError || getEmptyFieldsError(emeterForm, emptyEMeterForm)
  })
  return emptyFieldsError
}

export const emptyPlantProjectForm = {
  ...emptyProjectForm,
  emeter_set: [],
  accept_mandate_signature: null,
  accept_mandate_document: null,
  resourcetype: 'PlantProject'
}
