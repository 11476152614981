import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Input, Form, FormGroup } from 'reactstrap'
import axios from 'axios'
import { HexColorPicker } from 'react-colorful'

import { Typography } from '@mui/material'

import { API_URL_USER, API_URL_TASKTYPE } from '../../settings'
import DropDown from '../../elements/DropDown'
import ConfirmationModalFooter from '../shared/modal_utils/ConfirmationModalFooter'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { taskTypePropType } from '../../elements/PropTypes'
import './styles.css'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { isValidForm } from '../../elements/utils'
import ErrorMessage from '../../elements/ErrorMessage'
import { toast } from 'react-toastify'

const emptyTaskTypeForm = {
  name: '',
  default_coordinator: null,
  color: '#346ec9'
}

const mandatoryFields = ['name']

export default function TaskTypeFormModal ({ taskType, isOpen, setIsOpen, getOpenButton, resetParent, session }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [employees, setEmployees] = useState([])
  const [taskTypeForm, setTaskTypeForm] = useState({ ...emptyTaskTypeForm })

  const [deletionModalIsOpen, setDeletionModalIsOpen] = useState(false)
  const [deletionLoadingElements, setDeletionLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
    else loadData()
  }

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false })
    setTaskTypeForm({ ...emptyTaskTypeForm })
    setEmployees([])
  }

  const loadData = () => {
    setTaskTypeForm({ ...taskType || emptyTaskTypeForm })
    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } })
      .then(res => { setEmployees(res.data) })
  }

  const submit = async (taskTypeForm) => {
    taskTypeForm = { ...taskTypeForm }
    return taskType
      ? axios.put(API_URL_TASKTYPE + taskType.id, taskTypeForm)
      : axios.post(API_URL_TASKTYPE, taskTypeForm)
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    if (!isValidForm(mandatoryFields, taskTypeForm)) {
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false, showMissingFields: true })
      toast.error(<ErrorMessage message={'Bitte alle Felder ausfüllen!'} />)
      return
    }
    return submit(taskTypeForm).then((res) => {
      if (resetParent) resetParent(res.data)
      if (onSuccess) onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "TaskType:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false, showMissingFields: true }) })
  }

  const onDelete = async (onSuccess) => {
    setDeletionLoadingElements({ ...deletionLoadingElements, inProgress: true, submitError: false })
    return axios.delete(API_URL_TASKTYPE + taskType.id).then((res) => {
      if (resetParent) resetParent(res.data)
      if (onSuccess) onSuccess()
      setDeletionLoadingElements({ ...deletionLoadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "TaskType:onDelete"', error, error.stack); setDeletionLoadingElements({ ...deletionLoadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return (
      <>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSubmit(toggle)}
          onCancel={toggle}
          onDelete={taskType ? () => { setDeletionModalIsOpen(true) } : null}
        />
        <CustomModal
          size="small" title={'Aufgaben-Typ wirklich löschen?'}
          isOpen={deletionModalIsOpen}
          setIsOpen={(isOpen) => { if (!isOpen) setDeletionModalIsOpen(false) }}
          getFooter={(toggleDelete) => getDeletionFooter(toggleDelete, toggle)}
          onClose={() => setDeletionModalIsOpen(false)}></CustomModal>
      </>
    )
  }

  const getDeletionFooter = (toggle, toogleAll) => {
    return (
      <ConfirmationModalFooter
        submitError={deletionLoadingElements.submitError}
        inProgress={deletionLoadingElements.inProgress}
        onConfirm={() => onDelete(() => { setDeletionModalIsOpen(false); setIsOpen(false) })}
        onCancel={() => setDeletionModalIsOpen(false)}
        btnLabel={'Löschen'}
      />

    )
  }

  const modalTitle = taskType ? taskType.name : 'Neuer Aufgaben-Typ'

  return (
    <CustomModal size="lg" isOpen={isOpen} setIsOpen={setIsOpen} getOpenButton={getOpenButton} title={modalTitle} getFooter={getFooter} onToggle={onToggle}>
      <Form>
        <FormGroup>
          <Typography className='secondary-textcolor'>Name:</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <Input
              id="input"
              placeholder="Name"
              type="text"
              width={'15px'}
              value={taskTypeForm.name}
              onChange={(e) => setTaskTypeForm((x) => ({ ...x, name: e.target.value }))}
              style={{ boxShadow: 'none' }} />
            {loadingElements.showMissingFields && !taskTypeForm.name.trim()
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>
        <FormGroup>
          <Typography className='secondary-textcolor'>Default-Koordinator:</Typography>
          <DropDown
            text={'Default-Koordinator'}
            onChange={(value) => setTaskTypeForm((x) => ({ ...x, default_coordinator: value }))}
            options={employees.map(e => ({ label: `${e.first_name} ${e.last_name}`, value: e.id, hidden: !e.is_active }))}
            value={taskTypeForm.default_coordinator}
            sort={true}
            search={true}
            noOptionsText={'Keine Mitarbeiter'}
          // onInputChange={(input, reason) => { if (reason === "input") setProjectSearchInput(input) }}
          />
        </FormGroup>
        <FormGroup>
          <HexColorPicker color={taskTypeForm.color} onChange={value => setTaskTypeForm(t => ({ ...t, color: value }))} />
        </FormGroup>
      </Form>
    </CustomModal >
  )
}

TaskTypeFormModal.propTypes = {
  taskType: taskTypePropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
