import React from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import { API_URL_SENDDATAREQUEST } from '../../../../settings'
import { CustomButton } from '../../../../elements/StyledElements'
import { getCustomerName } from '../../../../elements/utils'
import EmailModal from '../../../shared/modal_utils/EmailModal'
import { projectPropType } from '../../../../elements/PropTypes'

export default function DataInputRequestModal ({ project, resetParent, session }) {
  const submit = (subject, message) => {
    return axios.post(API_URL_SENDDATAREQUEST, { project: project.id, mail: { subject, message } })
  }

  const skip = () => {
    return axios.post(API_URL_SENDDATAREQUEST, { project: project.id })
  }

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle} icon="email">Datenanfrage</CustomButton>
  }

  return (
        <EmailModal
            customer={project.customer_obj}
            title={'Datenanfrage an ' + getCustomerName(project.customer_obj)}
            subject={'Daten für Ihre PV-Anlage ' + project.street_and_number_project}
            getEmailMessage={() => axios.get(API_URL_SENDDATAREQUEST, { params: { project: project.id } })}
            submit={submit}
            skip={skip}
            resetParent={resetParent}
            getModalOpenButton={getOpenButton}
            session={session}
        />
  )
}

DataInputRequestModal.propTypes = {
  project: projectPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
