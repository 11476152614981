import React from 'react'

import { Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingPage () {
  return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
  >
    <CircularProgress color="inherit" />
    &nbsp;&nbsp;
    <Typography fontSize='h4.fontSize' className='primary-textcolor'>Wird geladen...</Typography>

  </Backdrop>
}
