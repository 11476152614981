import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { IconButton, Tooltip } from '@mui/material'

import { offerPropType, projectPropType } from '../../elements/PropTypes'
import { CustomButton } from '../../elements/StyledElements'
import { filterObj, getErrorMessage, modifyExtraCharge, sortByOrder, sortByPriority } from '../../elements/utils'
import {
  API_URL_PLANNING,
  API_URL_CREATEOFFER,
  API_URL_CUSTOMPRODUCT,
  API_URL_OFFER,
  API_URL_POSTCREATEOFFER,
  API_URL_PRODUCT,
  API_URL_PRODUCTGROUP, API_URL_ROOF_PROJECT_CUSTOMPRODUCT_LINE_MAPPING,
  API_URL_ROOF_PROJECT_CUSTOMPRODUCT_LINE_MAPPING_BULK
} from '../../settings'
import { getDefaultExtraCharge, getProductExtraCostKeys } from '../project_types/projectUtils'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import OfferForm from './OfferForm'
import { toast } from 'react-toastify'

const emptyOfferForm = {
  id: null,
  project: null,
  planning: null,
  sent: false,
  accepted: false,
  extra_charge: 0,
  total_gross: null,
  total_net: null,
  vat: false
}

export default function OfferFormModal ({ project, offer, planningId = null, resetParent, getOpenButton, isOpen, setIsOpen = null, onToggle, session, additionalDetails, setPlanning }) {
  const [offerForm, setOfferForm] = useState({ ...emptyOfferForm })
  const [customProductForms, setCustomProductForms] = useState([])
  const [amountCols, setAmountCols] = useState({ plannedAmountCol: false, realAmountCol: false, offerAmountCol: false, invoiceAmountCol: false })
  const [products, setProducts] = useState(null)
  const [productGroups, setProductsGroup] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })
  const [lineMappings, setLineMappings] = useState({})
  const isRoofProject = project.resourcetype === 'RoofProject'
  const roofProjectProps =
    isRoofProject ? { lineMappings, setLineMappings } : {}

  const productKeysForExtraCosts = getProductExtraCostKeys(project)
  const defaultExtraCharge = getDefaultExtraCharge(project, session)

  const _onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
    if (onToggle) onToggle(isOpen)
  }

  const clearData = () => {
    setOfferForm({ ...emptyOfferForm })
    setLoadingElements({ inProgress: false, submitError: false })
    setCustomProductForms([])
    setProducts(null)
  }

  const resetProducts = async () => {
    return axios.get(API_URL_PRODUCT, { params: { project: project.id, planning: planningId || null } })
      .then((res) => {
        const products = sortByPriority(res.data)
        setProducts(products)
        const amountCols = {
          plannedAmountCol: res.headers.planned_amounts === 'True',
          realAmountCol: res.headers.real_amounts === 'True',
          offerAmountCol: res.headers.offer_amounts === 'True',
          invoiceAmountCol: res.headers.invoice_amounts === 'True'
        }
        setAmountCols(amountCols)
        return axios.get(API_URL_PRODUCTGROUP)
          .then((res) => {
            const productGroups = res.data
            setProductsGroup(productGroups)
            return [products, amountCols, productGroups]
          })
      })
  }

  const loadPlanningData = () => {
    if (!planningId) {
      return new Promise((resolve, reject) => resolve(false))
    }

    return axios.get(API_URL_PLANNING + planningId, { params: { nested: true } })
      .then((res) => {
        const planning = res.data

        if (project.customer_obj.company !== null) {
          return true
        } else if ((planning.tenant_system === false && planning.kwp >= 30) || (planning.tenant_system === true && (planning.kwp / planning.n_tenants) >= 15)) {
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        console.error('Error loading planning data:', error)
        return false
      })
  }

  useEffect(() => {
    if (!offer) return
    const loadLineMappings = async () => {
      const res = await axios.get(API_URL_ROOF_PROJECT_CUSTOMPRODUCT_LINE_MAPPING, { params: { offer: offer.id } })
      const mappings = res.data
      const newLineMappings = {}
      for (const mapping of mappings) {
        const cp = customProductForms.find(cp => cp.id === mapping.custom_product)
        if (!cp) continue
        if (cp.product in newLineMappings) {
          newLineMappings[cp.product].push(mapping.line)
        } else {
          newLineMappings[cp.product] = [mapping.line]
        }
      }
      setLineMappings(newLineMappings)
    }
    loadLineMappings()
  }, [customProductForms])

  const loadData = () => {
    loadPlanningData()
      .then((isVat) => {
        setOfferForm(offer || { ...emptyOfferForm, project: project.id, planning: planningId, extra_charge: defaultExtraCharge, vat: isVat })
        if (offer) {
          resetProducts()
            .then(([products, amountCols]) => {
              axios.get(API_URL_CUSTOMPRODUCT, { params: { offer: offer.id } }).then(res => {
                setCustomProductForms(res.data.map((customProduct, _idx) => {
                  return {
                    id: customProduct.id,
                    name: customProduct.name,
                    product: customProduct.product,
                    price: customProduct.price,
                    amount: customProduct.amount,
                    vat: customProduct.vat,
                    description: customProduct.description,
                    order: customProduct.order ? customProduct.order : _idx + 1,
                    priority: customProduct.priority ? customProduct.priority : null,
                    productObj: products.find(p => p.id === customProduct.product)
                  }
                }))
              })
            })
        } else {
          const _setCustomProductForms = (customProducts, prevExtraCharge, extraCharge) => {
            setCustomProductForms(modifyExtraCharge(customProducts, productKeysForExtraCosts, prevExtraCharge, extraCharge))
          }
          resetProducts()
            .then(([products, amountCols]) => {
              const customProductForms = products
                .filter(p => showProduct(p, amountCols))
                .map((product, _idx) => (
                  {
                    id: null,
                    name: product.name,
                    product: product.id,
                    price: product.price,
                    amount: getAmount(product, amountCols),
                    vat: product.vat,
                    description: product.description,
                    order: product.order ? product.order : _idx + 1,
                    priority: product.priority ? product.priority : null,
                    productObj: product
                  }
                ))

              const sortedOffers = project.baseoffer_set
                .filter(offer => offer.planning === planningId)
                .sort((a, b) => {
                  const aDateTime = new Date(`${a.registration_date}T${a.registration_time}`)
                  const bDateTime = new Date(`${b.registration_date}T${b.registration_time}`)
                  return bDateTime - aDateTime
                })

              if (sortedOffers.length > 0) {
                const latestOffer = sortedOffers[0]
                axios.get(API_URL_CUSTOMPRODUCT, { params: { offer: latestOffer.id } })
                  .then(res => {
                    const latestCustomProducts = res.data
                    latestCustomProducts.forEach(customProduct => {
                      const matchIdx = customProductForms.findIndex(p => p.productObj.id === customProduct.product)
                      if (matchIdx !== -1) {
                        const productObj = customProductForms[matchIdx].productObj

                        if (productObj.key === 'pv_module') {
                          customProductForms[matchIdx].name = productObj.name
                          customProductForms[matchIdx].description = productObj.description
                          customProductForms[matchIdx].price = productObj.price
                        } else {
                          customProductForms[matchIdx].description = customProduct.description
                        }
                      }
                    })

                    _setCustomProductForms(customProductForms, 0, session.user.default_extra_charge)
                  })
              } else {
                _setCustomProductForms(customProductForms, 0, session.user.default_extra_charge)
              }
            })
        }
      })
  }

  const getAmount = (product, amountCols) => {
    const amount = amountCols.plannedAmountCol
      ? product.amount_planned
      : 0
    return Math.max(amount, 0)
  }

  const showProduct = (product, amountCols) =>
    (amountCols.plannedAmountCol && product.amount_planned !== 0) ||
    (amountCols.invoiceAmountCol && product.amount_invoices !== 0) ||
    (amountCols.realAmountCol && product.amount_real !== 0) ||
    (amountCols.offerAmountCol && product.amount_offer !== 0)

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    if (offer) {
      return (
      <Tooltip title='Bearbeiten' PopperProps={{ style: { zIndex: 9999 } }} >
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small"
                  onClick={
        (state) => {
          if (setPlanning) setPlanning(project.planning_set.find(p => p.id === planningId))
          toggle(state)
        }
      }>
        <EditOutlinedIcon className='secondary-textcolor' fontSize='large' />
      </IconButton>
      </Tooltip>
      )
    }
    return <CustomButton onClick={toggle}> Neues Angebot </CustomButton>
  }

  const submit = async (offerForm) => {
    // remove pdf as pdf is handled by backend
    const offerFormToSubmit = filterObj(offerForm, Object.keys(emptyOfferForm))
    const promise = offer ? axios.put(API_URL_OFFER + offerForm.id, { ...offerFormToSubmit, total_gross: null, total_net: null }) : axios.post(API_URL_OFFER, offerFormToSubmit)
    return promise.then(async (res) => {
      const offerResponse = res.data
      const offerId = res.data.id
      const customProductPromise = offer
        ? axios.delete(API_URL_CUSTOMPRODUCT, { data: { offer: offerId, except_ids: customProductForms.filter(x => x.id).map(x => x.id) } })
        : Promise.resolve()
      return customProductPromise
        .then(async () => Promise.all(customProductForms.map(customProduct => {
          customProduct.offer = offerId
          if (customProduct.id) { return (customProduct.amount) ? axios.put(API_URL_CUSTOMPRODUCT + customProduct.id, customProduct) : axios.delete(API_URL_CUSTOMPRODUCT + customProduct.id) }
          return (customProduct.amount) ? axios.post(API_URL_CUSTOMPRODUCT, customProduct) : new Promise((resolve) => { resolve() })
        })))
        .then(async () => {
          if (!isRoofProject) return Promise.resolve()
          await axios.delete(API_URL_ROOF_PROJECT_CUSTOMPRODUCT_LINE_MAPPING, { params: { offer: offerId } })

          const customProducts = await axios.get(API_URL_CUSTOMPRODUCT, { params: { offer: offerId } })
          if (!customProducts.data) return
          const req = []

          Object.keys(lineMappings).forEach(key => {
            const pId = key.toString()
            const cp = customProducts.data.find(p => p.product.toString() === pId)
            if (cp) {
              for (const line of lineMappings[key]) {
                req.push([cp.id, line, offerId])
              }
            }
          })

          await axios.post(API_URL_ROOF_PROJECT_CUSTOMPRODUCT_LINE_MAPPING_BULK, req)
          return Promise.resolve()
        })
        .then(async () => {
          return axios.post(API_URL_CREATEOFFER, { offer: offerId })
            .then(res => {
              axios.post(API_URL_POSTCREATEOFFER, { offer: offerId })
              return res
            })
        }
        ).then(() => offerResponse)
    })
  }

  const transformLineMapping = () => {
    const keys = Object.keys(lineMappings)
    const newLineMappings = {}
    keys.forEach(key => {
      const val = structuredClone(lineMappings[keys])

      const custProduct = customProductForms.find(x => {
        const cpId = x.id ? x.id.toString() : null
        const pId = x.product ? x.product.toString() : null

        return (cpId && cpId === key.toString()) || (pId && pId === key.toString())
      })

      const id = custProduct ? custProduct.id : 0

      newLineMappings[id] = val
    })
    setLineMappings(newLineMappings)
  }

  const onSubmit = async (onSuccess) => {
    if (isRoofProject) transformLineMapping()
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false })
    return submit(offerForm)
      .then((res) => {
        resetParent(res.data)
        onSuccess()
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      })
      .catch(error => {
        console.error('Error in OfferFormModal: ', error, error.stack)
        toast.error(getErrorMessage(error))
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
      })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
      />)
  }

  return (<CustomModal size='fullscreen' isOpen={isOpen} setIsOpen={setIsOpen} getOpenButton={_getOpenButton} title={offer ? ('Angebot ' + offer.id) : 'Neues Angebot'} getFooter={getFooter} onToggle={_onToggle}>
    <OfferForm
      offer={offerForm}
      setOffer={setOfferForm}
      customProducts={sortByOrder(customProductForms)}
      setCustomProducts={setCustomProductForms}
      project={project}
      resetProducts={resetProducts}
      products={products}
      productGroups={productGroups}
      productKeysForExtraCosts={productKeysForExtraCosts}
      amountCols={amountCols}
      session={session}
      planningId={planningId}
      {...roofProjectProps}
    />
    {additionalDetails}
  </CustomModal>)
}

OfferFormModal.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  planningId: PropTypes.number,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onToggle: PropTypes.func,
  session: PropTypes.object,
  additionalDetails: PropTypes.object,
  setPlanning: PropTypes.func
}
