import React, { useState, useEffect } from 'react'
import { Table } from 'reactstrap'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'
import { isMobileOnly } from 'react-device-detect'

import InverterFormModal from './InverterFormModal'
import { API_URL_INVERTER, PROTOCOL } from '../../../settings'
import ConfirmationModal from '../../shared/modal_utils/ConfirmationModal'
import PdfViewModal from '../../shared/modal_utils/PdfViewModal'
import CustomModal from '../../shared/modal_utils/CustomModal'
import { CustomButton, CustomIconButton } from '../../../elements/StyledElements'

export default function InverterList ({ session }) {
  const [inverters, setInverters] = useState([])
  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Wechselrichterliste bearbeiten</CustomButton>
  }

  const loadData = () => {
    axios.get(API_URL_INVERTER)
      .then(res => {
        setInverters(res.data)
      })
      .catch(error => console.error('Error loading Wechselrichter:', error))
  }

  useEffect(() => {
    loadData()
  }, [setInverters])

  return (
    <CustomModal title='Wechselrichterliste bearbeiten' getOpenButton={getOpenButton} size='fullscreen'>
      <Table >
        <thead>
          <tr>
            <th className="align-middle"><Typography className='secondary-textcolor'>Wechselrichter-Nr.</Typography></th>
            <th className="align-middle"><Typography className='secondary-textcolor'>Name</Typography> </th>
            <th className="align-middle"><Typography className='secondary-textcolor'>kWp</Typography> </th>
            <th className="align-middle" style={{ textAlign: 'center' }}><Typography className='secondary-textcolor'>Aktion</Typography></th>
          </tr>
        </thead>
        <tbody>
          {!inverters || inverters.length <= 0
            ? (
              <tr>
                <td colSpan="4" align="center">
                  <Typography className='secondary-textcolor'> Keine Planung vorhanden </Typography>
                </td>
              </tr>
              )
            : (
                inverters.map(inverter => {
                  return (
                    <tr key={inverter.id}>
                      <td className="align-middle"><Typography className={'secondary-textcolor'}>{inverter.id}</Typography></td>
                      <td className="align-middle"><Typography className={'secondary-textcolor'}>{inverter.name}</Typography></td>
                      <td className="align-middle" align="center"><Typography className={'secondary-textcolor'}>{inverter.power}</Typography></td>
                      <td className="align-middle" align="center">
                        <Stack direction="row" justifyContent="end">
                          {inverter.certificate
                            ? <>
                              &nbsp;
                              <PdfViewModal
                                key='view-plant-modal'
                                title={isMobileOnly ? 'Wechselrichter' : 'Wechselrichter ' + inverter.name}
                                filepath={(inverter.certificate) ? inverter.certificate.replace('http:', PROTOCOL + ':') : null} />
                            </>
                            : null}
                          {inverter.editable
                            ? <>
                              &nbsp;
                              <InverterFormModal
                                inverter={inverter}
                                getOpenButton={(toggle) => <CustomIconButton icon='edit' onClick={toggle} size='large' description='Bearbeiten' title='Bearbeiten'/>}
                                resetParent={loadData}
                                session={session}
                              />
                              <ConfirmationModal title={'Soll die Wechselrichter wirklich gelöscht werden?'}
                                confirm={() => axios.delete(API_URL_INVERTER + inverter.id)}
                                getOpenButton={(toggle) => <CustomIconButton icon='delete' onClick={toggle} size='large' description='Löschen' title='Löschen'/>}
                                resetParent={loadData} />
                            </>
                            : null}
                        </Stack>
                      </td>
                    </tr>
                  )
                })
              )}
        </tbody>
        <br/>
        <br/>
        <InverterFormModal
          resetParent={loadData}
          session={session}
        />
      </Table>
    </CustomModal>
  )
}

InverterList.propTypes = {
  resetParent: PropTypes.func,
  session: PropTypes.object
}
