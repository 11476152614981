import React, { Fragment, useState } from 'react'
import axios from 'axios'
import { API_URL_PASSWORDUPDATE, API_URL_USER } from '../settings'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@mui/material/Button'
import { Container, TextField, Typography } from '@material-ui/core'
import { PropTypes } from 'prop-types'

const useStyles = makeStyles((theme) => ({

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  input: {
    color: '#424242'
  },
  inputfocused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#424242'
    }
  }
}))

export default function UserInfo ({ session }) {
  const classes = useStyles()
  const [newPassword1, setNewPassword1] = useState(null)
  const [newPassword2, setNewPassword2] = useState(null)
  const [newPhone, setNewPhone] = useState(session.user.phone)
  const [newExtraCharge, setNewExtraCharge] = useState(session.user.default_extra_charge)
  const [success, setSuccess] = useState(false)
  const [phoneSuccess, setPhoneSuccess] = useState(false)
  const [extraChargeSuccess, setExtraChargeSuccess] = useState(false)
  const [errorMsg, setError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [extraChargeError, setExtraChargeError] = useState(null)

  const handleFormFieldChange = (event) => {
    setSuccess(false)
    setPhoneSuccess(false)
    setExtraChargeSuccess(false)
    setPhoneError(null)
    setExtraChargeError(null)
    setError(null)
    switch (event.target.id) {
      case 'new_password1': setNewPassword1(event.target.value); break
      case 'new_password2': setNewPassword2(event.target.value); break
      case 'new_phone': setNewPhone(event.target.value); break
      case 'new_extra_charge': setNewExtraCharge(event.target.value); break
      default: return null
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (newPassword1 !== newPassword2) {
      setError('Die Passwörter stimmen nicht überein!')
    } else {
      const headers = { Authorization: `Token ${session.token}` }
      const method = 'post'
      const url = API_URL_PASSWORDUPDATE
      const passwordFormData = new FormData()
      passwordFormData.append('new_password1', newPassword1)
      passwordFormData.append('new_password2', newPassword2)
      const config = { headers, method, url, data: passwordFormData }
      // Axios update_password API call
      axios(config).then(res => {
        setSuccess(true)
      }).catch(
        error => {
          setError(error.response.data.new_password2[0])
        })
    }
  }

  const handlePhoneSubmit = (e) => {
    e.preventDefault()
    if (newPhone === '') {
      setPhoneError('Die Telefonnummer ist ungültig!')
    } else {
      const headers = { Authorization: `Token ${session.token}` }
      const method = 'put'
      const url = API_URL_USER + session.user.id
      const phoneFormData = new FormData()
      phoneFormData.append('phone', newPhone)
      const config = { headers, method, url, data: phoneFormData }
      // Axios update_password API call
      axios(config).then(res => {
        setPhoneSuccess(true)
      }).then(session.resetUser).catch(
        error => {
          setPhoneError(error.response.data.new_phone[0])
        })
    }
  }

  const handleExtraChargeSubmit = (e) => {
    e.preventDefault()
    if (newExtraCharge === '') {
      setExtraChargeError('Der Wert ist ungültig!')
    } else {
      const headers = { Authorization: `Token ${session.token}` }
      const method = 'put'
      const url = API_URL_USER + session.user.id
      const extraChargeFormData = new FormData()
      extraChargeFormData.append('default_extra_charge', newExtraCharge)
      const config = { headers, method, url, data: extraChargeFormData }
      // Axios update_password API call
      axios(config).then(res => {
        setExtraChargeSuccess(true)
      }).then(session.resetUser).catch(
        error => {
          setExtraChargeError(error.response.data.new_extra_charge[0])
        })
    }
  }

  return (
    <Fragment>
      <br />
      <Container component="main" maxWidth="xs">
        {/* <div className={classes.paper}> */}
          {session.user.is_staff
            ? <>
              {phoneSuccess ? <Typography variant="button" className={classes.success} gutterBottom>Änderung erfolgreich!</Typography> : null}
              <Typography className='secondary-textcolor' component="h1" fontSize='h5.fontSize'>
                Telefonnummer ändern
              </Typography>
              <form className={classes.form} noValidate onSubmit={handlePhoneSubmit}>
                <TextField
                  className={classes.inputfocused}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{ style: { color: '#424242' } }}
                  name="new_phone"
                  label="Telefonnummer"
                  type="text"
                  id="new_phone"
                  onChange={handleFormFieldChange}
                  error={newPhone === ''}
                  helperText={newPhone === '' ? 'Telefonnummer ungültig!' : null}
                  defaultValue={session.user.phone}
                />
                <div className="d-grid gap-2">
                  {phoneError ? <Typography variant="button" className={classes.error} gutterBottom> {phoneError} </Typography> : null}
                  <br />
                  <Button
                    type="submit"
                    variant="outlined"
                    style={{ color: '#424242', borderColor: '#424242' }}
                    className={classes.submit}
                  >
                    Telefonnummer ändern
                  </Button>
                </div>
              </form>
              <div>
                <br />
                <br />
                <br />
              </div>
              {extraChargeSuccess ? <Typography variant="button" className={classes.success} gutterBottom>Änderung erfolgreich!</Typography> : null}
              <Typography className='secondary-textcolor' component="h1" fontSize='h5.fontSize'>
                Preisaufschlag ändern
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleExtraChargeSubmit}>
                <TextField
                  className={classes.inputfocused}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{ style: { color: '#424242' } }}
                  name="new_extra_charge"
                  label="Preisaufschlag"
                  type="text"
                  id="new_extra_charge"
                  onChange={handleFormFieldChange}
                  error={newExtraCharge === ''}
                  helperText={(newExtraCharge === '') ? 'Wert ungültig!' : null}
                  defaultValue={session.user.default_extra_charge}
                />
                <div className="d-grid gap-2">
                  {phoneError ? <Typography variant="button" className={classes.error} gutterBottom> {extraChargeError} </Typography> : null}
                  <br />
                  <Button
                    type="submit"
                    variant="outlined"
                    style={{ color: '#424242', borderColor: '#424242' }}
                    className={classes.submit}
                  >
                    Preisaufschlag ändern
                  </Button>
                </div>
              </form>
              <div>
                <br />
                <br />
                <br />
              </div>
            </>
            : null}
          {success ? <Typography variant="button" className={classes.success} gutterBottom>Änderung erfolgreich!</Typography> : null}
          <Typography className='secondary-textcolor' component="h1" fontSize='h5.fontSize'>
            Passwort ändern
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              className={classes.inputfocused}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              InputProps={{ className: classes.input }}
              InputLabelProps={{ style: { color: '#424242' } }}
              name="new_password1"
              label="Neues Passwort"
              type="password"
              id="new_password1"
              onChange={handleFormFieldChange}
              error={newPassword1 !== newPassword2}
              helperText={newPassword1 !== newPassword2 ? 'Passwörter stimmen nicht überein!' : null}
            />
            <TextField
              className={classes.inputfocused}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              InputProps={{ className: classes.input }}
              InputLabelProps={{ style: { color: '#424242' } }}
              name="new_password2"
              label="Erneut neues Passwort"
              type="password"
              id="new_password2"
              onChange={handleFormFieldChange}
              error={newPassword1 !== newPassword2}
              helperText={newPassword1 !== newPassword2 ? 'Passwörter stimmen nicht überein!' : null}
            />
            <div className="d-grid gap-2">
              {errorMsg ? <Typography variant="button" className={classes.error} gutterBottom> {errorMsg} </Typography> : null}
              <br />
              <Button
                type="submit"
                variant="outlined"
                style={{ color: '#424242', borderColor: '#424242' }}
                className={classes.submit}
              >
                Passwort ändern
              </Button>
            </div>
          </form>
        {/* </div> */}
      </Container>
      <br />
      <br />
      <br />
    </Fragment>
  )
}

UserInfo.propTypes = {
  session: PropTypes.object
}
