import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'react-bootstrap'
import { Col, Container, Row } from 'reactstrap'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import Add from '@mui/icons-material/Add'
import { Stack, Typography, IconButton } from '@mui/material'
import ConstructionTimeManagementModal from './ConstructionTimeManagementModal'
import TaskFormModal from '../tasks/TaskFormModal'

import '../shared/calendarStyle.css'
import CustomModal from '../shared/modal_utils/CustomModal'
import { date2String, date2FormattedString, isString, getCalendarTaskColor } from '../../elements/utils'
import { taskPropType, constructionPropType, userPropType } from '../../elements/PropTypes'
import DayOffEmployeeModal from './DayOffEmployeeModal'
import EmployeeAssignmentModal from './EmployeeAssignmentModal'
import TasksBacklogModal from './TasksBacklogModal'
import { getConstructionListFields, getConstructionName } from '../project_types/projectUtils'
import { Link } from 'react-router-dom'

export default function ConstructionDate ({ constructions, employees, tasks, date, isOpen, setIsOpen, resetParent, session, filter }) {
  const [chosenDate, setChosenDate] = useState(null)
  const [chosenTaskDate, setChosenTaskDate] = useState(null)
  const [chosenTask, setChosenTask] = useState(null)
  const [filteredConstructionsArray, setFilteredConstructionsArray] = useState([])
  const [filteredTasksArray, setFilteredTasksArray] = useState([])

  useEffect(() => {
    const formattedDate = date2String(new Date(date))
    const filteredTasks = tasks.filter(task => task.taskdates_set.some(taskDate => taskDate.date === formattedDate))
    setFilteredTasksArray(filteredTasks)
  }, [tasks, date])

  useEffect(() => {
    const formattedDate = date2String(new Date(date))
    const filteredConstructions = constructions.filter(c => c.constructiondates_set.some(constructionDate => constructionDate.date === formattedDate))
    setFilteredConstructionsArray(filteredConstructions)
  }, [constructions, date])

  const groupedConstructions = filteredConstructionsArray.reduce(function (r, c) {
    r[c.resourcetype] = r[c.resourcetype] || []
    r[c.resourcetype].push(c)
    return r
  }, {})
  const title = date ? 'Bautermin am ' + date2FormattedString(date) : ''

  return (
    <CustomModal size="xl" isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
      <Stack spacing={1} direction="row">
        <EmployeeAssignmentModal
          constructions={filteredConstructionsArray}
          employees={employees}
          tasks={filteredTasksArray}
          date={date}
          resetParent={resetParent}
          session={session}
          filter={filter}
        />
        <DayOffEmployeeModal date={date} isOpen={isOpen} employees={employees} />
      </Stack>

      <br />
      <Container>
        <Row>
          <Col>
            <Row>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', overlfow: 'hidden' }}>
                <div>
                  <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
                    Baustellen
                  </Typography>
                </div>
                <div style={{ alignSelf: 'flex-end' }}>
                  <IconButton size="small" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={() => setChosenDate(date)}>
                    <Add style={{ color: '#424242' }} fontSize='small' /><Typography display="inline" className='secondary-textcolor'>Baustelle hinzufügen</Typography>
                  </IconButton>
                </div>
              </div>
            </Row>
            <br />
            <Row>
              <Col sm="10" md={{ size: 12, offset: -1 }}>
                {filteredConstructionsArray.length > 0
                  ? null
                  : <Table className={!filteredConstructionsArray || filteredConstructionsArray.length <= 0 ? 'table-not-hover' : 'table-hover'}>
                    <thead>
                      <tr>
                        <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Kunde</Typography></th>
                        <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Ort</Typography></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td colSpan="5" align="center">
                          <Typography className='secondary-textcolor'> Keine Baustellen </Typography>
                        </td>
                      </tr>

                    </tbody>
                  </Table>}
                {Object.entries(groupedConstructions).map(([resourcetype, rConstructions]) => {
                  const fields = getConstructionListFields(resourcetype)
                  return <Fragment key={`construction-table-${resourcetype.toLowerCase()}`}>
                    <div className="text-center">
                      <Typography fontWeight='bold' className='secondary-textcolor'>
                        {getConstructionName(resourcetype)}
                      </Typography>
                    </div>
                    <Table className={'table-hover'}>
                      <thead>
                        <tr>
                          <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Kunde</Typography></th>
                          <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Ort</Typography></th>
                          {fields.map((f, fIdx) => <th key={`construction-table-${resourcetype.toLowerCase()}-header-${fIdx}`} className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>{f.name}</Typography></th>)}
                        </tr>
                      </thead>

                      <tbody>
                        {
                          rConstructions.map((construction, cIdx) => (
                            <tr key={`construction-row-${construction.id}`}>
                              <td className="align-middle" style={{ textAlign: 'left' }}>
                                <Link to={'/projekt/' + construction.project + '/bauplanung'} style={{ textDecoration: 'none' }}>
                                  <Typography className='secondary-textcolor'>{construction.customer_name}</Typography>
                                </Link>
                              </td>
                              <td className="align-middle" style={{ textAlign: 'left' }}>
                                <Link to={'/projekt/' + construction.project + '/bauplanung'} style={{ textDecoration: 'none' }}>
                                  <Typography className='secondary-textcolor'>{construction.zip_and_city}</Typography>
                                </Link>
                              </td>
                              {fields.map((f, fIdx) => <td key={`construction-table-${resourcetype.toLowerCase()}-row-${cIdx}-col-${fIdx}`} className="align-middle" style={{ textAlign: 'left' }}>
                                  <Link to={'/projekt/' + construction.project + '/bauplanung'} style={{ textDecoration: 'none' }}>
                                    <Typography className='secondary-textcolor'>{isString(f.key) ? construction[f.key] : f.key(construction)}</Typography>
                                  </Link>
                                </td>
                              )}
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Fragment>
                })}
              </Col>
            </Row>
            <br />
            <Row>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', overlfow: 'hidden' }}>
                <div></div>
                <div>
                  <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
                    Aufgaben
                  </Typography>
                </div>
                <div style={{ alignSelf: 'flex-end' }}>
                  <IconButton size="small" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={() => setChosenTaskDate(date)}>
                    <Add style={{ color: '#424242' }} fontSize='small' /><Typography className='secondary-textcolor'>Aufgabe hinzufügen</Typography>
                  </IconButton>
                </div>
              </div>
            </Row>
            <Row>
              <Col sm="10" md={{ size: 12, offset: -1 }}>
                <Table className={!filteredConstructionsArray || filteredConstructionsArray.length <= 0 ? 'table-not-hover' : 'table-hover'}>
                  <thead>
                    <tr>
                      <th className="align-middle" style={{ textAlign: 'left' }}></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Kunde</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Titel</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Startzeit</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Mitarbeiter</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Erstellt von</Typography></th>
                    </tr>
                  </thead>

                  <tbody>
                    {((!filteredTasksArray || filteredTasksArray.length <= 0))
                      ? (
                        <tr>
                          <td colSpan="5" align="center">
                            <Typography className='secondary-textcolor'> Keine Aufgaben </Typography>
                          </td>
                        </tr>
                        )
                      : (
                        <>
                          {
                            filteredTasksArray.map((task) => (
                              <tr key={`task-row-${task.id}`} style={{ cursor: 'pointer', ...(task.finished ? { backgroundColor: '#D3D3D3' } : {}) }}>
                                <td className="align-middle" style={{ textAlign: 'left' }} onClick={() => setChosenTask(task)}>
                                  <span style={{ backgroundColor: getCalendarTaskColor(task), borderRadius: '50%', width: 25, height: 25, display: 'inline-block' }} />
                                </td>
                                <td className="align-middle" style={{ textAlign: 'left' }} onClick={() => setChosenTask(task)}>
                                  {
                                    task.customer && (<Link to={'/kunde/' + task.customer} style={{ textDecoration: 'none' }}>
                                      <div style={{
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        borderRadius: '4px',
                                        padding: '5px 15px',
                                        border: '1px solid #424242'
                                      }}>
                                        <Typography className='secondary-textcolor'>{task.customer_name} </Typography>
                                      </div>
                                    </Link>)
                                  }
                                </td>
                                <td className="align-middle" style={{ textAlign: 'left' }} onClick={() => setChosenTask(task)}><Typography className='secondary-textcolor'>{task.title}</Typography></td>
                                <td className="align-middle" style={{ textAlign: 'left' }} onClick={() => setChosenTask(task)}><Typography className='secondary-textcolor'>{task.start_time}</Typography></td>
                                <td className="align-middle" style={{ textAlign: 'left' }} onClick={() => setChosenTask(task)}><Typography className='secondary-textcolor'>
                                  {task.employee_names.join(', ')}
                                </Typography></td>
                                <td className="align-middle" style={{ textAlign: 'left' }} onClick={() => setChosenTask(task)}><Typography className='secondary-textcolor'>{task.registered_by_name}</Typography></td>
                              </tr>
                            ))
                          }
                        </>
                        )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div>
        <ConstructionTimeManagementModal
          chosenDate={chosenDate}
          isOpen={!!chosenDate}
          setIsOpen={(isOpen) => { if (!isOpen) setChosenDate(null) }}
          resetParent={resetParent}
          session={session}
        />
        <TasksBacklogModal
          isOpen={!!chosenTaskDate}
          setIsOpen={(isOpen) => { if (!isOpen) setChosenTaskDate(null) }}
          session={session}
          resetParent={resetParent}
          date={chosenTaskDate}
        />
        <TaskFormModal
          task={chosenTask}
          isOpen={!!chosenTask}
          setIsOpen={(isOpen) => { if (!isOpen) { setChosenTask(null) } }}
          resetParent={resetParent}
          session={session}
        />
      </div>
    </CustomModal>
  )
}

ConstructionDate.propTypes = {
  constructions: PropTypes.arrayOf(constructionPropType),
  employees: PropTypes.arrayOf(userPropType),
  tasks: PropTypes.arrayOf(taskPropType),
  date: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  filter: PropTypes.object
}
