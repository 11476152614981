import React, { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Input } from 'reactstrap'
import { Table } from 'react-bootstrap'

import { Typography } from '@mui/material'

import CustomModal from '../shared/modal_utils/CustomModal'
import { CustomButton } from '../../elements/StyledElements'
import { checkForFixedProductKey, hasPermission, defaultIfEmpty } from '../../elements/utils'
import MultiSelect from '../../elements/MultiSelect'
import { getProductGroupKeys } from '../project_types/projectUtils'

export default function AddDocumentItemModal ({ allItems, productGroups, project, addDocumentItem, showPrices = true, fixedProductKeys = null, session }) {
  const [searchInput, setSearchInput] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [productGroupFilterValue, setProductGroupFilterValue] = useState([])
  const [filteredItems, setFilteredItems] = useState([])

  const keyToId = (key) => {
    let productGroupMatchId = null
    if (productGroups) {
      const productGroupMatch = productGroups.find(productGroup => productGroup.key === key)
      if (productGroupMatch) productGroupMatchId = productGroupMatch.id
    }
    return productGroupMatchId
  }
  const onRowClick = (item) => {
    if (checkForFixedProductKey(fixedProductKeys, item.key) && !hasPermission(session.user, 'project_change_fixed_products')) return
    setModalIsOpen(false)
    addDocumentItem(item)
  }

  const onToggle = (isOpen) => {
    setSearchInput('')
  }

  const getOpenButton = (toggle) => <CustomButton onClick={toggle}>Position hinzufügen</CustomButton>

  useEffect(() => {
    // Apply both search and filter criteria to allItems
    const filtered = allItems.filter(item => {
      const searchRegex = new RegExp(`.*${searchInput.toLowerCase()}.*`)
      const isNameMatch = searchInput ? item.name.toLowerCase().match(searchRegex) : true
      const isProductGroupMatch = !productGroupFilterValue.length || productGroupFilterValue.every(value => item.product_group.includes(value))
      return isNameMatch && isProductGroupMatch
    })
    const defaultFiltered = productGroupFilterValue.includes(keyToId('deactivated')) ? filtered : filtered.filter(item => !item.product_group.includes(keyToId('deactivated')))
    setFilteredItems(defaultFiltered.sort((a, b) => a.id - b.id))
  }, [allItems, searchInput, productGroupFilterValue])

  useEffect(() => {
    if (project && (productGroups && productGroups.length)) {
      setProductGroupFilterValue(getProductGroupKeys(project).map(k => productGroups.find(g => g.key === k).id))
    }
  }, [project, productGroups])

  return (
    <CustomModal size="lg" getOpenButton={getOpenButton} title="Position hinzufügen" isOpen={modalIsOpen} setIsOpen={setModalIsOpen} onToggle={onToggle}>
      <Fragment>
        <Input
          type='text'
          style={{ boxShadow: 'none' }}
          placeholder="Produkt suchen"
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
        />
        <br />
        <MultiSelect
          onChange={e => setProductGroupFilterValue(e)}
          options={
            productGroups
              ? productGroups
                .filter((productGroup) => productGroup.state === true)
                .map((productGroup) => ({
                  value: productGroup.id,
                  label: productGroup.name
                }))
              : null
          }
          values={defaultIfEmpty(productGroupFilterValue)}
          text='Produktgruppe'
          search={true}
          name='product_group'
        />

        <Table className={!filteredItems || filteredItems.length <= 0 ? 'table-not-hover' : 'table-hover'}>
          <thead>
            <tr>
              <th className="align-middle"><Typography className='secondary-textcolor' style={{ textAlign: 'center' }}>Produkt</Typography> </th>
              {showPrices ? <th><Typography className='secondary-textcolor' style={{ textAlign: 'right' }}>Preis</Typography></th> : null}
              <th><Typography className='secondary-textcolor' style={{ textAlign: 'right' }}>Produkt-Nr.</Typography> </th>
            </tr>
          </thead>

          <tbody>
            {!filteredItems || filteredItems.length <= 0
              ? (
                <tr>
                  <td colSpan="4" align="center">
                    <Typography className='secondary-textcolor'> Keine passenden Produkte </Typography>
                  </td>
                </tr>
                )
              : (
                  filteredItems.map(item => {
                    const className = checkForFixedProductKey(fixedProductKeys, item.key) ? 'disable-textcolor' : 'secondary-textcolor'
                    return <tr key={item.id}>
                    <td className="align-middle" onClick={() => onRowClick(item)}><Typography className={className}>{item.name}</Typography></td>
                    {showPrices ? <td align='right' onClick={() => onRowClick(item)}><Typography className={className}>{item.price.toFixed(2)} €</Typography></td> : null}
                    <td align='right' onClick={() => onRowClick(item)}><Typography className={className}>{item.id}</Typography></td>
                  </tr>
                  })
                )}
          </tbody>
        </Table>
      </Fragment>
    </CustomModal>
  )
}

AddDocumentItemModal.propTypes = {
  allItems: PropTypes.arrayOf(PropTypes.object),
  productGroups: PropTypes.arrayOf(PropTypes.object),
  project: PropTypes.object,
  addDocumentItem: PropTypes.func,
  showPrices: PropTypes.bool,
  fixedProductKeys: PropTypes.arrayOf(PropTypes.string),
  session: PropTypes.object
}
