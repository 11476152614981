import React from 'react'
import { PropTypes } from 'prop-types'

import axios from 'axios'

import { IconButton, Tooltip } from '@mui/material'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'

import { getAcceptedOfferEmailSubject, getPostAcceptedOfferRequest } from '../project_types/projectUtils'
import { API_URL_CONFIRMOFFER } from '../../settings'
import EmailModal from '../shared/modal_utils/EmailModal'
import { offerPropType, projectPropType } from '../../elements/PropTypes'

export default function ConfirmOfferModal ({ project, offer, isOpen, setIsOpen = null, getOpenButton, resetParent, session }) {
  // currently only for pv-project
  const postRequest = (res) => {
    getPostAcceptedOfferRequest(project)
    return res
  }

  const submit = async (subject, message) => axios.post(API_URL_CONFIRMOFFER, { offer: offer.id, mail: { subject, message } }).then(postRequest)

  const skip = async () => axios.post(API_URL_CONFIRMOFFER, { offer: offer.id }).then(postRequest)

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return (
        <Tooltip title="Bestätigen" PopperProps={{ style: { zIndex: 9999 } }} >
          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={toggle}>
            <ThumbUpOffAltIcon className='secondary-textcolor' fontSize='large' />
          </IconButton>
        </Tooltip>
    )
  }

  const title = `Bestätigtes Angebot ${offer.id}`

  return (
        <EmailModal
          customer={project.customer_obj}
          title={title}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          subject={getAcceptedOfferEmailSubject(project, session)}
          getEmailMessage={() => axios.get(API_URL_CONFIRMOFFER, { params: { offer: offer.id } })}
          submit={(subject, message) => submit(subject, message)}
          skip={skip}
          resetParent={resetParent}
          getModalOpenButton={_getOpenButton}
          session={session}
        />
  )
}

ConfirmOfferModal.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
