import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { Table } from 'react-bootstrap'
import { Container } from 'reactstrap'

import { Typography, IconButton, Tooltip } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

import LoadingPage from '../../../../../elements/LoadingPage'
import { API_URL_OPERATOR } from '../../../../../settings'
import { sortById } from '../../../../../elements/utils'
import CustomModal from '../../../../shared/modal_utils/CustomModal'
import OperatorFormModal from './OperatorFormModal'

export default function OperatorModal ({ resetParent, project, session }) {
  const [operators, setOperators] = useState([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => getOperators(), [])

  const resetState = () => {
    getOperators()
    resetParent()
  }

  const getOperators = () => {
    axios.get(API_URL_OPERATOR)
      .then(
        res => setOperators(sortById(res.data)))
      .then(() => setLoaded(true))
  }

  if (!loaded) return <LoadingPage />

  const getOpenButton = (toggle) => {
    return (
      <Tooltip title='Bearbeiten' PopperProps={{ style: { zIndex: 9999 } }} >
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={toggle}><EditOutlinedIcon className='secondary-textcolor' fontSize='small' /></IconButton>
      </Tooltip>
    )
  }

  return (
    <CustomModal getOpenButton={getOpenButton} size='xl'>
      <Fragment>
        <Container>
          <OperatorFormModal
            session={session}
            project={project}
            resetParent={resetState}
          />
          <br />
          <Table >
            <thead>
              <tr>
                <th className="align-middle"><Typography className='secondary-textcolor'>#</Typography></th>
                <th className="align-middle"><Typography className='secondary-textcolor'>Name</Typography></th>
                <th style={{ textAlign: 'center' }} className="align-middle"><Typography className='secondary-textcolor'>Email</Typography></th>
                <th style={{ textAlign: 'center', width: '15%' }}><Typography className='secondary-textcolor'>Aktion</Typography></th>
              </tr>
            </thead>

            <tbody>
              {!operators || operators.length <= 0
                ? (
                  <tr>
                    <td colSpan="4" align="center">
                      <Typography className='secondary-textcolor'> Keine Netzbetreiber </Typography>
                    </td>
                  </tr>
                  )
                : (
                    operators.map(operator => (
                    <tr key={operator.id}>
                      <td className="align-middle"><Typography className='secondary-textcolor'>{operator.id} </Typography></td>
                      <td className="align-middle"><Typography className='secondary-textcolor'>{operator.name} </Typography></td>
                      <td className="align-middle" style={{ textAlign: 'right' }}><Typography className='secondary-textcolor'>{operator.email}</Typography></td>
                      <td align="center">
                        <OperatorFormModal
                          session={session}
                          operator={operator}
                          project={project}
                          resetParent={resetState}
                        />
                      </td>
                    </tr>
                    ))
                  )}
            </tbody>
          </Table>
        </Container >
      </Fragment >
    </CustomModal>
  )
}

OperatorModal.propTypes = {
  resetParent: PropTypes.func,
  project: PropTypes.object,
  session: PropTypes.object
}
