import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import CollapsibleTable from '../../elements/CollapsibleTable'
import { getProjectType } from '../project_types/projectUtils'
import TaskFormModal from '../tasks/TaskFormModal'

export default function NewTaskModal ({ customer, project, resetParent, session, isOpen, setIsOpen }) {
  const [_project, setProject] = useState(null)
  const [newTaskModalOpen, setNewTaskModalOpen] = useState(false)

  useEffect(() => {
    if (!newTaskModalOpen) setIsOpen(false)
  }
  , [newTaskModalOpen])

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        onCancel={toggle}
        cancelBtnLabel={'Abbrechen'}
      />
    )
  }

  return (
    <CustomModal title="Projekt auswählen" isOpen={isOpen} setIsOpen={setIsOpen} getFooter={getFooter} onToggle={(isOpen) => { if (isOpen) setProject(null) }}>
    {customer?.project_set
      ? (<CollapsibleTable
            columns={[{
              name: 'Name',
              key: 'name'
            }, {
              name: 'Typ',
              key: 'type'
            }, {
              name: 'Nächster Schritt',
              key: 'todo'
            }]}
            rows={customer.project_set
              .map(project => ({
                key: `${project.id}`,
                name: project.name,
                type: getProjectType(project),
                link: () => {
                  setProject(project)
                  setNewTaskModalOpen(true)
                },
                todo: project.open_todo_name
              }))}
            counter={true}
            collapsible={false}
          />
        )
      : null}
      <TaskFormModal
        customer={customer}
        project={project || _project}
        resetParent={resetParent}
        session={session}
        isOpen={newTaskModalOpen}
        setIsOpen={setNewTaskModalOpen}
      />
    </CustomModal>
  )
}

NewTaskModal.propTypes = {
  customer: PropTypes.object,
  project: PropTypes.object,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}
