import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Button } from '@mui/material'

import EditHeatpumpImagePlanning from '../../../shared/images/EditHeatpumpImagePlanning'
import HeatpumpImageUpload from '../../../shared/images/HeatpumpImageUpload'
import { getRandomId, round } from '../../../../elements/utils'
import HeatpumpImage from '../../../shared/images/HeatpumpImage'

const currentSystemOptions = [
  { label: 'Öl', value: 'oil' },
  { label: 'Gas', value: 'gas' },
  { label: 'Elektro', value: 'electric' },
  { label: 'Benutzerdefiniert', value: 'custom' }
]

// options for oil tank
const oilTankOptions = [
  { label: 'GFK', value: 0 },
  { label: 'Metall', value: 1 }
]

const flatOptions = Array.from({ length: 3 }, (_, i) => ({ value: i + 1, name: i + 1 })).concat([{ value: -1, name: 'Benutzerdefiniert' }])
const peopleOptions = Array.from({ length: 6 }, (_, i) => ({ value: i + 1, name: i + 1 })).concat([{ value: -1, name: 'Benutzerdefiniert' }])
const heaterOptions = Array.from({ length: 4 }, (_, i) => ({ value: i, name: i })).concat([{ value: -1, name: 'Benutzerdefiniert' }])
const gesamtOptions = [
  { name: '11 kW', value: 11 },
  { name: '22 kW', value: 22 },
  { name: '33 kW', value: 33 },
  { name: '44 kW', value: 44 },
  { name: 'Benutzerdefiniert', value: -1 }
]
const oilCountOptions = Array.from({ length: 3 }, (_, i) => ({ value: i + 1, name: i + 1 })).concat([{ value: -1, name: 'Benutzerdefiniert' }])
const wallboxOptions = Array.from({ length: 2 }, (_, i) => ({ value: i + 1, name: i + 1 })).concat([{ value: -1, name: 'Benutzerdefiniert' }])

export const getHeatpumpPlanningFormTemplate = (heatpumps) => [
  {
    type: 'dropdown',
    name: 'Wärmepumpe:',
    key: 'heatpump',
    condition: () => heatpumps,
    options: (heatpumps || []).map(h => ({ label: h.name, value: h.id }))
  },
  {
    type: 'custom_toggle',
    name: 'Anzahl Wohneinheiten:',
    key: 'n_flats',
    options: flatOptions,
    custom_field: {
      key: 'n_flats_custom',
      condition: form => form.n_flats === -1
    }
  },
  {
    type: 'custom_toggle',
    name: 'Anzahl Personen:',
    key: 'n_people',
    options: peopleOptions,
    custom_field: {
      key: 'n_people_custom',
      condition: form => form.n_people === -1
    }
  },
  {
    type: 'input_number',
    name: 'Baujahr:',
    key: 'construction_year'
  },
  {
    type: 'toggle',
    name: 'Wurde eine Förderung vom Kunden vorher schon beantagt?',
    key: 'funding'
  },
  {
    type: 'input_number',
    name: 'Beantragte Fördersumme:',
    key: 'funding_amount',
    condition: form => form.funding
  },
  {
    type: 'date',
    name: 'Umsetzungfrist:',
    key: 'funding_deadline',
    condition: form => form.funding
  },
  {
    type: 'toggle',
    name: 'Will der Kunde den Antrag selbst stellen?',
    key: 'funding_application_by_customer',
    condition: form => form.funding === false
  },
  {
    type: 'toggle',
    name: 'Ist eine PV-Anlage vorhanden?',
    key: 'pv'
  },
  {
    type: 'toggle',
    name: 'Ist ein separater Zähler für die Wärmepumpe gewünscht?',
    key: 'extra_e_meter'
  },
  {
    type: 'toggle',
    name: 'Sind Wallboxen vorhanden?',
    key: 'wallbox'
  },
  {
    type: 'custom_toggle',
    name: 'Anzahl Wallboxen:',
    key: 'n_wallboxes',
    options: wallboxOptions,
    condition: form => form.wallbox,
    custom_field: {
      key: 'n_wallboxes_custom',
      condition: form => form.n_wallboxes === -1
    }
  },
  {
    type: 'custom_toggle',
    name: 'Gesamt kW:',
    key: 'wallboxes_kw',
    options: gesamtOptions,
    condition: form => form.wallbox,
    custom_field: {
      key: 'wallboxes_kw_custom',
      condition: form => form.wallboxes_kw === -1
    }
  },
  {
    type: 'custom_toggle',
    name: 'Anzahl Durchlauferhitzer:',
    key: 'n_flow_heater',
    options: heaterOptions,
    custom_field: {
      key: 'n_flow_heater_custom',
      condition: form => form.n_flow_heater === -1
    }
  },
  {
    type: 'toggle',
    name: 'Ist eine Solarthermie vorhanden?',
    key: 'solar_thermal'
  },
  {
    type: 'toggle',
    name: 'Soll Solarthermie entfernt werden?',
    key: 'remove_solar_thermal',
    condition: form => form.solar_thermal
  },
  {
    type: 'custom_dropdown',
    name: 'Welche Bestandsheizung ist installiert?',
    key: 'current_system',
    options: currentSystemOptions,
    custom_field: {
      key: 'current_system_custom',
      condition: form => form.current_system === 'custom'
    }
  },
  {
    type: 'dropdown',
    name: 'Art des Öltanks:',
    key: 'oil_tank',
    options: oilTankOptions,
    condition: form => form.current_system === 'oil'
  },
  {
    type: 'custom_toggle',
    name: 'Anzahl Öltanks:',
    key: 'n_oil_tanks',
    condition: form => form.current_system === 'oil',
    options: oilCountOptions,
    custom_field: {
      key: 'n_oil_tanks_custom',
      condition: form => form.n_oil_tanks === -1
    }
  },
  {
    type: 'input_number',
    name: 'Restvolumen in l:',
    key: 'oil_volume',
    condition: form => form.current_system === 'oil'
  },
  {
    type: 'images',
    name: 'Fotos des Öltanks:',
    key: 'heatertankimage_set',
    condition: form => form.current_system === 'oil'
  },
  {
    type: 'toggle',
    name: 'Soll die Demontage der Bestandsheizung durch Planville erfolgen?',
    key: 'disassembly_current_system'
  },
  {
    type: 'date',
    name: 'Datum der Installation der Bestandsheizung:',
    key: 'installation_date_current_system'
  },
  {
    type: 'toggle',
    name: 'Welches Heizsystem ist vorhanden?',
    key: 'heating_system',
    options: [{ value: 'Fußbodenheizung', name: 'Fußbodenheizung' }, { value: 'Heizkörper', name: 'Heizkörper' }, { value: 'Beide', name: 'Beide' }]
  },
  {
    type: 'images',
    name: 'Fotos Heizsystem:',
    key: 'heaterimage_set'
  },
  {
    type: 'input_number',
    name: 'Anzahl Heizkreise:',
    key: 'n_flow_heating_circuits',
    condition: form => form.heating_system === 'Fußbodenheizung' || form.heating_system === 'Beide'
  },
  {
    type: 'toggle',
    name: 'Sind Heizkreisverteiler vorhanden?',
    key: 'heating_circuit_distributor',
    condition: form => form.heating_system === 'Fußbodenheizung' || form.heating_system === 'Beide'
  },
  {
    type: 'images',
    name: 'Fotos Heizkreisverteiler:',
    key: 'heaterdistributorimage_set',
    condition: form => form.heating_system === 'Fußbodenheizung' || form.heating_system === 'Beide'
  },
  {
    type: 'input_number',
    name: 'Anzahl Raumthermostate:',
    key: 'n_thermostats',
    condition: form => form.heating_system === 'Fußbodenheizung' || form.heating_system === 'Beide'
  },
  {
    type: 'toggle',
    name: 'Sind in jedem Raum Thermostate vorhanden?',
    key: 'thermostats_in_every_room',
    condition: form => form.heating_system === 'Fußbodenheizung' || form.heating_system === 'Beide'
  },
  {
    type: 'toggle',
    name: 'Austausch der Heizkörper:',
    key: 'replace_radiators'
  },
  {
    type: 'toggle',
    name: 'Update der Heizkörperventile:',
    key: 'update_radiator_valves'
  },
  {
    type: 'input_number',
    name: 'Anzahl Heizkörperventile:',
    key: 'n_radiator_valves',
    condition: form => form.update_radiator_valves
  },
  {
    type: 'toggle',
    name: 'Austausch der Heizkreisverteiler:',
    key: 'replace_heating_circuit_distributor'
  },
  {
    type: 'input_number',
    name: 'Anzahl Heizkreisverteiler:',
    key: 'n_heating_circuit_distributor',
    condition: form => form.replace_heating_circuit_distributor
  },
  {
    type: 'toggle',
    name: 'Art der Raumthermostate:',
    key: 'thermostats_digital',
    options: [{ value: 0, name: 'Digital' }, { value: 1, name: 'Analog' }, { value: 2, name: 'Nicht Vorhanden' }]
  },
  {
    type: 'custom',
    name: 'Fotos Standort Außengerät',
    condition: () => heatpumps,
    key: 'heatpumpplanningimage_set',
    getFieldHtml: (form, setForm, showMissingFields, fieldIsEmpty) => {
      const deleteHeatpumpImage = (imageId) => {
        setForm(f => ({ ...f, heatpumpplanningimage_set: f.heatpumpplanningimage_set.filter(i => i.id !== imageId) }))
      }

      const updateHeatpumpImage = (heatpumpImageId, update) => {
        setForm(f => {
          const images = [...f.heatpumpplanningimage_set]
          const image = images.find(x => x.id === heatpumpImageId)
          Object.entries(update).forEach(([key, value]) => { image[key] = value })
          return { ...f, heatpumpplanningimage_set: images }
        })
      }

      const addHeatpumpImage = (image, defaultImage, scale, angle, position) => {
        setForm(f => ({
          ...f,
          heatpumpplanningimage_set: [...f.heatpumpplanningimage_set].concat([{
            id: -getRandomId(),
            image,
            default_image: defaultImage,
            scale_w: scale.w,
            scale_h: scale.h,
            image_width_edit: scale.r,
            angle,
            x: position.x,
            y: position.y
          }])
        }
        ))
      }

      if (!heatpumps) return null
      const heatpump = heatpumps.find(h => h.id === form.heatpump) || null

      return (heatpump)
        ? (
          <>
            <div>
              {form.heatpumpplanningimage_set
                .map(heatpumpPlanningImage => <div key={`heatpump_image_${heatpumpPlanningImage.id}`}>
                  <Button variant="outlined" color="error" onClick={() => deleteHeatpumpImage(heatpumpPlanningImage.id)}>Bild löschen</Button>
                  <br />
                  <br />
                  <br />
                  <HeatpumpImage
                    heatpumpImage={heatpumpPlanningImage}
                    heatpump={heatpump}
                    width={round((isMobileOnly ? 0.9 : 0.43) * window.innerWidth)}
                  />
                  <br />
                  <EditHeatpumpImagePlanning
                    image={heatpumpPlanningImage.image}
                    defaultImage={heatpumpPlanningImage.default_image}
                    setImage={(image) => updateHeatpumpImage(heatpumpPlanningImage.id, { image })}
                    heatpump={heatpump}
                    heatpumpPosition={{ x: heatpumpPlanningImage.x, y: heatpumpPlanningImage.y }}
                    heatpumpImageScale={{ w: heatpumpPlanningImage.scale_w, h: heatpumpPlanningImage.scale_h, r: heatpumpPlanningImage.image_width_edit }}
                    setHeatpumpImageScale={(scale) => updateHeatpumpImage(heatpumpPlanningImage.id, { scale_w: scale.w, scale_h: scale.h, image_width_edit: scale.r })}
                    setHeatpumpPosition={(position) => updateHeatpumpImage(heatpumpPlanningImage.id, { x: position.x, y: position.y })}
                    angle={heatpumpPlanningImage.angle}
                    setAngle={(angle) => updateHeatpumpImage(heatpumpPlanningImage.id, { angle })}
                    winWidth={window.innerWidth}
                    winHeight={window.innerHeight}
                  />
                  <hr className='secondary-textcolor' />
                </div>)
              }
            </div>
            <HeatpumpImageUpload
              heatpump={heatpump}
              addHeatpumpImage={addHeatpumpImage}
              winWidth={window.innerWidth}
              winHeight={window.innerHeight} />
            {(showMissingFields && fieldIsEmpty)
              ? <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
              : null}
          </>)

        : null
    }
  },
  {
    type: 'images',
    name: 'Zählerschrank und Umgebung:',
    key: 'heatpumpemetercabinetimage_set'
  },
  {
    type: 'images',
    name: 'Fotos Standort Innengerät:',
    key: 'heatpumpcontrollerimage_set'
  },
  {
    type: 'pdf',
    name: 'PDF Heizlastberechnung:',
    key: 'heating_load_calculation_pdf'
  },
  {
    type: 'text',
    name: 'Notizen: (optional):',
    key: 'notes',
    optional: true
  }
]
