import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { PropTypes } from 'prop-types'

import { Typography } from '@mui/material'

import PlanningList from './PlanningList'
import CustomModal from '../shared/modal_utils/CustomModal'
import { CustomButton } from '../../elements/StyledElements'
import { getCustomerName, hasPermission } from '../../elements/utils'
import { isMobileOnly } from 'react-device-detect'
import { projectPropType } from '../../elements/PropTypes'
import PlanningFormModal from '../project_types/PlanningFormModal'

export default function PlanningModal ({ project, planning, setPlanning, disable, resetParent, getOpenButton, session }) {
  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton disabled={disable} onClick={toggle} icon="ruler">Planen</CustomButton>
  }

  return (
    <CustomModal size="fullscreen" getOpenButton={_getOpenButton} title={isMobileOnly ? 'Planung' : 'Planung für ' + getCustomerName(project.customer_obj)}>
      <Container >
        <Row>
          <Col>
            <Typography fontSize='h5.fontSize' className='secondary-textcolor'>Erstellte Planungen</Typography>
            <PlanningList
              project={project}
              resetParent={resetParent}
              session={session}
            />
            <br />
            {hasPermission(session.user, 'customer_handling')
              ? <PlanningFormModal
                project={project}
                planning={planning}
                setPlanning={setPlanning}
                resetParent={resetParent}
                session={session}
              />
              : null}
            <br />
          </Col>
        </Row>
      </Container >
    </CustomModal>
  )
}

PlanningModal.propTypes = {
  project: projectPropType,
  planning: PropTypes.object,
  disable: PropTypes.bool,
  setPlanning: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
