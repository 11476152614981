import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Input } from 'reactstrap'
import axios from 'axios'

import { IconButton, Tooltip, Typography } from '@mui/material'
import ClearOutlined from '@mui/icons-material/ClearOutlined'

import { API_URL_REJECTTASK } from '../../settings'
import { taskPropType } from '../../elements/PropTypes'

import ConfirmationModalFooter from '../shared/modal_utils/ConfirmationModalFooter'
import CustomModal from '../shared/modal_utils/CustomModal'
import { defaultIfEmpty } from '../../elements/utils'

export default function RejectTaskModal ({ task, resetParent, isOpen, setIsOpen, getOpenButton, session }) {
  const [reason, setReason] = useState()
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
  }

  const clearData = () => {
    setLoadingElements({ submitError: false, inProgress: false })
  }

  const confirm = () => {
    // return Promise.all([
    //   axios.put(API_URL_TASK + task.id, { accepted: false }),
    //   axios.post(API_URL_TASKCOMMENT, { task: task.id, text: `Absage: ${reason}` })
    // ])
    return axios.post(API_URL_REJECTTASK, { task: task.id, reason })
  }

  const onConfirm = (onSuccess) => {
    if (!reason || reason === '') {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte einen Grund eintragen!', inProgress: false })
      return
    }
    setLoadingElements({ ...loadingElements, submitError: false, inProgress: true })
    confirm().then(() => {
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false })
    }).catch((e) => { console.error(e); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const _getOpenButton = (toggle) => {
    return getOpenButton
      ? getOpenButton(toggle)
      : <Tooltip title={'Ablehnen'} PopperProps={{ style: { zIndex: 9999 } }}>
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={toggle}>{<ClearOutlined className='secondary-textcolor' fontSize='medium' />} </IconButton>
    </Tooltip>
  }

  const getFooter = (toggle) => {
    return (
      <ConfirmationModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onConfirm={() => onConfirm(toggle)}
        onCancel={toggle}
        color={'error'}
        btnLabel={'Aufgabe ablehnen'}
      />
    )
  }

  return <CustomModal
    getOpenButton={_getOpenButton}
    getFooter={getFooter}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    onToggle={onToggle}
    size="lg"
    title={'Aufgabe wirklich ablehnen?'}
  >
    <Typography className='secondary-textcolor'>Grund:</Typography>
    <Input
      id="input"
      type="textarea"
      name="reason"
      onChange={e => setReason(e.target.value)}
      value={defaultIfEmpty(reason)}
      required={true}
      style={{ boxShadow: 'none', margin: '10px 0px' }}
    />
  </CustomModal>
}

RejectTaskModal.propTypes = {
  task: taskPropType,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  session: PropTypes.object
}
