import React, { Fragment, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { Input } from 'reactstrap'

import { Typography } from '@mui/material'

export default function UploadPdf ({ pdf, setPdf }) {
  const [pdfError, setPdfError] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)

  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  // onchange event

  const onFileChange = (e) => {
    if ((!e.target.files) || e.target.files.length < 1) {
      setPdf(null)
      setPdfError('Bitte laden Sie eine gültige PDF-Datei hoch.')
      return
    }
    const file = e.target.files[0]
    const fileType = ['application/pdf']
    if (!file || !fileType.includes(file.type)) {
      setPdf(null)
      setPdfError('Bitte laden Sie eine gültige PDF-Datei hoch.')
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      setPdfFile(e.target.result)
      setPdf(file)
      setPdfError(null)
    }
  }

  return (
        <Fragment>
            <br />
            <Input style={{ width: '550px', boxShadow: 'none' }} id="input" type="file" onChange={onFileChange} />
            <br />
            {pdfError ? <Typography className='secondary-textcolor'>{pdfError}</Typography> : null}
            <br />
            {pdf
              ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
                    <div style={{ height: '1200px' }}>
                        <Viewer theme="light" fileUrl={pdfFile} defaultScale={1} plugins={[defaultLayoutPluginInstance]} />
                    </div>
                </Worker>
              : null}
        </Fragment>
  )
}

UploadPdf.propTypes = {
  pdf: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setPdf: PropTypes.func
}
