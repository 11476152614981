import { PropTypes } from 'prop-types'
import React, { Fragment, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import EmailEditor from 'react-email-editor'

import LoadingPage from '../elements/LoadingPage'
import LoadDesignModal from './newsletter/LoadDesignModal'
import SaveDesignModal from './newsletter/SaveDesignModal'
import SendNewsletterModal from './newsletter/SendNewsletterModal'

import { Typography, Stack } from '@mui/material'
import { CustomButton } from '../elements/StyledElements'

export default function Newsletter ({ session }) {
  const [loaded, setLoaded] = useState(false)
  const emailEditorRef = useRef(null)

  const resetState = () => {
  }

  const onReady = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)

    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
    setLoaded(true)
  }

  const onDesignLoad = (data) => {
    // console.log('onDesignLoad', data)
  }

  const onLoad = (unlayer) => {
    unlayer.addEventListener('design:loaded', onDesignLoad)
  }
  const chooseDesign = (design) => {
    const unlayer = emailEditorRef.current?.editor
    unlayer.loadDesign(design)
  }

  const deletecurrentDesign = () => {
    const unlayer = emailEditorRef.current?.editor
    unlayer.loadBlank()
  }

  const EmailNewsletter = <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} minHeight="800px" />

  if (isMobile) {
    return (
      <Fragment>
        <Typography className="secondary-textcolor" style={{ fontSize: 22 }}>Bitte auf einen Desktop wechseln!</Typography>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Stack direction="row" spacing={2}>
        <SendNewsletterModal resetParent={resetState} session={session} emailEditorRef={emailEditorRef} />
        <LoadDesignModal chooseDesign={chooseDesign} resetParent={resetState} session={session} />
        <SaveDesignModal emailEditorRef={emailEditorRef} resetParent={resetState} session={session} />
        <CustomButton onClick={deletecurrentDesign} color='#d32f2f' icon="delete">Design löschen</CustomButton>
      </Stack>
      <br />
      {EmailNewsletter}
      {loaded ? null : <LoadingPage />}
    </Fragment>
  )
}

Newsletter.propTypes = {
  session: PropTypes.object.isRequired
}
