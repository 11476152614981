import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { Container } from 'react-bootstrap'

import { Typography } from '@mui/material'
import Alert from '@mui/material/Alert'

import LoadingPage from '../../elements/LoadingPage'
import { CAPI_URL_CUSTOMER, CAPI_URL_PROJECT } from '../../settings'
import CustomerDataInput from './data_input/CustomerDataInput'
import ProjectDataInput from './project_types/ProjectDataInput'
import { dataInputNeeded } from './project_types/projectUtils'

export default function DataInput ({ session }) {
  const [customer, setCustomer] = useState(null)
  const [projects, setProjects] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [showMissingFieldsForProject, setShowMissingFieldsForProject] = useState(null)
  const [showSuccessForProject, setShowSuccessForProject] = useState(null)

  useEffect(() => {
    loadData()
  }, [])

  const resetState = (data, resetMsgs = true) => {
    if (resetMsgs) {
      setShowMissingFieldsForProject(null)
      setShowSuccessForProject(null)
    }
    loadData()
  }

  const loadData = () => {
    Promise.all([
      axios.get(CAPI_URL_CUSTOMER)
        .then(res => { setCustomer(res.data) }),
      axios.get(CAPI_URL_PROJECT, { params: { nested: true } })
        .then(res => { setProjects(res.data) })
    ])
      .then(() => setLoaded(true))
  }

  if (!loaded || !customer || !projects) return <LoadingPage />

  return (
    <Fragment>
      <Container>
        <div className="text-center">
          <Typography className='secondary-textcolor'>
            Folgende Informationen benötigen wir für die Planung Ihres Projektes.
            Wenn Sie ein Angebot angenommen und eine Bestätigungsemail erhalten haben,
            in der Sie zur Dateneingabe aufgefordert werden, tragen Sie bitte folgend die
            Informationen ein. Sobald sie alle Daten eingegeben haben, klicken Sie bitte &quot;Abschicken&quot;.
            Falls Daten fehlen, wird Ihnen eine Fehlermeldung angezeigt.
            Andernfalls, werden Ihre Daten an uns übermittelt und wir können mit der Planung Ihres Projektes fortfahren.
          </Typography>
        </div>
        {showMissingFieldsForProject !== null ? <Alert severity="error">Die Daten sind nicht vollständig. Bitte füllen Sie die fehlenden Daten aus!</Alert> : null}
        {(showSuccessForProject !== null) ? <Alert severity="success">Die Daten wurden erfolgreich gespeichert!</Alert> : null}
        <br />
        <br />
        <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
          Kundeninformationen
        </Typography>
        <CustomerDataInput
          customer={customer}
          showAllMissingFields={showMissingFieldsForProject !== null}
          resetParent={resetState}
          session={session}
        />
        <br />
        <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
          Projektinformationen
        </Typography>
        {projects.filter(project => dataInputNeeded(project)).map(project =>
          <Fragment key={project.id}>
            <Typography className='secondary-textcolor'>
              {project.name}
            </Typography>
            {showMissingFieldsForProject === project.id ? <Alert severity="error">Die Daten sind nicht vollständig. Bitte füllen Sie die fehlenden Daten aus!</Alert> : null}
            {(showSuccessForProject === project.id) ? <Alert severity="success">Die Daten wurden erfolgreich gespeichert!</Alert> : null}
            <ProjectDataInput
              key={`project-data-input-${project.id}`}
              project={project}
              showAllMissingFields={showMissingFieldsForProject === project.id}
              setShowAllMissingFields={() => setShowMissingFieldsForProject(project.id)}
              setSuccess={() => setShowSuccessForProject(project.id)}
              resetParent={resetState}
              session={session}
            />
          </Fragment>
        )}
        <hr className='secondary-textcolor' />
        <div className="text-center">
          <Typography className='secondary-textcolor' fontSize='h6.fontSize'>{`Bei Problemen mit der App schreiben Sie bitte eine E-Mail an ${session.companyConfig.support_email}.`}</Typography>
          <br />
        </div>
      </Container>
    </Fragment >
  )
}

DataInput.propTypes = {
  session: PropTypes.object
}
