import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'
import 'leaflet/dist/leaflet.css'
import './styles.css'
import L from 'leaflet'
import { MapContainer, TileLayer, GeoJSON, useMap, useMapEvents } from 'react-leaflet'

import LoadingPage from '../../../elements/LoadingPage'
import { popFromArr, removeDuplicates, round } from '../../../elements/utils'
import { areaPropType, userPropType } from '../../../elements/PropTypes'
import './LegendComponent.css'

const ZOOM = 6
const CENTER = [51.26191485308451, 10.56884765625]

function ChangeView ({ center, setCenter, zoom, setZoom }) {
  const map = useMap()
  map.setView(center, zoom)
  const mapEvents = useMapEvents({
    zoomend: () => {
      const _zoom = mapEvents.getZoom()
      if (zoom !== _zoom) setZoom(_zoom)
    },
    moveend: () => {
      const _center = mapEvents.getCenter()
      if (round(center.lat, 4) !== round(_center.lat, 4) || round(center.lng, 4) !== round(_center.lng, 4)) setCenter(_center)
    }
  })
  return null
}

ChangeView.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number),
  setCenter: PropTypes.func,
  zoom: PropTypes.number,
  setZoom: PropTypes.func
}

function Legend ({ salesmen }) {
  const map = useMap()
  useEffect(() => {
    if (map) {
      const div = L.DomUtil.get('leadlegend')
      if (salesmen.length === 0) { if (div) L.DomUtil.remove(div); return }
      const legend = L.control({ position: 'bottomright' })
      legend.onAdd = () => {
        let div = L.DomUtil.get('leadlegend')
        if (!div) {
          div = L.DomUtil.create('div', 'info legend')
          div.setAttribute('id', 'leadlegend')
        }
        const labels = salesmen.map(salesman => {
          return '<i style="background:' + salesman.area_color + '"></i> ' + salesman.name
        })
        div.innerHTML = labels.join('<br>') // '<><p>asdfasdfsdf</p>' + labels[0] + '</>'
        // '<h4>This is the legend</h4>' +
        // '<b>Lorem ipsum dolor sit amet consectetur adipiscing</b>'
        return div
      }
      legend.addTo(map)
    }
  }, [map, salesmen]) // here add map
  return null
}

Legend.propTypes = {
  salesmen: PropTypes.arrayOf(userPropType)
}

export default function LeadMap ({ areas, setAreas, salesmanId, salesmen, disabled = false }) {
  const [zoom, setZoom] = useState(ZOOM)
  const [center, setCenter] = useState(CENTER)
  const [state, setState] = useState(null)
  const [loaded, setLoaded] = useState(true)

  const geoRef = useRef(null)

  const salesmanRef = useRef(salesmanId) // without ref the salesman is always null in the dblclick function
  const areaRef = useRef(areas)
  const salesmenRef = useRef(salesmen)
  const disabledRef = useRef(disabled)

  const [geoData, setGeoData] = useState(null)

  useEffect(() => {
    if (geoRef.current) {
      geoRef.current.clearLayers() // remove old data
      geoRef.current.addData(geoData) // might need to be geojson.features
    }
  }, [geoData])

  useEffect(() => { salesmenRef.current = salesmen }, [salesmen])
  useEffect(() => { disabledRef.current = disabled }, [disabled])

  useEffect(() => {
    if (geoData) updateGeoData(geoData)
    salesmanRef.current = salesmanId
    areaRef.current = areas
  }, [areas, salesmanId])

  const updateGeoData = (data) => {
    data.features.filter(f => f.properties.type !== 'State').forEach(f => {
      // salesmen (f.properties?.plz_code)
      const area = areas.find(a => a.zip_code === f.properties.plz_code)
      const salesmanIds = area ? area.salesmen : []
      const allSalesmanIds = salesmen.map(s => s.id)
      f.salesmen = salesmanIds.filter(s => allSalesmanIds.includes(s.id))
      // f.color = salesmanIds.length === 0 ? '#EDEDED' : salesmanIds.length === 1 ? salesmen.find(s => s.id === salesmanIds[0]).area_color : '#808080'
      f.color = getGeoColor(salesmanIds, salesmen)
      f.opacity = (salesmanIds.length > 1) ? 0.2 : 0.5
    })
    setGeoData(data)
  }

  const getGeoColor = (salesmanIds, salesmen) => {
    if (salesmanIds.length === 0 || !salesmanIds || !(salesmanIds.constructor === Array) ||
      !salesmen || !(salesmen.constructor === Array)) {
      return '#EDEDED'
    }
    if (salesmanIds.length === 1) {
      const salesman = salesmen.find(s => s.id === salesmanIds[0])
      if (!salesman) return '#EDEDED'

      return salesman.area_color
    }
    if (salesmanId && salesmanIds.includes(salesmanId)) {
      const salesman = salesmen.find(s => s.id === salesmanId)
      if (!salesman) return '#808080'

      return salesman.area_color
    }

    return '#808080'
  }

  useEffect(() => {
    setLoaded(false)
    let statePromise = fetch('/geojsons/states.geojson').then((r) => r.json())
    if (state) {
      statePromise = statePromise.then(async stateRes => {
        return fetch(`/geojsons/states/${state}.geojson`).then((r) => r.json())
          .then(areaRes => {
            const statesFeat = stateRes.features
            const stateFeat = popFromArr(statesFeat, f => f.properties.short === state)
            // const { item: stateFeat, arr: statesFeat } = popFromArr(stateRes.features, f => f.properties.short === state)
            setZoom(7)
            setCenter(stateFeat.properties.center)
            areaRes.features.push(...statesFeat)
            return areaRes
          })
      })
    } else setZoom(6)
    statePromise.then(r => { updateGeoData(r); setLoaded(true) })
  }, [state])

  const areaStyle = (feature) => {
    return {
      weight: 1,
      color: '#9370DB',
      fillColor: feature.color ? feature.color : 'lightblue',
      dashArray: '',
      fillOpacity: feature.opacity ? feature.opacity : 0.4,
      opacity: 1
    }
  }

  const selectArea = (feature) => {
    if (disabledRef.current || !salesmanRef.current) return
    const newAreas = [...areaRef.current]
    let area = newAreas.find(a => a.zip_code === feature.properties.plz_code)
    if (area) {
      if (!area.salesmen.includes(salesmanRef.current)) area.salesmen.push(salesmanRef.current)
      else area.salesmen = area.salesmen.filter(s => s !== salesmanRef.current)
    } else {
      area = { zip_code: feature.properties.plz_code, city: feature.properties.plz_name, salesmen: [salesmanRef.current] }
      newAreas.push(area)
    }
    area.modified = true
    setAreas(newAreas)
  }

  const areaSalesmenIds = removeDuplicates(areas.map(a => a.salesmen).flat())

  return (
    <div className="AreaMap" style={{ height: window.innerHeight - 200, width: round(window.innerWidth * 0.9) - 100 }}>
      {(!loaded) ? <LoadingPage/> : null}
      <MapContainer doubleClickZoom={false}>
        <ChangeView zoom={zoom} setZoom={setZoom} center={center} setCenter={setCenter} />
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoJSON
          data={geoData}
          ref={geoRef}
          style={areaStyle}
          onEachFeature={(feature, layer) => {
            let onClick = null
            let onDblClick = null
            if (feature.properties.type === 'State') {
              if (feature.properties) {
                const { name } = feature.properties
                layer.bindPopup(`${name}`)
                onClick = e => {
                  layer.closePopup()
                  setState(feature.properties.short)
                }
              }
            } else {
              if (feature.properties) {
                const { plz_code: plzCode, plz_name: plzName } = feature.properties
                const popupText = `${plzCode} ${plzName}`
                // const area = areaRef.current.find(a => a.zip_code === plzCode)
                // if (area) {
                //   const allSalesmanIds = salesmenRef.current.map(s => s.id)
                //   const areaSalesmenIds = area.salesmen.filter(s => allSalesmanIds.includes(s.id))
                //   const areaSalesmen = salesmenRef.current.filter(s => areaSalesmenIds.includes(s.id))
                //   areaSalesmen.forEach(s => { popupText = popupText + '<br/>' + s.name })
                // }
                layer.bindPopup(popupText)
                onDblClick = e => selectArea(feature)
              }
            }
            layer.on({
              mouseover: e => {
                layer.openPopup()
                const auxLayer = e.target
                auxLayer.setStyle({
                  weight: 4,
                  color: '#800080'
                })
              },
              mouseout: e => {
                layer.closePopup()
                const auxLayer = e.target
                auxLayer.setStyle(areaStyle(feature))
              },
              ...(onClick ? { click: onClick } : {}),
              ...(onDblClick ? { dblclick: onDblClick } : {})
            })
          }}
          attribution="&copy; credits due..."
        />
        <Legend salesmen={salesmen.filter(s => areaSalesmenIds.includes(s.id))} />
      </MapContainer>
    </div>
  )
}

LeadMap.propTypes = {
  areas: PropTypes.arrayOf(areaPropType),
  setAreas: PropTypes.func,
  salesmanId: PropTypes.number,
  salesmen: PropTypes.arrayOf(userPropType),
  disabled: PropTypes.bool
}
