import React from 'react'

import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'

import { Typography } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import AddressInput from '../../../../elements/AddressInput'
import { projectPropType } from '../../../../elements/PropTypes'
import { defaultIfEmpty } from '../../../../elements/utils'
import UploadImages from '../../../shared/images/UploadImages'

export default function HeatpumpProjectForm ({ project, setProject, showMissingFields, showAllMissingFields, session }) {
  if (!project) return null

  return (
    <Form>
      <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
        Addresse
      </Typography>
      <AddressInput
        address={{ street_and_number: project.street_and_number_project, zip_and_city: project.zip_and_city_project, province: project.province_project }}
        setAddress={(address) => setProject(p => ({ ...p, street_and_number_project: address.street_and_number, zip_and_city_project: address.zip_and_city, province_project: address.province }))}
        setStreetAndNumber={(streetAndNumber) => setProject(p => ({ ...p, street_and_number_project: streetAndNumber }))}
        setZipAndCity={(zipAndCity) => setProject(p => ({ ...p, zip_and_city_project: zipAndCity }))}
        setProvince={(province) => setProject(p => ({ ...p, province_project: province }))}
        showMissingFields={showMissingFields}
        session={session}
      />
      <hr className='secondary-textcolor' />
      <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
        Bestandsheizung
      </Typography>
      <FormGroup>
        <Typography className='secondary-textcolor'>Hersteller Bestandsheizung:</Typography>
        <Input
          id="input"
          type="text"
          name="manufacturer_current_heater"
          value={defaultIfEmpty(project.manufacturer_current_heater)}
          onChange={(e) => setProject(p => ({ ...p, manufacturer_current_heater: e.target.value }))}
          required={true}
          invalid={!!(showMissingFields && !project.manufacturer_current_heater)}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Typenbezeichnung Bestandsheizung:</Typography>
        <Input
          id="input"
          type="text"
          name="typename_current_heater"
          value={defaultIfEmpty(project.typename_current_heater)}
          onChange={(e) => setProject(p => ({ ...p, typename_current_heater: e.target.value }))}
          required={true}
          invalid={!!(showMissingFields && !project.typename_current_heater)}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
      <FormGroup>
        <Typography className='secondary-textcolor'>Foto von Typenschild von Bestandheizung:</Typography>
        <UploadImages
          images={project.labelcurrentheater_set}
          addImage={(image) => setProject(p => ({ ...p, labelcurrentheater_set: [...p.labelcurrentheater_set, image] }))}
          deleteImage={(image) => setProject(p => ({ ...p, labelcurrentheater_set: p.labelcurrentheater_set.filter(i => i.id !== image.id) }))}
        />
        {showMissingFields && !project.labelcurrentheater_set.length && <ErrorOutlineOutlinedIcon color='error' fontSize='large' />}
      </FormGroup>
    </Form>
  )
}

HeatpumpProjectForm.propTypes = {
  showMissingFields: PropTypes.bool,
  showAllMissingFields: PropTypes.bool,
  project: projectPropType,
  setProject: PropTypes.func,
  session: PropTypes.object
}
