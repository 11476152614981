import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import CollapsibleTable from '../../elements/CollapsibleTable'
import { customerPropType, projectPropType } from '../../elements/PropTypes'
import { CustomButton } from '../../elements/StyledElements'
import { getCustomerName } from '../../elements/utils'
import { API_URL_CUSTOMERACTIVITY } from '../../settings'
import CustomModal from '../shared/modal_utils/CustomModal'

export default function CustomerActivities ({ customer, project, getOpenButton, session }) {
  const [activities, setActivities] = useState([])

  const _customer = customer || project.customer_obj

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const loadData = () => {
    axios.get(API_URL_CUSTOMERACTIVITY, { params: { customer: _customer.id, project: project ? project.id : null } })
      .then(res => setActivities(res.data))
  }

  const clearData = () => {
    setActivities([])
  }

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton onClick={toggle} icon="history">Aktivitäten</CustomButton>
  }

  const projectColumn = !project && customer && customer.project_set && customer.project_set.length > 0

  const columns = [
    {
      name: 'Datum',
      key: 'date'
    }
  ]

  if (projectColumn) { columns.push({ name: 'Projekt', key: 'project' }) }

  columns.push(...[
    {
      name: 'Benutzer',
      key: 'user'
    },
    {
      name: 'Beschreibung',
      key: 'description'
    }
  ])

  return (
        <CustomModal size="fullscreen" getOpenButton={_getOpenButton} title={'Aktivitäten für ' + getCustomerName(_customer)} onToggle={onToggle}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}>
            <CollapsibleTable
              collapsible={false}
              columns={columns}
              rows={activities.map(activity => ({
                date: activity.date.slice(0, 10) + ', ' + activity.date.slice(11, 19),
                description: activity.description,
                user: activity.user_name,
                project: projectColumn ? (activity.project ? customer.project_set.find(p => p.id === activity.project).name : '') : null
              }))}
            />
            {
              customer?.lead && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: '10px'
                }}>
                  <Link to={`/leads/${customer?.lead?.id}?leadactivitymodal=true`}>
                    <CustomButton icon='mail'>Lead Aktivitäten</CustomButton>
                  </Link>
                </div>
              )
            }
          </div>
        </CustomModal>
  )
}

CustomerActivities.propTypes = {
  customer: customerPropType,
  project: projectPropType,
  getOpenButton: PropTypes.func,
  session: PropTypes.object
}
