import React, { useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import { projectPropType } from '../../../elements/PropTypes'
import { CustomButton } from '../../../elements/StyledElements'
import { getEmptyFieldsError } from '../../../elements/utils'
import { CAPI_URL_DATAINPUT } from '../../../settings'
import CustomModal from '../../shared/modal_utils/CustomModal'
import ConfirmationModalFooter from '../../shared/modal_utils/ConfirmationModalFooter'
import { emptyCustomerForm } from './forms'
import { getEmptyProjectFieldsError } from '../project_types/projectUtils'

export default function FinishDataInput ({ project, disable, setShowAllMissingFields, resetParent, session }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const onToggle = (isOpen) => {
    if (!isOpen) {
      setLoadingElements({
        inProgress: false, submitError: false
      })
    }
  }

  const getOpenButton = (toggle) => {
    return <CustomButton style={{ float: 'right' }} onClick={toggle} disabled={disable}>Abschicken</CustomButton>
  }

  const submit = async () => {
    return axios.post(CAPI_URL_DATAINPUT, { project: project.id })
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    const additionalOptionalKeys = ['company']
    if (project.customer_obj.gender === 'Firma') additionalOptionalKeys.push('first_name')
    if (project.customer_obj.gender === 'Firma') additionalOptionalKeys.push('last_name')
    if (project.customer_obj.gender !== 'Firma') additionalOptionalKeys.push('company')
    let emptyFieldsError = getEmptyFieldsError(project.customer_obj, emptyCustomerForm, additionalOptionalKeys)
    emptyFieldsError = emptyFieldsError || getEmptyProjectFieldsError(project)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false })
      console.error(emptyFieldsError)
      setShowAllMissingFields()
      onSuccess()
      return
    }
    return submit()
      .then((res) => {
        resetParent(res.data)
        onSuccess()
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      }).catch(error => { console.error('Error in "customer:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return <ConfirmationModalFooter
      btnLabel="Fertigstellen"
      id="customer-view-submit-data-input"
      // submitError={loadingElements.submitError}
      inProgress={loadingElements.inProgress}
      onConfirm={() => onSubmit(toggle)}
      onCancel={() => toggle()}
    />
  }

  return <CustomModal size='lg' getOpenButton={getOpenButton} onToggle={onToggle} title="Dateneingabe fertigstellen" getFooter={getFooter}>
    </CustomModal>
}

FinishDataInput.propTypes = {
  project: projectPropType,
  disable: PropTypes.bool,
  setShowAllMissingFields: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
