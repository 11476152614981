import React, { useState, useRef, useEffect } from 'react'

import { PropTypes } from 'prop-types'

import { CustomButton } from '../../../../elements/StyledElements'
import DropDown from '../../../../elements/DropDown'
import { Stack } from '@mui/material'
import { round, urlToFile } from '../../../../elements/utils'
import RoofProjectRoofImagePlanning from './RoofProjectRoofImagePlanning'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import html2canvas from 'html2canvas'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'

export default function RoofProjectEditRoofImagePlanning ({ roofImage, setAll }) {
  const winWidth = window.innerWidth
  const winHeight = window.innerHeight

  const [currentLines, setCurrentLines] = useState([])
  const [currentAngle, setCurrentAngle] = useState(roofImage.angle)
  const [currentFlatRoof, setCurrentFlatRoof] = useState(roofImage.flat_roof)
  const blendedStageRef = useRef(null)
  const legendRef = useRef(null)
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const roofWidthPx = roofImage.image_width_px
  const roofWidthM = roofImage.image_width_m

  const getBlendedImage = async () => {
    const uri = blendedStageRef.current.toDataURL()
    return await urlToFile(uri, 'roof-line.png', 'image/png')
  }

  const getLegendImage = async () => {
    const canvas = await html2canvas(legendRef.current,
      {
        scale: 1,
        onclone: (document, element) => {
          element.style.setProperty('zoom', 100 + '%')
        }
      })
    const uri = canvas.toDataURL('image/png', 1.0)

    return await urlToFile(uri, 'roof-line-legend.png', 'image/png')
  }

  const resetState = () => {
    setCurrentLines(roofImage.roofprojectroofline_set.map(line => {
      return {
        lineType: line.line_type,
        name: line.name,
        length: line.length,
        area: line.area,
        points: line.points,
        hullPoints: line.hull_points,
        centroid: line.centroid
      }
    }))
    setCurrentAngle(roofImage.angle)
    setCurrentFlatRoof(roofImage.flat_roof)
    blendedStageRef.current = null
    legendRef.current = null
  }

  const save = async () => {
    const blendedImage = await getBlendedImage()
    const legendImage = await getLegendImage()
    setAll(currentLines, currentAngle, currentFlatRoof, blendedImage, legendImage)
  }

  const getOpenButton = (toggle) => <CustomButton onClick={() => {
    resetState()
    toggle()
  }}>Bearbeiten
  </CustomButton>

  const getFooter = readyToSubmit
    ? (toggle) => <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={async () => {
            setLoadingElements({ ...loadingElements, inProgress: true })
            await save()
            setLoadingElements({ ...loadingElements, inProgress: false })
            toggle()
          }}
        />
    : () => {}

  useEffect(() => {
    resetState()
  }, [])

  return (
    <CustomModal title="Dachfotos bearbeiten" size="fullscreen" getOpenButton={getOpenButton} getFooter={getFooter}>
      <Stack direction='row' spacing={2} style={{ marginTop: '2px' }}>
        <DropDown
          onChange={setCurrentFlatRoof}
          text="Dachtyp wählen"
          options={[{ value: false, label: 'Satteldach' }, { value: true, label: 'Flachdach' }]}
          value={currentFlatRoof}
          disabled={!setCurrentFlatRoof}
        />
      </Stack>
      <br/>
      <RoofProjectRoofImagePlanning
        image={roofImage.image}
        currentImageWidthM={roofWidthM}
        currentImageWidthPx={roofWidthPx}
        maxWidth={round(95 / 100 * winWidth)}
        maxHeight={round(80 / 100 * winHeight)}
        polygons={currentLines}
        setPolygons={setCurrentLines}
        blendedStageRef={blendedStageRef}
        legendRef={legendRef}
        setReadyToSubmit={setReadyToSubmit}
      />
    </CustomModal>
  )
}

RoofProjectEditRoofImagePlanning.propTypes = {
  roofImage: PropTypes.object,
  setAll: PropTypes.func
}
