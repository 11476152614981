import React, { useState, Fragment } from 'react'
import { PropTypes } from 'prop-types'

import Collapse from '@material-ui/core/Collapse'
import CollapsibleArrow from './CollapsibleArrow'

export default function Collapsible ({ getOpenButton, children }) {
  const [open, setOpen] = useState(false)
  return <Fragment>
    <CollapsibleArrow isOpen={open}>
      {getOpenButton(() => setOpen(!open))}
    </CollapsibleArrow>
    <Collapse in={open} timeout="auto" unmountOnExit>
      {children}
    </Collapse>
  </Fragment>
}

Collapsible.propTypes = {
  getOpenButton: PropTypes.func,
  children: PropTypes.node
}
