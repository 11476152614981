import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Table } from 'react-bootstrap'
import { Form, Input, Spinner } from 'reactstrap'
import { groupPropType, userPropType } from '../../elements/PropTypes'
import { defaultIfEmpty, swapItem } from '../../elements/utils'
import { API_URL_COMMISSIONAMOUNT, API_URL_COMMISSIONTYPE } from '../../settings'
import axios from 'axios'
import LoadingPage from '../../elements/LoadingPage'
import { CustomButton } from '../../elements/StyledElements'

export default function UserCommissionSettings ({ employees, groups, projectType, session }) {
  const [commissionAmounts, setCommissionAmounts] = useState(null)
  const [commissionTypes, setCommissionTypes] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  useEffect(() => {
    loadData()
  }, [projectType])

  const loadData = () => {
    setLoadingElements({ submitError: false, inProgress: false })
    Promise.all([
      axios.get(API_URL_COMMISSIONTYPE, { params: { project_type: projectType } }).then(res => res.data),
      axios.get(API_URL_COMMISSIONAMOUNT, { params: { project_type: projectType } }).then(res => res.data)
    ]).then(([commissionTypes, commissionAmounts]) => {
      const employeeIds = employees.map(e => e.id)
      commissionAmounts = commissionAmounts.filter(a => employeeIds.includes(a.user))
      employees.forEach(employee => {
        commissionTypes.forEach(commissionType => {
          const getsCommission = commissionType.groups.includes(employee.group)
          const commissionAmount = commissionAmounts.find(a => a.user === employee.id && a.commission_type === commissionType.id)
          if (!commissionAmount) {
            commissionAmounts.push({ id: null, user: employee.id, commission_type: commissionType.id, changed: getsCommission, amount: getsCommission ? commissionType.default : 0 })
          } else {
            if (!getsCommission) {
              commissionAmount.amount = 0
              commissionAmount.changed = true
            }
          }
        })
      })
      setCommissionAmounts(commissionAmounts)
      const commissionData = commissionTypes
      swapItem(commissionData, 'Zusätzliche Optimierer', 'Zusätzliche Batteriemodule')
      setCommissionTypes(commissionTypes)
    })
  }

  const editCommissionAmount = (employee, commissionType, newAmount) => {
    setCommissionAmounts(cas => {
      const _amounts = [...cas]
      const _amount = _amounts.find(a => a.user === employee.id && a.commission_type === commissionType.id)
      const number = newAmount
      _amount.amount = number || number === 0 ? number : null
      _amount.changed = true
      return _amounts
    })
  }

  const save = () => {
    setLoadingElements({ ...loadingElements, submitError: false, inProgress: true })
    const filteredCommissionAmounts = commissionAmounts
      .filter(a => {
        const commissionType = commissionTypes.find(c => c.id === a.commission_type)
        const employee = employees.find(e => e.id === a.user)
        return commissionType.groups.includes(employee.group)
      })
    filteredCommissionAmounts.forEach(a => { a.amount = a.amount || 0 })
    axios.delete(API_URL_COMMISSIONAMOUNT, { data: { project_type: projectType, except_ids: filteredCommissionAmounts.filter(a => a.id).map(a => a.id) } })
      .then(() => axios.put(API_URL_COMMISSIONAMOUNT, filteredCommissionAmounts.filter(a => a.changed)))
      .then(() => setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      ).catch(() => { setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
      .then(() => { setCommissionTypes(null); setCommissionAmounts(null); loadData() })
  }

  if (!commissionTypes || !commissionAmounts) return <LoadingPage />

  const colWidth = `${Math.ceil(80 / commissionTypes.length)}%`

  return <Form>
    <Stack direction={'row'} spacing={1}>
      <CustomButton onClick={() => save()}>Speichern</CustomButton>
      {loadingElements.inProgress ? <Spinner size="sm" /> : null}
    </Stack>
    <Table>
      <thead>
        <tr>
          <th className="align-middle" style={{ width: '250px' }}><Typography className='secondary-textcolor'>Mitarbeiter</Typography></th>
          {commissionTypes
            .map(commissionType => <th style={{ width: colWidth }} className="align-middle" key={`commissions-header-commission-type-${commissionType.id}`}><Typography className='secondary-textcolor'>{commissionType.name}</Typography></th>)}
        </tr>
      </thead>
      <tbody>
        {groups.map(group =>
          <>
            <tr ><td colSpan={`${commissionTypes.length + 1}`}><Typography fontWeight='bold' className='secondary-textcolor'>{group.name}</Typography></td> </tr>
            {employees
              .filter(employee => employee.group === group.id)
              .map(employee => {
                const _commissionAmounts = commissionAmounts.filter(a => a.user === employee.id)
                return <tr key={`commissions-row-employee-${employee.id}`}>
                  <td><Typography className='secondary-textcolor'>{`${employee.first_name} ${employee.last_name}`}</Typography></td>
                  {commissionTypes
                    .map(commissionType => {
                      const disabled = !commissionType.groups.includes(employee.group)
                      const _commissionAmount = _commissionAmounts.find(a => a.commission_type === commissionType.id)
                      const textColorStyle = _commissionAmount.changed ? { color: '#ff0000' } : disabled ? { color: '#a6a5a2' } : {}
                      return <td key={`commissions-employee-${employee.id}-commissiontype-${commissionType.id}`}>
                        <Stack direction="row" spacing={1}>
                          <Input
                            id="input"
                            style={{ ...textColorStyle, textAlign: 'right', boxShadow: 'none', width: '30px', padding: '0px 0px 0px 0px', WebkitAppearance: 'none', MozAppearance: 'textfield', border: 'none' }}
                            type="number"
                            name="commission"
                            value={defaultIfEmpty(_commissionAmount.amount)}
                            autoComplete="off"
                            disabled={disabled}
                            onChange={e => editCommissionAmount(employee, commissionType, e.target.valueAsNumber)}
                            min={0}
                            onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
                          />
                          <Typography style={textColorStyle} className='secondary-textcolor'>{commissionType.unit}</Typography>
                        </Stack>
                      </td>
                    }
                    )}
                </tr>
              }
              )}
          </>
        )}
      </tbody>
    </Table>
  </Form>
}

UserCommissionSettings.propTypes = {
  employees: PropTypes.arrayOf(userPropType),
  groups: PropTypes.arrayOf(groupPropType),
  projectType: PropTypes.string,
  session: PropTypes.object
}
