import { PropTypes } from 'prop-types'
import React from 'react'

import TextField from '@material-ui/core/TextField'
import { usePlacesWidget } from 'react-google-autocomplete'

export default function AddressSearch ({ setLocation, session }) {
  const { ref: materialRef } = usePlacesWidget({
    apiKey: session.creds.google_api_key,
    onPlaceSelected: (place) => {
      setLocation({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
    },
    // inputAutocompleteValue: "country",
    options: {
      componentRestrictions: { country: 'de' },
      types: ['address'],
      fields: ['name', 'formatted_address', 'address_components', 'geometry.location']
    }
  })

  return (

    <TextField
      id="input"
      type="text"
      name="search"
      variant="outlined"
      label='Suche'
      // className={classes.inputfocused}
      // onChange={(e) => console.log(e.target.value)}
      // value={defaultIfEmpty(address.street_and_number)}
      required={false}
      size="small"
      // fullWidth
      inputRef={materialRef}
    />

  )
}

AddressSearch.propTypes = {
  setLocation: PropTypes.func,
  session: PropTypes.object
}
