import React, { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import { IconButton, Tooltip } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

import PlantPlanningForm from './PlantPlanningForm'

import { isString, filterObj, hasPermission, getErrorMessage, sortByOrder } from '../../../../elements/utils'
import { CustomButton } from '../../../../elements/StyledElements'
import { API_URL_MODULE, API_URL_PLANNING, API_URL_EMETERIMAGE, API_URL_EMETERCABINETIMAGE, API_URL_CREATEPLANNING, API_URL_ROOFTILEIMAGE, API_URL_BATTERY_KWH } from '../../../../settings'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import { projectPropType, planningPropType } from '../../../../elements/PropTypes'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import ErrorMessage from '../../../../elements/ErrorMessage'

const emptyPlanningForm = {
  project: null,
  module: null,
  heating_system: null,
  optimization: null,
  battery_kwh: null,
  batterykwh_set: [{ id: -1, kwh: null, order: 1 }],
  n_vertical_panels: 0,
  n_horizontal_panels: 0,
  n_optimizers: 0,
  kwp: 0,
  consumption_house: null,
  consumption_heatpump: null,
  consumption_car: null,
  floors: null,
  water_heater: 0,
  notes_frame: '',
  notes_cable: '',
  e_meter: null,
  e_meter_cabinet: null,
  big_e_meter_cabinet: null,
  ground_anchor: null,
  wallbox: null,
  wallbox_required: 0,
  heatpump: null,
  heatpump_interest: null,
  service_water_heatpump_interest: null,
  ecar: null,
  ecar_interest: null,
  circuit_preparation: null,
  large_household_consumers: null,
  emergency_socket: 0,
  emergency_house: null,
  pv_setbycustomer: false,
  rooftiles: null,
  wifi_basement: null,
  above_winter_garden: null,
  merge_circuits: 0,
  move_satellite_dish: null,
  special_installation: null,
  easy_cable: null,
  notes: '',
  n_inverters: null,
  roi_image: null,
  independence_image: null,
  onepointeight_emeterimage_set: [],
  twopointeight_emeterimage_set: [],
  twopointeight_unavailability: false,
  slsswitch_emeterimage_set: [],
  slsswitch_unavailability: false,
  additional_emeterimage_set: [],
  emetercabinetimage_set: [],
  roofimage_set: [],
  rooftileimage_set: [],
  tenant_system: false,
  tenant_model: null,
  n_tenants: null,
  n_additional_emeters: null,
  tenant_attachment: null,
  converter_measurement_available: null,
  grid_plant_protection_available: null,
  plant_expansion: false,
  commissioning_date_primary_plant: null,
  total_power_primary_plant_kVa: null,
  metering_point_identifier_primary_plant: null,
  contract_account_number_primary_plant: null,
  electricity_price: 0.35,
  resourcetype: 'PlantPlanning',
  smartmeter_rent: null
}

export default function PlantPlanningFormModal ({ project, planning, setPlanning, getOpenButton, resetParent, session }) {
  const [planningForm, setPlanningForm] = useState({ ...emptyPlanningForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [modules, setModules] = useState([])
  const [submitDefaultModal, setSubmitDefaultModal] = useState(false)

  const existOffer = !!planning && project.baseoffer_set.some(offer => offer.planning === planning.id)

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData(); toast.dismiss()
  }

  const clearData = () => {
    setPlanningForm({ ...emptyPlanningForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setSubmitDefaultModal(false)
    setModules([])
    if (setPlanning) setPlanning(null)
  }

  useEffect(() => {
    let nVerticalPanels = 0
    let nHorizontalPanels = 0
    let nOptimizers = 0
    planningForm.roofimage_set
      .forEach((roofImage) => {
        roofImage.roofimagepanel_set.forEach(panel => {
          if (panel.vertical) nVerticalPanels += 1
          else nHorizontalPanels += 1

          if (panel.optimizer) nOptimizers += 1
        })
      })
    setPlanningForm(form => ({
      ...form,
      n_vertical_panels: nVerticalPanels,
      n_horizontal_panels: nHorizontalPanels,
      n_optimizers: nOptimizers
    }))
  }, [planningForm.roofimage_set])

  useEffect(() => {
    setPlanningForm(p => {
      const nHorizontalPanels = p.n_horizontal_panels ? p.n_horizontal_panels : 0
      const nVerticalPanels = p.n_vertical_panels ? p.n_vertical_panels : 0
      const moduleId = p.module
      const module = modules.find(module => module.id === moduleId)
      const moduleKwp = module ? module.kwp : 0
      return { ...p, kwp: (nVerticalPanels + nHorizontalPanels) * moduleKwp }
    })
  }, [planningForm.n_horizontal_panels, planningForm.n_vertical_panels, planningForm.module, modules])

  /* FILL ############################################################## */

  const getDefaultModule = (modules) => {
    let defaultModule = modules.find(x => x.default)
    if (!defaultModule && modules.length > 0) {
      defaultModule = modules[0]
    }
    return defaultModule || null
  }

  const loadData = () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    axios.get(API_URL_MODULE).then(async res => {
      const modules = res.data.filter(module => module.available || module.id === (planning ? planning.module : null))
      setModules(modules)
      // fill Planning
      let filledPlanning = {
        ...emptyPlanningForm,
        batterykwh_set: emptyPlanningForm.batterykwh_set.map(battery => ({ ...battery }))
      }
      const defaultModule = getDefaultModule(modules)
      filledPlanning.module = defaultModule ? defaultModule.id : null
      if (planning) {
        planning = {
          ...planning,
          onepointeight_emeterimage_set: planning.emeterimage_set ? planning.emeterimage_set.filter(image => image.type === '1.8.0') : [],
          twopointeight_emeterimage_set: planning.emeterimage_set ? planning.emeterimage_set.filter(image => image.type === '2.8.0') : [],
          slsswitch_emeterimage_set: planning.emeterimage_set ? planning.emeterimage_set.filter(image => image.type === 'SLS-Switch') : [],
          additional_emeterimage_set: planning.emeterimage_set ? planning.emeterimage_set.filter(image => image.type === 'additional') : [],
          batterykwh_set: planning.batterykwh_set.length === 0 ? emptyPlanningForm.batterykwh_set : sortByOrder(planning.batterykwh_set)
        }
        filledPlanning = { ...filledPlanning, ...planning }
      } else {
        filledPlanning = { ...filledPlanning, project: project.id }
      }
      setPlanningForm(filledPlanning)
    })
  }

  /* UPLOADING ############################################################## */

  const submit = async (planningForm, onSaveClicked) => {
    const promise = planning
      ? await axios.put(API_URL_PLANNING + planning.id, planningForm)
      : await axios.post(API_URL_PLANNING, planningForm)

    const { data } = promise
    const { id } = data
    await getEmeterImagePromises(id, planningForm.onepointeight_emeterimage_set, API_URL_EMETERIMAGE, '1.8.0', getEmeterImagePromise)
    await getEmeterImagePromises(id, planningForm.twopointeight_emeterimage_set, API_URL_EMETERIMAGE, '2.8.0', getEmeterImagePromise)
    await getEmeterImagePromises(id, planningForm.slsswitch_emeterimage_set, API_URL_EMETERIMAGE, 'SLS-Switch', getEmeterImagePromise)
    await getEmeterImagePromises(id, planningForm.additional_emeterimage_set, API_URL_EMETERIMAGE, 'additional', getEmeterImagePromise)
    await getImagePromises(id, planningForm.emetercabinetimage_set, API_URL_EMETERCABINETIMAGE, getImagePromise)
    await getImagePromises(id, planningForm.rooftileimage_set, API_URL_ROOFTILEIMAGE, getImagePromise)
    await getBatteryKwhPromises(id, planningForm.batterykwh_set, API_URL_BATTERY_KWH, getBatteryKwhPromise)
    if (onSaveClicked) await axios.post(API_URL_CREATEPLANNING, { planning: id })
    return promise
  }

  const getBatteryKwhPromises = async (planningId, batteryKwh, url, getPromise) => {
    batteryKwh = batteryKwh.filter(b => b.kwh !== null)
    const deletePromise = (planning)
      ? axios.delete(url, { data: { planning: planningId, except_ids: batteryKwh.filter(b => !isNewObj(b)).map(b => b.id) } })
      : Promise.resolve()
    return deletePromise
      .then(() => Promise.all(batteryKwh.map(battery => getPromise(planningId, battery, url)))
      )
  }

  const getBatteryKwhPromise = async (planningId, batteryKwh, url) => {
    const formData = new FormData()
    formData.append('planning', planningId)
    formData.append('kwh', batteryKwh.kwh)
    formData.append('order', batteryKwh.order)
    if (batteryKwh.id < 0) return axios.post(url, formData)
    formData.append('id', batteryKwh.id)
    return axios.put(url + batteryKwh.id, formData)
  }

  const getEmeterImagePromises = async (planningId, images, url, type, getPromise) => {
    const deletePromise = planning
      ? axios.delete(url, { data: { planning: planningId, type, except_ids: images.filter(i => !isNewObj(i)).map(i => i.id) } })
      : Promise.resolve()
    return deletePromise
      .then(() => Promise.all(images.map(image => getPromise(planningId, image, url, type))))
  }

  const getEmeterImagePromise = async (planningId, image, url, type) => {
    const formData = new FormData()
    if (!isString(image.image)) {
      formData.append('image', image.image, image.image.name)
    } else return Promise.resolve()
    formData.append('planning', planningId)
    formData.append('type', type)
    if (image.id < 0) return axios.post(url, formData)
    formData.append('id', image.id)
    return axios.put(url + image.id, formData)
  }

  const getImagePromises = async (planningId, images, url, getPromise) => {
    const deletePromise = (planning)
      ? axios.delete(url, { data: { planning: planningId, except_ids: images.filter(i => !isNewObj(i)).map(i => i.id) } })
      : Promise.resolve()
    return deletePromise
      .then(() => Promise.all(images.map(image => getPromise(planningId, image, url))))
  }

  const getImagePromise = async (planningId, image, url) => {
    const formData = new FormData()
    if (!isString(image.image)) {
      formData.append('image', image.image, image.image.name)
    } else return Promise.resolve()
    formData.append('planning', planningId)
    if (image.id < 0) return axios.post(url, formData)
    formData.append('id', image.id)
    return axios.put(url + image.id, formData)
  }

  const isNewObj = (obj) => obj.id <= 0

  const fillWithDefault = (planningForm) => {
    const planningFormWithDefault = { ...planningForm }
    const defaultModule = getDefaultModule(modules)
    const formDefaultValues = {
      consumption_house: 4000,
      consumption_car: 0,
      consumption_heatpump: 0, // car: 2250, heatpump 5400
      e_meter: false,
      e_meter_cabinet: false,
      big_e_meter_cabinet: false,
      wallbox: false,
      ground_anchor: 1,
      wallbox_required: 0,
      heatpump: false,
      heatpump_interest: false,
      service_water_heatpump_interest: false,
      ecar: false,
      ecar_interest: false,
      circuit_preparation: false,
      emergency_socket: 0,
      emergency_house: false,
      pv_setbycustomer: false,
      rooftiles: false,
      wifi_basement: false,
      merge_circuits: 0,
      move_satellite_dish: false,
      easy_cable: true,
      special_installation: false,
      above_winter_garden: false,
      battery_kwh: 0,
      batterykwh_set: [{ id: -1, kwh: 0, order: 1 }],
      module: defaultModule,
      plant_expansion: false,
      electricity_price: 0.35,
      heating_system: 0,
      large_household_consumers: false
    }
    Object.keys(formDefaultValues).forEach(key => {
      if (planningFormWithDefault[key] === null || planningFormWithDefault[key] === '') {
        planningFormWithDefault[key] = formDefaultValues[key]
      }
    })
    return planningFormWithDefault
  }

  const onSubmit = async (onSuccess = null, onSaveClicked = false, submitWithDefault = false) => {
    let planningFormToSubmit = submitWithDefault ? fillWithDefault(planningForm) : planningForm
    planningFormToSubmit = filterObj(planningFormToSubmit, Object.keys(emptyPlanningForm))
    const optionalKeys = ['notes_frame', 'n_inverters', 'above_winter_garden', 'floors', 'notes_cable', 'optimization', 'notes', 'pdf', 'map', 'roi_image', 'independence_image', 'electricity_price', 'additional_emeterimage_set', 'smartmeter_rent', 'wifi_basement']
    if (planningForm.heatpump) optionalKeys.push('heatpump_interest', 'service_water_heatpump_interest')
    if (planningForm.ecar) optionalKeys.push('ecar_interest')
    if (!planningForm.plant_expansion) optionalKeys.push('commissioning_date_primary_plant', 'total_power_primary_plant_kVa', 'metering_point_identifier_primary_plant', 'contract_account_number_primary_plant')
    if (!planningForm.tenant_system) optionalKeys.push('tenant_model', 'n_tenants', 'n_additional_emeters', 'tenant_attachment', 'smartmeter_rent')
    if (!(planningForm.tenant_system || planningForm.kwp >= 30)) optionalKeys.push('converter_measurement_available')
    if (!(planningForm.converter_measurement_available === true && planningForm.kwp >= 30)) optionalKeys.push('grid_plant_protection_available')

    Object.keys(planningForm)
      .filter(key => !Object.keys(emptyPlanningForm).includes(key))
      .forEach(key => optionalKeys.push(key))
    if (!planning) optionalKeys.push('id')
    const planningKeys = Object.keys(planningFormToSubmit).filter(x => !optionalKeys.includes(x))
    const checkIfEmpty = (key, val) => {
      const excludedKeys = ['onepointeight_emeterimage_set', 'emetercabinetimage_set',
        'rooftileimage_set', 'roofimage_set']

      if (submitWithDefault && excludedKeys.includes(key)) {
        return false
      }
      return (val === null || val === '' || (Array.isArray(val) && val.length === 0))
    }
    const emptyFields = planningKeys.filter(key => checkIfEmpty(key, planningFormToSubmit[key]))
    const validForEmeterImage = (emeterimageSet, emeterUnavailability) => {
      return emeterimageSet.length > 0 || emeterUnavailability
    }
    if (validForEmeterImage(planningFormToSubmit.twopointeight_emeterimage_set, planningFormToSubmit.twopointeight_unavailability)) {
      const index = emptyFields.indexOf('twopointeight_emeterimage_set')
      if (index > -1) {
        emptyFields.splice(index, 1)
      }
    } else {
      emptyFields.push('twopointeight_emeterimage_set')
    }
    if (validForEmeterImage(planningFormToSubmit.slsswitch_emeterimage_set, planningFormToSubmit.slsswitch_unavailability)) {
      const index = emptyFields.indexOf('slsswitch_emeterimage_set')
      if (index > -1) {
        emptyFields.splice(index, 1)
      }
    }
    if (onSaveClicked && emptyFields.length !== 0) {
      console.error('Following fields are missing: ', emptyFields)
      toast.error(<ErrorMessage message='Bitte alle Informationen eintragen!' />)
      if (!submitWithDefault) {
        setLoadingElements({ ...loadingElements, showMissingFields: true })
        setSubmitDefaultModal(true)
      } else {
        setLoadingElements({
          ...loadingElements,
          submitError: 'Bitte alle Informationen eintragen!',
          inProgress: false,
          showMissingFields: true
        })
        console.error('Error when submitting with default as following fields are not filled: ', emptyFields)
        toast.error(<ErrorMessage message={'Error when submitting with default as following fields are not filled: ' + emptyFields.join(', ')} />)
      }
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    if (planningFormToSubmit.n_inverters === null) planningFormToSubmit.n_inverters = ((planningFormToSubmit.kwp >= 17) ? 3 : ((planningFormToSubmit.kwp >= 13) ? 2 : 1))
    planningFormToSubmit.is_complete_click = onSaveClicked
    try {
      if (planningFormToSubmit.project === null) return
      const planningResponse = await submit(planningFormToSubmit, onSaveClicked)
      resetParent(planningResponse.data)
      if (!planning && setPlanning) {
        setPlanning(planningResponse.data)
        setPlanningForm({ ...planningResponse.data, ...planningForm })
      }
      if (onSaveClicked && onSuccess) onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    } catch (error) {
      console.error('Error in PlantPlanningFormModal:onSubmit', error, error.stack)
      toast.error(<ErrorMessage message={getErrorMessage(error)} />)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    }
  }

  /* MODAL ############################################################## */

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    if (planning && !setPlanning) {
      return (
        <Tooltip title={existOffer ? 'Sehen' : 'Bearbeiten'} PopperProps={{ style: { zIndex: 9999 } }} >
          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={toggle} >
            {existOffer
              ? <SearchOutlinedIcon className='secondary-textcolor' fontSize='large' />
              : <EditOutlinedIcon className='secondary-textcolor' fontSize='large' />
            }
          </IconButton>
        </Tooltip>
      )
    }
    return <CustomButton onClick={toggle}>Neue Planung</CustomButton>
  }

  const getFooter = (toggle) => {
    return (
      <Fragment>
        <CustomModal
          size='md'
          isOpen={submitDefaultModal}
          setIsOpen={setSubmitDefaultModal}
          title="Planung unvollständig!"
          getFooter={(toggleDefaultModal) =>
            <SaveModalFooter
              onSave={() => { toggleDefaultModal(); onSubmit(toggle, true, true) }}
              onCancel={toggleDefaultModal}
              saveDisabled={existOffer}
              saveBtnLabel='Default-Werte'
              cancelBtnLabel='Weiter ausfüllen' />}>
        </CustomModal>
        <SaveModalFooter
          id="submit-planning-form"
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          saveBtnLabel='Abschließen'
          onSave={() => { onSubmit(toggle, true) }}
          saveDisabled={!hasPermission(session.user, 'customer_handling') || existOffer}
        />
      </Fragment>
    )
  }

  useEffect(() => {
    const debounceSubmit = debounce(() => onSubmit(null, false), 2500)

    if (!existOffer) debounceSubmit()

    return () => {
      debounceSubmit.cancel()
    }
  }, [planningForm])

  return (<CustomModal size='fullscreen' getOpenButton={_getOpenButton} title="Planung" getFooter={getFooter} onToggle={onToggle}>
    {(modules === null || planningForm === null)
      ? null
      : <Fragment>
        <PlantPlanningForm
          planning={planningForm}
          setPlanning={setPlanningForm}
          project={project}
          // voltavoData={voltavoData}
          modules={modules}
          session={session}
          showMissingFields={loadingElements.showMissingFields}
        />
      </Fragment>
    }
  </CustomModal>)
}

PlantPlanningFormModal.propTypes = {
  project: projectPropType,
  planning: planningPropType,
  setPlanning: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
