import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import { customerPropType, projectPropType } from '../../elements/PropTypes'
import { CustomButton } from '../../elements/StyledElements'
import { getEmptyFieldsError, removeFromObj } from '../../elements/utils'
import { API_URL_POSTCREATEPROJECT, API_URL_PROJECT, API_URL_USER, API_URL_PLANVILLELOCATION, API_URL_NEARESTPLANVILLELOCATION, API_URL_PREFERREDPLANVILLELOCATION } from '../../settings'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import ProjectForm from './ProjectForm'

export const emptyProjectForm = {
  id: null,
  resourcetype: null,
  customer: null,
  name: null,
  street_and_number_project: null,
  zip_and_city_project: null,
  province_project: null,
  note: null,
  salesman: null,
  planville_location: null
}

export default function ProjectFormModal ({ customer, project, resetParent, session }) {
  const [projectForm, setProjectForm] = useState({ ...emptyProjectForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [varyingAddress, setVaryingAddress] = useState(null)
  const [salesmen, setSalesmen] = useState(null)
  const [locations, setLocations] = useState([])
  const [nearestLocation, setNearestLocation] = useState(null)

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const clearData = () => {
    setProjectForm(emptyProjectForm)
    setVaryingAddress(null)
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
  }

  const loadLocations = () => {
    axios.get(API_URL_PLANVILLELOCATION).then(res => setLocations(res.data))
    if (!project && customer) {
      axios.get(API_URL_NEARESTPLANVILLELOCATION, { params: { customer: customer.id } }).then(res => {
        setProjectForm(p => ({ ...p, planville_location: res.data }))
        setNearestLocation(res.data)
      })
    }
  }

  const deleteLocation = (id) => {
    axios.delete(API_URL_PLANVILLELOCATION + id).then(() => {
      loadLocations()
    })
  }

  const loadData = () => {
    setProjectForm(project || { ...emptyProjectForm, customer: customer.id, street_and_number_project: customer.street_and_number, zip_and_city_project: customer.zip_and_city, province_project: customer.province })
    setVaryingAddress(project ? (project.street_and_number_project !== customer.street_and_number) || (project.zip_and_city_project !== customer.zip_and_city) || (project.province_project !== customer.province) : false)
    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } }).then(res => {
      const salesmen = res.data
        .filter(user => ['admins', 'salesmen', 'telemarketers', 'sales_managers', 'managers', 'office'].includes(user.group_key))
      setSalesmen(salesmen)
    })
    loadLocations()
  }

  useEffect(() => {
    if (!varyingAddress) {
      setProjectForm(form => ({ ...form, street_and_number_project: customer.street_and_number, zip_and_city_project: customer.zip_and_city, province_project: customer.province }))
    }
  }, [varyingAddress])

  useEffect(() => { loadData() }, [project])

  useEffect(() => { if (!project) loadData() }, [session.user])

  const getOpenButton = (toggle) => {
    if (project) { return <CustomButton onClick={toggle} icon="info">Projekt</CustomButton> }
    return <CustomButton onClick={toggle} style={{ width: '300px' }} icon="add" className='float-end' id="add-project-btn">Projekt hinzufügen</CustomButton>
  }

  const submit = async (projectForm) => {
    if (project) { return axios.put(API_URL_PROJECT + project.id, removeFromObj(projectForm, ['accept_mandate_signature', 'accept_mandate_document'])) }
    const promise = axios.post(API_URL_PROJECT, projectForm)
      .then((res) => {
        axios.post(API_URL_POSTCREATEPROJECT, { project: res.data.id })
        axios.post(API_URL_PREFERREDPLANVILLELOCATION, { location: nearestLocation, zip_code: projectForm.zip_and_city_project.split(' ')[0] })
        return res
      })
    return promise
  }

  const onSubmit = async (onSuccess) => {
    const additionalOptionalKeys = ['note', 'planville_location']
    const emptyFieldsError = getEmptyFieldsError(projectForm, emptyProjectForm, additionalOptionalKeys)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false })
      console.error(emptyFieldsError)
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(projectForm).then((res) => {
      resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "project:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                id="submit_project_form"
                submitError={loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={() => onSubmit(toggle)}
            />)
  }

  return (<CustomModal getOpenButton={getOpenButton} title="Projektinformationen" getFooter={getFooter} onToggle={onToggle}>
        <ProjectForm
            project={projectForm}
            setProject={setProjectForm}
            locations={locations}
            loadLocations={loadLocations}
            nearestLocation={nearestLocation}
            setNearestLocation={setNearestLocation}
            deleteLocation={deleteLocation}
            salesmen={salesmen}
            varyingAddress={varyingAddress}
            setVaryingAddress={setVaryingAddress}
            session={session}
            showMissingFields={loadingElements.showMissingFields}
        />
    </CustomModal>)
}

ProjectFormModal.propTypes = {
  customer: customerPropType,
  project: projectPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
