import { checkIfValueIsEmpty } from '../../../../elements/utils'
import { emptyProjectForm } from '../forms'

export const checkIfHeatpumpProjectFieldIsEmpty = (key, val, projectForm) => {
  // Removed for now
  if (key === 'accept_mandate_signature' || key === 'accept_mandate_document') {
    return checkIfValueIsEmpty(projectForm.accept_mandate_signature) && checkIfValueIsEmpty(projectForm.accept_mandate_document)
  }
  return checkIfValueIsEmpty(val)
}

export const additionalHeatpumpProjectFieldCheck = () => {
  const emptyFieldsError = null
  return emptyFieldsError
}

export const emptyHeatpumpProjectForm = {
  ...emptyProjectForm,
  manufacturer_current_heater: '',
  typename_current_heater: '',
  accept_mandate_signature: null,
  accept_mandate_document: null,
  labelcurrentheater_set: [],
  resourcetype: 'HeatpumpProject'
}
