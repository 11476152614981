import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import { Typography } from '@mui/material'

import CollapsibleTable from '../../elements/CollapsibleTable'
import { customerPropType, projectPropType } from '../../elements/PropTypes'
import { CustomButton } from '../../elements/StyledElements'
import { getCustomerName } from '../../elements/utils'
import { API_URL_CUSTOMEREMAIL } from '../../settings'
import CustomModal from '../shared/modal_utils/CustomModal'
import { Box } from '@material-ui/core'

export default function CustomerEmails ({
  customer,
  project,
  getOpenButton,
  session
}) {
  const [emails, setEmails] = useState([])

  const _customer = customer || project.customer_obj

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const loadData = () => {
    axios
      .get(API_URL_CUSTOMEREMAIL, {
        params: {
          customer: _customer.id,
          project: project ? project.id : null
        }
      })
      .then((res) => setEmails(res.data))
  }

  const clearData = () => {
    setEmails([])
  }

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return (
      <CustomButton onClick={toggle} icon="mail">
        E-Mails
      </CustomButton>
    )
  }

  const projectColumn =
    !project &&
    customer &&
    customer.project_set &&
    customer.project_set.length > 0

  const columns = [
    {
      name: 'Datum',
      key: 'date'
    }
  ]

  if (projectColumn) {
    columns.push({ name: 'Projekt', key: 'project' })
  }

  columns.push(
    ...[
      {
        name: 'E-Mail',
        key: 'emailKey'
      },
      {
        name: 'Absender',
        key: 'sender'
      },
      {
        name: 'Betreff',
        key: 'subject'
      }
    ]
  )

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={_getOpenButton}
      title={'Emails für ' + getCustomerName(_customer)}
      onToggle={onToggle}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}>
        <CollapsibleTable
          columns={columns}
          rows={emails.map((email, eidx) => ({
            date: email.sent_date,
            key: `${email.key}-${eidx}`,
            emailKey: email.key,
            sender: email.sender_name,
            subject: email.subject,
            child: (
              <Box marginLeft={0} marginTop={0} marginBottom={0}>
                <Typography
                  className={'secondary-textcolor'}
                  style={{ textAlign: 'left' }}
                >
                  {email.message}
                </Typography>
              </Box>
            ),
            project:
              (projectColumn &&
                email.project &&
                customer.project_set.find((p) => p.id === email.project)?.name) ||
              ''
          }))}
        />
        {
          customer?.lead && (
            <div style={{
              display: 'flex',
              justifyContent: 'end',
              padding: '10px'
            }}>
              <Link to={`/leads/${customer?.lead?.id}?leademailmodal=true`}>
                <CustomButton icon='mail'>Lead E-Mails</CustomButton>
              </Link>
            </div>
          )
        }
      </div>
    </CustomModal>
  )
}

CustomerEmails.propTypes = {
  customer: customerPropType,
  project: projectPropType,
  getOpenButton: PropTypes.func,
  session: PropTypes.object
}
