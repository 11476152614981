import React, { useState } from 'react'

import { PropTypes } from 'prop-types'
import axios from 'axios'
import { Input } from 'reactstrap'

import Button from '@mui/material/Button'

import { API_URL_CONTACTDENIAL } from '../../settings'
import { customerPropType } from '../../elements/PropTypes'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'

export default function ContactDenialModal ({ customer, resetParent, session }) {
  const [reason, setReason] = useState('')
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const submit = () => {
    return axios.post(API_URL_CONTACTDENIAL, { customer: customer.id, contact_denial_reason: reason })
  }

  const getOpenButton = (toggle) => {
    return <Button variant="outlined" color="error" onClick={toggle}>Kein Kontakt</Button>
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false })
    return submit().then((res) => {
      if (resetParent) resetParent(res.data)
      if (onSuccess) onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "ContactDenial:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => <SaveModalFooter
    submitError={loadingElements.submitError}
    inProgress={loadingElements.inProgress}
    onSave={() => onSubmit(toggle)}
    onCancel={toggle}
    saveBtnLabel={'Speichern'}
    cancelBtnLabel={'Abbrechen'}
  />

  return (
    <CustomModal title="Kunde wünscht keine weitere Kontaktaufnahme" getOpenButton={getOpenButton} getFooter={getFooter} onToggle={(isOpen) => { if (isOpen) setReason('') }}>
      <Input
        type="text"
        name="contact_denial_reason"
        onChange={e => setReason(e.target.value)}
        value={reason}
        placeholder="Grund für die Ablehnung weiteren Kontaktes"
        autoComplete="off" />
    </CustomModal>
  )
}

ContactDenialModal.propTypes = {
  customer: customerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
