import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'
import { isMobileOnly } from 'react-device-detect'

import { Typography, IconButton, Grid } from '@mui/material'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import CustomModal from './modal_utils/CustomModal'
import QRCode from 'react-qr-code'
import DropDown from '../../elements/DropDown'
import { CustomButton } from '../../elements/StyledElements'
import axios from 'axios'
import { API_URL_USER } from '../../settings'
import { PropTypes } from 'prop-types'

// import MapContainer from "../../elements/drafts/GoogleMapsView";

export default function BusinessVCard ({ session, onClose = () => { }, smallButton = false, passedOpenButton = null }) {
  const { user, companyConfig } = session
  const [currUser, setCurrUser] = useState(user.is_staff ? user : null)
  const [users, setUsers] = useState(user.is_staff ? [user] : [])
  const [vCardData, setVCardData] = useState(null)

  const buildVCardData = (userData) => {
    const title = '' // userData.group_key ? `\nTITLE;CHARSET=utf-8:${userData.group_key}` : ''
    const email = userData.email ? `\nEMAIL;INTERNET:${userData.email}` : ''
    const phone = userData.phone ? `\nTEL;WORK:${userData.phone}` : ''
    const ret = 'BEGIN:VCARD\nVERSION:3.0' + `\nN;CHARSET=utf-8:${userData.last_name};${userData.first_name};;;` + `\nFN;CHARSET=utf-8:${userData.name}` + `\nORG;CHARSET=utf-8:${companyConfig.company}` + title + email + phone + '\nEND:VCARD'
    return ret
  }

  useEffect(() => {
    setVCardData(currUser ? buildVCardData(currUser) : null)
  }, [currUser])

  const loadData = () => {
    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } }).then(res => {
      const users = res.data
      setUsers(users)
    })
  }

  const clearData = () => setUsers([user])

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else {
      clearData()
      onClose()
    }
  }

  useEffect(() => { loadData() }, [])

  const setVcardData = (userId) => {
    setCurrUser(users.find(e => e.id === userId))
    // const data = users.find(e => e.id === userId)
    const vData = buildVCardData(user)
    setVCardData(vData)
  }

  const iconfontsize = isMobileOnly ? 30 : 40

  const getOpenButton = (toggle) =>
    <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={toggle}>
      <WorkOutlineOutlinedIcon className='secondary-textcolor' style={{ fontSize: iconfontsize }} fontSize='large' />
      &nbsp;
      <Typography className='secondary-textcolor' fontSize='h6.fontSize'>
        Visitenkarte
      </Typography>
    </IconButton>

  const getSmallOpenButton = (toggle) =>
    <CustomButton onClick={toggle} icon="business">Visitenkarte</CustomButton>

  const openButton = passedOpenButton || (smallButton ? getSmallOpenButton : getOpenButton)

  const tableItems = currUser
    ? [
        { key: 'Vorname', value: currUser.first_name },
        { key: 'Nachname', value: currUser.last_name },
        { key: 'Telefon', value: currUser.phone },
        { key: 'E-Mail', value: currUser.email },
        { key: 'Firma', value: companyConfig.company }
      ]
    : []

  return (
    <CustomModal getOpenButton={openButton} size="xl" title="Visitenkarte" onToggle={onToggle}>
      <Fragment>
        <Container>
          <Row>
            <div className="text-center">
              <br />
              <DropDown
                onChange={setVcardData}
                options={users.map(t => ({ label: t.name, value: t.id }))}
                value={currUser ? currUser.id : null}
                text='Mitarbeiter wählen'
                search={true}
                style={{ marginBottom: '30px' }}
              />
            </div>

            <br />
            {currUser
              ? <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={6} className="text-center">
                  <QRCode value={vCardData} />
                </Grid>
                <Grid container item xs={12} sm={12} md={6}>
                  {tableItems.map((item, index) => <Fragment key={index}>
                    <Grid item xs={6} sm={6} md={3}>
                      <Typography style={{ wordWrap: 'break-word' }} className='secondary-textcolor'>
                        {item.key}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={9}>
                      <Typography style={{ wordWrap: 'break-word' }} className='secondary-textcolor'>
                        {item.value}
                      </Typography>
                    </Grid>
                  </Fragment>)}
                  {/* <Grid item xs={6} sm={6} md={6}>
                    <Typography style={{ wordWrap: 'break-word' }} className='secondary-textcolor'>
                      {tableItems.map(item => <Fragment key={`user-items-key-${item.key}`}>{item.key}: <br /></Fragment>)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography style={{ wordWrap: 'break-word' }} className='secondary-textcolor'>
                      {tableItems.map(item => <Fragment key={`user-items-value-${item.key}`}>{item.value}<br /></Fragment>)}
                    </Typography>
                  </Grid> */}
                  {/* <>
                    <Row>
                      <Col xs={6} className='d-flex justify-content'>
                        <Typography style={{ wordWrap: 'break-word' }} className='secondary-textcolor'>
                          {tableItems.map(item => <Fragment key={`user-items-key-${item.key}`}>{item.key}: <br /></Fragment>)}
                        </Typography>
                      </Col>
                      <Col>
                        <Typography style={{ wordWrap: 'break-word' }} className='secondary-textcolor'>
                          {tableItems.map(item => <Fragment key={`user-items-value-${item.key}`}>{item.value}<br /></Fragment>)}
                        </Typography>
                      </Col>
                    </Row>
                  </> */}
                </Grid>
              </Grid>
              : null}
            <br />

          </Row>
        </Container>
      </Fragment>
    </CustomModal>
  )
}
BusinessVCard.propTypes = {
  session: PropTypes.object,
  onClose: PropTypes.func,
  smallButton: PropTypes.bool,
  passedOpenButton: PropTypes.func
}
