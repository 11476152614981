import React from 'react'
import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'

import { Typography } from '@mui/material'

import { defaultIfEmpty } from '../../../../../elements/utils'
import { operatorPropType } from '../../../../../elements/PropTypes'

export default function OperatorForm ({ operator, setOperator, showMissingFields }) {
  const onChange = e => {
    setOperator({ ...operator, [e.target.name]: e.target.value })
  }

  return (
    <Form>
      <FormGroup>
        <Typography className='secondary-textcolor'>Netzbetreiber</Typography>
        <Input
          id="input"
          type="text"
          name="name"
          onChange={onChange}
          value={defaultIfEmpty(operator.name)}
          required={true}
          invalid={!!(showMissingFields && !(operator.name))}
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>
      <Typography className='secondary-textcolor'>Emails</Typography>
      <FormGroup key={'email-input'}>
        <Input
          id="input"
          type="email"
          name="email"
          onChange={onChange}
          value={defaultIfEmpty(operator.email)}
          autoComplete="off"
          style={{ boxShadow: 'none' }}
        />
      </FormGroup>

    </Form>)
}

OperatorForm.propTypes = {
  operator: operatorPropType,
  setOperator: PropTypes.func,
  showMissingFields: PropTypes.bool
}
