/* eslint-disable camelcase */
import React from 'react'
import UploadImages from '../../../../shared/images/UploadImages'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

const fieldWidth = '50%'

export const getDataInputFormTemplate = () => [
  {
    type: 'text',
    name: 'Hersteller Bestandsheizung:',
    key: 'manufacturer_current_heater',
    width: fieldWidth
  },
  {
    type: 'text',
    name: 'Typenbezeichnung Bestandsheizung:',
    key: 'typename_current_heater',
    width: fieldWidth
  },
  {
    type: 'custom',
    name: 'Foto von Typenschild von Bestandheizung:',
    key: 'labelcurrentheater_set',
    isEmpty: labelcurrentheater_set => !labelcurrentheater_set.length,
    getFieldHtml: (form, setForm, showMissingFields, fieldIsEmpty) => {
      const deleteImage = (image) => {
        setForm(f => ({ ...f, labelcurrentheater_set: f.labelcurrentheater_set.filter(i => i.id !== image.id) }))
      }

      const addImage = (image) => {
        setForm(f => ({
          ...f,
          labelcurrentheater_set: [...f.labelcurrentheater_set, image]
        }))
      }

      return (
        <>
          <UploadImages
            images={form.labelcurrentheater_set}
            addImage={addImage}
            deleteImage={deleteImage}
          />
          {showMissingFields && !form.labelcurrentheater_set.length && <ErrorOutlineOutlinedIcon color='error' fontSize='large' />}
        </>
      )
    }
  }
]
