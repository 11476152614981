import { PropTypes } from 'prop-types'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { Input } from 'reactstrap'
import axios from 'axios'

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Collapse, IconButton, Stack, Typography } from '@mui/material'

import DropDown from '../../elements/DropDown'
import { date2String, hasPermission, hexToRGB } from '../../elements/utils'
import { API_URL_GROUP, API_URL_TASKLABEL, API_URL_TASKTYPE, API_URL_USER } from '../../settings'
import TaskTable from './TaskTable'
import TaskFormModal from './TaskFormModal'
import { CustomButton } from '../../elements/StyledElements'
import { taskPriorities } from './utils'
import TaskLegend from './TaskLegend'
import CollapsibleArrow from '../../elements/CollapsibleArrow'
import TaskCarousel from './TaskCarousel'

export default function TaskLists ({ employeeId = null, registeredById = null, coordinatorId = null, session }) {
  // ensure that at leas one of the variables coordinator, registeredBy and employee is equal to the current user
  // -> make sure, that users don't see all tasks which are not allowed to
  const permissionAllTasks = hasPermission(session.user, 'tasks_all_employees')
  if (!(permissionAllTasks || [employeeId, coordinatorId, registeredById].includes(session.user.id))) return null
  const permissionEmployeeSelection = !employeeId || permissionAllTasks
  const permissionCoordinatorSelection = !coordinatorId || permissionAllTasks
  const permissionRegisteredBySelection = !registeredById || permissionAllTasks

  const { search: urlSearch } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(urlSearch), [urlSearch])

  const searchParams2Employee = () => {
    if (permissionEmployeeSelection && searchParams.get('mitarbeiter') !== null) {
      const _employeeParam = searchParams.get('mitarbeiter')
      const _employeeId = (_employeeParam === 'alle') ? null : Number(_employeeParam)
      return _employeeId
    } else return undefined
  }

  const getInitialEmployee = () => {
    const _employeeId = searchParams2Employee()
    return (_employeeId !== undefined) ? _employeeId : employeeId
  }

  const [filterOpen, setFilterOpen] = useState(false)

  const [group, setGroup] = useState(null)

  const [employee, setEmployee] = useState(getInitialEmployee())
  const [coordinator, setCoordinator] = useState(coordinatorId)
  const [registeredBy, setRegisteredBy] = useState(registeredById)

  const [taskType, setTaskType] = useState(null)
  const [taskLabel, setTaskLabel] = useState(null)
  const [priority, setPriority] = useState(null)
  const [seed, setSeed] = useState(0)

  const [taskLabels, setTaskLabels] = useState(null)
  const [taskTypes, setTaskTypes] = useState(null)
  const [employees, setEmployees] = useState(null)
  const [groups, setGroups] = useState(null)

  const [searchInput, setSearchInput] = useState(null)
  const [search, setSearch] = useState(null)

  const resetState = () => setSeed(Math.random())

  useEffect(() => {
    if (permissionAllTasks) {
      axios.get(API_URL_GROUP).then(res => setGroups(res.data))
    }
    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } }).then(res => setEmployees(res.data))
    axios.get(API_URL_TASKTYPE).then(res => setTaskTypes(res.data))
    axios.get(API_URL_TASKLABEL).then(res => setTaskLabels(res.data))
  }, [])

  useEffect(() => {
    if (!employees) return // run if 'searchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchInput)
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchInput])

  const getQueryParams = (params) => {
    return {
      finished: false,
      employees: employee,
      coordinator,
      registered_by: registeredBy,
      group,
      seed,
      priority,
      nested: true,
      task_type: taskType,
      labels: taskLabel,
      search,
      ...params
    }
  }

  return (
    <Fragment>
      <Stack direction='row' alignItems="center" spacing={2} width='100%'>
        <TaskFormModal
          getOpenButton={(toggle) => <CustomButton onClick={toggle}>Neue Aufgabe</CustomButton>}
          resetParent={resetState}
          session={session}
        />
        <Stack direction='row' justifyContent="space-between" alignItems="center" spacing={2} width='100%'>
          <CollapsibleArrow isOpen={filterOpen}>
            <IconButton size="medium" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={() => { setFilterOpen(!filterOpen) }}><FilterAltOutlinedIcon style={{ color: '#424242' }} fontSize='medium' />
              <Typography className='secondary-textcolor'>Filtern</Typography>
            </IconButton>
          </CollapsibleArrow>
          <TaskLegend />
        </Stack>
      </Stack>
      <Collapse in={filterOpen} timeout="auto" unmountOnExit>
        <br />
        <Stack direction={isMobileOnly ? 'column' : 'row'} spacing={2} style={{ marginBottom: '30px' }}>
          {permissionEmployeeSelection && employees &&
            <DropDown
              value={employee}
              onChange={setEmployee}
              options={employees.map(e => ({ value: e.id, label: e.name }))}
              text='Mitarbeiter'
              search={true}
            />}
          {permissionCoordinatorSelection && employees &&
            <DropDown
              value={coordinator}
              onChange={setCoordinator}
              options={employees.filter(e => e.is_coordinator).map(e => ({ value: e.id, label: e.name }))}
              text='Koordinator'
              search={true}
            />}
          {permissionRegisteredBySelection && employees &&
            <DropDown
              value={registeredBy}
              onChange={setRegisteredBy}
              options={employees.map(e => ({ value: e.id, label: e.name }))}
              text='Erstellt von'
              search={true}
            />}
          {permissionEmployeeSelection && groups && <DropDown
            value={group}
            onChange={setGroup}
            options={groups.map(g => ({ value: g.id, label: g.name }))}
            text='Rolle'
            search={true}
          />}
        </Stack>
        <Stack direction={isMobileOnly ? 'column' : 'row'} spacing={2} style={{ marginBottom: '30px' }}>
          {taskTypes && <DropDown
            value={taskType}
            onChange={setTaskType}
            options={taskTypes.map(t => ({ value: t.id, label: t.name, props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } } }))}
            text='Aufgaben-Typ'
            search={true}
          />}
          {taskLabels && <DropDown
            value={taskLabel}
            onChange={setTaskLabel}
            options={taskLabels.map(t => ({ value: t.id, label: t.name, props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } } }))}
            text='Label'
            search={true}
          />}
          <DropDown
            value={priority}
            onChange={setPriority}
            options={taskPriorities.map(t => ({ value: t.value, label: t.label, props: { style: { backgroundColor: hexToRGB(t.color, 1) } } }))}
            text='Priorität'
            search={true}
          />
        </Stack>
        <Stack direction={isMobileOnly ? 'column' : 'row'} spacing={2} style={{ marginBottom: '30px' }}>
          <Input
            style={{ flex: 1, marginRight: '8px', backgroundColor: '#ffffff', color: '#424242', borderColor: '#424242', boxShadow: 'none', maxWidth: '300px' }}
            type="text"
            name="searchInput"
            onChange={e => setSearchInput(e.target.value)}
            value={searchInput}
            placeholder="Suche"
            autoComplete="off" />
        </Stack>
      </Collapse>

      <TaskCarousel
        header={'Heute'}
        queryParams={getQueryParams({ include_not_scheduled: false })}
        resetParent={resetState}
        session={session}
      />

      <TaskTable
        header='Frist verstrichen'
        queryParams={getQueryParams({
          deadline_or_date_passed: date2String(new Date()),
          include_not_scheduled: false
        })}
        resetParent={resetState}
        showIfEmpty={false}
        session={session}
      />

      <TaskTable
        header='Abgelehnt'
        queryParams={getQueryParams({
          accepted: false
        })}
        resetParent={resetState}
        showIfEmpty={false}
        session={session}
      />

      <TaskTable
        header='Bestätigung ausstehend'
        queryParams={getQueryParams({
          wait_for_acceptance: true
        })}
        resetParent={resetState}
        showIfEmpty={false}
        session={session}
      />

      <TaskTable
        header='Nicht terminiert'
        queryParams={getQueryParams({
          scheduled: false
        })}
        resetParent={resetState}
        showIfEmpty={false}
        session={session}
      />
      <TaskTable
        header='Überprüfung ausstehend'
        queryParams={getQueryParams({
          finished: true,
          finished_confirmed: false
        })}
        resetParent={resetState}
        showIfEmpty={false}
        session={session}
      />
      <TaskTable
        header='Erledigt'
        defaultIsOpen={false}
        queryParams={getQueryParams({
          finished: true,
          finished_confirmed: true
        })}
        resetParent={resetState}
        session={session}
      />
    </Fragment>
  )
}

TaskLists.propTypes = {
  employeeId: PropTypes.Number,
  registeredById: PropTypes.Number,
  coordinatorId: PropTypes.Number,
  session: PropTypes.object
}
