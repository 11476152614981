import React, { Fragment } from 'react'

import { PropTypes } from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'

import { Typography } from '@mui/material'

import { projectPropType } from '../../../../elements/PropTypes'
import { eMeterActions } from '../../../../elements/utils'
import FinishDataInput from '../../data_input/FinishDataInput'
import PlantProjectFormModal from './PlantProjectFormModal'

export default function PlantProjectDataInput ({ project, showAllMissingFields, setShowAllMissingFields, setSuccess, resetParent, session }) {
  return (
    <Fragment>
      <Container>
        <Row>
          <Col xs={2} classname='d-flex justify-content'>
            <Typography className='secondary-textcolor' display="inline">Straße Nr.: </Typography><br />
            <Typography className='secondary-textcolor' display="inline">PLZ Ort: </Typography><br />
            <Typography className='secondary-textcolor' display="inline">Vollmacht: {showAllMissingFields && !project.accept_mandate_signature && !project.accept_mandate_document ? <Typography display="inline" style={{ color: 'red', fontWeight: 500 }}>!</Typography> : null}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">Zähler: {showAllMissingFields && project.emeter_set.length === 0 ? <Typography display="inline" style={{ color: 'red', fontWeight: 500 }}>!</Typography> : null}</Typography><br />
            {project.emeter_set.length > 0
              ? <ul>
                <li>
                  {project.emeter_set.map(emeter => <>
                    <Typography className='secondary-textcolor' display="inline">Zählernr.: </Typography><br />
                    <Typography className='secondary-textcolor' display="inline">Zählerstand: </Typography><br />
                    <Typography className='secondary-textcolor' display="inline">Aktion: </Typography><br />
                  </>)}
                </li>
              </ul>
              : null}
          </Col>
          <Col>
            <Typography className='secondary-textcolor' display="inline">{project.street_and_number_project}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">{project.zip_and_city_project}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">{(project.accept_mandate_signature || project.accept_mandate_document) ? <>&#10004;</> : <>&#x2716;</>}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">{project.emeter_set.length === 0 ? 'Keine Zähler eingetragen' : null}</Typography><br />
            {project.emeter_set.map(emeter => <>
              <Typography className='secondary-textcolor' display="inline">{emeter.e_meter_id}</Typography><br />
              <Typography className='secondary-textcolor' display="inline">{emeter.value}</Typography><br />
              <Typography className='secondary-textcolor' display="inline">{(emeter.action !== null) ? eMeterActions.find(a => a.value === emeter.action).label : ''}</Typography><br />
            </>)}
          </Col>
          <Col>
            <PlantProjectFormModal
              project={project}
              disable={project.data_input_done}
              showAllMissingFields={showAllMissingFields}
              resetParent={resetParent}
              session={session}
            />
            <br />
            <br />
            <FinishDataInput
              project={project}
              disable={project.data_input_done}
              setShowAllMissingFields={setShowAllMissingFields}
              session={session}
              resetParent={(data) => { setSuccess(); resetParent(data, false) }}
            />
          </Col>
        </Row>
      </Container>
    </Fragment >
  )
}

PlantProjectDataInput.propTypes = {
  project: projectPropType,
  showAllMissingFields: PropTypes.bool,
  setShowAllMissingFields: PropTypes.func,
  setSuccess: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
