import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

import ConstructionCalendar from './calendar/ConstructionCalendar'

export default function Constructions ({ session }) {
  return (
    <Fragment>
      {session.user
        ? <ConstructionCalendar
          session={session}
        />
        : null}
    </Fragment>)
}

Constructions.propTypes = {
  session: PropTypes.object
}
