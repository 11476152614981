import React from 'react'

import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { PropTypes } from 'prop-types'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

export default function PDFView ({ filepath }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const token = localStorage.getItem('token')
  const axiosHeaders = { Authorization: `Token ${token}` }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
      <div style={{ height: '1200px' }}>
        <Viewer theme="light" fileUrl={filepath} httpHeaders={axiosHeaders} withCredentials={true} defaultScale={1} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  )
}

PDFView.propTypes = {
  filepath: PropTypes.string
}
