import React, { useState } from 'react'
import { Input } from 'reactstrap'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import { Typography } from '@mui/material'
import CustomModal from '../shared/modal_utils/CustomModal'
import { CustomButton } from '../../elements/StyledElements'
import { API_URL_WAREHOUSECOMISSION } from '../../settings'

export default function WarehouseDeliveryConfirmationModal ({ warehouseComission, isSubmitted, style }) {
  const [comissionId, setComissionId] = useState(null)

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle} style={style} disabled={isSubmitted}> Komission </CustomButton>
  }

  const onToggle = () => {
    axios.get(API_URL_WAREHOUSECOMISSION).then(res => { setComissionId(res.data) })
  }

  return (<CustomModal getOpenButton={getOpenButton} title="Komission" onToggle={onToggle}>
        <br />
        <div style={{ position: 'relative' }}>
          <Typography className='secondary-textcolor'>KomissionName</Typography>
          <Input style={{ boxShadow: 'none', backgroundColor: 'white', color: 'black' }}
            type="text" disabled value={warehouseComission.name ? warehouseComission.name : comissionId}
          />
        </div>
    </CustomModal>)
}

WarehouseDeliveryConfirmationModal.propTypes = {
  warehouseComission: PropTypes.object,
  isSubmitted: PropTypes.bool,
  style: PropTypes.object
}
