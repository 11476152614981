import React, { useState, Fragment, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Input } from 'reactstrap'
import 'react-image-crop/dist/ReactCrop.css'
import Compress from 'browser-image-compression'

import CropImage from './CropImage'

export default function UploadCroppedImage ({ setCroppedImage, id, maxImageWidth = 600, maxImageHeight = 450 }) {
  const [imageSrc, setImageSrc] = useState(null)

  const filename = useRef(null)
  const compressedImg = useRef(null)

  const onFileChange = (e) => {
    if ((!e.target.files) || e.target.files.length < 1) return
    const file = e.target.files[0]
    filename.current = file.name.split('.')[0]

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1600,
      // Use webworker for faster compression
      useWebWorker: true
    }

    Compress(file, options)
      .then(compressedBlob => {
        compressedBlob.lastModifiedDate = new Date()

        const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })

        compressedImg.current = convertedBlobFile
        setCroppedImage(convertedBlobFile)
        const reader = new FileReader()
        reader.addEventListener('load', () => setImageSrc(reader.result), false)
        reader.readAsDataURL(convertedBlobFile)
      }).catch(e => {
        alert('Fehler bei der Bildkompression!')
      })
  }

  return (
    <Fragment>
      <br />
      <Input style={{ width: '30%', boxShadow: 'none' }} id={`${id}-upload-cropped-image-input`} type="file" onChange={onFileChange} />
      <br />
      {imageSrc
        ? <>
          {/* <Typography fontSize='h6.fontSize' className='secondary-textcolor'> Bild ausschneiden </Typography> */}
          <CropImage
            setCroppedImage={setCroppedImage}
            image={imageSrc}
            maxImageHeight={maxImageHeight - 200}
            maxImageWidth={maxImageWidth}
          />
        </>
        : null}
      <br />

    </Fragment>
  )
}

UploadCroppedImage.propTypes = {
  id: PropTypes.string,
  setCroppedImage: PropTypes.func,
  maxImageWidth: PropTypes.number,
  maxImageHeight: PropTypes.number
}
