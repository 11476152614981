import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import CollapsibleTable from '../../elements/CollapsibleTable'
import { CustomButton } from '../../elements/StyledElements'
import { getCustomerName } from '../../elements/utils'
import { API_URL_LEAD_ACTIVITY } from '../../settings'
import CustomModal from '../shared/modal_utils/CustomModal'

export default function LeadActivities ({ lead, getOpenButton }) {
  const LeadActivityModal = new URLSearchParams(window.location.search).get('leadactivitymodal')
  const [isOpen, _setIsOpen] = useState(LeadActivityModal === 'true')

  const setIsOpen = (value) => {
    _setIsOpen(value)
    const newURL = new URL(window.location.href)
    newURL.searchParams.set('leadactivitymodal', value)
    window.history.pushState({}, '', newURL)
  }

  const [activities, setActivities] = useState([])

  const loadData = async () => {
    const res = await axios.get(API_URL_LEAD_ACTIVITY, {
      params: {
        lead: lead.id
      }
    })
    setActivities(res.data)
  }

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton onClick={toggle} icon="history">Aktivitäten</CustomButton>
  }

  const columns = [
    {
      name: 'Datum',
      key: 'date'
    }
  ]

  columns.push(...[
    {
      name: 'Benutzer',
      key: 'user'
    },
    {
      name: 'Beschreibung',
      key: 'description'
    }
  ])

  return (
    <CustomModal
      size="fullscreen"
      getOpenButton={_getOpenButton}
      title={'Aktivitäten für ' + getCustomerName(lead)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <CollapsibleTable
        collapsible={false}
        columns={columns}
        rows={activities.map(activity => ({
          date: activity.date.slice(0, 10) + ', ' + activity.date.slice(11, 19),
          description: activity.description,
          user: activity.user_name
        }))}
      />
    </CustomModal>
  )
}

LeadActivities.propTypes = {
  lead: PropTypes.object,
  getOpenButton: PropTypes.func,
  session: PropTypes.object
}
