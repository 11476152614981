import React from 'react'

import { Box, Tooltip, Typography } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { getTaskColor } from './utils'

export default function TaskLegend () {
  return <Tooltip title={
      <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'inline-box', background: getTaskColor({ priority: 3, on_hold: false }, 1), width: '44px', height: '14px', verticalAlign: 'middle', marginRight: '5px' }} />
          <Typography inline={true}> Kritische Priorität </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'inline-box', background: getTaskColor({ priority: 2, on_hold: false }, 1), width: '44px', height: '14px', verticalAlign: 'middle', marginRight: '5px' }} />
          <Typography inline={true}> Hohe Priorität </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'inline-box', background: getTaskColor({ priority: 0, on_hold: true }, 1), width: '50px', height: '14px', verticalAlign: 'middle', marginRight: '5px' }} />
          <Typography inline={true}> Vorherige Aufgaben noch nicht erledigt </Typography>
        </Box>
      </>
    } enterTouchDelay={0}>
      <InfoOutlined />
    </Tooltip>
}

TaskLegend.propTypes = {
}
