import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types'
import 'leaflet/dist/leaflet.css'
import './mapstyles.css'
import L from 'leaflet'
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, useMap, Polyline } from 'react-leaflet'

import { round } from '../../elements/utils'

const legendObjectPropType = PropTypes.shape({
  color: PropTypes.string,
  label: PropTypes.string
})

const markerPropType = PropTypes.shape({
  popup: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  coords: PropTypes.arrayOf(PropTypes.number),
  color: PropTypes.string,
  getIcon: PropTypes.func
})

const pathPropType = PropTypes.shape({
  popup: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  color: PropTypes.string,
  getIcon: PropTypes.func
})

const getMarkerIcon = (color, borderColor) => {
  color = color || '#55F5FF'

  const markerHtmlStyles = `
    background-color: ${color};
    width: 1rem;
    height: 1rem;
    display: block;
    left: 0rem;
    top: 0rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid ${borderColor || '#ffffff'}
    `

  const markerIcon = L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [8, 20],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="${markerHtmlStyles}"/>`
  })

  return markerIcon
}

function Legend ({ legend }) {
  const map = useMap()
  useEffect(() => {
    if (map) {
      const div = L.DomUtil.get('leadlegend')
      if (legend.length === 0) { if (div) L.DomUtil.remove(div); return }
      const llegend = L.control({ position: 'bottomright' })
      llegend.onAdd = () => {
        let div = L.DomUtil.get('leadlegend')
        if (!div) {
          div = L.DomUtil.create('div', 'info legend')
          div.setAttribute('id', 'leadlegend')
        }
        const labels = legend.map(object => {
          if (!object.color && !object.borderColor) return object.label
          const border = object.borderColor ? `${object.borderColor} solid 2px` : ''
          return `<i style="background:${object.color || 'transparent'}; border: ${border}"></i>` + object.label
        })
        div.innerHTML = labels.join('<br>')
        return div
      }
      llegend.addTo(map)
    }
  }, [map, legend]) // here add map
  return null
}

Legend.propTypes = {
  legend: PropTypes.arrayOf(legendObjectPropType)
}

const ZOOM = 6
const CENTER = [51.094490, 6.219180] // Genenderstr. Erkelenz

export default function EventMap ({ location, paths, markers, legend }) {
  const [map, setMap] = useState(null)
  const featureGroupRef = useRef()

  useEffect(() => {
    if (!map) return
    if (location) {
      map.setView([location.lat, location.lng], 10)
    } else {
      map.setView(CENTER, ZOOM)
    }
  }, [map, location])

  useEffect(() => {
    if (!map) return
    if (location) return
    const bounds = featureGroupRef.current.getBounds()
    if (Object.keys(bounds).length === 0) return
    map.fitBounds(bounds)
  }, [map, markers, paths])

  return (
    <div>
      <div className="EventMap" style={{ height: round(window.innerHeight * 0.8), width: round(window.innerWidth * 0.8), display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
        {/* <div className="EventMap" style={{ height: round(window.innerHeight * 0.9), width: round(window.innerWidth * 0.9) }}> */}
        <MapContainer doubleClickZoom={false} whenCreated={setMap} ref={setMap} >
          <FeatureGroup ref={featureGroupRef}>
            {location && <Marker
              position={[location.lat, location.lng]} icon={getMarkerIcon('red', 'red')}
            />}
            {markers && markers.map((marker, markerIdx) => {
              return <Marker key={`marker-${markerIdx}`} position={marker.coords} icon={(marker.getIcon || getMarkerIcon)(marker.color, marker.borderColor)}>
                {marker.popup && <Popup>
                  {marker.popup}
                </Popup>}
              </Marker>
            })}
            {paths && paths.map((path, pathIdx) => <Polyline key={`path-${pathIdx}`} pathOptions={{ color: path.color || '#0000ff' }} positions={path.points} />)}
            {/* {paths && paths.filter(path => path.points.length > 0).map((path, pathIdx) => path.points.map((point, pointIdx) => {
              return <Marker key={`path-${pathIdx}-point-${pointIdx}`} position={point} icon={path.icon || getMarkerIcon(path.color)}>
              </Marker>
            })).flat()} */}
            {paths && paths.filter(path => path.points.length > 0).map((path, pathIdx) => {
              return <Marker key={`path-${pathIdx}-lastpoint`} position={path.points[path.points.length - 1]} icon={(path.getIcon || getMarkerIcon)(path.color, path.borderColor)}>
                {path.popup && <Popup>
                  {path.popup}
                </Popup>}
              </Marker>
            })}
          </FeatureGroup>
          {legend && <Legend legend={legend} />}
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </div>
    </div >
  )
}

EventMap.propTypes = {
  location: PropTypes.object,
  paths: PropTypes.arrayOf(pathPropType),
  markers: PropTypes.arrayOf(markerPropType),
  legend: PropTypes.arrayOf(legendObjectPropType)
}
