import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Input } from 'reactstrap'

import { Grid, Stack, Typography, Container } from '@mui/material'

import { getRandomId, defaultIfEmpty } from '../../../../../elements/utils'
import DropDown from '../../../../../elements/DropDown'
import { CustomButton } from '../../../../../elements/StyledElements'
import CustomModal from '../../../../shared/modal_utils/CustomModal'

const emeterActions = [
  { label: 'Zählerwechsel', value: 0 },
  { label: 'Keine Aktion', value: 1 }
]

const fieldWidth = '50%'

export const getDataInputFormTemplate = (projectId) => [
  {
    type: 'date',
    name: 'Geburtsdatum:',
    key: 'birth_date',
    width: fieldWidth,
    optional: false
  },
  {
    type: 'text',
    name: 'Kontoinhaber:',
    key: 'account_owner',
    width: fieldWidth,
    optional: false
  },
  {
    type: 'text',
    name: 'IBAN:',
    key: 'iban',
    width: fieldWidth,
    optional: false
  },
  {
    type: 'text',
    name: 'BIC:',
    key: 'bic',
    width: fieldWidth,
    optional: false
  },
  {
    type: 'text',
    name: 'Bank:',
    key: 'bank',
    width: fieldWidth,
    optional: false
  },
  {
    type: 'text',
    name: 'Steuernummer:',
    key: 'tax_number',
    width: fieldWidth,
    optional: false
  },
  {
    type: 'custom',
    name: 'Zähler',
    key: 'emeter_set',
    isEmpty: emeterSet => !(
      (emeterSet.length > 0) && emeterSet.every(emeter => (emeter.action != null && emeter.value != null && emeter.e_meter_id != null && emeter.e_meter_id !== ''))
    ),
    getFieldHtml: (form, setForm, showMissingFields, fieldIsEmpty) => {
      const deleteEMeter = (id) => {
        setForm(f => ({ ...f, emeter_set: f.emeter_set.filter(i => i.id !== id) }))
      }

      const updateEMeter = (id, update) => {
        setForm(f => {
          const emeters = [...f.emeter_set]
          const emeter = emeters.find(x => x.id === id)
          Object.entries(update).forEach(([key, value]) => { emeter[key] = value })
          return { ...f, emeter_set: emeters }
        })
      }

      const addEMeter = (eMeterId = null, value = null, action = null) => {
        setForm(f => ({
          ...f,
          emeter_set: [...f.emeter_set, {
            id: -getRandomId(), e_meter_id: eMeterId, value, action, project: projectId
          }]
        }
        ))
      }
      const getOpenButtonHint = (toggle) => {
        return <CustomButton onClick={toggle}>Hinweis</CustomButton>
      }

      return (
        <>
          {/* <Typography>
            Tragen Sie bitten folgend Informationen über Ihren Zähler ein.
            Dieser ist die Schnittstelle zum Stromnetz und muss den Stromfluss in beide Richtungen erfassen können,
            damit der Netzbetreiber ablesen kann, wie viel Strom Sie verbraucht bzw. eingespeist haben.
            Falls Sie noch keinen Zähler besitzen, der den Stromfluss in beide Richtungen erfasst, sondern im Falle einer Einspeisung rückwärts läuft,
            muss der Zähler durch den Netzbetreiber ausgetauscht werden.
            Für Hilfe beim Ablesen der Zählernummer bzw. der Unterscheidung zwischen Ein- und Zweirichtungszähler klicken Sie den Hinweis-Knopf neben dem entsprechenden Eingabefeld.
          </Typography> */}
          <Grid container spacing={3} >
            <Grid item xs={12} sm={12} md={12}>
              <Container>
                {(form.emeter_set.length > 0) ? <hr className='secondary-textcolor' /> : null}
              {form.emeter_set
                .map(emeter => <div key={`emeter_${emeter.id}`}>

                  <Typography className="secondary-textcolor">Zählernummer</Typography>
                  <Stack direction='row' spacing={2}>
                    <Input
                      style={{ width: fieldWidth, boxShadow: 'none' }}
                      id="input"
                      width={'70px'}
                      type="text"
                      name="e_meter_id"
                      onChange={e => updateEMeter(emeter.id, { e_meter_id: e.target.value || null })}
                      value={defaultIfEmpty(emeter.e_meter_id)}
                      autoComplete="off"
                      maxLength={1500}
                      invalid={(showMissingFields && emeter.e_meter_id == null)}
                    />
                    <CustomModal size='lg' getOpenButton={getOpenButtonHint} title="Hinweis Zähler und Zählernummer">
                      <br />
                      <Typography className='secondary-textcolor' >{'Die Zählernummer befindet sich auf Ihrem aktuellen Stromzähler. Meist befindet er sich in der Nähe des Strichcodes und wird entweder mit “Nr.” oder “Eigentum..” eingeleitet. Bei neueren Zählern beginnt die Nummer in der Regel mit “1Z…” oder “1E…”. Sollten Sie sich weiterhin unsicher sein, finden Sie die Zählernummer auch auf Ihrem Stromvertrag.'}</Typography>
                      <br />
                      <Grid container justifyContent="center" alignItems="center">
                        <img src="/emeter_examples.png" alt="emeter_examples" width="706" height="268" />
                      </Grid>
                    </CustomModal>
                  </Stack>

                  <Typography className="secondary-textcolor">Zählerstand</Typography>
                  <Input
                    style={{ width: fieldWidth, boxShadow: 'none' }}
                    id="input"
                    width={'70px'}
                    type="number"
                    name="value"
                    onChange={e => { console.log(e.target.valueAsNumber); updateEMeter(emeter.id, { value: e.target.valueAsNumber || e.target.valueAsNumber === 0 ? e.target.valueAsNumber : null }) }}
                    value={defaultIfEmpty(emeter.value)}
                    autoComplete="off"
                    maxLength={1500}
                    invalid={(showMissingFields && emeter.value == null)}
                  />

                  <Typography className="secondary-textcolor">Aktion</Typography>
                  <Stack direction='row' spacing={2}>
                    <DropDown invalid={(showMissingFields && emeter.action == null)} style={{ width: fieldWidth, boxShadow: 'none' }} options={emeterActions} search={false} value={emeter.action} onChange={value => updateEMeter(emeter.id, { action: value })} />
                    <CustomModal size='lg' getOpenButton={getOpenButtonHint} title="Hinweis Zählerwechsel">
                      <br />
                      <Typography className='secondary-textcolor' >{'Sollte in Ihrem Haushalt noch ein klassischer Ferraris-Zähler installiert sein oder ein Zähler, der den Stromfluss nicht in beide Richtungen erfassen kann, muss dieser zwingend gegen einen Zweirichtungszähler ausgetauscht werden. Digitale Zähler, die sowohl den Bezug von Strom (Kennzeichnung 180) als auch die Einspeisung ins Netz (Kennzeichnung 280) messen können, wechseln sichtbar zwischen diesen beiden Modi. Für den Fall, dass ein Zählerwechsel notwendig ist, übernehmen wir die Beantragung beim zuständigen Netzbetreiber für Sie.'}</Typography>
                      <br />
                      <Grid container justifyContent="center" alignItems="center">
                        <img src="/emeter_change.png" alt="emeter_examples" width="536" height="298" />
                      </Grid>
                    </CustomModal>
                  </Stack>

                  <br />
                  <CustomButton className='primary-textcolor' onClick={() => deleteEMeter(emeter.id)} style={{ width: '200px', color: '#424242', borderColor: '#424242' }} icon="bin" id="add-roof-btn">Zähler löschen</CustomButton>
                  <br />
                  {/* <br /> */}
                  <hr className='secondary-textcolor' />
                </div>)
              }

              </Container>
              <CustomButton className='primary-textcolor' onClick={() => addEMeter()} style={{ width: '200px', color: '#424242', borderColor: '#424242' }} icon="add" id="add-roof-btn">Zähler hinzufügen</CustomButton>
            </Grid>
          </Grid >

        </>
      )
    }
  }
]
