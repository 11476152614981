import React, { Fragment } from 'react'

import { PropTypes } from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'

import { Typography } from '@mui/material'

import { projectPropType } from '../../../../elements/PropTypes'
import FinishDataInput from '../../data_input/FinishDataInput'
import HeatpumpProjectFormModal from './HeatpumpProjectFormModal'

export default function HeatpumpProjectDataInput ({ project, showAllMissingFields, setShowAllMissingFields, setSuccess, resetParent, session }) {
  return (
    <Fragment>
      <Container>
        <Row>
          <Col xs={2} classname='d-flex justify-content'>
            <Typography className='secondary-textcolor' display="inline">Straße Nr.: </Typography><br />
            <Typography className='secondary-textcolor' display="inline">PLZ Ort: </Typography><br />
          </Col>
          <Col>
            <Typography className='secondary-textcolor' display="inline">{project.street_and_number_project}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">{project.zip_and_city_project}</Typography><br />
          </Col>
          <Col>
            <HeatpumpProjectFormModal
              project={project}
              disable={project.data_input_done}
              showAllMissingFields={showAllMissingFields}
              resetParent={resetParent}
              session={session}
            />
            <br />
            <br />
            <FinishDataInput
              project={project}
              disable={project.data_input_done}
              setShowAllMissingFields={setShowAllMissingFields}
              session={session}
              resetParent={(data) => { setSuccess(); resetParent(data, false) }}
            />
          </Col>
        </Row>
      </Container>
    </Fragment >
  )
}

HeatpumpProjectDataInput.propTypes = {
  project: projectPropType,
  showAllMissingFields: PropTypes.bool,
  setShowAllMissingFields: PropTypes.func,
  setSuccess: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
