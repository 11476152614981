import React, { Fragment, useEffect, useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Stage, Layer, Image, Line, Transformer } from 'react-konva'
import { Col, Row, ButtonGroup, FormGroup } from 'reactstrap'
import { Typography } from '@mui/material'
import { CustomButton } from '../../../elements/StyledElements'
import { dataURLToFile, getImageWindowWithAuth, getMimeType, urlToFile } from '../../../elements/utils'
import { heatpumpPropType } from '../../../elements/PropTypes'

const ANGLESTEP = 0.1
const MAXANGLE = 5

export default function HeatpumpImagePlanning ({ image, defaultImage, setImage, heatpump, angle, setAngle, heatpumpImageScale, setHeatpumpImageScale, heatpumpPosition, setHeatpumpPosition, maxWidth, maxHeight }) {
  const [imageState, setImageState] = useState({ stageWidth: null, stageHeight: null, imageScale: null, imageWindow: null })
  const [heatpumpImageWindow, setHeatpumpImageWindow] = useState(null)
  const [heatpumpSelected, setHeatpumpSelected] = useState(false)
  const [lines, setLines] = useState([])
  const [resetting, setResetting] = useState(false)

  const origHeatpumpWidthPx = useRef(null)
  const origHeatpumpHeightPx = useRef(null)
  const trRef = useRef()
  const shapeRef = useRef()
  const isDrawing = useRef(null)
  const saveRef = useRef()

  const colWidth = 300
  const maxImageWidth = maxWidth - colWidth
  const maxImageHeight = maxHeight
  const originalImage = image

  const loadImage = () => {
    if (image === null) return
    getImageWindowWithAuth(image, handleLoad)
  }

  const handleLoad = (newImageWindow) => {
    const scaler = Math.min(maxImageWidth / newImageWindow.width, maxImageHeight / newImageWindow.height)

    const stageWidth = newImageWindow.width * scaler
    const stageHeight = newImageWindow.height * scaler
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight
    })
    setHeatpumpImageScale({ ...heatpumpImageScale, r: stageWidth })
    getImageWindowWithAuth(heatpump.image, handleHeatpumpLoad)
    getImageWindowWithAuth(defaultImage, (newImage) => {
      const scaler = Math.min(maxImageWidth / newImage.width, maxImageHeight / newImage.height)

      setImageState(prev => ({ ...prev, defaultImage: newImage, defaultImageScale: scaler }))
    })
  }

  const handleHeatpumpLoad = (newImageWindowVertical) => {
    setHeatpumpImageWindow(newImageWindowVertical)
  }

  const handleDragEnd = (e) => {
    const x = e.target.x()
    const y = e.target.y()
    setHeatpumpPosition({ x: x / imageState.stageWidth, y: y / imageState.stageHeight })
  }

  const handleTransformEnd = (e) => {
    const node = shapeRef.current
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()

    node.scaleX(1)
    node.scaleY(1)
    setHeatpumpImageScale({
      ...heatpumpImageScale,
      w: scaleX,
      h: scaleY
    })
    setHeatpumpPosition({ x: node.x() / imageState.stageWidth, y: node.y() / imageState.stageHeight })
  }

  const rotateHeatpumpImage = (anglestep) => {
    if (anglestep !== 0) {
      if ((angle < MAXANGLE && angle > -MAXANGLE) || (angle >= MAXANGLE && anglestep <= 0) || (angle <= -MAXANGLE && anglestep >= 0)) {
        setAngle(angle + anglestep)
      }
    } else setAngle(0)
  }

  const handleMouseDown = (e) => {
    const isHeatpump = e.target.getStage().pointertargetShape.attrs.id === 'heatpump'
    const isTransformer = e.target.getStage().pointertargetShape.parent.attrs.id === 'heatpumptransformer'
    if (isHeatpump || isTransformer) {
      setHeatpumpSelected(true)
      return
    }

    setHeatpumpSelected(false)
    isDrawing.current = true
    const pos = e.target.getStage().getPointerPosition()
    setLines([...lines, [pos.x, pos.y]])
  }

  const handleMouseMove = (e) => {
    if (!isDrawing.current || heatpumpSelected) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    const lastLine = lines[lines.length - 1].concat([point.x, point.y])

    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleMouseUp = () => {
    if (isDrawing.current) {
      const dataURL = saveRef.current.toDataURL()

      const file = dataURLToFile(dataURL, image.name ?? image.split('/').pop())

      setImage(file)
    }
    isDrawing.current = false
  }

  const getImage = async (data) => {
    if (typeof data === 'string' && data.startsWith('http')) {
      const urlObj = new URL(data)
      const pathname = urlObj.pathname
      const filename = pathname.substring(pathname.lastIndexOf('/') + 1)
      const extension = filename.substring(filename.lastIndexOf('.') + 1)

      return await urlToFile(data, filename, getMimeType(extension), true)
    }
    return data
  }

  useEffect(() => loadImage(), [image, heatpump])

  useEffect(() => {
    if (heatpumpImageWindow == null || imageState.stageWidth == null || heatpumpImageScale.r === 0) return
    origHeatpumpWidthPx.current = heatpumpImageWindow.width
    origHeatpumpHeightPx.current = heatpumpImageWindow.height

    if (!defaultImage) {
      // make the heatpump initially 25% of the image width
      const heatpumpImageP = 0.25
      const heatpumpWidthP = imageState.stageWidth * heatpumpImageP
      const scalerW = heatpumpWidthP / origHeatpumpWidthPx.current
      const heatpumpHeightP = heatpump.height / heatpump.width * heatpumpWidthP
      const scalerH = heatpumpHeightP / origHeatpumpHeightPx.current
      setHeatpumpImageScale({
        ...heatpumpImageScale,
        w: scalerW,
        h: scalerH
      })
    }
  }, [heatpumpImageWindow, imageState.stageWidth])

  useEffect(() => {
    if (heatpumpSelected) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [heatpumpSelected])

  return (
    <Fragment key='heatpump_image_planning'>
      <Row>
        <Col style={{ maxWidth: colWidth }}>
          {setAngle
            ? <FormGroup>
              <Typography className='secondary-textcolor'>Bild drehen:</Typography>
              <ButtonGroup>
                <CustomButton onClick={() => { rotateHeatpumpImage(-ANGLESTEP) }} icon="rotateLeft" />
                &nbsp;&nbsp;
                <CustomButton onClick={() => { rotateHeatpumpImage(0) }}>0</CustomButton>
                &nbsp;&nbsp;
                <CustomButton onClick={() => { rotateHeatpumpImage(ANGLESTEP) }} icon="rotateRight" />
              </ButtonGroup>
              <br />
            </FormGroup>
            : null}
            <FormGroup>
              <Typography className='secondary-textcolor'>Zeichenwerkzeug</Typography>
              <CustomButton disabled={resetting} onClick={async () => {
                setLines([])

                if (!defaultImage) {
                  setImage(originalImage)
                } else {
                  setResetting(true)

                  const defaultImageFile = await getImage(defaultImage)
                  setImage(defaultImageFile)
                  setImageState(prev => ({ ...prev, imageWindow: prev.defaultImage, imageScale: prev.defaultImageScale }))

                  setResetting(false)
                }

                setHeatpumpPosition({ x: 0.0, y: 0.0 })
                shapeRef.current.rotation(0)

                const heatpumpImageP = 0.25
                const heatpumpWidthP = imageState.stageWidth * heatpumpImageP
                const scalerW = heatpumpWidthP / origHeatpumpWidthPx.current
                const heatpumpHeightP = heatpump.height / heatpump.width * heatpumpWidthP
                const scalerH = heatpumpHeightP / origHeatpumpHeightPx.current
                setHeatpumpImageScale({
                  ...heatpumpImageScale,
                  w: scalerW,
                  h: scalerH
                })
              }}>
                {resetting ? 'Zurücksetzen...' : 'Löschen'}
              </CustomButton>
            </FormGroup>
        </Col>
        <Col>
          <div style={{ width: imageState.stageWidth }}>
            <Stage
              width={imageState.stageWidth}
              height={imageState.stageHeight}
              style={{ backgroundColor: '#888888' }}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
            >
              <Layer ref={saveRef}>
                <Image
                  rotation={(angle != null) ? angle : 0}
                  id='heatpumpimage'
                  image={imageState.imageWindow}
                  scaleX={imageState.imageScale}
                  scaleY={imageState.imageScale}
                  draggable={false}
                />
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line}
                    stroke="red"
                    strokeWidth={5}
                    tension={0.5}
                    lineCap="round"
                    lineJoin='round'
                  />
                ))}
              </Layer>
              <Layer>
                <Image
                  id={'heatpump'}
                  key={'heatpump'}
                  ref={shapeRef}
                  image={heatpumpImageWindow}
                  scaleX={heatpumpImageScale.w}
                  scaleY={heatpumpImageScale.h}
                  x={heatpumpPosition.x * imageState.stageWidth}
                  y={heatpumpPosition.y * imageState.stageHeight}
                  draggable={true}
                  onDragEnd={handleDragEnd}
                  onTransformEnd={handleTransformEnd}
                />
                {heatpumpSelected && (
                  <Transformer
                    id='heatpumptransformer'
                    boundBoxFunc={(oldBox, newBox) => {
                      if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox
                      }
                      return newBox
                    }}
                    ref={trRef}
                    flipEnabled={false}
                  />
                )}
              </Layer>
            </Stage>
          </div>
        </Col>
      </Row>
    </Fragment >
  )
}

HeatpumpImagePlanning.propTypes = {
  image: PropTypes.string,
  defaultImage: PropTypes.string,
  setImage: PropTypes.func,
  heatpump: heatpumpPropType,
  heatpumpPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  setHeatpumpPosition: PropTypes.func,
  angle: PropTypes.number,
  setAngle: PropTypes.func,
  heatpumpImageScale: PropTypes.shape({
    w: PropTypes.number,
    h: PropTypes.number,
    r: PropTypes.number
  }),
  setHeatpumpImageScale: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number
}
