import React, { Fragment } from 'react'

import { PropTypes } from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'

import { Typography } from '@mui/material'

import CustomerFormModal from './CustomerFormModal'
import { customerPropType } from '../../../elements/PropTypes'

export default function CustomerDataInput ({ customer, showAllMissingFields, resetParent, session }) {
  return (
    <Fragment>
      <Container>
        <Row>
          <Col xs={2} classname='d-flex justify-content'>
            {customer.gender === 'Firma' ? <><Typography className='secondary-textcolor' display="inline">Firma: </Typography><br /></> : null}
            <Typography className='secondary-textcolor' display="inline">Vorname: </Typography><br />
            <Typography className='secondary-textcolor' display="inline">Nachname: </Typography><br />
            <Typography className='secondary-textcolor' display="inline">Straße Nr.: </Typography><br />
            <Typography className='secondary-textcolor' display="inline">PLZ Ort: </Typography><br />
          </Col>
          <Col>
            {customer.gender === 'Firma' ? <><Typography className='secondary-textcolor' display="inline">{customer.company}</Typography><br /></> : null}
            <Typography className='secondary-textcolor' display="inline">{customer.first_name}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">{customer.last_name}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">{customer.street_and_number}</Typography><br />
            <Typography className='secondary-textcolor' display="inline">{customer.zip_and_city}</Typography><br />
          </Col>
          <Col>
            <CustomerFormModal
              customer={customer}
              showAllMissingFields={showAllMissingFields}
              resetParent={resetParent}
              session={session}
            />
          </Col>
        </Row>
      </Container>
    </Fragment >
  )
}

CustomerDataInput.propTypes = {
  customer: customerPropType,
  showAllMissingFields: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
