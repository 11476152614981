// TODO: implement documentation form here

import 'react-datepicker/dist/react-datepicker.css'
import { hasPermission } from '../../../../elements/utils'

export const getHeatpumpConstructionDocumentationFormTemplate = (form, heatingInstallers, employees, session) => [
  {
    type: 'dropdown',
    name: 'Heizungsinstallateur:',
    key: 'heating_installer',
    condition: () => heatingInstallers,
    optional: false,
    options: (heatingInstallers || []).map(h => ({ label: h.name, value: h.id })),
    additionalUpdate: (key, value, prevForm) => {
      const prevHeatingInstaller = prevForm.heating_installer
      const heatingInstaller = value
      let employees = prevForm.employees
      if (prevHeatingInstaller && employees.includes(prevHeatingInstaller)) {
        employees = employees.filter(y => y !== prevHeatingInstaller)
      }
      if (heatingInstaller && !employees.includes(heatingInstaller)) {
        employees.push(heatingInstaller)
      }
      return { employees }
    }
  },
  {
    type: 'multiselect',
    name: 'Mitarbeiter:',
    key: 'employees',
    condition: () => heatingInstallers,
    optional: false,
    disabled: !hasPermission(session.user, 'task_assignment'),
    options: employees.map(e => ({ label: e.name, value: e.id, hidden: !e.is_active, disabled: (e.id === form.heating_installer || !hasPermission(session.user, 'task_assignment')) }))
  },
  {
    type: 'images',
    name: 'Wärmepumpenbilder:',
    key: 'constructionheatpumpimage_set'
  },
  {
    type: 'signature',
    name: 'Unterschrift Heizungsinstallateur:',
    key: 'heating_installer_signature',
    optional: false
  }
]
