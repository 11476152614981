import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton } from '@mui/material'

import ProductGroupForm from './ProductGroupForm'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { API_URL_PRODUCTGROUP } from '../../settings'
import { CustomButton } from '../../elements/StyledElements'
import { productPropType, customerPropType } from '../../elements/PropTypes'

const emptyProductGroupForm = {
  name: null,
  key: null
}

export default function ProductGroupModal ({ product, customer, resetParent, session, style }) {
  const [productGroupForm, setProductGroupForm] = useState({ ...emptyProductGroupForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const getOpenButton = (toggle) => {
    if (product) { return <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={toggle}><InfoOutlinedIcon className='secondary-textcolor' fontSize='large' /></IconButton> }
    return <CustomButton onClick={toggle} style={style}> Neue Gruppe erstellen </CustomButton>
  }

  const submit = async (productGroupForm) => {
    const key = productGroupForm.name.replace(/ /g, '_').toLowerCase()
    productGroupForm.key = key
    return axios.post(API_URL_PRODUCTGROUP, productGroupForm)
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(productGroupForm).then((res) => {
      resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    })
      .catch(error => {
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
        console.error(error)
      })
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                submitError={loadingElements.submitError ? 'Doppelter Gruppenname' : loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={function () { onSubmit(toggle) }}
            />)
  }

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
  }

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setProductGroupForm({ ...emptyProductGroupForm })
  }

  return (<CustomModal getOpenButton={getOpenButton} title="Produktgruppen" getFooter={getFooter} onToggle={onToggle}>
        <ProductGroupForm
            productGroup={productGroupForm}
            session={session}
            showMissingFields={loadingElements.showMissingFields}
            setProductGroup={setProductGroupForm}
            customer={customer}
        />
    </CustomModal>)
}

ProductGroupModal.propTypes = {
  product: productPropType,
  customer: customerPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  style: PropTypes.object
}
