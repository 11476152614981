import { PropTypes } from 'prop-types'

export const stringPropType = PropTypes.object

export const roofImagePropType = PropTypes.object

export const heatpumpImagePropType = PropTypes.object

export const customerToDoPropType = PropTypes.object

export const operatorPropType = PropTypes.object

export const circuitDiagramPropType = PropTypes.object

export const productPropType = PropTypes.object

export const customProductPropType = PropTypes.object

export const warehouseProductPropType = PropTypes.object

export const supplierPropType = PropTypes.object

export const batteryPropType = PropTypes.object

export const inverterPropType = PropTypes.object

export const planningPropType = PropTypes.object

export const plantPlanningPropType = PropTypes.object

export const modulePropType = PropTypes.object

export const panelPropType = PropTypes.object

export const customerPropType = PropTypes.object

export const simulationHousePropType = PropTypes.object

export const invoicePropType = PropTypes.object

export const offerPropType = PropTypes.object

export const permissionPropType = PropTypes.object

export const constructionDocumentationPropType = PropTypes.object

export const userPropType = PropTypes.object

export const groupPropType = PropTypes.object

export const eMeterPropType = PropTypes.object

export const taskPropType = PropTypes.object

export const taskSettingsPropType = PropTypes.object

export const taskLabelPropType = PropTypes.object

export const taskTypePropType = PropTypes.object

export const timeManagementEventPropType = PropTypes.object

export const commissionAmountPropType = PropTypes.object

export const commissionTypePropType = PropTypes.object

export const voltavoPolygonPropType = PropTypes.object

export const voltavoDataPropType = PropTypes.object

export const constructionPropType = PropTypes.shape({

})

export const areaPropType = PropTypes.object

export const leadPropType = PropTypes.object

export const leadTypePropType = PropTypes.object

export const projectPropType = PropTypes.object

export const heatpumpPropType = PropTypes.object

export const attachmentPropType = PropTypes.object

export const carPropType = PropTypes.object
// export const projectPropType = PropTypes.shape({
//   id: PropTypes.number,
//   street_and_number_project: PropTypes.string,
//   zip_and_city_project: PropTypes.string,
//   construction: constructionPropType
// })
