import React from 'react'
import { Form, FormGroup, Input } from 'reactstrap'
import { Table } from 'react-bootstrap'

import { IconButton, Checkbox, Typography } from '@mui/material'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
// import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { PropTypes } from 'prop-types'

import DropDown from '../../elements/DropDown'
import { CustomButton } from '../../elements/StyledElements'
import { defaultIfEmpty } from '../../elements/utils'
import MultiSelect from '../../elements/MultiSelect'

const emptySupplier = {
  id: null,
  supplier_id: null,
  product_name: null,
  product_id: null,
  price: null,
  is_active: false
}
const emptyUsedInProduct = {
  id: null,
  product_id: null,
  amount: 0
}

export default function WarehouseProductForm ({ warehouseProduct, setWarehouseProduct, warehouseProductTypes, suppliers, products, showMissingFields }) {
  const onChange = e => {
    setWarehouseProduct({ ...warehouseProduct, [e.target.name]: e.target.value })
  }

  // commented out because it is not used
  // const handleToggleChange = (e, newValue, key) => {
  //   setWarehouseProduct({ ...warehouseProduct, [key]: newValue })
  // }

  const handleChangeSupplier = (idx, field, value) => {
    const newList = [...warehouseProduct.suppliers]
    newList[idx] = { ...newList[idx], [field]: value }
    setWarehouseProduct({ ...warehouseProduct, suppliers: newList })
  }
  const handleDefaultSupplier = (id) => {
    setWarehouseProduct({
      ...warehouseProduct,
      active_supplier: id
    })
  }

  const deleteSupplier = (idx) => {
    setWarehouseProduct({ ...warehouseProduct, suppliers: warehouseProduct.suppliers.filter((e, i) => { return i !== idx }) })
  }
  const addSupplier = () => {
    setWarehouseProduct({
      ...warehouseProduct,
      suppliers: [...(warehouseProduct.suppliers), { ...emptySupplier }]
    })
  }

  const handleChangeUsedInProduct = (idx, field, value) => {
    const newList = [...warehouseProduct.used_in_products]
    newList[idx] = { ...newList[idx], [field]: value }
    setWarehouseProduct({ ...warehouseProduct, used_in_products: newList })
  }
  const deleteUsedInProduct = (idx) => {
    setWarehouseProduct({ ...warehouseProduct, used_in_products: warehouseProduct.used_in_products.filter((e, i) => { return i !== idx }) })
  }
  const addUsedInProduct = () => {
    setWarehouseProduct({ ...warehouseProduct, used_in_products: [...(warehouseProduct.used_in_products), emptyUsedInProduct] })
  }

  return (
        <Form>
            {(warehouseProduct.id)
              ? (
                <FormGroup>
                    <Typography className='secondary-textcolor'>Produkt ID</Typography>
                    <Input
                        id="input"
                        type="text"
                        name="warehouseProduct_id"
                        value={defaultIfEmpty('i' + warehouseProduct.id)}
                        disabled={true}
                        style={{ boxShadow: 'none' }}
                    />
                </FormGroup>)
              : null
            }

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Kategorie</Typography>
                <MultiSelect
                    onChange={(e) => { setWarehouseProduct({ ...warehouseProduct, product_type: e }) }}
                    options={warehouseProductTypes
                      ? warehouseProductTypes
                        .map((type) => ({ value: type.id, label: type.name }))
                      : null}
                    values={defaultIfEmpty(warehouseProduct.product_type)}
                    text='Kategorie'
                    search={true}
                    name='product_type'
                    style={{ position: 'absolute', zIndex: 10 }}
                />
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Name</Typography>
                <Input
                    id="input"
                    type="text"
                    name="name"
                    onChange={onChange}
                    value={defaultIfEmpty(warehouseProduct.name)}
                    required={true}
                    invalid={!!(showMissingFields && !(warehouseProduct.name))}
                    style={{ boxShadow: 'none' }}
                />
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Zulieferer</Typography>
                <Table className='table-not-hover'>
                    <thead>
                        <tr>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Zulieferername</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Ausführung bei Zulieferer</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Nummer bei Zulieferer</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Preis Netto</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Standard Zulieferer</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>{'Löschen'}</Typography></th>
                        </tr>
                    </thead>
                    <tbody>{warehouseProduct.suppliers.map((supplier, idx) => <tr key={idx}>
                        <td className="align-middle" style={{ position: 'relative' }}>
                            <DropDown
                                onChange={(val) => { handleChangeSupplier(idx, 'supplier_id', val) }}
                                options={ suppliers.map((type) => ({ value: type.id, label: type.name })) }
                                value={defaultIfEmpty(supplier.supplier_id)}
                                required={true}
                                invalid={!!(showMissingFields && !(supplier.supplier_id))}
                                text='Zulieferer'
                                search={true}
                                style={{ position: 'absolute', zIndex: 0, top: '10.5px', width: '120px' }}
                            />
                        </td>
                        <td className="align-middle">
                            <Input
                                id="input"
                                type="text"
                                onChange={(e) => { handleChangeSupplier(idx, 'product_name', e.target.value) }}
                                value={defaultIfEmpty(supplier.product_name)}
                                required={true}
                                invalid={!!(showMissingFields && !(supplier.product_name))}
                                style={{ boxShadow: 'none' }}
                            />
                        </td>
                        <td className="align-middle">
                            <Input
                                id="input"
                                type="text"
                                onChange={(e) => { handleChangeSupplier(idx, 'product_id', e.target.value) }}
                                value={defaultIfEmpty(supplier.product_id)}
                                required={false}
                                style={{ boxShadow: 'none' }}
                            />
                        </td>
                        <td className="align-middle">
                            <Input
                                id="input"
                                type="number"
                                step="0.01"
                                min="0"
                                onChange={(e) => { handleChangeSupplier(idx, 'price', e.target.value) }}
                                value={defaultIfEmpty(supplier.price)}
                                required={false}
                                style={{ boxShadow: 'none' }}
                            />
                        </td>
                        <td className="align-middle"><Checkbox
                            disableFocusRipple disableRipple
                            disabled={!supplier.id || supplier.id === warehouseProduct.active_supplier}
                            checked={supplier.id && supplier.id === warehouseProduct.active_supplier}
                            onChange={() => { handleDefaultSupplier(supplier.id) }}
                            style={{ color: '#424242', backgroundColor: 'transparent' }}
                        /></td>
                        <td className="align-middle">
                            <IconButton disableFocusRipple disableRipple disabled={supplier.id && supplier.id === warehouseProduct.active_supplier}
                            style={{ backgroundColor: 'transparent' }} size="small" onClick={() => { deleteSupplier(idx) }}>
                                <HighlightOffOutlinedIcon color={supplier.id && supplier.id === warehouseProduct.active_supplier ? 'disabled' : 'error'} fontSize='large' />
                            </IconButton>
                        </td>
                    </tr>)}</tbody>
                </Table>
                <CustomButton style={{ alignSelf: 'center' }} onClick={addSupplier} >Zulieferer hinzufügen</CustomButton>
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Mindestens im Lager</Typography>
                <Input
                    id="input"
                    type="number"
                    min="0"
                    name="min_amount_material"
                    onChange={onChange}
                    value={defaultIfEmpty(warehouseProduct.min_amount_material)}
                    required={true}
                    invalid={!!(showMissingFields && !(warehouseProduct.min_amount_material))}
                    style={{ boxShadow: 'none' }}
                />
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Beschreibung</Typography>
                <Input
                    id="input"
                    type="text"
                    name="description"
                    onChange={onChange}
                    value={defaultIfEmpty(warehouseProduct.description)}
                    required={false}
                    style={{ boxShadow: 'none' }}
                />
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Kommentar</Typography>
                <Input
                    id="input"
                    type="text"
                    name="comment"
                    onChange={onChange}
                    value={defaultIfEmpty(warehouseProduct.comment)}
                    required={false}
                    style={{ boxShadow: 'none' }}
                />
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Stückzahl in VPE</Typography>
                <Input
                    id="input"
                    type="number"
                    min="0"
                    name="material_per_box"
                    onChange={onChange}
                    value={defaultIfEmpty(warehouseProduct.material_per_box)}
                    required={true}
                    invalid={!!(showMissingFields && !(warehouseProduct.material_per_box))}
                    style={{ boxShadow: 'none' }}
                />
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Gewicht [g]</Typography>
                <Input
                    id="input"
                    type="number"
                    min="0"
                    name="weight"
                    onChange={onChange}
                    value={defaultIfEmpty(warehouseProduct.weight)}
                    required={false}
                    style={{ boxShadow: 'none' }}
                />
            </FormGroup>

            {/* ############################################################## */}

            <FormGroup>
                <Typography className='secondary-textcolor'>Verwendet in Produkt</Typography>
                <Table className='table-not-hover'>
                    <thead>
                        <tr>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Produkt</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>Benötigtes Material</Typography></th>
                            <th className="align-middle"><Typography className='secondary-textcolor'>{'Löschen'}</Typography></th>
                        </tr>
                    </thead>
                    <tbody>{warehouseProduct.used_in_products.map((product, idx) => <tr key={idx}>
                        <DropDown
                            onChange={(val) => { handleChangeUsedInProduct(idx, 'product_id', val) }}
                            options={ products.map((type) => ({ value: type.id, label: type.name })) }
                            value={defaultIfEmpty(product.product_id)}
                            required={true}
                            invalid={!!(showMissingFields && !(product.product_id))}
                            text='Produkt'
                            search={true}
                        />
                        <td className="align-middle">
                            <Input
                                id="input"
                                type="number"
                                min="1"
                                onChange={(e) => { handleChangeUsedInProduct(idx, 'amount', e.target.value) }}
                                value={defaultIfEmpty(product.amount)}
                                required={true}
                                invalid={!!(showMissingFields && !(product.amount) && (product.amount !== 0))}
                                style={{ boxShadow: 'none' }}
                            />
                        </td>
                        <td className="align-middle">
                            <IconButton disableFocusRipple disableRipple
                            style={{ backgroundColor: 'transparent' }} size="small" onClick={() => { deleteUsedInProduct(idx) }}>
                                <HighlightOffOutlinedIcon color="error" fontSize='large' />
                            </IconButton>
                        </td>
                    </tr>)}</tbody>
                </Table>
                <CustomButton style={{ alignSelf: 'center' }} onClick={addUsedInProduct} >Verwendet in Produkt hinzufügen</CustomButton>
            </FormGroup>

            {/* <FormGroup>
        <Typography className='secondary-textcolor'>Muss die MwSt. berücksichtigt werden?</Typography>
        <ToggleButtonGroup size="small" value={warehouseProduct.vat} exclusive onChange={(e, newValue) => { handleToggleChange(e, newValue, "vat") }}>
          <CustomToggle value={false}>Nein</CustomToggle>
          <CustomToggle value={true}>Ja</CustomToggle>
        </ToggleButtonGroup>
        {(showMissingFields && warehouseProduct.vat === null) ? <div>
          &nbsp;&nbsp;
          <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
        </div> : null}
      </FormGroup> */}

            {/* ############################################################## */}

        </Form>)
}

WarehouseProductForm.propTypes = {
  warehouseProduct: PropTypes.object,
  setWarehouseProduct: PropTypes.func,
  warehouseProductTypes: PropTypes.array,
  suppliers: PropTypes.array,
  products: PropTypes.array,
  showMissingFields: PropTypes.bool
}
