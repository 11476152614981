import React, { Fragment, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { toast } from 'react-toastify'

import {
  API_URL_CONSTRUCTION,
  API_URL_CUSTOMPRODUCT,
  API_URL_PRODUCT,
  API_URL_PRODUCTGROUP, API_URL_ROOF_PROJECT_LINETYPE,
  API_URL_ROOF_PROJECT_ROOFIMAGE,
  API_URL_ROOF_PROJECT_ROOFLINE,
  API_URL_ROOF_PROJECT_ROOFLINE_BULK
} from '../../../../settings'
import { projectPropType } from '../../../../elements/PropTypes'
import { CustomButton } from '../../../../elements/StyledElements'
import {
  date2String,
  filterObj,
  getCustomerName,
  getEmptyFieldsError, getMimeType,
  hasPermission, urlToFile
} from '../../../../elements/utils'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import ErrorMessage from '../../../../elements/ErrorMessage'
import RoofConstructionForm from './RoofConstructionForm'

const emptyConstructionForm = {
  project: null,
  planning: null,
  constructiondates_set: null,
  completion_date: null,
  commissioning_date: null,
  construction_manager: null,
  employees: [],
  resourcetype: 'RoofProjectConstruction'
}

export default function RoofConstructionFormModal ({ project, chosenDate, disable, resetParent, session, isOpen, setIsOpen, onClose }) {
  const [constructionForm, setConstructionForm] = useState({ ...emptyConstructionForm })

  const [customProductForms, setCustomProductForms] = useState([])
  const [currCustomProducts, setCurrCustomProducts] = useState([])
  const [amountCols, setAmountCols] = useState({ plannedAmountCol: false, realAmountCol: false, offerAmountCol: false, invoiceAmountCol: false })

  const [products, setProducts] = useState([])
  const [productGroups, setProductGroups] = useState([])
  const [lineTypes, setLineTypes] = useState([])
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const construction = project ? project.construction_obj : null
  const acceptedPlanning = project ? project.planning_set.find(p => p.id === project.accepted_offer_obj.planning) : null

  const onToggle = async (isOpen) => {
    if (isOpen) await loadData()
    else clearData(); toast.dismiss()
  }

  const _setConstructionForm = (update) => {
    setConstructionForm(oldConstruction => {
      const newConstruction = (typeof update === 'function') ? update(oldConstruction) : { ...oldConstruction, ...update }
      return newConstruction
    })
  }

  const clearData = () => {
    setConstructionForm({ ...emptyConstructionForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setProducts([])
    setCustomProductForms([])
    setCurrCustomProducts([])
  }

  /* FILL ############################################################## */
  const loadData = async () => {
    await Promise.all([loadCustomProducts()])
    // fill Construction
    let filledConstruction = { ...emptyConstructionForm, planning: acceptedPlanning.id, project: project.id }
    const constructionDate = chosenDate ? date2String(chosenDate) : null
    const constructionDateObj = chosenDate ? [{ date: date2String(chosenDate), construction: construction ? construction.id : null }] : null
    if (construction) {
      filledConstruction = {
        ...filledConstruction, ...construction
      }
      if (chosenDate) {
        filledConstruction = {
          ...filledConstruction,
          constructiondates_set: constructionDateObj,
          completion_date: constructionDate,
          commissioning_date: constructionDate
        }
      }
    } else {
      filledConstruction = {
        ...filledConstruction,
        constructiondates_set: constructionDateObj,
        completion_date: constructionDate,
        commissioning_date: constructionDate
      }
      filledConstruction.roofprojectroofimage_set = acceptedPlanning.roofprojectroofimage_set.map((roofImage, i) => (
        {
          ...roofImage,
          op: 'create',
          construction: null,
          id: i,
          roofprojectroofline_set: roofImage.roofprojectroofline_set.map(line => ({
            ...line,
            roof_image: null,
            id: null
          }))
        }
      ))
    }
    const lineTypes = await axios.get(API_URL_ROOF_PROJECT_LINETYPE)
    setLineTypes(lineTypes.data)
    setConstructionForm(filledConstruction)
  }
  const resetProducts = async () => {
    const requestOfferId = project.accepted_offer_obj.id
    const requestPlanningId = project.accepted_offer_obj.planning_obj.id
    return axios.get(API_URL_PRODUCT, { params: { project: project.id, offer: requestOfferId, planning: requestPlanningId } })
      .then((res) => {
        const products = res.data
        setProducts(products)
        const amountCols = {
          plannedAmountCol: res.headers.planned_amounts === 'True',
          realAmountCol: res.headers.real_amounts === 'True',
          offerAmountCol: res.headers.offer_amounts === 'True',
          invoiceAmountCol: res.headers.invoice_amounts === 'True'
        }
        setAmountCols(amountCols)
        return axios.get(API_URL_PRODUCTGROUP)
          .then((res) => {
            const productGroups = res.data
            setProductGroups(productGroups)
            return [products, amountCols, productGroups]
          })
      })
  }

  const loadCustomProducts = async () => {
    if (construction) {
      return resetProducts()
        .then(([products, amountCols]) => {
          axios.get(API_URL_CUSTOMPRODUCT, { params: { construction: project.construction_obj.id } })
            .then(res => {
              const customProducts = res.data.map((customProduct, _idx) => {
                const product = products.find(p => p.id === customProduct.product)
                return {
                  id: customProduct.id,
                  name: customProduct.name,
                  description: customProduct.description,
                  product: customProduct.product,
                  price: customProduct.price,
                  amount: customProduct.amount,
                  order: customProduct.order ? customProduct.order : _idx + 1,
                  priority: customProduct.priority,
                  productObj: product
                }
              })
              const customProductsCopy = customProducts.map((product) => {
                return JSON.parse(JSON.stringify(product))
              })
              setCustomProductForms([...customProductForms, ...customProducts])
              setCurrCustomProducts([...currCustomProducts, ...customProductsCopy])
              return products
            })
        })
    } else {
      return resetProducts()
        .then(([products, amountCols]) => {
          axios.get(API_URL_CUSTOMPRODUCT, { params: { offer: project.accepted_offer } })
            .then(res => {
              const customProducts = res.data
              setCustomProductForms(
                products
                  .filter(p => showProduct(p, amountCols))
                  .map((product, _idx) => {
                    const customProduct = customProducts.find(cp => cp.product === product.id)
                    return {
                      id: null,
                      name: customProduct ? customProduct.name : product.name,
                      description: customProduct ? customProduct.description : product.description,
                      product: product.id,
                      price: product.price,
                      amount: getAmount(product, amountCols),
                      order: customProduct && customProduct.order ? customProduct.order : _idx + 1,
                      priority: customProduct ? customProduct.priority : null,
                      productObj: product
                    }
                  }))
            })
          return products
        })
    }
  }

  const getAmount = (product, amountCols) => {
    const amount = amountCols.offerAmountCol
      ? product.amount_offer
      : 0
    return Math.max(amount, 0)
  }

  const showProduct = (product, amountCols) =>
    (amountCols.plannedAmountCol && product.amount_planned !== 0) ||
    (amountCols.invoiceAmountCol && product.amount_invoices !== 0) ||
    (amountCols.realAmountCol && product.amount_real !== 0) ||
    (amountCols.offerAmountCol && product.amount_offer !== 0)

  /* MODAL ############################################################## */

  const getOpenButton = (toggle) => {
    if (isOpen === null || isOpen === undefined) { return <CustomButton disabled={disable} onClick={toggle} icon="construction">Baustelle</CustomButton> }
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => { onSubmit(toggle) }}
        saveDisabled={!(hasPermission(session.user, 'customer_handling') || hasPermission(session.user, 'page_constructions')) || (construction && construction.documentation_completed && !hasPermission(session.user, 'finish_construction_documentation'))}
      />
    )
  }

  /* UPLOADING ############################################################## */
  const getImage = async (data) => {
    if (typeof data === 'string' && data.startsWith('http')) {
      const urlObj = new URL(data)
      const pathname = urlObj.pathname
      const filename = pathname.substring(pathname.lastIndexOf('/') + 1)
      const extension = filename.substring(filename.lastIndexOf('.') + 1)

      return await urlToFile(data, filename, getMimeType(extension), true)
    }
    return data
  }

  const submit = async (form) => {
    // save construction to get its id
    const constructionRes = construction
      ? await axios.put(API_URL_CONSTRUCTION + construction.id, form)
      : await axios.post(API_URL_CONSTRUCTION, form)

    const constructionId = constructionRes.data.id
    const promises = []
    const promisesAfter = []

    // products create/update
    promises.push(getProductPromise(constructionId))

    // roof images create/update
    for (const roofImage of constructionForm.roofprojectroofimage_set) {
      if (!roofImage.op) continue

      if (roofImage.op === 'delete') {
        promises.push(axios.delete(API_URL_ROOF_PROJECT_ROOFIMAGE + roofImage.id.toString()))
        continue
      }

      const lines = roofImage.roofprojectroofline_set
      const lineBulkReq = lines.map(line => {
        return {
          roof_image: null,
          line_type: line.line_type,
          name: line.name,
          length: line.length,
          area: line.area,
          points: line.points,
          hull_points: line.hull_points,
          centroid: line.centroid
        }
      })
      const roofImageForm = new FormData()
      const blendedImage = await getImage(roofImage.blended_image)
      const legendImage = await getImage(roofImage.legend_image)
      roofImageForm.append('blended_image', blendedImage)
      roofImageForm.append('legend_image', legendImage)
      roofImageForm.append('image_width_px', roofImage.image_width_px)
      roofImageForm.append('image_width_m', roofImage.image_width_m)
      roofImageForm.append('angle', roofImage.angle)
      roofImageForm.append('flat_roof', roofImage.flat_roof)
      roofImageForm.append('construction', constructionId)

      if (roofImage.op === 'create') {
        const image = await getImage(roofImage.image)
        roofImageForm.append('image', image)
        axios.post(API_URL_ROOF_PROJECT_ROOFIMAGE, roofImageForm).then(async res => {
          const roofImageId = res.data.id
          lineBulkReq.forEach(l => {
            l.roof_image = roofImageId
          })
          await axios.post(API_URL_ROOF_PROJECT_ROOFLINE_BULK, lineBulkReq)
        })
        continue
      }
      if (roofImage.op === 'update') {
        lineBulkReq.forEach(l => {
          l.roof_image = roofImage.id.toString()
        })
        promises.push(
          axios.put(API_URL_ROOF_PROJECT_ROOFIMAGE + roofImage.id.toString(), roofImageForm),
          axios.delete(API_URL_ROOF_PROJECT_ROOFLINE,
            { data: { roof_image: roofImage.id.toString(), except_ids: [] } })
        )
        promisesAfter.push(
          axios.post(API_URL_ROOF_PROJECT_ROOFLINE_BULK, lineBulkReq)
        )
      }
    }
    await axios.all(promises)
    await axios.all(promisesAfter)

    return await axios.get(API_URL_CONSTRUCTION + constructionId)
  }

  const getProductPromise = async (constructionId) => {
    const customProductPromise = construction
      ? axios.delete(API_URL_CUSTOMPRODUCT, { data: { construction: constructionId, except_ids: customProductForms.filter(x => x.id).map(x => x.id) } })
      : Promise.resolve()
    return customProductPromise
      .then(async () => Promise.all(
        customProductForms.map(customProduct => {
          customProduct.construction = constructionId
          if (customProduct.id) { return (customProduct.amount) ? axios.put(API_URL_CUSTOMPRODUCT + customProduct.id, customProduct) : axios.delete(API_URL_CUSTOMPRODUCT + customProduct.id) }
          return (customProduct.amount) ? axios.post(API_URL_CUSTOMPRODUCT, customProduct) : Promise.resolve()
        })
      ))
  }

  const onSubmit = async (onSuccess) => {
    const additionalOptionalKeys = ['commissioning_date', 'construction_manager', 'employees', 'completion_date', 'constructiondates_set']
    if (construction) {
      if (additionalOptionalKeys.constructiondates_set == null) additionalOptionalKeys.push('constructiondates_set')
      if (additionalOptionalKeys.completion_date == null) additionalOptionalKeys.push('completion_date')
    }
    const emptyFieldsError = getEmptyFieldsError(constructionForm, emptyConstructionForm, additionalOptionalKeys)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false })
      console.error(emptyFieldsError)
      toast.error(<ErrorMessage message={'Bitte alle Felder ausfüllen!'} />)
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    const constructionFormKeys = Object.keys(emptyConstructionForm)

    return submit(filterObj(constructionForm, constructionFormKeys)).then((res) => {
      if (resetParent) resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => {
      console.error('Error in ConstructionFormModal:onSubmit', error, error.stack)
      toast.error(<ErrorMessage message={'Anfrage konnte nicht an Server übermittelt werden!'} />)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    })
  }

  const title = (project) ? 'Bauplanung von ' + getCustomerName(project.customer_obj) : 'Bauplanung'
  return (<CustomModal size='fullscreen' isOpen={isOpen} setIsOpen={setIsOpen} getOpenButton={getOpenButton} title={title} getFooter={getFooter} onToggle={onToggle} onClose={onClose}>
    {(constructionForm === null)
      ? null
      : <Fragment>
        <RoofConstructionForm
          project={project}
          construction={constructionForm}
          setConstruction={_setConstructionForm}
          products={products}
          productGroups={productGroups}
          resetProducts={resetProducts}
          customProducts={customProductForms}
          setCustomProducts={setCustomProductForms}
          currCustomProducts={currCustomProducts}
          showMissingFields={loadingElements.showMissingFields}
          amountCols={amountCols}
          fixedProductKeys={lineTypes.map(l => l.key)}
          session={session}
        />
      </Fragment>
    }
  </CustomModal>)
}

RoofConstructionFormModal.propTypes = {
  project: projectPropType,
  chosenDate: PropTypes.instanceOf(Date),
  disable: PropTypes.bool,
  resetParent: PropTypes.func,
  session: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func
}
