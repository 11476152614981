import React, { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'react-bootstrap'
import { Col, Container, Row } from 'reactstrap'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Stack, Typography } from '@mui/material'

import '../shared/calendarStyle.css'
import CustomModal from '../shared/modal_utils/CustomModal'
import { date2String, date2FormattedString, isString } from '../../elements/utils'
import { taskPropType, constructionPropType, projectPropType } from '../../elements/PropTypes'
import DayOffEmployeeModal from './DayOffEmployeeModal'
import { getConstructionListFields, getConstructionName } from '../project_types/projectUtils'
import { Link } from 'react-router-dom'
import { API_URL_USER } from '../../settings'
import axios from 'axios'
import ConfirmationModalFooter from '../shared/modal_utils/ConfirmationModalFooter'
import ConstructionModal from '../project_types/ConstructionModal'

export default function ScheduleProjectAtDateModal ({ project, constructions, tasks, date, isOpen, setIsOpen, resetParent, session }) {
  const [chosenDate, setChosenDate] = useState(null)
  const [employees, setEmployees] = useState([])

  const filteredConstructions = constructions.filter(c => c.constructiondates_set.some(dateObj => date2String(new Date(dateObj.date)) === date2String(new Date(date))))
  const filteredTasks = tasks.filter(c => c.taskdates_set.some(dateObj => date2String(new Date(dateObj.date)) === date2String(new Date(date))))

  const groupedConstructions = filteredConstructions.reduce(function (r, c) {
    r[c.resourcetype] = r[c.resourcetype] || []
    r[c.resourcetype].push(c)
    return r
  }, {})
  const title = date ? 'Bautermin am ' + date2FormattedString(date) : ''

  const getEmployees = async () => {
    return axios.get(API_URL_USER, { params: { is_staff: true, is_active: true, visible: true } })
      .then(res => {
        const employees = res.data
        setEmployees(employees)
      })
  }

  useEffect(() => {
    getEmployees()
  }, [])

  const getFooter = (toggle) => {
    return <ConfirmationModalFooter
      onConfirm={() => setChosenDate(date)}
      onCancel={() => toggle()}
      btnLabel={'Terminieren'}
    />
  }

  return (
    <CustomModal size="xl" isOpen={isOpen} setIsOpen={setIsOpen} title={title} getFooter={getFooter}>
      <Stack spacing={1} direction="row">
        <DayOffEmployeeModal date={date} isOpen={isOpen} employees={employees}/>
      </Stack>

      <br />
      <Container>
        <Row>
          <Col>
            <Row>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', overlfow: 'hidden' }}>
                <div>
                  <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
                    Baustellen
                  </Typography>
                </div>
              </div>
            </Row>
            <br />
            <Row>
              <Col sm="10" md={{ size: 12, offset: -1 }}>
                {filteredConstructions.length > 0
                  ? null
                  : <Table className={!filteredConstructions || filteredConstructions.length <= 0 ? 'table-not-hover' : 'table-hover'}>
                    <thead>
                      <tr>
                        <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Kunde</Typography></th>
                        <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Ort</Typography></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td colSpan="5" align="center">
                          <Typography className='secondary-textcolor'> Keine Baustellen </Typography>
                        </td>
                      </tr>

                    </tbody>
                  </Table>}
                {Object.entries(groupedConstructions).map(([resourcetype, rConstructions]) => {
                  const fields = getConstructionListFields(resourcetype)
                  return <Fragment key={`construction-table-${resourcetype.toLowerCase()}`}>
                    <div className="text-center">
                      <Typography fontWeight='bold' className='secondary-textcolor'>
                        {getConstructionName(resourcetype)}
                      </Typography>
                    </div>
                    <Table className={'table-hover'}>
                      <thead>
                        <tr>
                          <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Kunde</Typography></th>
                          <th className="align-middle" style={{ width: '23%', textAlign: 'left' }}><Typography className='secondary-textcolor'>Ort</Typography></th>
                          {fields.map((f, fIdx) => <th key={`construction-table-${resourcetype.toLowerCase()}-header-${fIdx}`} className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>{f.name}</Typography></th>)}
                        </tr>
                      </thead>

                      <tbody>
                        {
                          rConstructions.map((construction, cIdx) => (
                            <tr key={`construction-row-${construction.id}`}>
                              <td className="align-middle" style={{ textAlign: 'left' }}>
                                <Link to={'/projekt/' + construction.project + '/bauplanung'} style={{ textDecoration: 'none' }}>
                                  <Typography className='secondary-textcolor'>{construction.customer_name}</Typography>
                                </Link>
                              </td>
                              <td className="align-middle" style={{ textAlign: 'left' }}>
                                <Link to={'/projekt/' + construction.project + '/bauplanung'} style={{ textDecoration: 'none' }}>
                                  <Typography className='secondary-textcolor'>{construction.zip_and_city}</Typography>
                                </Link>
                              </td>
                              {fields.map((f, fIdx) => <td key={`construction-table-${resourcetype.toLowerCase()}-row-${cIdx}-col-${fIdx}`} className="align-middle" style={{ textAlign: 'left' }}>
                                <Link to={'/projekt/' + construction.project + '/bauplanung'} style={{ textDecoration: 'none' }}>
                                  <Typography className='secondary-textcolor'>{isString(f.key) ? construction[f.key] : f.key(construction)}</Typography>
                                </Link>
                              </td>
                              )}
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Fragment>
                })}
              </Col>
            </Row>
            <br />
            <Row>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', overlfow: 'hidden' }}>
                <div></div>
                <div>
                  <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
                    Aufgaben
                  </Typography>
                </div>
              </div>
            </Row>
            <Row>
              <Col sm="10" md={{ size: 12, offset: -1 }}>
                <Table className={!filteredConstructions || filteredConstructions.length <= 0 ? 'table-not-hover' : 'table-hover'}>
                  <thead>
                    <tr>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Kunde</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Titel</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Startzeit</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Mitarbeiter</Typography></th>
                      <th className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>Erstellt von</Typography></th>
                    </tr>
                  </thead>

                  <tbody>
                    {((!filteredTasks || filteredTasks.length <= 0))
                      ? (
                        <tr>
                          <td colSpan="5" align="center">
                            <Typography className='secondary-textcolor'> Keine Aufgaben </Typography>
                          </td>
                        </tr>
                        )
                      : (
                        <>
                          {
                            filteredTasks.map((task) => (
                              <tr key={`task-row-${task.id}`} style={{ cursor: 'pointer', ...(task.finished ? { backgroundColor: '#D3D3D3' } : {}) }}>
                                <td className="align-middle" style={{ textAlign: 'left' }}>
                                  {
                                    task.customer && (<Link to={'/kunde/' + task.customer} style={{ textDecoration: 'none' }}>
                                      <div style={{
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        borderRadius: '4px',
                                        padding: '5px 15px',
                                        border: '1px solid #424242'
                                      }}>
                                        <Typography className='secondary-textcolor'>{task.customer_name} </Typography>
                                      </div>
                                    </Link>)
                                  }
                                </td>
                                <td className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>{task.title}</Typography></td>
                                <td className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>{task.start_time}</Typography></td>
                                <td className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>
                                  {task.employees.map(eid => employees.find(e => e.id === eid)?.name).join(', ')}
                                </Typography></td>
                                <td className="align-middle" style={{ textAlign: 'left' }}><Typography className='secondary-textcolor'>{task.registered_by}</Typography></td>
                              </tr>
                            ))
                          }
                        </>
                        )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ConstructionModal
        project={project}
        chosenDate={chosenDate}
        resetParent={() => { setIsOpen(false); resetParent() }}
        session={session}
        isOpen={!!chosenDate}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenDate(null) }}
      />
    </CustomModal>
  )
}

ScheduleProjectAtDateModal.propTypes = {
  project: projectPropType,
  constructions: PropTypes.arrayOf(constructionPropType),
  tasks: PropTypes.arrayOf(taskPropType),
  date: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
