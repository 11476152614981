import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import PropTypes from 'prop-types'

import { getErrorMessage, getNewURLWithNewQueryParam } from './elements/utils'
import { toast } from 'react-toastify'

const ErrorFallback = ({ error }) => {
  const newURL = getNewURLWithNewQueryParam('error', getErrorMessage(error))
  window.history.pushState({ path: newURL }, '', newURL)
  // window.location.reload()

  return <></>
}

ErrorFallback.propTypes = {
  error: PropTypes.object
}

export default function RootErrorBoundary ({ children, session }) {
  const showErrorQueryParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const error = urlParams.get('error')
    if (!error) return

    toast.error(error)
    urlParams.delete('error')
    window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
  }

  showErrorQueryParam()

  return (
    <ErrorBoundary
      FallbackComponent={session.isAuthenticated ? ErrorFallback : <></>}
    >
      {children}
    </ErrorBoundary>
  )
}

RootErrorBoundary.propTypes = {
  children: PropTypes.node,
  session: PropTypes.object
}
