import { PropTypes } from 'prop-types'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'

import axios from 'axios'

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material'

import Collapsible from '../elements/Collapsible'
import DateInput from '../elements/DateInput'
import DropDown from '../elements/DropDown'
import LoadingPage from '../elements/LoadingPage'
import { addDaysToDate, date2String, hasPermission, hexToRGB, string2FormattedString } from '../elements/utils'
import { getNextDate } from './tasks/utils'
import { API_URL_CONSTRUCTION, API_URL_GROUP, API_URL_TASK, API_URL_TASKLABEL, API_URL_TASKTYPE, API_URL_USER, API_URL_VEHICLE } from '../settings'
import EventMap from './map/EventMap'

import L from 'leaflet'
import { ProjectType2ConstructionType, projectTypes as allProjectTypes, getAdditionalMapPopupDescription } from './project_types/projectUtils'
import AddressSearch from './map/AddressSearch'

function getCarMarkerIcon (color) {
  const markerIcon = new L.DivIcon({
    className: 'svg-icon',
    iconSize: [24, 40],
    iconAnchor: [8, 20],
    html: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="${color}">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path stroke="#000000" d="M18.92 5.01C18.72 4.42 18.16 4 17.5 4h-11c-.66 0-1.21.42-1.42 1.01L3 11v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 15c-.83 0-1.5-.67-1.5-1.5S5.67 12 6.5 12s1.5.67 1.5 1.5S7.33 15 6.5 15zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z"/>
</svg>`
  })
  return markerIcon
}

const getCoordinatesFromString = (string) => {
  if (!string) return null
  try {
    let [lat, lng] = string.split(',')
    lat = parseFloat(lat)
    lng = parseFloat(lng)
    return (!isNaN(lat) && !isNaN(lng)) ? { lat, lng } : null
  } catch (error) {
    console.error(error)
    return null
  }
}

export default function Map ({ session }) {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const permissionAllTasks = hasPermission(session.user, 'tasks_all_employees')

  const projectTypes = allProjectTypes.filter(t => ProjectType2ConstructionType(t.key))

  // current location
  const [currentLocation, setCurrentLocation] = useState(getCoordinatesFromString(searchParams.get('koordinaten')))

  // --------  cars
  const [cars, setCars] = useState(null)
  // filter
  const [showCars, setShowCars] = useState(true)
  const [car, setCar] = useState(null)

  // --------  tasks
  const [tasks, setTasks] = useState(null)
  // filter
  const [showTasks, setShowTasks] = useState(true)
  const [taskType, setTaskType] = useState(null)
  const [taskLabel, setTaskLabel] = useState(null)
  const [includeNotScheduled, setIncludeNotScheduled] = useState(searchParams.get('heute') === null)
  // filter options
  const [taskLabels, setTaskLabels] = useState(null)
  const [taskTypes, setTaskTypes] = useState(null)

  // --------  constructions
  const [constructions, setConstructions] = useState(null)
  // filter
  const [showConstructions, setShowConstructions] = useState(true)
  const [projectType, setProjectType] = useState(null)

  const allCars = useRef(null)

  useEffect(() => { setCars(allCars.current && (car ? allCars.current.filter(c => c.id === car) : allCars.current)) }, [car, allCars.current])

  // --------  general
  // filter
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(searchParams.get('heute') !== null ? new Date() : addDaysToDate(new Date(), 7 * 4))
  const [group, setGroup] = useState(null)
  const [employee, setEmployee] = useState(permissionAllTasks ? null : session.user.id)
  // filter options
  const [employees, setEmployees] = useState(null)
  const [groups, setGroups] = useState(null)

  useEffect(() => {
    if (permissionAllTasks) {
      axios.get(API_URL_USER, {
        params: { is_staff: true, visible: true, is_active: true }
      }).then(res => setEmployees(res.data))
      axios.get(API_URL_GROUP).then(res => setGroups(res.data))
    }
    axios.get(API_URL_TASKTYPE).then(res => setTaskTypes(res.data))
    axios.get(API_URL_TASKLABEL).then(res => setTaskLabels(res.data))
  }, [])

  useEffect(() => {
    axios.get(API_URL_TASK, {
      params: {
        start_date: date2String(startDate),
        end_date: endDate && date2String(endDate),
        project_assigned: true,
        include_not_scheduled: includeNotScheduled,
        finished: false,
        on_site: true,
        employees: employee,
        task_type: taskType,
        labels: taskLabel,
        group
        // nested: true,
      }
    }).then(res => {
      setTasks(res.data)
    })
  }, [startDate, endDate, group, employee, includeNotScheduled, taskType, taskLabel])

  useEffect(() => {
    axios.get(API_URL_CONSTRUCTION, {
      params: {
        start_date: date2String(startDate),
        end_date: endDate && date2String(endDate),
        employees: employee,
        group,
        project_type: projectType
      }
    }).then(res => {
      setConstructions(res.data)
    })
  }, [startDate, endDate, group, employee, projectType])

  useEffect(() => {
    axios.get(API_URL_VEHICLE, { params: { date: date2String(startDate), nested: false } }).then((res) => { allCars.current = res.data })
  }, [startDate])

  const carMarkers = showCars && cars && cars.map(car => ({
    coords: [car.last_state.lat, car.last_state.lng],
    popup: <p><b>{car.plate}</b> {car.brand_model}</p>,
    color: car.color,
    getIcon: getCarMarkerIcon
  }))

  const taskType2Color = taskTypes ? Object.fromEntries(taskTypes.map(t => [t.id, t.color])) : {}
  taskType2Color.null = '#acacad'

  const taskBorderColor = '#f7313f'
  let includedTaskTypes = new Set([])
  const taskMarkers = showTasks && tasks && tasks.map((task) => {
    includedTaskTypes.add(task.task_type)
    return {
      coords: task.lat_lng,
      popup: (
        <Link to={`/aufgabe/${task.id}`} style={{ textDecoration: 'none', display: 'contents', color: 'black' }}>
          <div style={{ cursor: 'pointer' }}>
            {task.taskdates_set && <><b>Datum:</b> {`${string2FormattedString(getNextDate(task.taskdates_set))}`}<br /></>}
            <b>Kunde:</b> {`${task.customer_name}`}<br />
            <b>Aufgabe:</b> {task.title}<br />
            <b>Adresse:</b> {task.address}<br />
            {(task.employee_names.length > 0) ? <><b>Mitarbeiter:</b> {task.employee_names.join(', ')}<br /></> : null}
          </div>
        </Link>
      ),
      color: taskType2Color[task.task_type] || taskType2Color.null,
      borderColor: taskBorderColor
    }
  })
  includedTaskTypes = Array.from(includedTaskTypes)
  const notAssignedTasksIncluded = includedTaskTypes.includes(null)
  includedTaskTypes = includedTaskTypes.filter(t => t)
  const taskLegend = taskMarkers && taskMarkers.length > 0 && [
    { label: 'Aufgaben' },
    ...(taskTypes || []).filter(t => includedTaskTypes.includes(t.id)).map(t => ({ label: t.name, color: t.color, borderColor: taskBorderColor })),
    ...(notAssignedTasksIncluded ? [{ label: 'Nicht zugeordnet', color: taskType2Color.null, borderColor: taskBorderColor }] : [])
  ]

  const constructionTypeType2Color = Object.fromEntries(projectTypes.map(t => [ProjectType2ConstructionType(t.key), t.color]))
  const constructionBorderColor = '#000000'
  let includedConstructionTypes = new Set([])
  const constructionMarkers = showConstructions && constructions && constructions.map((construction) => {
    includedConstructionTypes.add(construction.resourcetype)
    return {
      coords: construction.lat_lng,
      popup: (
        <Link to={`/projekt/${construction.project}/bauplanung`} style={{ textDecoration: 'none', display: 'contents', color: 'black' }}>
          <div style={{ cursor: 'pointer' }}>
            {construction.constructiondates_set && <><b>Datum:</b> {`${string2FormattedString(getNextDate(construction.constructiondates_set))}`}<br /></>}
            <b>Kunde:</b> {`${construction.customer_name}`}<br />
            <b>Adresse:</b> {`${construction.street_and_number} ${construction.zip_and_city}`}<br />
            {(construction.employee_names.length > 0) ? <><b>Mitarbeiter:</b> {construction.employee_names.join(', ')}<br /></> : null}
            {getAdditionalMapPopupDescription(construction)}
          </div>
        </Link>
      ),
      color: constructionTypeType2Color[construction.resourcetype],
      borderColor: constructionBorderColor
    }
  })
  includedConstructionTypes = Array.from(includedConstructionTypes)
  const constructionLegend = constructionMarkers && constructionMarkers.length > 0 && [
    { label: 'Baustellen' },
    ...projectTypes.filter(t => includedConstructionTypes.includes(ProjectType2ConstructionType(t.key))).map(t => ({ label: t.name, color: t.color, borderColor: constructionBorderColor }))
  ]

  if (!cars || !tasks || !constructions) return <LoadingPage />

  const gridItemProps = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }
  const bigGridItemProps = { xs: 12, sm: 12, md: 8, lg: 6, xl: 4 }

  return (
    <Fragment>
      <Collapsible getOpenButton={(toggle) => <IconButton size="medium" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={toggle}><FilterAltOutlinedIcon style={{ color: '#424242' }} fontSize='medium' />
        <Typography className='secondary-textcolor'>Filtern</Typography>
      </IconButton>}>
        <Grid container spacing={2} sx={{ marginTop: '0px', marginLeft: '20px', marginRight: '30px', marginBottom: '10px' }} justifyContent='flex-start' alignItems='center'>
          <Grid item {...gridItemProps}>
            <Typography>Startdatum</Typography>
            <DateInput
              value={startDate}
              onChange={setStartDate}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <Typography>Enddatum</Typography>
            <DateInput
              value={endDate}
              onChange={setEndDate}
            />
          </Grid>
          {employees && <Grid item {...gridItemProps}>
            <DropDown
              value={employee}
              onChange={setEmployee}
              options={(employees || []).map(e => ({ value: e.id, label: e.name }))}
              text='Mitarbeiter'
              search={true}
            />
          </Grid>}
          <Grid item {...gridItemProps}>
            {groups && <DropDown
              value={group}
              onChange={setGroup}
              options={groups.map(g => ({ value: g.id, label: g.name }))}
              text='Rolle'
              search={true}
            />}
          </Grid>
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...gridItemProps}>
            {/* <Grid item xs={12} md={12} lg={12} xl={12}> */}
            {/* <Typography fontWeight={'bold'}>Aufgaben</Typography> */}
            <FormControlLabel control={
              <Checkbox
                disableFocusRipple disableRipple
                checked={!!showTasks}
                onChange={(e) => { setShowTasks(e.target.checked) }}
                style={{ color: '#424242', backgroundColor: 'transparent' }}
              />
            } label={<Typography fontWeight={'bold'}>Aufgaben</Typography>} />
          </Grid>
          {showTasks && taskTypes && <Grid item {...gridItemProps}>
            <FormControlLabel control={
              <Checkbox
                disableFocusRipple disableRipple
                checked={!!includeNotScheduled}
                onChange={(e) => { setIncludeNotScheduled(e.target.checked) }}
                style={{ color: '#424242', backgroundColor: 'transparent' }}
              />
            } label="Nicht terminierte Aufgaben" />
          </Grid>}
          {showTasks && taskTypes && <Grid item {...gridItemProps}>
            <DropDown
              value={taskType}
              onChange={setTaskType}
              options={taskTypes.map(t => ({ value: t.id, label: t.name, props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } } }))}
              text='Aufgaben-Typ'
              search={true}
            />
          </Grid>}
          {showTasks && taskLabels && <Grid item {...gridItemProps}>
            <DropDown
              value={taskLabel}
              onChange={setTaskLabel}
              options={taskLabels.map(t => ({ value: t.id, label: t.name, props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } } }))}
              text='Label'
              search={true}
            />
          </Grid>}
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...gridItemProps}>
            <FormControlLabel control={
              <Checkbox
                disableFocusRipple disableRipple
                checked={!!showConstructions}
                onChange={(e) => { setShowConstructions(e.target.checked) }}
                style={{ color: '#424242', backgroundColor: 'transparent' }}
              />
            } label={<Typography fontWeight={'bold'}>Baustellen</Typography>} />
          </Grid>
          {showConstructions && projectTypes && <Grid item {...gridItemProps}>
            <DropDown
              value={projectType}
              onChange={setProjectType}
              options={projectTypes.map(t => ({ value: t.key, label: t.name, props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } } }))}
              text='Projekt-Typ'
              search={true}
            />
          </Grid>}
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...gridItemProps}>
            <FormControlLabel control={
              <Checkbox
                disableFocusRipple disableRipple
                checked={!!showCars}
                onChange={(e) => { setShowCars(e.target.checked) }}
                style={{ color: '#424242', backgroundColor: 'transparent' }}
              />
            } label={<Typography fontWeight={'bold'}>Autos</Typography>} />
          </Grid>
          {showCars && cars && <Grid item {...gridItemProps}>
            <DropDown
              value={car}
              onChange={setCar}
              options={cars.map(c => ({ value: c.id, label: c.plate, props: { style: { backgroundColor: hexToRGB(c.color, 0.5) } } }))}
              text='Auto'
              search={true}
            />
          </Grid>}
          <Box width="100%" /> {/* Force new line */}
          <Grid item {...bigGridItemProps}>
            <AddressSearch setLocation={setCurrentLocation} session={session}/>
          </Grid>
        </Grid>
      </Collapsible>
      <EventMap
        location={currentLocation}
        markers={[...(carMarkers || []), ...(taskMarkers || []), ...(constructionMarkers || [])]}
        legend={[...(constructionLegend || []), ...(taskLegend || [])]}
      />
    </Fragment>
  )
}

Map.propTypes = {
  session: PropTypes.object
}
