import React, { useState } from 'react'

import { PropTypes } from 'prop-types'

import { FormGroup, ToggleButtonGroup, Typography } from '@mui/material'

import { Input } from 'reactstrap'
import { defaultIfEmpty } from '../../elements/utils'
import { CustomButton, CustomToggle } from '../../elements/StyledElements'
import CustomModal from '../shared/modal_utils/CustomModal'
import axios from 'axios'
import DropDown from '../../elements/DropDown'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { API_URL_SENDNEWSLETTER } from '../../settings'

const offerAcceptedOptions = [
  { label: 'Alle Kunden', value: null },
  { label: 'Kunden, die ein Angebot angenommen haben', value: true },
  { label: 'Kunden, die noch kein Angebot angenommen haben', value: false }
]

export default function SendNewsletterModal ({ getOpenButton, disable, resetParent, emailEditorRef, session }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, skipInProgress: false
  })
  const [currentSubject, setCurrentSubject] = useState('')
  const [currentMessage, setCurrentMessage] = useState('')
  const [customerFilter, setCustomerFilter] = useState({
    offer_accepted: null,
    test: false,
    test_email: ''
  })

  const createHtml = () => {
    const unlayer = emailEditorRef.current?.editor
    unlayer?.exportHtml((data) => {
      const { html } = data
      setCurrentMessage(html)
    })
  }

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const loadData = () => {
    createHtml()
  }

  const clearData = () => {
    setCurrentSubject('')
    setCurrentMessage('')
    setLoadingElements({ inProgress: false, submitError: false, skipInProgress: false })
  }

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton disabled={disable} onClick={toggle} icon="mail">Newsletter senden</CustomButton>
  }

  const submit = () => {
    axios.post(API_URL_SENDNEWSLETTER, { ...customerFilter, mail: { subject: currentSubject, message: currentMessage } })
    return Promise.resolve(null)
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false })
    return submit().then((res) => {
      if (resetParent) resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    })
      .catch(error => { console.error('Error in Email Modal:', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        saveBtnLabel={'Senden'}
      />)
  }

  return (
    <CustomModal size='xl' onToggle={onToggle} getFooter={getFooter} getOpenButton={_getOpenButton} title="Newsletter senden" >
      <div>
        <Typography fontSize='h5.fontSize' className='secondary-textcolor'>E-Mail verfassen</Typography>
        <br />
        <FormGroup>
          <Typography className='secondary-textcolor'>Test-Email:</Typography>
          <ToggleButtonGroup size="small" value={customerFilter.test} exclusive onChange={(e, value) => { if (value !== null) setCustomerFilter((f) => ({ ...f, test: value })) }}>
            <CustomToggle value={true}>Ja</CustomToggle>
            <CustomToggle value={false}>Nein</CustomToggle>
          </ToggleButtonGroup>
        </FormGroup>
        <FormGroup>
          {!customerFilter.test
            ? <>
              <Typography className='secondary-textcolor'>Empfänger:</Typography>
              <DropDown
                onChange={val => setCustomerFilter((f) => ({ ...f, offer_accepted: val }))}
                options={offerAcceptedOptions}
                value={customerFilter.offer_accepted}
                text='Empfänger'
                search={null}
                style={{ width: '400px' }}
              />
            </>
            : <>
              <Typography className='secondary-textcolor'>E-Mail:</Typography>
              <Input
                id="input"
                type="text"
                name="subject"
                onChange={(e) => setCustomerFilter((f) => ({ ...f, test_email: e.target.value }))}
                value={defaultIfEmpty(customerFilter.test_email)}
                required={true}
                style={{ boxShadow: 'none', maxWidth: '600px' }}
              />
              <></>
            </>}
          <br />
        </FormGroup>
        <FormGroup>
          <Typography className='secondary-textcolor'>Betreff:</Typography>
          <Input
            id="input"
            type="text"
            name="subject"
            onChange={(e) => setCurrentSubject(e.target.value)}
            value={defaultIfEmpty(currentSubject)}
            required={true}
            style={{ boxShadow: 'none', maxWidth: '600px' }}
          />
          <br />
        </FormGroup>
      </div>
      <br />
      <br />
      <br />
      <div dangerouslySetInnerHTML={{ __html: currentMessage }} />
    </CustomModal >
  )
}

SendNewsletterModal.propTypes = {
  emailEditorRef: PropTypes.object,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
