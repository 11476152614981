import React, { useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import LoadingPage from '../../../../elements/LoadingPage'
import { projectPropType } from '../../../../elements/PropTypes'
import { CustomButton } from '../../../../elements/StyledElements'
import { filterObj, hasPermission, isString } from '../../../../elements/utils'
import { API_URL_CUSTOMER, API_URL_HEATERLABEL, API_URL_HEATPUMPPROJECTDATA } from '../../../../settings'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import DataInputForm from './DataInputForm'

const emptyCustomerForm = {
  id: null,
  gender: null,
  first_name: null,
  last_name: null,
  street_and_number: null,
  zip_and_city: null,
  province: null
}
const emptyProjectForm = {
  id: null,
  street_and_number_project: null,
  zip_and_city_project: null,
  province: null,
  manufacturer_current_heater: null,
  typename_current_heater: null
}

export default function DataInputFormModal ({ project, getOpenButton, resetParent, session }) {
  const [customerForm, setCustomerForm] = useState(null)
  const [projectForm, setProjectForm] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })
  const [sent, setSent] = useState(true)

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
  }

  const loadData = () => {
    setCustomerForm(project.customer_obj)
    setProjectForm(project)
  }

  const clearData = () => {
    setCustomerForm(null)
    setProjectForm(null)
    setLoadingElements({ inProgress: false, submitError: false })
  }

  const isNewObj = (obj) => obj.id <= 0

  const getImagePromises = async (projectId, images, url, getPromise) => {
    const deletePromise = (project)
      ? axios.delete(url, { data: { project: projectId, except_ids: images.filter(i => !isNewObj(i)).map(i => i.id) } })
      : Promise.resolve()
    return deletePromise
      .then(() => Promise.all(images.map(image => getPromise(projectId, image, url))))
  }

  const getImagePromise = async (projectId, image, url) => {
    const formData = new FormData()
    if (!isString(image.image)) {
      formData.append('image', image.image, image.image.name)
    } else return Promise.resolve()
    formData.append('project', projectId)
    if (image.id < 0) return axios.post(url, formData)
    formData.append('id', image.id)
    return axios.put(url + image.id, formData)
  }

  const submit = async () => {
    const promises = [
      axios.put(API_URL_CUSTOMER + project.customer, filterObj(customerForm, Object.keys(emptyCustomerForm))),
      axios.put(API_URL_HEATPUMPPROJECTDATA + project.id, filterObj(projectForm, Object.keys(emptyProjectForm))),
      getImagePromises(project.id, projectForm.labelcurrentheater_set, API_URL_HEATERLABEL, getImagePromise)
    ]
    return Promise.all(promises).then((res) => res)
  }

  const onSubmit = async (onSuccess) => {
    setSent(false)
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false })
    return submit()
      .then((res) => {
        resetParent(res.data)
        onSuccess()
        setSent(true)
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      })
      .catch(error => {
        setSent(true)
        console.error('Error in "customer:onSubmit"', error, error.stack)
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
      })
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        saveDisabled={!hasPermission(session.user, 'customer_handling')}
      />)
  }

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton onClick={toggle} icon="clipboard">Dateneingabe</CustomButton>
  }

  if (!sent) return <LoadingPage />

  return (<CustomModal getOpenButton={_getOpenButton} size='fullscreen' title="Dateneingabe" getFooter={getFooter} onToggle={onToggle}>
    {customerForm
      ? <DataInputForm
        customer={customerForm}
        setCustomer={setCustomerForm}
        project={projectForm}
        setProject={setProjectForm}
        session={session}
      />
      : null}
  </CustomModal>)
}

DataInputFormModal.propTypes = {
  project: projectPropType,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
