import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'
import { Typography } from '@mui/material'
import { defaultIfEmpty } from '../../../../elements/utils'
import React from 'react'
import DropDown from '../../../../elements/DropDown'

const translate = {
  Linie: 'Line',
  Fläche: 'Area',
  Line: 'Linie',
  Area: 'Fläche'
}

export default function LineTypeForm ({ lineTypeForm, setLineTypeForm, showMissingFields }) {
  return (
      <Form>
        <FormGroup>
          <Typography className='secondary-textcolor'>Linientypname: (muss einzigartig sein)</Typography>
          <Input
            id="input"
            type="text"
            name="name"
            onChange={(e) => setLineTypeForm({
              ...lineTypeForm,
              name: e.target.value.toString(),
              key: e.target.value.toString().toLowerCase()
            })}
            value={defaultIfEmpty(lineTypeForm.name)}
            required={true}
            invalid={!!(showMissingFields && !(lineTypeForm.name))}
            style={{ boxShadow: 'none' }}
          />
        </FormGroup>
        <FormGroup>
          <Typography className='secondary-textcolor'>Typ:</Typography>
          <DropDown
            name="type"
            onChange={(type) => setLineTypeForm({ ...lineTypeForm, type: translate[type] })}
            text="Typ"
            options={['Linie', 'Fläche']}
            value={translate[lineTypeForm.type]}
          />
        </FormGroup>
      </Form>
  )
}

LineTypeForm.propTypes = {
  lineTypeForm: PropTypes.object,
  setLineTypeForm: PropTypes.func,
  showMissingFields: PropTypes.bool
}
