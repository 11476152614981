import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { PropTypes } from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FeatureGroup, MapContainer, Marker, Polyline, Popup, TileLayer } from 'react-leaflet'
import './styles.css'

import { carPropType } from '../../elements/PropTypes'
import { datetime2FormattedString, getDistanceFromLatLonInKm, round, string2datetime } from '../../elements/utils'
import './LegendComponent.css'

const markerHtmlStyles = (color) => {
  return (`
  background-color: ${color};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1rem;
  top: -1rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 3px solid #FFFFFF
  `)
}

const getMarkerIcon = (color) => {
  return (L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="${markerHtmlStyles(color)}" />`
  })
  )
}

const ZOOM = 6
const CENTER = [51.26191485308451, 10.56884765625]

// const ArrowPolyline = ({ pathOptions, positions }) => {
//   const map = useMap()

//   const arrow = [
//     {
//       offset: "100%",
//       repeat: 0,
//       symbol: L.Symbol.arrowHead({
//         pixelSize: 15,
//         polygon: false,
//         pathOptions: { stroke: true }
//       })
// }]
//   L.polyline(positions, pathOptions).addTo(map)
//   L.polylineDecorator(positions, {
//     patterns
//   }).addTo(map);
// }

export default function CarMap ({ cars }) {
  const [map, setMap] = useState(null)
  const featureGroupRef = useRef()

  useEffect(() => {
    if (!map) return
    const bounds = featureGroupRef.current.getBounds()
    if (Object.keys(bounds).length === 0) return
    map.fitBounds(bounds)
  }, [map, cars])

  const filterCarStates = (car) => {
    const states = car.states.filter(state => state.lat && state.lng)
    const filteredStates = []
    let lastState = null
    states.forEach((state, idx) => {
      if (!lastState || (getDistanceFromLatLonInKm(lastState.lat, lastState.lng, state.lat, state.lng) > 0.2)) {
        filteredStates.push(state)
        lastState = state
      }
    })
    return filteredStates
  }

  const mapCars = useMemo(() => {
    return cars.map(car => ({ ...car, states: filterCarStates(car) }))
  }, [cars])

  return (
    <div>
      <div className="CarMap" style={{ height: round(window.innerHeight * 0.8), width: round(window.innerWidth * 0.8), display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
        <MapContainer doubleClickZoom={false} zoom={ZOOM} center={CENTER} whenCreated={setMap} ref={setMap} >
          <FeatureGroup ref={featureGroupRef}>
            {mapCars.map(car => car.states.map(state => {
              return <Marker key={`marker-${state.id}`} position={[state.lat, state.lng]} icon={getMarkerIcon(car.color || '#0000ff')}>
                <Popup>
                  {car.plate} <br /> {datetime2FormattedString(string2datetime(state.timestamp))}
                </Popup>
              </Marker>
            })).flat()}
            {mapCars.map(car => <Polyline key={`path-${car.id}`} pathOptions={{ color: car.color || '#0000ff' }} polyline positions={car.states.map(state => [state.lat, state.lng])} />)}

          </FeatureGroup>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </div>
    </div>
  )
}

CarMap.propTypes = {
  cars: PropTypes.arrayOf(carPropType)
}
