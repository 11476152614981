import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import { PropTypes } from 'prop-types'
import DatePicker from 'react-datepicker'
import axios from 'axios'

import { Typography, ToggleButtonGroup, Stack } from '@mui/material'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { API_URL_WAREHOUSEADDRESS, API_URL_CUSTOMER } from '../../settings'
import { CustomButton, CustomToggle } from '../../elements/StyledElements'
import DropDown from '../../elements/DropDown'
import { date2String, defaultIfEmpty } from '../../elements/utils'

export default function WarehouseDeliveryLocationModal ({ parentComission, setParentComission, session, isSubmitted, style }) {
  const [newStreetNumber] = useState('')
  const [newZipCity] = useState('')
  const [, setNewAddressError] = useState(false)
  const [addressList, setAddressList] = useState([])

  const [customer, setCustomer] = useState(null)
  const [customerList, setCustomerList] = useState([])
  const [projectList, setProjectList] = useState([])

  const [isWarehouseAddress, setIsWarehouseAddress] = useState(true)
  const [warehouseComission, setWarehouseComission] = useState(parentComission)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  useEffect(() => {
    axios.get(API_URL_WAREHOUSEADDRESS).then(res => setAddressList(res.data))
    axios.get(API_URL_CUSTOMER).then(res => setCustomerList(res.data))
  }, [])

  useEffect(() => {
    setWarehouseComission({ ...warehouseComission, construction_id: null })
    if (customer) {
      axios.get(API_URL_CUSTOMER + customer, { params: { nested: true } }).then(res => {
        setProjectList(res.data.project_set.filter(e => e.construction))
      })
    } else {
      setProjectList([])
    }
  }, [customer])

  useEffect(() => {
    setNewAddressError(false)
  }, [newStreetNumber, newZipCity])

  const handleIsWarehouseAddress = (val) => {
    if (val) {
      setWarehouseComission({ ...warehouseComission, construction_id: null, warehouse_address_id: 1 })
    } else {
      setWarehouseComission({ ...warehouseComission, warehouse_address_id: null })
    }
    setIsWarehouseAddress(val)
  }

  // const submitNewAddress = () => {
  //   axios.post(API_URL_WAREHOUSEADDRESS, { street_and_number: newStreetNumber, zip_and_city: newZipCity }).then(_ => {
  //     axios.get(API_URL_WAREHOUSEADDRESS).then(res => setAddressList(res.data))
  //     setNewStreetNumber('')
  //     setNewZipCity('')
  //   }).catch(_ => {
  //     setNewAddressError(true)
  //   })
  // }

  // const deleteAddress = () => {
  //   axios.delete(API_URL_WAREHOUSEADDRESS + warehouseComission.warehouse_address_id).then(_ => {
  //     axios.get(API_URL_WAREHOUSEADDRESS).then(res => setAddressList(res.data))
  //     setWarehouseComission({ ...warehouseComission, warehouse_address_id: null })
  //   })
  // }

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle} style={style} disabled={isSubmitted}> Lieferort </CustomButton>
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    if (!warehouseComission.delivery_date || (!warehouseComission.construction_id && !warehouseComission.warehouse_address_id)) {
      setLoadingElements({ ...loadingElements, inProgress: false, showMissingFields: true })
      return
    }

    const cust = customerList.find(e => (e.id === customer))
    setParentComission({
      ...warehouseComission,
      name: warehouseComission.construction_id
        ? cust.first_name + ' ' + cust.last_name + ' ' + date2String(warehouseComission.delivery_date)
        : null
    })
    setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    onSuccess()
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                submitError={loadingElements.showMissingFields}
                inProgress={loadingElements.inProgress}
                onSave={function () { onSubmit(toggle) }}
            />)
  }

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
  }

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setWarehouseComission(parentComission)
  }

  return (<CustomModal getOpenButton={getOpenButton} title="Lieferort" getFooter={getFooter} onToggle={onToggle}>
        <br />
        <div style={{ position: 'relative' }}>
          <Typography className='secondary-textcolor'>Zeitpunkt der Lieferung:</Typography>
          <DatePicker
            dateFormat="dd.MM.yyyy" locale="de"
            required showIcon showYearDropdown
            selected={warehouseComission.delivery_date ? warehouseComission.delivery_date : null}
            onChange={(date) => { setWarehouseComission({ ...warehouseComission, delivery_date: date || null }) }}
          />
          <br/>
          <ToggleButtonGroup value={isWarehouseAddress} exclusive style={{ marginTop: 20 }}>
            <CustomToggle value={true} onClick={() => { handleIsWarehouseAddress(true) }}>Lager</CustomToggle>
            <CustomToggle value={false} onClick={() => { handleIsWarehouseAddress(false) }}>Baustelle</CustomToggle>
          </ToggleButtonGroup>
          {isWarehouseAddress
            ? <Stack direction="column" spacing={2} marginTop={2}>
            {/* <div>
              <Typography className='secondary-textcolor'>Straße Hausnr</Typography>
              <Input type="text" value={newStreetNumber}
                invalid={newAddressError}
                onChange={(e) => { setNewStreetNumber(e.target.value) }}/>
            </div>
            <div>
              <Typography className='secondary-textcolor'>PLZ Ort</Typography>
              <Input type="text" value={newZipCity}
                invalid={newAddressError}
                onChange={(e) => { setNewZipCity(e.target.value) }}/>
            </div>
            <CustomButton onClick={submitNewAddress} >Neue Addresse</CustomButton>
            <div>
              <Typography className='secondary-textcolor'>Ausgewählte Addresse:</Typography>
              <DropDown
                onChange={(val) => { setWarehouseComission({ ...warehouseComission, warehouse_address_id: val }) }}
                options={addressList.map(address => ({
                  label: address.street_and_number + (address.street_and_number && address.zip_and_city && ', ') + address.zip_and_city,
                  value: address.id
                }))}
                value={ defaultIfEmpty(warehouseComission.warehouse_address_id) }
                required={true}
                invalid={loadingElements.showMissingFields && !warehouseComission.warehouse_address_id}
                text='Addresse'
                search={true}
              />
            </div> */}
            <div>
              <Typography className='secondary-textcolor'>Straße Hausnr</Typography>
              <Input type="text" disabled value={warehouseComission.warehouse_address_id
                ? addressList.find(e => (e.id === warehouseComission.warehouse_address_id))?.street_and_number
                : ''
              } style={{ backgroundColor: 'white', color: 'black' }}/>
            </div>
            <div>
              <Typography className='secondary-textcolor'>PLZ Ort</Typography>
              <Input type="text" disabled value={warehouseComission.warehouse_address_id
                ? addressList.find(e => (e.id === warehouseComission.warehouse_address_id))?.zip_and_city
                : ''
              } style={{ backgroundColor: 'white', color: 'black' }}/>
            </div>
            {/* <CustomButton color="red" onClick={deleteAddress}> {"Löschen Addresse"} </CustomButton> */}
          </Stack>
            : <Stack direction="column" spacing={2} marginTop={2}>
            <DropDown
              onChange={setCustomer}
              options={customerList.map((cust) => ({ label: cust.first_name + ' ' + cust.last_name, value: cust.id }))}
              value={customer}
              text='Kunden'
              sort={true}
              search={true}
            />
            <DropDown
              onChange={(val) => { setWarehouseComission({ ...warehouseComission, construction_id: val }) }}
              options={projectList && projectList.map((proj) => ({ label: proj.name, value: proj.construction }))}
              value={ defaultIfEmpty(warehouseComission.construction_id) }
              text='Projekte'
              required={true}
              invalid={loadingElements.showMissingFields && !warehouseComission.construction_id}
              sort={true}
              search={true}
            />
            <div>
              <Typography className='secondary-textcolor'>Straße Hausnr</Typography>
              <Input type="text" disabled value={warehouseComission.construction_id
                ? projectList.filter(e => (e.construction === warehouseComission.construction_id))[0].street_and_number_project
                : ''
              } style={{ backgroundColor: 'white', color: 'black' }}/>
            </div>
            <div>
              <Typography className='secondary-textcolor'>PLZ Ort</Typography>
              <Input type="text" disabled value={warehouseComission.construction_id
                ? projectList.filter(e => (e.id === warehouseComission.construction_id))[0].zip_and_city_project
                : ''
              } style={{ backgroundColor: 'white', color: 'black' }}/>
            </div>
          </Stack>}
        </div>
    </CustomModal>)
}

WarehouseDeliveryLocationModal.propTypes = {
  parentComission: PropTypes.object,
  setParentComission: PropTypes.func,
  session: PropTypes.object,
  isSubmitted: PropTypes.bool,
  style: PropTypes.object
}
