import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import axios from 'axios'

import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Button from '@mui/material/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@mui/material/Grid'
import { MuiThemeProvider, createTheme } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'

import { API_URL_LOGIN, SESSION_DURATION } from '../settings'
import LogoHeader from '../elements/LogoHeader'

import { isMobileOnly } from 'react-device-detect'

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#ffffff'
        }
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#2E3B55'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  input: {
    color: '#424242'
  },
  inputfocused: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#424242'
    }
  }
}))

export default function Login ({ session, resetUser }) {
  const classes = useStyles()
  const [username, setuserName] = useState(null)
  const [password, setPassword] = useState(null)
  const [values, setValues] = useState({ showPassword: false })
  const [loginError, setLoginError] = useState(null)

  const history = useHistory()
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }

  useEffect(() => {
    if (session.isAuthenticated) { history.replace(from) }
  })

  const handleFormFieldChange = (event) => {
    switch (event.target.id) {
      case 'username': setuserName(event.target.value); break
      case 'password': setPassword(event.target.value); break
      default: return null
    }
  }

  const handleSubmit = (e) => {
    setLoginError(false)
    e.preventDefault()
    axios.post(API_URL_LOGIN, { username, password })
      .then(loginRes => {
        const token = loginRes.data.key
        const expirationDate = new Date(new Date().getTime() + SESSION_DURATION)
        localStorage.setItem('token', token)
        localStorage.setItem('expirationDate', expirationDate)
        localStorage.setItem('companyConfig', JSON.stringify(loginRes.data.company_config))
        localStorage.setItem('creds', JSON.stringify(loginRes.data.creds))
        resetUser()
      })
      .catch(err => { setLoginError(true); console.error(err) })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const gridsize = !isMobileOnly ? 5 : 8

  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {!isMobileOnly
          ? (
          <div>
            <br />
            <br />
            <br />
            <br />
          </div>)
          : null}
        <br />
        <br />
        <br />
        <Grid container spacing={1} justifyContent="center" >

          <Grid item xs={gridsize}>
            <LogoHeader />
          </Grid>
          <Grid item xs={gridsize}>
            <Container component="main" maxWidth="xs">
              {/* <div className={classes.paper}> */}
              <div className="text-center">
                <Typography className='secondary-textcolor' component="h1" fontSize='h5.fontSize'>
                  Login
                </Typography>
              </div>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  className={classes.inputfocused}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{ style: { color: '#424242' } }}
                  id="username"
                  label="Nutzer"
                  name="username"
                  // autoFocus
                  onChange={handleFormFieldChange}
                // style={{ backgroundColor: "#001e3cff" }}
                />
                <br />
                <TextField
                  className={classes.inputfocused}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff style={{ color: '#424242' }} /> : <Visibility style={{ color: '#424242' }} />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{ style: { color: '#424242' } }}
                  name="password"
                  label="Passwort"
                  type={values.showPassword ? 'text' : 'password'}
                  id="password"
                  onChange={handleFormFieldChange}
                // style={{ backgroundColor: "#001e3cff" }}
                />
                <div className="d-grid gap-2">
                  <Link to={'/reset_password/'} style={{ textDecoration: 'none' }}>
                    <Button
                      margin="normal"
                      style={{ color: '#424242', textDecoration: 'underline', fontSize: '12px' }}
                    >
                      Passwort vergessen?
                    </Button>
                  </Link>
                  {loginError
                    ? <Typography color='error' component="h1" fontSize='h6.fontSize'>
                      Benutzername oder Passwort falsch!
                    </Typography>
                    : null}
                  <Button
                    margin="normal"
                    type="submit"
                    variant="outlined"
                    style={{ color: '#424242', borderColor: '#424242' }}
                    className={classes.submit}
                  >
                    Einloggen
                  </Button>
                </div>
              </form>
              {/* </div> */}
            </Container>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </Fragment>
  )
}

Login.propTypes = {
  session: PropTypes.object,
  resetUser: PropTypes.func
}
