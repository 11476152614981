import axios from 'axios'
import { PropTypes } from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { FormGroup } from 'reactstrap'

import { Typography } from '@mui/material'

import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import DropDown from '../../elements/DropDown'
import { customerToDoPropType, projectPropType } from '../../elements/PropTypes'
import { getCustomerName } from '../../elements/utils'
import { API_URL_TODO, API_URL_USER } from '../../settings'

export default function AssignCustomerToDo ({ project, session, isOpen, setIsOpen, id, resetParent }) {
  const [data, setData] = useState({ name: '', description: '', visible_for_customer: false, customer_action: false, employee: null })
  const [employees, setEmployees] = useState([])
  const [employee, setEmployee] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })
  useEffect(() => {
    getEmployees()
  }, [])

  const getEmployees = () => {
    axios
      .get(API_URL_USER, { params: { is_staff: true, is_active: true, visible: true } })
      .then((res) => {
        setEmployees(res.data)
      })
  }

  const submit = () => {
    return axios.put(API_URL_TODO, { todo_id: id, name: data.name, description: data.description, visible_for_customer: data.visible_for_customer, customer_action: data.customer_action, employee })
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false })
    return submit().then(() => {
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "AssignCustomerToDo:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const loadData = useCallback(() => {
    if (!project) return
    const currData = project.customertodo_set.find(t => t.id === id)
    if (currData) {
      setData(currData)
      setEmployee(currData.employee)
    }
  }, [id])

  useEffect(() => {
    loadData()
  }, [id, loadData])

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        onCancel={toggle}
      />
    )
  }

  return <CustomModal
    size="lg"
    getFooter={getFooter}
    title={'ToDo für ' + getCustomerName(project.customer_obj)}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
  >
    <FormGroup>
      <Typography className='secondary-textcolor'>Mitarbeiter</Typography>
      <DropDown
        onChange={setEmployee}
        options={employees.map((user) => ({ label: user.first_name + ' ' + user.last_name, value: user.id }))}
        value={employee}
        style={{ padding: '20px 0px', overflowY: 'visible' }}
        listBoxStyle={{ padding: '20px 0px', maxHeight: '90px', overflowY: 'auto' }}
        text='Mitarbeiter wählen'
        sort={true}
        search={true}
      />
    </FormGroup>
  </CustomModal>
}

AssignCustomerToDo.propTypes = {
  project: projectPropType,
  parent: customerToDoPropType,
  prevToDo: customerToDoPropType,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  id: PropTypes.number
}
