/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'

import { Typography, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'
import { toast } from 'react-toastify'
import LoadingPage from '../../../../../elements/LoadingPage'
import CustomForm, { getEmptyFieldsErrorFromTemplate } from '../../../../../elements/CustomForm'
import CustomModal from '../../../../shared/modal_utils/CustomModal'
import ConfirmationModalFooter from '../../../../shared/modal_utils/ConfirmationModalFooter'
import { CustomButton } from '../../../../../elements/StyledElements'
import { getDataInputFormTemplate } from './DataInputFormTemplate'
import { API_URL_HEATERLABEL, CAPI_URL_HPDATAINPUT } from '../../../../../settings'
import { isString } from '../../../../../elements/utils'
import LogoHeader from '../../../../../elements/LogoHeader'

const emptyDataInputForm = {
  manufacturer_current_heater: null,
  typename_current_heater: null,
  labelcurrentheater_set: []
}

export default function DataInput ({ session }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [projectInfo, setProjectInfo] = useState(null)
  const [notFound, setNotFound] = useState(null)
  const [dataInputForm, setDataInputForm] = useState(emptyDataInputForm)
  const [showSuccessForProject, setShowSuccessForProject] = useState(null)

  const { token } = useParams()

  const loadData = async () => {
    try {
      const { data } = await axios.get(CAPI_URL_HPDATAINPUT, { params: { token } })
      setProjectInfo(data)
      setDataInputForm({ ...emptyDataInputForm })
    } catch (error) {
      setNotFound(true)
      console.error(error)
    }
  }

  const isNewObj = (obj) => obj.id <= 0

  const getImagePromises = async (projectId, images, url, getPromise) => {
    const deletePromise = (projectInfo.project_id)
      ? axios.delete(url, { data: { project: projectId, except_ids: images.filter(i => !isNewObj(i)).map(i => i.id) } })
      : Promise.resolve()
    return deletePromise
      .then(() => Promise.all(images.map(image => getPromise(projectId, image, url))))
  }

  const getImagePromise = async (projectId, image, url) => {
    const formData = new FormData()
    if (!isString(image.image)) {
      formData.append('image', image.image, image.image.name)
    } else return Promise.resolve()
    formData.append('project', projectId)
    if (!image.id || image.id < 0) return axios.post(url, formData)
    formData.append('id', image.id)
    return axios.put(url + image.id, formData)
  }

  const getOpenButton = (toggle) => {
    return <CustomButton style={{ float: 'right' }} color='#357a38' icon='send' onClick={toggle}>Daten absenden</CustomButton>
  }

  const submit = async () => {
    const payload = {
      ...dataInputForm,
      token,
      labelcurrentheater_set: dataInputForm.labelcurrentheater_set.map(image => ({
        image: image.image
      }))
    }
    const { labelcurrentheater_set, ...rest } = payload

    await axios.post(CAPI_URL_HPDATAINPUT, rest)
    await getImagePromises(projectInfo.project_id, labelcurrentheater_set, API_URL_HEATERLABEL, getImagePromise)
  }

  useEffect(() => {
    loadData()
  }, [])

  if (notFound) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={6} sm={6} md={6} item>
          <br />
          <br />
          <LogoHeader />
          <br />
          <div className="text-center">
            <Typography color={'error'} className='secondary-textcolor' variant='h4'>Die Dateneingabe ist bereits erfolgt.</Typography>
            {/* <br />
            <Typography className='secondary-textcolor' variant='h5'>Die Dateneingabe ist bereits erfolgt.</Typography> */}
          </div>
        </Grid>
      </Grid>
    )
  }
  if (!projectInfo) return <LoadingPage />

  const template = getDataInputFormTemplate()

  const onSubmit = async (onSuccess) => {
    const emptyFieldsError = getEmptyFieldsErrorFromTemplate(template, dataInputForm, emptyDataInputForm)
    if (emptyFieldsError) {
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
      console.error(emptyFieldsError)
      toast.error('Bitte alle Informationen eintragen!')
      return
    }
    return submit()
      .then((res) => {
        onSuccess()
        setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
        setShowSuccessForProject(true)
      }).catch(error => { console.error('Error in "customer:onSubmit"', error, error.stack); setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return <ConfirmationModalFooter
      btnLabel="Bestätigen"
      id="view-submit-data-input"
      // submitError={loadingElements.submitError}
      inProgress={loadingElements.inProgress}
      onConfirm={() => onSubmit(toggle)}
      onCancel={() => toggle()}
    />
  }

  if (showSuccessForProject) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={6} sm={6} md={6} item>
          <LogoHeader />
          <br />
          <br />
          <Alert severity="success">Ihre Daten wurden erfolgreich gespeichert!</Alert>
        </Grid>
      </Grid>
    )
  }

  return (
    <Fragment>
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={6} sm={6} md={6} item>
          <div className="text-center">
            <br />
            <Grid container alignItems="center" justifyContent="center">
              <Grid xs={2} sm={2} md={2} item>
                <LogoHeader />
              </Grid>
              <Grid xs={10} sm={10} md={10} item>
                <Typography className='secondary-textcolor' variant='h5'>Dateneingabe für Projekt {projectInfo.street_and_number}</Typography>
              </Grid>
            </Grid>
            <br />
            <Typography className='secondary-textcolor' align='justify'>
              Folgende Angaben benötigen wir, um Ihre Wärmepumpe besser planen zu können.
              <br /><br />
              Sobald Sie alles angegeben haben, klicken Sie bitte auf &quot;Daten absenden&quot;.
              Falls Angaben fehlen, wird eine Fehlermeldung,
              andernfalls eine Meldung über die erfolgreiche Übermittlung Ihrer Daten angezeigt.
            </Typography>
          </div>
          <br />
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={6} sm={6} md={6} >
          <CustomForm
            template={template}
            form={dataInputForm}
            setForm={setDataInputForm}
            defaultForm={emptyDataInputForm}
            showMissingFields={loadingElements.showMissingFields}
          />

          {loadingElements.showMissingFields ? <><Alert fontSize="12" severity="error">Die Daten sind nicht vollständig. Bitte füllen Sie die fehlenden Daten aus!</Alert><br /></> : null}

          <CustomModal size='md' getOpenButton={getOpenButton} title="Dateneingabe fertigstellen" getFooter={getFooter}>
            <br />
            <Typography className='secondary-textcolor' sx={{ fontWeight: 'bold' }} >
              {'Mit einem Klick auf "Bestätigen" wird die Firma Planville bevollmächtigt, alle für den Betrieb einer Wärmepumpenanlage notwendigen Anmeldungen vorzunehmen!'}
            </Typography>
            <br />
          </CustomModal>
          {/* <hr className='secondary-textcolor' /> */}
          <div className="text-center">
            <br />

            <br />
            <Typography className='secondary-textcolor' variant='body'>{'Bei Problemen bei der Dateneingabe wenden Sie sich bitte an Planville!'}</Typography>
            <br />
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
    </Fragment >
  )
}

DataInput.propTypes = {
  session: PropTypes.object
}
