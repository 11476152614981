import React, { Fragment, useState, useEffect, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Col, Container, Row, Input, FormGroup } from 'reactstrap'

import { Typography, FormControlLabel, Checkbox } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import DropDown from '../../../../elements/DropDown'
import { defaultIfEmpty } from '../../../../elements/utils'
import { projectPropType, customerPropType } from '../../../../elements/PropTypes'
import LoadingPage from '../../../../elements/LoadingPage'
import UploadImages from '../../../shared/images/UploadImages'

export default function DataInputForm ({ project, setProject, customer, setCustomer, showMissingFields, session }) {
  const [varyingAddress, setVaryingAddress] = useState(null)

  const customerModified = useRef(false)

  useEffect(() => {
    if (varyingAddress === false) {
      setProject({
        ...project,
        street_and_number_project: customer.street_and_number,
        zip_and_city_project: customer.zip_and_city,
        province: customer.province
      })
    }
  }, [varyingAddress, customer.street_and_number, customer.zip_and_city, customer.province])

  useEffect(() => {
    setVaryingAddress((project.street_and_number_project !== customer.street_and_number) || (project.zip_and_city_project !== customer.zip_and_city) || (project.province_project !== customer.province))
  }, [])

  const onCustomerChange = e => {
    customerModified.current = true
    setCustomer(c => ({ ...c, [e.target.name]: e.target.value }))
  }

  const onProjectChange = e => {
    setProject(p => ({ ...p, [e.target.name]: e.target.value }))
  }

  if (!customer || !project) return <LoadingPage />

  return (
    <Fragment>
      <>
        <Container>
          <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
            Persönliche Daten
          </Typography>
          <Row>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Anrede:</Typography>
                <DropDown
                  id="gender_dropdown"
                  onChange={(value) => setCustomer(c => ({ ...c, gender: value }))}
                  text={(customer.gender === 'Familie') ? 'Familie' : 'Anrede'}
                  options={['Frau', 'Herr', 'Firma']}
                  value={customer.gender}
                />
                {(showMissingFields && !(customer.gender))
                  ? <div>
                    &nbsp;&nbsp;
                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                  </div>
                  : null}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Vorname</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="first_name"
                  value={customer.first_name}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && (customer.gender !== 'Firma') && !(customer.first_name))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Nachname</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="last_name"
                  value={customer.last_name}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && (customer.gender !== 'Firma') && !(customer.last_name))}

                />
              </FormGroup>
            </Col>
          </Row>
          {(customer.gender === 'Firma')
            ? <Row>
              <Col>
                <FormGroup>
                  <Typography className='secondary-textcolor'>Firma</Typography>
                  <Input
                    id="input"
                    required={true}
                    style={{ boxShadow: 'none' }}
                    type="text"
                    name="company"
                    value={customer.company}
                    autoComplete="off"
                    onChange={onCustomerChange}
                    invalid={!!(showMissingFields && (customer.gender === 'Firma') && !(customer.company))}

                  />
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
            : null}
          <Row>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Straße Hausnr.</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="street_and_number"
                  value={customer.street_and_number}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !(customer.street_and_number))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>PLZ Ort</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="zip_and_city"
                  value={customer.zip_and_city}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !(customer.zip_and_city))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Bundesland:</Typography>
                <Input
                  id="input"
                  required={true}
                  style={{ boxShadow: 'none' }}
                  type="text"
                  name="province"
                  value={customer.province}
                  autoComplete="off"
                  onChange={onCustomerChange}
                  invalid={!!(showMissingFields && !(customer.province))}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <FormControlLabel className='secondary-textcolor' control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} checked={!varyingAddress} onChange={(e) => { setVaryingAddress(!e.target.checked) }} />} label="Anlagenanschrift und Betreiberanschrift stimmen überein." />
          </FormGroup>
          {varyingAddress
            ? <>
              <Typography fontSize='h6.fontSize' className='secondary-textcolor'>
                Abweichende Adresse der Anlage
              </Typography>
              <Row>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>Straße Hausnr.</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="street_and_number_project"
                      value={project.street_and_number_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !(project.street_and_number_project))}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>PLZ Ort</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="zip_and_city_project"
                      value={project.zip_and_city_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !(project.zip_and_city_project))}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Typography className='secondary-textcolor'>Bundesland:</Typography>
                    <Input
                      id="input"
                      required={true}
                      style={{ boxShadow: 'none' }}
                      type="text"
                      name="province_project"
                      value={project.province_project}
                      autoComplete="off"
                      onChange={onProjectChange}
                      invalid={!!(showMissingFields && !(project.province_project))}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
            : null}
          <hr className='secondary-textcolor' />
          <Typography fontSize='h6.fontSize' className='secondary-textcolor' display="inline">
            Bestandsheizung
          </Typography>
          <Row>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Hersteller Bestandsheizung:</Typography>
                <Input
                  id="input"
                  type="text"
                  name="manufacturer_current_heater"
                  value={defaultIfEmpty(project.manufacturer_current_heater)}
                  onChange={(e) => setProject(p => ({ ...p, manufacturer_current_heater: e.target.value }))}
                  required={true}
                  invalid={!!(showMissingFields && !project.manufacturer_current_heater)}
                  style={{ boxShadow: 'none' }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Typenbezeichnung Bestandsheizung:</Typography>
                <Input
                  id="input"
                  type="text"
                  name="typename_current_heater"
                  value={defaultIfEmpty(project.typename_current_heater)}
                  onChange={(e) => setProject(p => ({ ...p, typename_current_heater: e.target.value }))}
                  required={true}
                  invalid={!!(showMissingFields && !project.typename_current_heater)}
                  style={{ boxShadow: 'none' }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Typography className='secondary-textcolor'>Foto von Typenschild von Bestandheizung:</Typography>
                <UploadImages
                  images={project.labelcurrentheater_set}
                  addImage={(image) => setProject(p => ({ ...p, labelcurrentheater_set: [...p.labelcurrentheater_set, image] }))}
                  deleteImage={(image) => setProject(p => ({ ...p, labelcurrentheater_set: p.labelcurrentheater_set.filter(i => i.id !== image.id) }))}
                />
                {showMissingFields && !project.labelcurrentheater_set.length && <ErrorOutlineOutlinedIcon color='error' fontSize='large' />}
              </FormGroup>
            </Col>
          </Row>
        </Container >
      </>
    </Fragment >
  )
}

DataInputForm.propTypes = {
  project: projectPropType,
  setProject: PropTypes.func,
  customer: customerPropType,
  setCustomer: PropTypes.func,
  showMissingFields: PropTypes.bool,
  session: PropTypes.object
}
