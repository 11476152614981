import React, { useRef, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import { Button, IconButton, Tooltip } from '@mui/material'

import { invoicePropType, projectPropType } from '../../elements/PropTypes'
import { getCustomerName } from '../../elements/utils'
import { API_URL_SENDINVOICE } from '../../settings'
import CustomModal from '../shared/modal_utils/CustomModal'
import EmailModal from '../shared/modal_utils/EmailModal'
import { getInvoiceEmailSubject } from '../project_types/projectUtils'

export default function SendInvoiceModal ({ project, invoice, resetParent, session }) {
  const [feedbackIsOpen, setFeedbackIsOpen] = useState(false)
  const [emailIsOpen, setEmailIsOpen] = useState(false)

  const feedback = useRef(null)

  const submit = (subject, message) => {
    return axios.post(API_URL_SENDINVOICE, { invoice: invoice.id, project: project.id, mail: { subject, message } })
  }

  const skip = () => {
    return axios.post(API_URL_SENDINVOICE, { invoice: invoice.id, project: project.id })
  }

  const getOpenButton = () => {
    return (
      <Tooltip title='Senden' PopperProps={{ style: { zIndex: 9999 } }} >
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} onClick={() => { feedback.current = null; setFeedbackIsOpen(true) }}><ForwardToInboxOutlinedIcon className='secondary-textcolor' fontSize='large' /></IconButton>
      </Tooltip>
    )
  }

  const getConfirmFooter = (toggle) => {
    return <div>
            <Button
                variant="contained"
                color={'success'}
                onClick={() => { toggle(); feedback.current = true; setEmailIsOpen(true) }}>
                Ja
            </Button>
            &nbsp;
            &nbsp;
            <Button
                variant="contained"
                color={'error'}
                onClick={() => { toggle(); feedback.current = false; setEmailIsOpen(true) }}>
                Nein
            </Button>
        </div>
  }

  return (
        <>
            {getOpenButton()}
            <CustomModal size="medium" isOpen={feedbackIsOpen} setIsOpen={setFeedbackIsOpen} title={'Soll der Kunde in der E-Mail um Feedback gebeten werden?'} getFooter={getConfirmFooter} />
            <EmailModal
                customer={project.customer_obj}
                title={'Rechnung ' + invoice.id + ' versenden an ' + getCustomerName(project.customer_obj)}
                subject={getInvoiceEmailSubject(project, session)}
                getEmailMessage={() => axios.get(API_URL_SENDINVOICE, { params: { invoice: invoice.id } })
                  .then(res => {
                    let msg = res.data
                    const feedbackString = feedback.current ? '\nWir würden uns über ein Feedback von Ihnen freuen: https://g.page/r/CYXngcpoqjdxEBM/review.' : ''
                    msg = msg.replace('__FEEDBACK__', feedbackString)
                    return { data: msg }
                  })}
                submit={submit}
                skip={skip}
                resetParent={resetParent}
                getModalOpenButton={() => null}
                session={session}
                isOpen={emailIsOpen}
                setIsOpen={setEmailIsOpen}
            />
        </>
  )
}

SendInvoiceModal.propTypes = {
  project: projectPropType,
  invoice: invoicePropType,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
