import React, { useEffect, useState, useRef } from 'react'

import { PropTypes } from 'prop-types'
import axios from 'axios'
import { Spinner } from 'reactstrap'

import { Grid, Stack, Typography } from '@mui/material'

import LeadMap from './LeadMap'
import LoadingPage from '../../../elements/LoadingPage'
import { API_URL_AREA } from '../../../settings'
import { isString } from '../../../elements/utils'
import DropDown from '../../../elements/DropDown'
import { CustomButton } from '../../../elements/StyledElements'
import { leadTypePropType, userPropType } from '../../../elements/PropTypes'

export default function LeadAssignment ({ leadType, setLeadType, leadTypes, salesman, setSalesman, salesmen, session }) {
  const [areas, setAreas] = useState(null)

  // const [leadTypes, setLeadTypes] = useState(null)
  // const [leadType, setLeadType] = useState(null)

  // const [salesmen, setSalesmen] = useState(null)
  // const [salesman, setSalesman] = useState(null)

  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const leadTypeRef = useRef(leadType)
  useEffect(() => { leadTypeRef.current = leadType }, [leadType])

  useEffect(() => {
    // axios.get(API_URL_LEADTYPE).then(res => setLeadTypes(res.data))
    axios.get(API_URL_AREA).then(res => setAreas(res.data))
    // axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } })
    //   .then(res => {
    //     const salesmen = res.data.filter(user => ['salesmen', 'telemarketers', 'sales_managers'].includes(user.group_key)).map((s, idx) => ({ ...s, area_color: DISTINCTCOLORS[idx % DISTINCTCOLORS.length] }))
    //     setSalesmen(salesmen)
    //   })
  }, [])

  const submit = () => {
    const promises = []
    areas
      .filter(area => area.modified)
      .forEach(area => {
        promises.push(area.id ? axios.put(API_URL_AREA + area.id, area) : axios.post(API_URL_AREA, area))
      })
    return Promise.all(promises)
  }

  const onSubmit = () => {
    setLoadingElements({ ...loadingElements, submitError: false, inProgress: true })
    submit().then(() => {
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(() => { setLoadingElements({ ...loadingElements, submitError: true, inProgress: false }) })
  }

  if (areas === null || salesmen === null) return <LoadingPage />

  const leadTypeAreas = leadType ? areas.filter(area => area.lead_type === leadType) : []
  const setLeadTypeAreas = (_areas) => { setAreas(prevAreas => [...prevAreas.filter(a => a.lead_type !== leadTypeRef.current), ..._areas.map(a => ({ ...a, lead_type: leadTypeRef.current }))]) }

  return <>
    <Grid container>
      <Grid xs={10} sm={10} md={10} item justifyContent="flex-start">
        <Stack direction='row' spacing={2}>
          <DropDown
            onChange={setLeadType}
            options={leadTypes ? leadTypes.map(t => ({ label: t.name, value: t.id })) : []}
            value={leadType}
            text='Lead-Typ'
            sort={true}
            search={true}
            style={{ paddingBottom: '5px' }}
          />
          <DropDown
            onChange={setSalesman}
            options={salesmen ? salesmen.map(user => ({ label: user.name, value: user.id })) : []}
            value={salesman}
            text='Verkäufer'
            sort={true}
            search={true}
            style={{ paddingBottom: '5px' }}
          />
        </Stack>
      </Grid>
      <Grid xs={2} sm={2} md={2} item justifyContent="flex-end" display="grid">
        <Stack direction="row" spacing={2} style={{ justifyContent: 'flex-end' }}>
          {loadingElements.inProgress ? <Spinner color="light" size="sm" /> : null}
          &nbsp;
          {loadingElements.submitError
            ? <Typography color={'red'}>
              {isString(loadingElements.submitError) ? loadingElements.submitError : 'Anfrage konnte nicht an Server übermittelt werden!'}
            </Typography>
            : null}
          <CustomButton
            onClick={onSubmit}
            disabled={loadingElements.inProgress}
            style={{ marginBottom: '5px' }}
          >
            {`Speichern ${(loadingElements.inProgress) ? '...' : ''}`}
          </CustomButton>
        </Stack>
      </Grid>
    </Grid>
    <LeadMap areas={leadTypeAreas} setAreas={setLeadTypeAreas} salesmen={salesmen} salesmanId={salesman} disabled={leadType === null} />

  </>
}

LeadAssignment.propTypes = {
  leadType: PropTypes.number,
  setLeadType: PropTypes.func,
  leadTypes: PropTypes.arrayOf(leadTypePropType),
  salesman: PropTypes.number,
  setSalesman: PropTypes.func,
  salesmen: PropTypes.arrayOf(userPropType),
  session: PropTypes.object
}
