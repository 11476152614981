import React, { useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'

import { Typography } from '@mui/material'

import { projectPropType } from '../../elements/PropTypes'
import { CustomButton } from '../../elements/StyledElements'
import { sortById } from '../../elements/utils'
import OfferFormModal from './OfferFormModal'
import CustomModal from '../shared/modal_utils/CustomModal'

export default function NewPlanningOfferModal ({ project, getOpenButton, resetParent, session, additionalDetails, setPlanning }) {
  const [openOfferPlanning, setOpenOfferPlanning] = useState(null)
  const [planningIsOpen, setPlanningIsOpen] = useState(false)

  // const prevIs = usePreviousValue(isOpen)

  useEffect(() => {
    if (!planningIsOpen && openOfferPlanning) setOpenOfferPlanning(null)
  }, [planningIsOpen])

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton onClick={toggle}>Neues Angebot</CustomButton>
  }

  const getOfferModalOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Auswählen</CustomButton>
  }

  const plannings = project.planning_set.filter(planning => planning.is_finished)

  return (
    <CustomModal size="md" getOpenButton={_getOpenButton} title="Planung für Angebot wählen" isOpen={planningIsOpen} setIsOpen={setPlanningIsOpen}>
      <Table >
        <thead>
          <tr>
            <th className="align-middle"><Typography className='secondary-textcolor'>#</Typography> </th>
            <th className="align-middle"><Typography className='secondary-textcolor'>Erstellt am</Typography> </th>
            <th style={{ textAlign: 'center' }}><Typography className='secondary-textcolor'></Typography></th>
          </tr>
        </thead>
        <tbody>
          {!plannings || plannings.length <= 0
            ? (
              <tr>
                <td colSpan="4" align="center">
                  <Typography className='secondary-textcolor'> Keine Planung vorhanden </Typography>
                </td>
              </tr>
              )
            : (
                sortById(plannings).map(planning => (
                <tr key={planning.id}>
                  <td className="align-middle"><Typography className='secondary-textcolor'>{planning.id}</Typography></td>
                  <td className="align-middle"><Typography className='secondary-textcolor'>{planning.registration_date + '  ' + planning.registration_time.split('.')[0]}</Typography></td>
                  <td align="center">
                    <OfferFormModal
                      offer={null}
                      planningId={planning.id}
                      project={project}
                      resetParent={resetParent}
                      session={session}
                      isOpen={openOfferPlanning === planning.id}
                      setIsOpen={(isOpen) => {
                        setOpenOfferPlanning(isOpen ? planning.id : null)
                        if (setPlanning) setPlanning(planning)
                      }}
                      getOpenButton={getOfferModalOpenButton}
                      onToggle={(isOpen) => (!isOpen && planningIsOpen) ? setPlanningIsOpen(false) : null}
                      additionalDetails={additionalDetails}
                    />
                  </td>
                </tr>
                ))
              )}
        </tbody>
      </Table>
    </CustomModal>
  )
}

NewPlanningOfferModal.propTypes = {
  project: projectPropType,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object,
  additionalDetails: PropTypes.object,
  setPlanning: PropTypes.func
}
