import React from 'react'
import { PropTypes } from 'prop-types'
import CustomModal from '../shared/modal_utils/CustomModal'
import { Button } from '@mui/material'

export default function ConfirmationModal ({
  isOpenModal,
  setIsOpenModal,
  title,
  onConfirm,
  onCancel,
  confirmText = 'Ja',
  cancelText = 'Nein'
}) {
  const getConfirmFooter = (toggle) => {
    return <div>
      <Button
        variant="contained"
        color={'success'}
        onClick={() => { toggle(); onConfirm() }}>
        {confirmText}
      </Button>
      &nbsp;
      <Button
        variant="contained"
        color={'error'}
        onClick={() => { toggle(); onCancel() }}>
        {cancelText}
      </Button>
    </div>
  }

  return (
    <CustomModal size="medium" isOpen={isOpenModal} setIsOpen={setIsOpenModal} title={title} getFooter={getConfirmFooter} />
  )
}

ConfirmationModal.propTypes = {
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string
}
