import React, { useEffect, useMemo, useState } from 'react'
import CustomModal from '../shared/modal_utils/CustomModal'
import { PropTypes } from 'prop-types'
import { CustomButton } from '../../elements/StyledElements'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import './ag.css'
import { getHolidayEventsByYear, THIS_YEAR, getTotalDays } from '../../elements/utils'
import { Typography } from '@mui/material'
import DropDown from '../../elements/DropDown'
import axios from 'axios'
import { API_URL_GROUP, API_URL_TIMEMANAGEMENTEVENT } from '../../settings'
import moment from 'moment'
import ExcelExport from './ExcelExportTimeEvents'
import { isMobileOnly } from 'react-device-detect'
import LoadingPage from '../../elements/LoadingPage'

const EMPLOYEE_LEAVE_TYPE = Object.freeze({
  VACATION: 1,
  SICK: 2
})

const DAY_TYPE = Object.freeze({
  WEEKDAY: 'Wochentag',
  WEEKEND: 'Wochenende',
  VACATION: 'Urlaub',
  SICK: 'Krankheit',
  HOLIDAY: 'Feiertag'
})

const COLOR = Object.freeze({
  GRAY: '#A9A9A9',
  GREEN: '#8FBC8F',
  RED: '#F08080',
  WHITE: '#FFFFFF',
  BLUE: '#6495ED'
})

const getColorByDayType = (dayType) => {
  let color

  switch (dayType) {
    case DAY_TYPE.HOLIDAY:
    case DAY_TYPE.WEEKEND:
      color = COLOR.GRAY
      break
    case DAY_TYPE.VACATION:
      color = COLOR.GREEN
      break
    case DAY_TYPE.SICK:
      color = COLOR.RED
      break
    case DAY_TYPE.WEEKDAY:
      color = COLOR.WHITE
      break
    default:
      color = COLOR.WHITE
      break
  }

  return color
}

const DATE_SEPARATOR = '.'

const getListOfDatesInYear = (year) => {
  const startDate = new Date(year, 0, 1) // January 1st
  const endDate = new Date(year, 11, 31) // December 31st

  const currentDate = startDate
  const datesList = []

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    const day = currentDate.getDate().toString().padStart(2, '0')
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const col = {
      field: `${day}${DATE_SEPARATOR}${month}`,
      headerName: `${day}${DATE_SEPARATOR}${month}`,
      width: 75,
      lockPosition: 'left',
      resizable: false,
      sortable: false,
      cellStyle: (params) => {
        const val = params.value

        return {
          backgroundColor: getColorByDayType(val)
        }
      },
      valueFormatter: () => '',
      headerClass: 'vc-date-headers'
    }

    datesList.push(col)
    currentDate.setDate(currentDate.getDate() + 1) // Move to the next day
  }

  return datesList
}

const getColDefs = (year) => {
  return [
    { field: 'employee_name', headerName: 'Mitarbeiter', pinned: 'left', cellStyle: { backgroundColor: COLOR.BLUE } },
    {
      field: 'employee_group',
      headerName: 'Gruppe',
      ...(!isMobileOnly && { pinned: 'left' }),
      sortable: false
    },
    {
      field: 'vacation_count',
      headerName: 'Urlaub',
      ...(!isMobileOnly && { pinned: 'left', width: 100 }),
      cellStyle: { backgroundColor: COLOR.GREEN },
      headerClass: 'vc-vacation-header'
    },
    {
      field: 'sick_count',
      headerName: 'Krankheit',
      ...(!isMobileOnly && { pinned: 'left', width: 110 }),
      cellStyle: { backgroundColor: COLOR.RED },
      headerClass: 'vc-sick-header'
    },
    ...getListOfDatesInYear(year)
  ]
}

const getHolidays = (year) => {
  const holidayEvents = getHolidayEventsByYear(year)
  const holidays = []

  for (const holiday of holidayEvents) {
    const startDate = new Date(holiday.start)
    const endDate = new Date(holiday.end)

    // eslint-disable-next-line no-unmodified-loop-condition
    while (startDate <= endDate) {
      const day = startDate.getDate().toString().padStart(2, '0')
      const month = (startDate.getMonth() + 1).toString().padStart(2, '0')

      const date = `${day}${DATE_SEPARATOR}${month}`

      holidays.push(date)

      startDate.setDate(startDate.getDate() + 1)
    }
  }

  return holidays
}

const getDayType = (day, date, vacationDays, sickDays, holidays) => {
  if (date.getDay() === 6 || date.getDay() === 0) {
    return DAY_TYPE.WEEKEND
  } else if (holidays.includes(day)) {
    return DAY_TYPE.HOLIDAY
  } else if (vacationDays.includes(day)) {
    return DAY_TYPE.VACATION
  } else if (sickDays.includes(day)) {
    return DAY_TYPE.SICK
  }

  return DAY_TYPE.WEEKDAY
}

export default function VacationCalendar ({ isOpen, setIsOpen, employees }) {
  const [year, setYear] = useState(new Date().getFullYear())
  const [_employees, _setEmployees] = useState([])
  const [employeeTimeEvents, setEmployeeTimeEvents] = useState([])
  const [rowData, setRowData] = useState([])
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false
  })

  const colDefs = useMemo(() => getColDefs(year), [year])
  const holidays = useMemo(() => getHolidays(year), [year])

  useEffect(() => {
    if (!isOpen) return
    const getEmployeeTimeEvents = async () => {
      setLoadingElements({ ...loadingElements, inProgress: true })
      try {
        const res = await axios.get(API_URL_TIMEMANAGEMENTEVENT, { params: { year } })
        setEmployeeTimeEvents(res.data)
      } finally {
        setLoadingElements({ ...loadingElements, inProgress: false })
      }
    }

    getEmployeeTimeEvents().then(() => {})
  }, [isOpen, year])

  useEffect(() => {
    if (!isOpen) return

    const getGroups = async () => {
      const res = await axios.get(API_URL_GROUP)
      setGroups(res.data)
    }

    getGroups().then(() => {})
  }, [isOpen])

  useEffect(() => {
    if (!group) {
      _setEmployees(employees)
      return
    }

    const employeeInGroup = employees.filter(e => e.group === group)

    _setEmployees(employeeInGroup)
  }, [group])

  useEffect(() => {
    if (!isOpen) return

    setRowData(getRowData())
  }, [isOpen, year, employeeTimeEvents, _employees])

  const getVacationAndSickDaysPerEmployee = (employeeId) => {
    let vacationDays = []
    let sickDays = []

    for (const timeEvent of employeeTimeEvents.filter(te => te.user === employeeId)) {
      const dates = []
      const startDate = new Date(timeEvent.start_date)
      const endDate = new Date(timeEvent.end_date)

      // eslint-disable-next-line no-unmodified-loop-condition
      while (startDate <= endDate) {
        const day = startDate.getDate().toString().padStart(2, '0')
        const month = (startDate.getMonth() + 1).toString().padStart(2, '0')

        const date = `${day}${DATE_SEPARATOR}${month}`

        dates.push(date)

        startDate.setDate(startDate.getDate() + 1)
      }

      if (timeEvent.event_type === EMPLOYEE_LEAVE_TYPE.VACATION) {
        vacationDays = [...vacationDays, ...dates]
      } else if (timeEvent.event_type === EMPLOYEE_LEAVE_TYPE.SICK) {
        sickDays = [...sickDays, ...dates]
      }
    }

    return [vacationDays, sickDays]
  }

  const getLeaveCount = (employeeId, leaveType) => {
    const timeEvents = employeeTimeEvents.filter(t => (t.user === employeeId) &&
      (t.event_type === leaveType))

    let count = 0
    timeEvents.forEach(timeEvent => {
      const startDate = new Date(timeEvent.start_date)
      const endDate = new Date(timeEvent.end_date)

      const totalDays = getTotalDays(startDate, endDate, year)
      count += totalDays
    })

    return count
  }
  const getRowData = () => {
    const rowData = []

    for (const employee of _employees) {
      const employeeId = employee.id
      if (!employee || !employeeId) continue

      const employeeName = employee.name || 'Kein Name'
      const employeeGroup = employee.group_obj ? employee.group_obj.name : 'Keine Gruppe'
      const vacationCount = getLeaveCount(employeeId, EMPLOYEE_LEAVE_TYPE.VACATION)
      const sickCount = getLeaveCount(employeeId, EMPLOYEE_LEAVE_TYPE.SICK)
      const [vacationDays, sickDays] = getVacationAndSickDaysPerEmployee(employeeId)

      let row = {
        employee_name: employeeName,
        employee_group: employeeGroup,
        vacation_count: vacationCount,
        sick_count: sickCount
      }

      for (const col of colDefs.slice(4)) {
        const day = col.field
        const date = moment(day + DATE_SEPARATOR + year, `DD${DATE_SEPARATOR}MM${DATE_SEPARATOR}YYYY`).toDate()
        row = { ...row, [col.field]: getDayType(day, date, vacationDays, sickDays, holidays) }
      }

      rowData.push(row)
    }

    return rowData
  }

  const exportToExcel = () => {
    const rows = []
    const colors = []

    const header = []
    const headerColors = []
    colDefs.forEach(colDef => {
      const colKey = colDef.field
      const col = ('headerName' in colDef) ? colDef.headerName : colKey
      const color = (colDef.cellStyle ? (colDef.cellStyle.backgroundColor || COLOR.BLUE) : COLOR.WHITE).substring(1)

      rowData.forEach((row, i) => {
        if (rows.length < (i + 1)) {
          rows.push([])
          colors.push([])
        }
        if (['employee_name', 'employee_group', 'vacation_count', 'sick_count'].includes(colKey)) {
          rows[i].push(row[colKey])
          colors[i].push(color)
        } else {
          rows[i].push('')
          colors[i].push(getColorByDayType(row[colKey]).substring(1))
        }
      })
      header.push(col)
      headerColors.push(color)
    })
    rows.unshift(header)
    colors.unshift(headerColors)

    return {
      data: rows, colors
    }
  }

  const getOpenButton = (toggle) => {
    return <CustomButton onClick={toggle}>Urlaubskalender</CustomButton>
  }

  return (
        <CustomModal size='fullscreen' isOpen={isOpen} setIsOpen={setIsOpen} title={'Urlaubskalender'} getFooter={null} getOpenButton={getOpenButton}>
            {loadingElements.inProgress && <LoadingPage />}
            <div style={{ display: 'flex', gap: '2vw', alignSelf: 'center', flexDirection: isMobileOnly ? 'column' : 'row' }}>
              <div>
                <Typography className='secondary-textcolor'>Jahr wählen:</Typography>
                <DropDown
                  onChange={setYear}
                  options={Array.from({ length: ((THIS_YEAR + 1) - 2022) + 1 }, (v, i) => 2022 + i)}
                  value={year}
                  text='Jahr'
                />
              </div>
              <div>
                <Typography className='secondary-textcolor'>Nutzergruppe wählen:</Typography>
                <DropDown
                  search={true}
                  onChange={(value) => setGroup(value)}
                  options={groups.map(g => ({ label: g.name, value: g.id }))}
                  value={group}
                  test={'Nutzergruppe'}
                />
              </div>
              <div>
                <Typography className='secondary-textcolor'>Urlaubskalender als Exceldatei exportieren:</Typography>
                <ExcelExport
                  exporter={exportToExcel}
                  fileName={'Urlaubskalender_' + year}
                  sheetName='Urlaubskalender'
                />
              </div>
            </div>
            <div
                className="ag-theme-balham-auto-dark"
                style={{ height: '90vh', margin: '1vh 1vh 1vh 0' }}
            >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    localeText={ { noRowsToShow: 'Keine Daten zum Anzeigen' } }
                    suppressFieldDotNotation={true}
                    autoSizeStrategy={isMobileOnly ? { type: 'fitCellContents' } : null}
                    rowBuffer={50}
                    suppressRowHoverHighlight={true}
                />
            </div>
        </CustomModal>
  )
}

VacationCalendar.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  employees: PropTypes.object
}
